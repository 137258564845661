import {
  getAdvisorFullNameColumn,
  getCounterSignedPurchaseOfferDateColumn,
  getCurrentStepColumn,
  getFurnitureAndWorkPriceColumn,
  getRealMasteosCommissionPriceColumn,
  getRealNetSellerPriceColumn,
  getStatusColumn,
  getTransactionIdColumn,
  getCustomerLinkColumn,
} from 'features/transactions/list/getTransactionColumns';
import { Translate } from 'react-admin';
import { GridColDef } from 'types/mui.types';
import { TransactionListItemFormatted } from 'types/transaction.types';

export const getRealEstateTransactionColumns = (
  translate: Translate
): GridColDef<TransactionListItemFormatted>[] => {
  return [
    getStatusColumn(translate),
    getCurrentStepColumn(translate),
    getCustomerLinkColumn(),
    getCounterSignedPurchaseOfferDateColumn(),
    getRealNetSellerPriceColumn(),
    getRealMasteosCommissionPriceColumn(),
    getFurnitureAndWorkPriceColumn(),
    getAdvisorFullNameColumn(),
    getTransactionIdColumn(),
  ];
};
