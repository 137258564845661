import { styled, IconButton, IconButtonProps } from '@mui/material';
import { Close } from '@mui/icons-material';

interface ButtonContainerProps {
  $onDark: boolean;
}

const ButtonContainer = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== '$onDark',
})<ButtonContainerProps>`
  color: ${(props) => (props.$onDark ? 'white' : 'black')};
`;

interface CloseButtonProps extends Omit<IconButtonProps, 'onClick'> {
  onDark?: boolean;
  onClose: () => void;
}

export const CloseButton: React.FC<CloseButtonProps> = (props) => {
  const { onDark = false, onClose, ...rest } = props;
  return (
    <ButtonContainer onClick={onClose} size="small" $onDark={onDark} {...rest}>
      <Close />
    </ButtonContainer>
  );
};
