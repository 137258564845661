import { GridValueFormatterParams } from '@mui/x-data-grid';
import { Translate } from 'react-admin';

export const formatColumnValueHunter = (
  param: GridValueFormatterParams
): string => param?.value?.fullName || '';

export const formatColumnValueType =
  (translate: Translate) =>
  (param: GridValueFormatterParams): string => {
    return param?.value
      ? translate(`realEstate.type.${param.value.toLowerCase()}`)
      : '';
  };

export const formatColumnValueTypeLocation =
  (translate: Translate) =>
  (param: GridValueFormatterParams): string => {
    return param?.value
      ? translate(`realEstate.location.type.${param.value.toLowerCase()}`)
      : '';
  };
