import { MenuItem, Select, Typography } from '@mui/material';
import {
  CountryIso2,
  FlagImage,
  defaultCountries,
  parseCountry,
} from 'react-international-phone';

import {
  flagImageOptionStyle,
  flagImageSelectStyle,
  menuPropsStyle,
  phoneCountrySelectSx,
} from './phone-input.styles';

type PhoneInputCountrySelectorProps = {
  value: CountryIso2;
  onChange: (country: CountryIso2) => void;
};

export const PhoneInputCountrySelector: React.FC<
  PhoneInputCountrySelectorProps
> = ({ value: country, onChange }) => {
  return (
    <Select
      MenuProps={{
        style: menuPropsStyle,
        transformOrigin: {
          horizontal: 'left',
          vertical: 'top',
        },
      }}
      sx={phoneCountrySelectSx}
      value={country}
      onChange={(e) => onChange(e.target.value as CountryIso2)}
      renderValue={(value) => (
        <FlagImage iso2={value} style={flagImageSelectStyle} />
      )}
    >
      {defaultCountries.map((c) => {
        const parsedCountry = parseCountry(c);
        return (
          <MenuItem key={parsedCountry.iso2} value={parsedCountry.iso2}>
            <FlagImage iso2={parsedCountry.iso2} style={flagImageOptionStyle} />
            <Typography marginRight="8px">{parsedCountry.name}</Typography>
            <Typography color="gray">+{parsedCountry.dialCode}</Typography>
          </MenuItem>
        );
      })}
    </Select>
  );
};
