import { HttpError, Options } from 'react-admin';
import { HttpClientResponse } from 'utils/http-client-auth/httpClientAuth';

export const fetchFile = (
  url: string,
  options: Options = {}
): Promise<HttpClientResponse> => {
  const requestHeaders = new Headers();
  if (options.user && options.user.authenticated && options.user.token) {
    requestHeaders.set('Authorization', options.user.token);
  }

  return fetch(url, { ...options, headers: requestHeaders })
    .then(async (response) => {
      const { status, headers, statusText } = response;
      if (status < 200 || status >= 300) {
        return Promise.reject(new HttpError(statusText, status));
      }

      const blob = await response.blob();
      const file = new Blob([blob], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      return Promise.resolve({
        body: fileURL,
        headers: headers,
        json: '',
        status: status,
      });
    })
    .catch((error) => {
      return Promise.reject(new HttpError(error, 500));
    });
};

export const fetchAndDownloadFile = (
  url: string,
  options: Options = {}
): Promise<HttpClientResponse> => {
  const requestHeaders = new Headers();
  if (options.user && options.user.authenticated && options.user.token) {
    requestHeaders.set('Authorization', options.user.token);
  }

  return fetch(url, { ...options, headers: requestHeaders })
    .then((response) =>
      response.blob().then((blob) => ({
        body: blob,
        headers: response.headers,
        status: response.status,
        statusText: response.statusText,
      }))
    )
    .then(({ status, statusText, headers, body }) => {
      if (status < 200 || status >= 300) {
        return Promise.reject(new HttpError(statusText, status));
      }

      const fileName =
        headers
          .get('Content-Disposition')
          ?.split(';')[1]
          .split('=')[1]
          .replaceAll('"', '') || 'file.pdf';

      const linkUrl = window.URL.createObjectURL(body);
      const link = document.createElement('a');
      link.href = linkUrl;
      link.download = fileName;

      link.click();
      link.remove();

      return Promise.resolve({
        body: fileName,
        headers,
        json: '',
        status,
      });
    });
};
