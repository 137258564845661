import { Theme } from '@mui/material';
import { GridValueFormatterParams } from '@mui/x-data-grid';
import { Translate } from 'react-admin';
import { CustomerTransactionStatus } from 'types/customer.types';
import { ChipListProps } from 'ui/molecules/chips-list/ChipList';
import { formatChipListValue } from 'utils/columns/valueFormatters.utils';

const TRANSACTION_STATUS_TO_CHIP_LIST_PROPS = {
  [CustomerTransactionStatus.NONE]: {
    label: `customer.transactionStatus.${CustomerTransactionStatus.NONE}`,
  },
  [CustomerTransactionStatus.ONGOING]: {
    color: 'green',
    label: `customer.transactionStatus.${CustomerTransactionStatus.ONGOING}`,
  },
  [CustomerTransactionStatus.INTEGRITY_ERROR]: {
    color: 'terminal',
  },
};

export const formatTransactionStatusColumnValue =
  (translate: Translate, theme: Theme) =>
  (param: GridValueFormatterParams): ChipListProps['list'] => {
    return formatChipListValue(
      translate,
      theme,
      TRANSACTION_STATUS_TO_CHIP_LIST_PROPS
    )({ ...param, value: [param.value] });
  };
