import { Filters } from 'components/filters/Filters';
import { yupResolver } from '@hookform/resolvers/yup';
import { PositioningFilter } from 'types/positioning.types';

import { FiltersBody } from './FiltersBody';
import { positioiningFilterFormValidator } from './PositioningFilter.validator';

export const resetFilters: PositioningFilter = {
  customerIds: [],
  fromDate: null,
  hunter: [],
  ownedBy: [],
  toDate: null,
};

export const PositioningsFilters: React.FC = () => {
  return (
    <Filters
      defaultValues={resetFilters}
      resolver={yupResolver(positioiningFilterFormValidator)}
    >
      <FiltersBody />
    </Filters>
  );
};
