import {
  AutocompleteInput,
  ReferenceInput,
  useGetIdentity,
  useTranslate,
} from 'react-admin';
import { AccordionForm } from 'ui/molecules/accordion-form/AccordionForm';
import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import { formatFullName } from 'utils/strings/strings.utils';
import { RoleName } from 'types/role.types';

import { RealEstateEditAccordeonFormProps } from '../EditFormContent.types';
import { sourceToLabelMap } from '../EditFormContent.utils';

export const formatOptionText = (param: Record<string, string>): string => {
  if (!param || !param?.firstName || !param?.lastName) {
    return '';
  }
  return `${formatFullName(param.firstName, param.lastName)}`;
};

const filterSearchToQuery = (search: string) => ({ search });

export const ContactForm: React.FC<RealEstateEditAccordeonFormProps> = ({
  defaultExpanded,
}) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate('realEstate.form.contact')}
    >
      <Fieldset>
        <ReferenceInput
          source="contact._id"
          label="customers"
          reference="users/filter"
          filter={{
            roles: [RoleName.Hunter, RoleName.HunterManager, RoleName.Mandator],
          }}
          perPage={100}
        >
          <AutocompleteInput
            data-testid="contact-input"
            variant="outlined"
            label={sourceToLabelMap.get('contact._id')}
            optionText={formatOptionText}
            defaultValue={identity?.id}
            optionValue="id"
            filterToQuery={filterSearchToQuery}
            fullWidth
            disableClearable
          />
        </ReferenceInput>
      </Fieldset>
    </AccordionForm>
  );
};
