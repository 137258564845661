import jwtDecode from 'jwt-decode';
import { JWTDecoded } from 'types/jwt.types';

import { ACCESS_TOKEN_STORAGE_KEY } from './tokenStorage';

export function decodeToken({
  access_token: token,
}: {
  access_token: string;
}): JWTDecoded {
  const tokenDecoded = jwtDecode<JWTDecoded>(token);

  localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token);

  return tokenDecoded;
}
