import { ChipField, Translate } from 'react-admin';
import { CellType, GridColDef } from 'types/mui.types';
import { formatMandateStatusColumnValue } from 'utils/columns/formatMandateStatusColumnValue.utils';
import { Theme } from '@mui/material';

export const getExternalCustomersColumns = (
  translate: Translate,
  theme: Theme
): GridColDef[] => {
  return [
    {
      field: 'fullName',
      headerName: 'customer.fullName',
      sortable: false,
      width: 200,
    },
    {
      field: 'phoneNumber',
      headerName: 'shared.phoneNumber',
      width: 150,
    },
    {
      field: 'email',
      headerName: 'shared.email',
      width: 250,
    },
    {
      cellType: CellType.TAG_LIST,
      field: 'managementMandateStatus',
      headerName: 'customer.mandateStatusLabel',
      sortable: false,
      valueFormatter: formatMandateStatusColumnValue(translate, theme),
      width: 250,
    },
    {
      field: 'isMissingInfo',
      headerName: 'customer.hasMissingInfo.label',
      renderCell: (params) => {
        return (
          <ChipField
            color={params.value ? 'error' : 'success'}
            source="isMissingInfo"
            record={{
              isMissingInfo: translate(
                params.value
                  ? 'customer.hasMissingInfo.yes'
                  : 'customer.hasMissingInfo.no'
              ),
            }}
          />
        );
      },
      sortable: false,
      width: 200,
    },
    {
      cellType: CellType.PRICE,
      field: 'downPayment',
      headerName: 'customer.downPayment',
      width: 150,
    },
    {
      cellType: CellType.PRICE,
      field: 'maxBudget',
      headerName: 'customer.searchSettings.budgetMax',
      width: 150,
    },
  ];
};
