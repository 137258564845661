import { styled } from '@mui/material';
import MUICard from '@mui/material/Card';

export const Card = styled(MUICard)`
  margin: auto;
  padding: ${({ theme }) => theme.spacing(1)};
  width: 368px;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 100%;
  }
`;
