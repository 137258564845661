import React, { useMemo } from 'react';

import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import { SelectInput, TextInput, useInput, useTranslate } from 'react-admin';
import { AccordionForm } from 'ui/molecules/accordion-form/AccordionForm';
import { TextArea } from 'ui/atoms/textarea/TextArea';
import { ImageInput } from 'components/image-input/ImageInput';
import { SelectArrayInput } from 'ui/organisms/select-array-input/SelectArrayInput';
import { InputValue } from 'features/realEstates/utils/inputValue.type';

import {
  DPE_DATA,
  ESTIMATED_WORKS_DATA,
  GES_DATA,
} from 'constants/real-estates';

import { RealEstateEditAccordeonFormProps } from '../EditFormContent.types';
import { sourceToLabelMap } from '../EditFormContent.utils';

enum RenovationDetail {
  conception = 'conception',
  demolitionAndRemoval = 'demolitionAndRemoval',
  electricityAndVentilation = 'electricityAndVentilation',
  exteriorOfTheBuilding = 'exteriorOfTheBuilding',
  exteriorWoodFurnishings = 'exteriorWoodFurnishings',
  facadeAndExteriorInsulation = 'facadeAndExteriorInsulation',
  flooring = 'flooring',
  heating = 'heating',
  interiorJoinery = 'interiorJoinery',
  kitchenAndHouseholdAppliances = 'kitchenAndHouseholdAppliances',
  masteosEngineering = 'masteosEngineering',
  paint = 'paint',
  partitioning = 'partitioning',
  roofing = 'roofing',
  sanitaryAndPlumbing = 'sanitaryAndPlumbing',
  siteManagement = 'siteManagement',
  tilesAndEarthenware = 'tilesAndEarthenware',
  variousWorks = 'variousWorks',
}

export const RenovationForm: React.FC<RealEstateEditAccordeonFormProps> = ({
  defaultExpanded,
  canSeeDpe,
  canSeeGes,
  overrideDpeValues,
  disabled = false,
}) => {
  const translate = useTranslate();
  const renovationDetailsChoices = useMemo(() => {
    return Object.values(RenovationDetail)
      .sort()
      .map((value) => ({
        id: value,
        name: translate(`renovationDetails.${value}`),
      }));
  }, [translate]);
  const {
    field: { value: countryCode },
  }: InputValue<string> = useInput({ source: 'address.countryCode' });
  const isFrenchRealEstate = countryCode === 'FR';

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate('realEstate.form.renovation')}
    >
      <Fieldset>
        {canSeeDpe ? (
          <SelectInput
            disabled={disabled}
            margin="none"
            variant="outlined"
            choices={overrideDpeValues || DPE_DATA}
            translateChoice={false}
            source="dpe"
            label={sourceToLabelMap.get('dpe')}
          />
        ) : (
          <React.Fragment />
        )}

        {canSeeGes ? (
          <SelectInput
            disabled={disabled}
            margin="none"
            variant="outlined"
            source="ges"
            label={sourceToLabelMap.get('ges')}
            choices={GES_DATA}
            translateChoice={false}
          />
        ) : (
          <React.Fragment />
        )}
        <TextInput
          disabled={disabled}
          margin="none"
          variant="outlined"
          source="renovationToolUrl"
          label={sourceToLabelMap.get('renovationToolUrl')}
        />
        <SelectInput
          disabled={disabled}
          choices={ESTIMATED_WORKS_DATA}
          source="estimatedWorks"
          label={sourceToLabelMap.get('estimatedWorks')}
          margin="none"
          variant="outlined"
        />
      </Fieldset>
      <Fieldset title={translate('realEstate.fieldset.renovationDetails')} />
      {!!isFrenchRealEstate && (
        <SelectArrayInput
          source="renovationDetails"
          choices={renovationDetailsChoices}
          label="realEstate.fieldset.scheduledRenovations"
          fullWidth
          margin="none"
          variant="outlined"
        />
      )}
      <TextArea
        fullWidth
        disabled={disabled}
        source="renovation.description"
        label={sourceToLabelMap.get('renovation.description')}
      />
      <Fieldset title={translate('realEstate.fieldset.imagesPlans')} />
      <ImageInput disabled={disabled} source="plans.images" />
    </AccordionForm>
  );
};
