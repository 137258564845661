import { Box, Typography } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';
import { HeliosCustomerOption } from 'types/positioning.types';
import { formatFullName } from 'utils/strings/strings.utils';
import Close from '@mui/icons-material/Close';
import { DocumentCategory } from 'types/document.enum';

export interface InvestorOptionProps {
  showPositionings?: boolean;
  showMissingDocuments?: boolean;
  missingDocumentTypes?: DocumentCategory[];
}

export const InvestorOption: React.FC<InvestorOptionProps> = ({
  showPositionings,
  showMissingDocuments,
  missingDocumentTypes,
}) => {
  const translate = useTranslate();
  const record = useRecordContext<HeliosCustomerOption>();
  const { fullName, firstName, lastName, email, meta } = record;

  const Name = (
    <div>
      {fullName || formatFullName(firstName, lastName)}
      <br />
      <Typography variant="subtitle2">{email}</Typography>
    </div>
  );

  if (!meta) {
    return <Box>{Name}</Box>;
  }

  const missingDocuments = missingDocumentTypes
    ? meta.missingDocuments.filter((doc) => missingDocumentTypes.includes(doc))
    : meta.missingDocuments;

  return (
    <Box>
      {Name}
      {!!showPositionings &&
        (meta.hasMaxPositioning ? (
          <Typography
            variant="caption"
            color="red"
            data-testid="CustomerOption-has-max-positionning"
          >
            {translate('positioning.userlist.error.hasMaxPositioning')}
          </Typography>
        ) : (
          <Typography variant="caption" color="green">
            {translate(
              meta.positioningCount > 0
                ? 'positioning.userlist.recommendationCount'
                : 'positioning.userlist.noRecommendation',
              { count: meta.positioningCount }
            )}
          </Typography>
        ))}
      <Box>
        {!!showMissingDocuments &&
          missingDocuments.map((missingDocument) => (
            <Box
              display="flex"
              flexDirection="row"
              lineHeight="normal"
              key={missingDocument}
            >
              <Typography variant="caption">
                <Box display="flex" alignItems="center" gap="5px">
                  <Box display="flex" component="span" lineHeight="inherit">
                    <Close
                      color="error"
                      fontSize="inherit"
                      style={{ marginTop: '-3px' }}
                    />
                  </Box>
                  <Box component="span">
                    {translate(`shared.document.${missingDocument}`)}
                  </Box>
                </Box>
              </Typography>
            </Box>
          ))}
      </Box>
    </Box>
  );
};
