import { NumberField, NumberFieldProps, useLocaleState } from 'react-admin';
import { withLabel } from 'ui/hoc/with-label/withLabel';

export const PriceField = ({
  price,
  ...rest
}: {
  price?: number;
} & NumberFieldProps): React.ReactElement => {
  const [locale] = useLocaleState();

  return (
    <NumberField
      locales={locale}
      options={{
        currency: 'EUR',
        minimumFractionDigits: 0,
        style: 'currency',
        unitDisplay: 'short',
      }}
      source="price"
      {...(price && { record: { price: Math.round(price) } })}
      {...rest}
    />
  );
};

export const PriceFieldWithLabel = withLabel(PriceField);
