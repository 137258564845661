import { isArray } from 'lodash';
import { CustomerListFormatted, CustomerListItem } from 'types/customer.types';
import { formatFullName } from 'utils/strings/strings.utils';

export const formatCustomersList = (
  customersList: CustomerListItem[] = []
): CustomerListFormatted[] => {
  return isArray(customersList)
    ? customersList.map(
        ({
          firstName,
          lastName,
          advisor,
          search,
          account,
          utm,
          broughtBy,
          owner,
          organization,
          ...rest
        }: CustomerListItem) => ({
          ...rest,
          'account.affiliateEmail': account?.affiliateEmail,
          'account.affiliateId': account?.affiliateId,
          'advisor.fullName': advisor
            ? formatFullName(advisor.firstName, advisor.lastName)
            : '',
          'broughtBy.fullName': broughtBy
            ? formatFullName(broughtBy.firstName, broughtBy.lastName)
            : '',
          fullName: formatFullName(firstName, lastName),
          organizationName: organization.name,
          'owner.fullName': owner
            ? formatFullName(owner.firstName, owner.lastName)
            : '',
          'searchSettings.budgetMax': search.budgetMax,
          'searchSettings.budgetMin': search.budgetMin,
          'searchSettings.flatSharing': search.sharedProperty,
          'searchSettings.groundFloor': search.propertyOnTheGroundFloor,
          'searchSettings.propertyKind': search.propertyKind,
          'searchSettings.propertyRented': search.propertyRented,
          'searchSettings.propertyRoomCount': search.propertyRoomCount,
          'searchSettings.regions': search.regions,
          'searchSettings.renovationNeeded': search.hasNeedRenovation,
          'searchSettings.timing': search.timing,
          'utm.campaign': utm.campaign,
          'utm.content': utm.content,
          'utm.medium': utm.medium,
          'utm.source': utm.source,
          'utm.term': utm.term,
        })
      )
    : [];
};
