import { styled } from '@mui/material';

import { Container as ErrorStepBottomBarContainer } from './ErrorStepBottomBar.styles';

export const Container = styled(ErrorStepBottomBarContainer)`
  background-color: ${({ theme }) => theme.palette.error.dark};

  .MuiIconButton-root {
    background-color: ${({ theme }) => theme.palette.error.main};
    border-radius: 0;
    color: white;
  }

  &.valid {
    background-color: ${({ theme }) => theme.palette.success.dark};

    .MuiIconButton-root {
      transition: none !important;
      background-color: ${({ theme }) => theme.palette.success.main};
    }
  }
`;
