import React from 'react';

import { useFormContext } from 'react-hook-form';

import { getFlattenErrors } from './ErrorStepBottomBar.utils';
import { ErrorStepBottomBarNoRemainingErrors } from './ErrorStepBottomBarNoRemainingErrors';
import { ErrorStepBottomBarRemainingErrors } from './ErrorStepBottomBarRemainingErrors';
import { ErrorStepBottomBarProps } from './ErrorStepBottomBar.types';

export const ErrorStepBottomBar: React.FC<ErrorStepBottomBarProps> = ({
  visible,
  sourceToLabelMap,
}) => {
  const {
    formState: { errors, isSubmitting },
  } = useFormContext();

  const flattenErrors = getFlattenErrors(errors);
  const flattenErrorsLength = flattenErrors.length;

  if (!visible || isSubmitting) {
    return null;
  }

  return flattenErrorsLength > 0 ? (
    <ErrorStepBottomBarRemainingErrors
      flattenErrors={flattenErrors}
      sourceToLabelMap={sourceToLabelMap}
    />
  ) : (
    <ErrorStepBottomBarNoRemainingErrors />
  );
};
