// TODO: update any
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isGroundFloor } from 'features/realEstates/utils/groundFloor';
import isArray from 'lodash/isArray';
import { RealEstateItemView } from 'types/realEstate.types';
import { formatFullName } from 'utils/strings/strings.utils';

export const formatRealEstateList = (
  realEstateList: RealEstateItemView[] = []
): any[] => {
  return isArray(realEstateList)
    ? realEstateList.map(
        ({
          address,
          organization,
          financialModel,
          hunter,
          floor,
          ownedBy,
          ...rest
        }) => ({
          ...rest,
          'address.city': address?.city,
          'address.street': address?.street,
          'financialModel.agencySellerCommission':
            financialModel?.agencySellerCommission,
          'financialModel.evaluationRate': financialModel?.evaluationRate
            ? parseFloat((financialModel.evaluationRate * 100).toFixed(2))
            : null,
          'financialModel.grossReturn': financialModel?.grossReturn,
          'financialModel.netReturn': financialModel?.netReturn,
          'financialModel.netSeller': financialModel?.netSeller,
          'financialModel.projectAmount': financialModel?.projectAmount,
          'financialModel.salesPrice':
            (financialModel?.netSeller || 0) +
            (financialModel?.agencySellerCommission || 0),
          floor,
          groundFloor: isGroundFloor(floor),
          'hunter.fullName': {
            fullName: formatFullName(
              hunter?.firstName ?? '',
              hunter?.lastName ?? ''
            ),
            lastName: hunter?.lastName,
          },
          'mandator.email': ownedBy?.email,
          'mandator.fullName': formatFullName(
            ownedBy?.firstName ?? '',
            ownedBy?.lastName ?? ''
          ),
          'mandator.phoneNumber': ownedBy?.phoneNumber,
          organizationName: organization?.name,
        })
      )
    : [];
};
