import React from 'react';

import { LinearProgress, Stack, styled } from '@mui/material';
import {
  DataGridProps,
  GridColumnVisibilityModel,
  GridFeatureModeConstant,
  GridRow,
} from '@mui/x-data-grid';
import { useDataGridLanguage } from 'hooks/use-datagrid-language/useDataGridLanguage';
import { TableToolbar } from 'ui/molecules/table-toolbar/TableToolbar';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { SortPayload, useListContext, useStore } from 'react-admin';
import { GridRowProps } from '@mui/x-data-grid/components/GridRow';
import {
  ClientSidePagination,
  DataGridPagination,
} from 'ui/molecules/data-grid-pagination/DataGridPagination';

import { DataGrid } from './DataGrid';

const RowLink = styled('a')`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const DataGridRow = (
  props: React.HTMLAttributes<HTMLDivElement> &
    GridRowProps & { target: 'edit' | 'show' }
) => (
  <RowLink
    href={`${process.env.PUBLIC_URL}/${props.resource}/${props.rowId}${
      props.target === 'show' ? '/show' : ''
    }`}
    data-testid="row-link"
  >
    <GridRow {...props} />
  </RowLink>
);

export const DataTable: React.FC<
  DataGridProps & {
    disableToolBar?: boolean;
    rowAsLink?: boolean;
    linkTarget?: 'edit' | 'show';
    noRowsMessage?: string;
  }
> = ({
  disableToolBar = false,
  rows,
  columns,
  onCellClick,
  rowAsLink = false,
  linkTarget = 'edit',
  density = 'compact',
  noRowsMessage,
  paginationMode = GridFeatureModeConstant.server,
  ...rest
}) => {
  const localeText = useDataGridLanguage();

  const { setSort, isFetching, total, page, setPage, perPage, resource } =
    useListContext();

  const onChangePage = (
    _: React.ChangeEvent<unknown>,
    nextPage: number
  ): void => {
    setPage(nextPage);
  };

  const Pagination = (
    <DataGridPagination
      currentPage={page}
      onChangePage={onChangePage}
      totalRecords={total}
      perPage={perPage}
    />
  );

  // Returns the initial sort if exist, otherwise returns default sort by id.
  const getInitialSort = (): SortPayload => {
    if (
      rest.initialState?.sorting?.sortModel &&
      rest.initialState?.sorting.sortModel.length !== 0
    ) {
      const [{ field, sort: order }] = rest.initialState.sorting.sortModel;

      return { field, order: order || 'ASC' } as SortPayload;
    }
    return { field: 'id', order: 'ASC' };
  };

  const handleSortModelChange = ([sortItem]: GridSortModel) => {
    if (sortItem) {
      setSort({
        field: sortItem?.field,
        order: sortItem.sort?.toUpperCase() || '',
      } as SortPayload);
    } else {
      // Restore default sort if no sort is provided
      setSort(getInitialSort());
    }
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useStore(
    `${resource}-columnVisibilityModel`,
    {}
  );

  const handleColumnVisibilityModelChange = (
    newModel: GridColumnVisibilityModel
  ) => {
    setColumnVisibilityModel(newModel);
  };

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={perPage}
      density={density}
      onCellClick={onCellClick}
      experimentalFeatures={{ newEditingApi: true }}
      pagination
      disableColumnFilter
      disableColumnMenu
      disableVirtualization
      loading={isFetching}
      localeText={localeText}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
      components={{
        LoadingOverlay: LinearProgress,
        Pagination:
          paginationMode === GridFeatureModeConstant.server
            ? () => Pagination
            : ClientSidePagination,
        ...(rowAsLink && {
          Row: (props) => (
            <DataGridRow {...props} target={linkTarget} resource={resource} />
          ),
        }),
        ...(disableToolBar ? {} : { Toolbar: TableToolbar }),
        ...(noRowsMessage && {
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              {noRowsMessage}
            </Stack>
          ),
        }),
      }}
      sortingMode="server"
      onSortModelChange={handleSortModelChange}
      {...rest}
    />
  );
};
