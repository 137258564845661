import { isArray } from 'lodash';
import { Dpe } from 'types/realEstate.enum';
import { Realization } from 'types/realization.types';

type FormattedRealization = Partial<Realization> & {
  'address.city': string;
  'afterRenovation.dpe': Dpe | null;
  'beforeRenovation.dpe': Dpe | null;
  'finances.projectAmount': number;
};

export const formatRealizationsList = (
  realizationsList: Realization[]
): FormattedRealization[] => {
  return isArray(realizationsList)
    ? realizationsList.map(
        ({
          address,
          finances,
          afterRenovation,
          beforeRenovation,
          ...rest
        }) => ({
          ...rest,
          'address.city': address.city,
          'afterRenovation.dpe': afterRenovation.dpe,
          'beforeRenovation.dpe': beforeRenovation.dpe,
          'finances.projectAmount': finances.projectAmount,
          'finances.rentingDelay': finances.rentingDelay,
        })
      )
    : [];
};
