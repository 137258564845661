import React from 'react';

import { SelectInput, TextInput, useTranslate } from 'react-admin';
import { DateInput } from 'components/custom-input/date-input/DateInput';

import { Container } from './AddCustomerFormFields.style';

const LANGUAGE_CHOICES = [
  { id: 'fr', name: 'Français (France)' },
  { id: 'en', name: 'English' },
];

export const AddCustomerFormFields: React.FC = () => {
  const translate = useTranslate();

  return (
    <Container>
      <TextInput
        margin="none"
        variant="outlined"
        label="user.input.firstname"
        source="firstName"
      />
      <TextInput
        margin="none"
        variant="outlined"
        label="user.input.lastname"
        source="lastName"
      />
      <TextInput
        margin="none"
        variant="outlined"
        label="user.input.email"
        source="email"
      />
      <SelectInput
        source="lang"
        label="shared.language"
        choices={LANGUAGE_CHOICES}
      />
      <DateInput
        label={translate('user.input.firstAppointmentDate')}
        source="firstAppointmentDate"
      />
    </Container>
  );
};
