import * as React from 'react';

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useTranslate } from 'react-admin';
import { useGenerateTitle } from 'features/realEstates/details/edit/hooks/useGenerateTitle';

import { URI } from 'constants/uri';

type PropertyBreadCrumbsProps = {
  propertyId: string;
  extraParts?: { label: string; path?: string }[];
};

export const PropertyBreadCrumbs: React.FC<PropertyBreadCrumbsProps> = ({
  propertyId,
  extraParts = [],
}) => {
  const translate = useTranslate();
  const title = useGenerateTitle();

  const propertyUrl = `${URI.realEstates}/${propertyId}`;
  const extras = extraParts?.map((extra, index) => {
    const bcKey = `bc-${index}`;
    return extra.path ? (
      <Typography variant="body2" key={bcKey}>
        <Link underline="hover" color="inherit" href={extra.path}>
          {translate(extra.label)}
        </Link>
      </Typography>
    ) : (
      <Typography variant="body2" key="">
        {translate(extra.label)}
      </Typography>
    );
  });

  return (
    <Breadcrumbs aria-label="breadcrumb" separator=">">
      <Typography variant="body2" key="title">
        <Link underline="hover" color="inherit" href={URI.realEstates}>
          {translate('realEstate.Breadcrumb.propertylist')}
        </Link>
      </Typography>
      <Typography variant="body2" key="edition">
        <Link
          underline="hover"
          color="inherit"
          href={propertyUrl}
          data-testid="property-breadcrumbs-link"
        >
          {translate('realEstate.Breadcrumb.edition')} &quot;{title}&quot;
        </Link>
      </Typography>
      {extraParts ? extras : null}
    </Breadcrumbs>
  );
};
