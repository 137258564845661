import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';
import { useGetIdentity, useRecordContext } from 'react-admin';
import { User } from 'types/user.types';
import { PermissionName, Scope } from 'types/permission.types';

export type UserPermissionsType = {
  canArchiveUser: boolean;
  canUpdateUser: boolean;
  canOnlyCreateExternalUser: boolean;
};

export const useUserPermissions = (): UserPermissionsType => {
  const user = useRecordContext<User>();
  const { identity } = useGetIdentity();
  const { hasPermission, getScope } = useHeliosPermissions();

  const canUpdateUser = (() => {
    if (!hasPermission(PermissionName.UpdateUser)) {
      return false;
    }

    if (!user) {
      return true;
    }

    switch (getScope(PermissionName.UpdateUser)) {
      case Scope.Organization: {
        if (!user.organization?.id || !identity?.organization) {
          return false;
        }
        return user.organization?.id === identity.organization;
      }
      case Scope.All:
        return true;
      default:
        return false;
    }
  })();

  const canUpdateExternal = hasPermission(PermissionName.UpdateUserExternal);
  const canOnlyCreateExternalUser = !canUpdateUser && canUpdateExternal;
  const canArchiveUser = hasPermission(PermissionName.ArchiveUserExternal);

  return {
    canArchiveUser,
    canOnlyCreateExternalUser,
    canUpdateUser,
  };
};
