import { useCallback, useContext, useEffect, useMemo } from 'react';

import { useMediaQuery } from '@mui/material';
import { RealEstate, RealEstateAction } from 'types/realEstate.types';
import { Theme } from '@mui/system';
import { EditControllerResult, SaveContextValue, useStore } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { RealEstateModalKey } from 'features/realEstates/constants';
import { useRealEstatePermissions } from 'features/realEstates/hooks/useRealEstatePermissions';
import { PublishConfirmationModalProps } from 'features/realEstates/components/modals/PublishConfirmationModal';
import { RealEstateEditAction } from 'features/realEstates/components/modals/constants';
import { trackPublishRealEstate } from 'libs/segment/segment.trackings';

import {
  ActionContext,
  ActionContextProps,
} from '../context/ActionContextProvider';
import { ArchiveMutationReturn } from './useArchiveAction';
import { DownloadBankPdfMutationReturn } from './useDownloadBankPdfAction';
import { GeneratePurchaseOfferMutationReturn } from './useGeneratePurchaseOfferAction';
import {
  AskPublishMutationReturn,
  DoPublishMutationReturn,
} from './usePublishActions';
import { GeneratePurchaseOfferModalStore } from '../components/action-modal/GeneratePurchaseOfferModal';
import { TransferRealEstateMutationReturn } from './useTransferRealEstateAction';
import { handleTransform } from '../utils/transformData';

export interface RealEstateActions {
  dropdownActions: RealEstateAction[];
  actionList: RealEstateAction[];
  archive: ArchiveMutationReturn;
  askPublish: AskPublishMutationReturn;
  doPublish: DoPublishMutationReturn;
  downloadBankPdf: DownloadBankPdfMutationReturn;
  transferRealEstate: TransferRealEstateMutationReturn;
  generatePurchaseOffer: GeneratePurchaseOfferMutationReturn;
  askPublishCallback?: () => void;
  doPublishCallback?: () => void;
}

/**
 * Provider all actions from ActionContextProvider.
 * @param formContext the current form context. Needed in order to dispatch server errors back to the form context.
 * @param editContext
 * @param saveContext
 */
export const useRealEstateActions = (
  formContext?: ReturnType<typeof useFormContext>,
  editContext?: EditControllerResult<RealEstate>,
  saveContext?: SaveContextValue
): RealEstateActions => {
  const {
    archive,
    askPublish,
    doPublish,
    downloadBankPdf,
    downloadPropertyPdf,
    transferRealEstate,
    generatePurchaseOffer,
    setFormContext,
    uploadCounterSignedOffer,
  } = useContext<ActionContextProps>(ActionContext);

  const { canAddOrDuplicate } = useRealEstatePermissions();

  /** Set the form context to the parent ActionContext provider */
  useEffect(() => {
    if (formContext) {
      setFormContext(formContext);
    }
  }, [formContext, setFormContext]);

  const [, displayArchiveModal] = useStore(RealEstateModalKey.ARCHIVE, false);
  const [, displayDuplicateModal] = useStore(RealEstateModalKey.ADD, false);
  const [, displayDownloadBankPdfModal] = useStore(
    RealEstateModalKey.DOWNLOAD_BANK_PDF,
    false
  );

  const [, displayPublishConfirmModal] =
    useStore<PublishConfirmationModalProps>(
      RealEstateModalKey.PUBLISH_CONFIRMATION,
      { isOpen: false }
    );

  const [, displayTransferModal] = useStore(
    RealEstateModalKey.TRANSFER_REAL_ESTATE,
    false
  );

  const [, displayPurchaseOfferModal] =
    useStore<GeneratePurchaseOfferModalStore>(
      RealEstateModalKey.GENERATE_PURCHASE_OFFER,
      {
        isOpen: false,
      }
    );
  const [, displayUploadCounterSignedOfferModal] = useStore(
    RealEstateModalKey.UPLOAD_COUNTER_SIGNED_OFFER,
    { isOpen: false }
  );
  const isSmall = useMediaQuery(
    (theme: Theme) => `(max-width:${theme.breakpoints.values.lg}px)`
  );

  const formValues = formContext?.getValues() as RealEstate;

  const publishRealEstate = useCallback(() => {
    trackPublishRealEstate();
    doPublish.mutate();
  }, [doPublish]);

  const resetErrors = useCallback(() => {
    askPublish.reset();
    doPublish.reset();
    formContext?.clearErrors();
  }, [askPublish, doPublish, formContext]);

  const askPublishCallback = formContext?.handleSubmit(() => {
    resetErrors();
    if (editContext && editContext?.save) {
      editContext.save(formValues, {
        onSuccess: () => askPublish.mutate(),
        transform: () => handleTransform(formValues),
      });
    }
  });

  const doPublishCallback = formContext?.handleSubmit(() => {
    resetErrors();
    if (editContext && editContext?.save) {
      editContext.save(formValues, {
        onSuccess: publishRealEstate,
        transform: () => handleTransform(formValues),
      });
    }
  });

  const doDisplayConfirmModal = !!formValues?.url3D;

  const actionList: RealEstateAction[] = useMemo(
    () => [
      {
        callback: () => {
          displayTransferModal(true);
        },
        loading: transferRealEstate.isLoading,
        name: 'realEstate.action.transfer.label',
        show: !!transferRealEstate.isAllowed,
        slug: 'transferRealEstate',
      },
      {
        callback: () =>
          doDisplayConfirmModal
            ? displayPublishConfirmModal({
                action: RealEstateEditAction.AskPublish,
                isOpen: true,
              })
            : askPublishCallback && askPublishCallback(),
        loading: askPublish.isLoading,
        name: 'realEstate.action.askPublish.label',
        show: !!askPublish.isAllowed && isSmall,
        slug: 'askPublish',
      },
      {
        callback: () =>
          doDisplayConfirmModal
            ? displayPublishConfirmModal({
                action: RealEstateEditAction.Publish,
                isOpen: true,
              })
            : doPublishCallback && doPublishCallback(),
        loading: doPublish.isLoading,
        name: 'realEstate.action.doPublish.label',
        show: !!doPublish.isAllowed && isSmall,
        slug: 'doPublish',
      },
      {
        callback: () => displayArchiveModal(true),
        loading: archive.isLoading,
        name: 'realEstate.action.archive.label',
        show: !!archive.isAllowed,
        slug: 'archive',
      },
      {
        callback: () => displayDuplicateModal(true),
        loading: !!saveContext?.saving,
        name: 'realEstate.action.duplicate.label',
        show: canAddOrDuplicate,
        slug: 'duplicate',
      },
      {
        callback: () => displayDownloadBankPdfModal(true),
        loading: downloadBankPdf.isLoading,
        name: 'realEstate.action.downloadBankPdf.label',
        show: !!downloadBankPdf.isAllowed,
        slug: 'downloadBankPdf',
      },
      {
        callback: downloadPropertyPdf.mutate,
        loading: downloadPropertyPdf.isLoading,
        name: 'realEstate.action.downloadPropertyPdf.label',
        show: !!downloadPropertyPdf.isAllowed,
        slug: 'downloadPropertyPdf',
      },
      {
        callback: () =>
          displayPurchaseOfferModal({
            isOpen: true,
          }),
        loading: generatePurchaseOffer.isLoading,
        name: 'realEstate.action.generatePurchaseOffer.label',
        show: !!generatePurchaseOffer.isAllowed,
        slug: 'generatePurchaseOffer',
      },
      {
        callback: () => displayUploadCounterSignedOfferModal({ isOpen: true }),
        loading: uploadCounterSignedOffer.isLoading,
        name: 'realEstate.action.uploadCounterSignedOffer.label',
        show: !!uploadCounterSignedOffer.isAllowed,
        slug: 'uploadCounterSignedOffer',
      },
    ],
    [
      askPublish.isLoading,
      askPublish.isAllowed,
      isSmall,
      doPublish.isLoading,
      doPublish.isAllowed,
      archive.isLoading,
      archive.isAllowed,
      canAddOrDuplicate,
      downloadBankPdf.isLoading,
      downloadBankPdf.isAllowed,
      downloadPropertyPdf,
      generatePurchaseOffer.isLoading,
      generatePurchaseOffer.isAllowed,
      uploadCounterSignedOffer.isLoading,
      uploadCounterSignedOffer.isAllowed,
      displayTransferModal,
      doDisplayConfirmModal,
      displayPublishConfirmModal,
      askPublishCallback,
      doPublishCallback,
      displayArchiveModal,
      displayDuplicateModal,
      displayDownloadBankPdfModal,
      displayPurchaseOfferModal,
      displayUploadCounterSignedOfferModal,
      saveContext,
      transferRealEstate,
    ]
  );

  const dropdownActions = useMemo(() => {
    return actionList.filter((action: RealEstateAction) => action.show);
  }, [actionList]);

  return {
    actionList,
    archive,
    askPublish,
    askPublishCallback,
    doPublish,
    doPublishCallback,
    downloadBankPdf,
    dropdownActions,
    generatePurchaseOffer,
    transferRealEstate,
  };
};
