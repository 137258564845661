import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import {
  SelectInput,
  useInput,
  useTranslate,
  NumberInput,
  NullableBooleanInput,
} from 'react-admin';
import { FormGroup } from '@mui/material';
import { CheckboxInput } from 'components/checkbox-input/CheckboxInput';
import { AccordionForm } from 'ui/molecules/accordion-form/AccordionForm';
import { styled } from '@mui/material/styles';
import { DateInput } from 'components/custom-input/date-input/DateInput';
import { Disclaimer } from 'ui/molecules/disclaimer/Disclaimer';
import { DisclaimerContainer } from 'ui/molecules/disclaimer/Disclaimer.styles';
import { SellTarget } from 'types/realEstate.types';

import {
  LOCATION_TYPE_DATA,
  PROPERTY_KIND_DATA,
  RENTAL_SITUATIONS_DATA,
  RENTING_KINDS,
} from 'constants/real-estates';

import { RealEstateEditAccordeonFormProps } from '../EditFormContent.types';
import { sourceToLabelMap } from '../EditFormContent.utils';

const NullableInput = styled(NullableBooleanInput)`
  &.RaNullableBooleanInput-input {
    width: 100%;
  }
`;

export const ProjectForm: React.FC<RealEstateEditAccordeonFormProps> = ({
  defaultExpanded,
  disabled = false,
}) => {
  const translate = useTranslate();

  const {
    field: { value: exclusiveEndDateCheckboxValue },
  } = useInput({
    source: 'exclusiveEndDateCheckbox',
  });
  const {
    field: { value: locationTypeValue },
  } = useInput({
    source: 'locationType',
  });

  const shouldDisplayColocationDisclaimer =
    locationTypeValue === RENTING_KINDS.COLOCATION;

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate('realEstate.form.project')}
    >
      <Fieldset>
        <SelectInput
          disabled={disabled}
          source="type"
          choices={PROPERTY_KIND_DATA}
          label={sourceToLabelMap.get('type')}
          margin="none"
          variant="outlined"
        />
        <NumberInput
          disabled={disabled}
          source="surface"
          label={sourceToLabelMap.get('surface')}
          margin="none"
          variant="outlined"
        />
        <NumberInput
          disabled={disabled}
          source="roomNumber"
          label={sourceToLabelMap.get('roomNumber')}
          margin="none"
          variant="outlined"
          step={1}
        />
        <NumberInput
          disabled={disabled}
          source="floor"
          label={sourceToLabelMap.get('floor')}
          margin="none"
          variant="outlined"
        />
        <SelectInput
          disabled={disabled}
          source="locationType"
          choices={LOCATION_TYPE_DATA}
          label={sourceToLabelMap.get('locationType')}
          margin="none"
          variant="outlined"
        />
        <SelectInput
          disabled={disabled}
          source="rentalSituation"
          choices={RENTAL_SITUATIONS_DATA}
          label={sourceToLabelMap.get('rentalSituation')}
          margin="none"
          variant="outlined"
        />
        {!!shouldDisplayColocationDisclaimer && (
          <DisclaimerContainer>
            <Disclaimer
              type="warning"
              title="disclaimer.colocation.title"
              content="disclaimer.colocation.content"
            />
          </DisclaimerContainer>
        )}
        <NumberInput
          disabled={disabled}
          source="totalLots"
          label={sourceToLabelMap.get('totalLots')}
          margin="none"
          variant="outlined"
        />
        <NullableInput
          disabled={disabled}
          label={sourceToLabelMap.get('isFurnished')}
          source="isFurnished"
          falseLabel="realEstate.isFurnished.notFurnished"
          trueLabel="realEstate.isFurnished.furnished"
          margin="none"
          variant="outlined"
        />
        <FormGroup row>
          <CheckboxInput
            disabled={disabled}
            label={sourceToLabelMap.get('hasCoOwnership')}
            source="hasCoOwnership"
          />
        </FormGroup>
      </Fieldset>
      <Fieldset title={translate('realEstate.fieldset.agency')}>
        <CheckboxInput
          disabled={disabled}
          label={translate(
            sourceToLabelMap.get('exclusiveEndDateCheckbox') ?? ''
          )}
          source="exclusiveEndDateCheckbox"
        />
        {!!exclusiveEndDateCheckboxValue && (
          <DateInput
            disabled={disabled}
            source="exclusiveEndDate"
            label={translate(sourceToLabelMap.get('exclusiveEndDate') ?? '')}
            helperText={translate('realEstate.form.exclusiveDateEnd.helperTxt')}
          />
        )}
      </Fieldset>
      <Fieldset title={translate('realEstate.fieldset.sellTarget')}>
        <CheckboxInput
          label={translate(sourceToLabelMap.get('sellTarget') ?? '')}
          source="sellTarget"
          parse={(value) => (value ? SellTarget.CGP : SellTarget.Masteos)}
          format={(value) => value === SellTarget.CGP}
        />
      </Fieldset>
    </AccordionForm>
  );
};
