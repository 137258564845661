import { useMemo } from 'react';

import {
  ListBase,
  useListController,
  useStore,
  useTranslate,
} from 'react-admin';
import { DataTable } from 'components/data-table/DataTable';
import { getColumns } from 'utils/columns/getColumns.utils';
import { FilteredListLayout } from 'ui/layouts/filtered-list-layout/FilteredListLayout';
import { useTheme } from '@mui/material';
import { SearchBar } from 'ui/molecules/search-bar/SearchBar';
import {
  ExternalCustomerListItem,
  ExternalCustomerModalKey,
} from 'types/external-customer.types';

import { AddExternalCustomerModalStore } from './components/modals/add-customer/AddExternalCustomerModal.types';
import { getExternalCustomersColumns } from './utils/getExternalCustomersColumns.utils';
import { useExternalCustomerPermissions } from '../hooks/useExternalCustomerPermissions';
import { AddExternalCustomerModal } from './components/modals/add-customer/AddExternalCustomerModal';

const PER_PAGE = 20;

export const ExternalCustomersList: React.FC = () => {
  const translate = useTranslate();
  const theme = useTheme();
  const { canCreateExternalCustomer, canManageAllCustomers } =
    useExternalCustomerPermissions();

  const [_addCustomerModalStore, setAddCustomerModalStore] =
    useStore<AddExternalCustomerModalStore>(
      ExternalCustomerModalKey.CREATE_EXTERNAL_CUSTOMER,
      {
        isOpen: false,
      }
    );

  const columnsList = getExternalCustomersColumns(translate, theme);

  const {
    data: externalCustomers = [],
    total,
    setFilters,
    filterValues,
  } = useListController<ExternalCustomerListItem>({
    perPage: PER_PAGE,
  });

  const columns = useMemo(
    () => (externalCustomers ? getColumns(columnsList, translate) : []),
    [externalCustomers, translate, columnsList]
  );

  const table = useMemo(
    () => <DataTable columns={columns} rows={externalCustomers} rowAsLink />,
    [columns, externalCustomers]
  );

  const searchBar = useMemo(
    () => (
      <SearchBar
        title={translate('externalCustomer.search.title')}
        placeholder={translate('externalCustomer.search.placeholder')}
        setFilters={setFilters}
        filterValues={filterValues}
      />
    ),
    [filterValues, setFilters, translate]
  );

  const openAddExternalCustomerModal = () => {
    setAddCustomerModalStore({
      isOpen: true,
      title: translate('customer.createCustomer'),
    });
  };

  return (
    <ListBase perPage={PER_PAGE}>
      <FilteredListLayout
        main={table}
        searchBar={searchBar}
        title={translate(
          canManageAllCustomers
            ? 'externalCustomer.listAdmin'
            : 'externalCustomer.list',
          { count: total ?? 0 }
        )}
        {...(canCreateExternalCustomer && {
          addTitle: translate('externalCustomer.add'),
          onAddClick: openAddExternalCustomerModal,
        })}
      />
      <AddExternalCustomerModal />
    </ListBase>
  );
};
