import { AutocompleteRenderGroupParams, Box, Typography } from '@mui/material';
import {
  AutocompleteArrayInput,
  ReferenceArrayInput,
  useTranslate,
} from 'react-admin';
import { filterSearchToQuery } from 'utils/filters/filters';

export const RegionsAutocomplete: React.FC = () => {
  const translate = useTranslate();

  return (
    <ReferenceArrayInput
      label="Regions"
      source="regions"
      reference="real-estates/regions"
      perPage={100}
    >
      <AutocompleteArrayInput
        groupBy={(option: { id: string; countryCode: string }) =>
          option.countryCode || ''
        }
        renderGroup={(params: AutocompleteRenderGroupParams) => (
          <li key={params.key}>
            <Box marginLeft={1}>
              <Typography color="GrayText">
                {translate(`shared.country.${params.group}`)}
              </Typography>
            </Box>

            <Box>{params.children}</Box>
          </li>
        )}
        variant="outlined"
        debounce={500}
        label={translate('shared.address.region')}
        filterToQuery={filterSearchToQuery}
        optionText={(param: { regionCode: string }) =>
          translate(`shared.region.${param.regionCode}`)
        }
        noOptionsText={translate('shared.filters.noOptions')}
      />
    </ReferenceArrayInput>
  );
};
