import { useMemo } from 'react';

import { DataTable } from 'components/data-table/DataTable';
import {
  ListBase,
  SortPayload,
  useListController,
  useStore,
  useTranslate,
} from 'react-admin';
import { FilteredListLayout } from 'ui/layouts/filtered-list-layout/FilteredListLayout';
import { getColumns } from 'utils/columns/getColumns.utils';
import { SearchBar } from 'ui/molecules/search-bar/SearchBar';
import { GridSortDirection } from '@mui/x-data-grid';
import { CustomerModalKey } from 'types/customer.types';
import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';
import { PermissionName } from 'types/permission.types';

import { useGetCustomersColumns } from './hooks/useGetCustomersColumns';
import { formatCustomersList } from './utils/formatCustomersList.utils';
import { AddCustomerModal } from './components/modals/add-customer/AddCustomerModal';
import { AddCustomerModalStore } from './components/modals/add-customer/AddCustomerModal.types';
import { useCustomerPermissions } from '../hooks/useCustomerPermissions';

const PER_PAGE = 20;
const INITIAL_SORT: SortPayload = { field: 'createdAt', order: 'DESC' };

export const CustomersList: React.FC = () => {
  const translate = useTranslate();
  const columnsList = useGetCustomersColumns();
  const { hasPermission } = useHeliosPermissions();
  const { canCreateCustomer, canViewCustomer } = useCustomerPermissions();

  const {
    data: customers,
    total,
    setFilters,
    filterValues,
  } = useListController({
    perPage: PER_PAGE,
    sort: INITIAL_SORT,
  });

  const [_addCustomerModalStore, setAddCustomerModalStore] =
    useStore<AddCustomerModalStore>(CustomerModalKey.CREATE_CUSTOMER, {
      isOpen: false,
    });

  const columns = useMemo(
    () => (customers ? getColumns(columnsList, translate) : []),
    [customers, translate, columnsList]
  );

  const rows = useMemo(
    () => (customers ? formatCustomersList(customers) : []),
    [customers]
  );

  const searchBar = useMemo(
    () => (
      <SearchBar
        title={translate('realEstate.search.title')}
        placeholder={translate('customer.search.placeholder')}
        setFilters={setFilters}
        filterValues={filterValues}
      />
    ),
    [filterValues, setFilters, translate]
  );

  const table = useMemo(
    () => (
      <DataTable
        columns={columns}
        rows={rows}
        rowAsLink={canViewCustomer}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: INITIAL_SORT.field,
                sort: INITIAL_SORT.order as GridSortDirection,
              },
            ],
          },
        }}
      />
    ),
    [canViewCustomer, columns, rows]
  );

  const addCustomerTitle = hasPermission(PermissionName.InviteCustomer)
    ? 'customer.inviteCustomer'
    : 'customer.createCustomer';

  const openAddCustomerModal = () => {
    setAddCustomerModalStore({
      isOpen: true,
      title: translate(addCustomerTitle),
    });
  };

  return (
    <ListBase perPage={PER_PAGE} sort={INITIAL_SORT}>
      <FilteredListLayout
        main={table}
        searchBar={searchBar}
        title={translate('customer.list.title', { count: total ?? 0 })}
        {...(canCreateCustomer && {
          addTitle: translate(addCustomerTitle),
          onAddClick: openAddCustomerModal,
        })}
      />
      <AddCustomerModal />
    </ListBase>
  );
};
