import { useContext, useMemo } from 'react';

import { useFormContext } from 'react-hook-form';
import { Realization, RealizationAction } from 'types/realization.types';
import { RealizationStatus } from 'types/realization.enum';
import { useNavigate } from 'react-router-dom';

import { UpdateStatusMutationReturn } from './useUpdateStatusAction';
import {
  ActionContext,
  ActionContextProps,
} from '../context/ActionContextProvider';

export interface RealizationActions {
  dropdownActions: RealizationAction[];
  actionList: RealizationAction[];
  updateStatusAction: UpdateStatusMutationReturn;
}

export const useRealizationActions = (
  formContext?: ReturnType<typeof useFormContext>
): RealizationActions => {
  const { updateStatusAction } = useContext<ActionContextProps>(ActionContext);
  const navigate = useNavigate();

  const formValues = formContext?.getValues() as Realization;

  const actionList: RealizationAction[] = useMemo(
    () => [
      {
        callback: () =>
          updateStatusAction.mutate({ status: RealizationStatus.ARCHIVED }),
        loading: updateStatusAction.isLoading,
        name: 'realization.action.archive.label',
        show: !!(
          updateStatusAction.isAllowed &&
          formValues?.status !== RealizationStatus.ARCHIVED
        ),
        slug: 'archive',
      },
      {
        callback: () =>
          updateStatusAction.mutate({
            status: RealizationStatus.PUBLISHED,
          }),
        loading: updateStatusAction.isLoading,
        name: 'realization.action.publish.label',
        show: !!(
          updateStatusAction.isAllowed &&
          formValues?.status !== RealizationStatus.PUBLISHED
        ),
        slug: 'publish',
      },
      {
        callback: () =>
          updateStatusAction.mutate({ status: RealizationStatus.DRAFT }),
        loading: updateStatusAction.isLoading,
        name: 'realization.action.MakeInDraft.label',
        show: !!(
          updateStatusAction.isAllowed &&
          formValues?.status !== RealizationStatus.DRAFT
        ),
        slug: 'makeInDraft',
      },
      {
        callback: () => {
          navigate(`/real-estates/${formValues?.realEstateId}`);
        },
        loading: updateStatusAction.isLoading,
        name: 'realization.action.redirectToRealEstate.label',
        show: true,
        slug: 'redirectToRealEstate',
      },
    ],
    [updateStatusAction, formValues, navigate]
  );

  const dropdownActions = useMemo(() => {
    return actionList.filter((action: RealizationAction) => action.show);
  }, [actionList]);

  return {
    actionList,
    dropdownActions,
    updateStatusAction,
  };
};
