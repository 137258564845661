import * as React from 'react';
import { useCallback } from 'react';

import clsx from 'clsx';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormGroup, { FormGroupProps } from '@mui/material/FormGroup';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { FieldTitle, useInput } from 'ra-core';
import {
  CommonInputProps,
  InputHelperText,
  sanitizeInputRestProps,
} from 'react-admin';
import { red } from '@mui/material/colors';

export type CheckboxInputProps = CommonInputProps &
  CheckboxProps &
  Omit<FormGroupProps, 'defaultValue' | 'onChange' | 'onBlur' | 'onFocus'>;

export const CheckboxInput: React.FC<CheckboxInputProps> = (props) => {
  const {
    className,
    row = false,
    defaultValue = false,
    format,
    label,
    fullWidth,
    helperText,
    onBlur,
    onChange,
    onFocus,
    disabled,
    parse,
    resource,
    source,
    validate,
    sx,
    ...rest
  } = props;
  const {
    id,
    field,
    isRequired,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
  } = useInput({
    defaultValue,
    format,
    onBlur,
    onChange,
    parse,
    resource,
    source,
    type: 'checkbox',
    validate,
    ...rest,
  });

  const handleChange = useCallback(
    (event: React.ChangeEvent) => {
      field.onChange(event);
      // Ensure field is considered as touched
      field.onBlur();
    },
    [field]
  );

  return (
    <FormGroup
      className={clsx('ra-input', `ra-input-${source}`, className)}
      row={row}
      sx={sx}
    >
      <FormControlLabel
        control={
          <Checkbox
            id={id}
            name={field.name}
            color="primary"
            onChange={handleChange}
            onFocus={onFocus}
            checked={field.value}
            {...sanitizeInputRestProps(rest)}
            {...(!!(isTouched || isSubmitted) && error?.message
              ? {
                  sx: {
                    color: red[700],
                  },
                }
              : {})}
            disabled={disabled}
            inputRef={field.ref}
          />
        }
        label={
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        }
      />
      <FormHelperText error={!!(isTouched || isSubmitted) && invalid}>
        <InputHelperText
          touched={isTouched || isSubmitted}
          error={error?.message}
          helperText={helperText}
        />
      </FormHelperText>
    </FormGroup>
  );
};
