import { Dpe, RealEstateType } from './realEstate.enum';
import { RealizationStatus } from './realization.enum';

export type Realization = {
  address: {
    city: string;
  };
  afterRenovation: {
    dpe: Dpe | null;
  };
  beforeRenovation: {
    dpe: Dpe | null;
  };
  finances: {
    projectAmount: number;
    rentingDelay: number;
  };
  id: string;
  realEstateId: string;
  status: RealizationStatus;
  title: string;
  type?: RealEstateType;
  condominium?: boolean;
};

export interface RealizationAction {
  slug: 'archive' | 'publish' | 'makeInDraft' | 'redirectToRealEstate';
  name: string;
  show: boolean;
  loading: boolean;
  callback?: (data?: unknown) => void;
}

export interface RealizationFieldValidationError {
  path: string;
  message: string;
  type: string;
}

export type RealizationValidationException = {
  context: {
    cause: {
      errors: string[];
      inner: RealizationFieldValidationError[];
    };
  };
};

export enum RealizationModalKey {
  CREATE_REALIZATION = 'realization.create.create_realization_modal',
}
