import { Theme } from '@mui/material';
import { GridValueFormatterParams } from '@mui/x-data-grid';
import { Translate } from 'react-admin';
import { RealEstateType } from 'types/realEstate.enum';
import { ChipListProps } from 'ui/molecules/chips-list/ChipList';
import { formatChipListValue } from 'utils/columns/valueFormatters.utils';

const REAL_ESTATE_TYPE_TO_CHIP_LIST_PROPS = {
  [RealEstateType.Apartment]: {
    color: 'cyan',
    label: `realEstate.type.${RealEstateType.Apartment.toLowerCase()}`,
  },
  [RealEstateType.Building]: {
    color: 'pink',
    label: `realEstate.type.${RealEstateType.Building.toLowerCase()}`,
  },
  [RealEstateType.House]: {
    color: 'lime',
    label: `realEstate.type.${RealEstateType.House.toLowerCase()}`,
  },
};

export const formatPropertyKindColumnValue =
  (translate: Translate, theme: Theme) =>
  (param: GridValueFormatterParams): ChipListProps['list'] => {
    return formatChipListValue(
      translate,
      theme,
      REAL_ESTATE_TYPE_TO_CHIP_LIST_PROPS
    )(param);
  };
