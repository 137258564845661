import React, { useCallback, useMemo, useState } from 'react';

import { SelectInput, TextInput, useStore, useTranslate } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { Typography } from '@mui/material';
import { ActionModal } from 'components/action-modal/ActionModal';
import { FileInput } from 'components/custom-input/file-input/FileInput';
import { fileToBase64 } from 'components/image-input/utils/imageInput.formater.utils';
import { CustomerDrawerKey } from 'types/customer.types';
import { Content } from 'ui/organisms/drawer/Drawer.styles';
import { FileProps } from 'components/custom-input/file-input/FileInput.types';
import { useUploadDocument } from 'features/customers/details/edit/hooks/useUploadDocument';
import { DocumentDirectUploadCategory } from 'types/document.enum';
import { useUploadOptions } from './useUploadOptions';

import { MAX_UPLOAD_FILE_SIZE } from 'constants/files';

import { InputLabel, Wrapper } from './UploadDocumentModal.styles';

interface UploadDocumentModalProps {
  customerId: string;
}

export const UploadDocumentModal: React.FC<UploadDocumentModalProps> = ({
  customerId,
}) => {
  const translate = useTranslate();
  const [isUploading, setIsUploading] = useState(false);
  const uploadDocument = useUploadDocument();
  const [uploadDocumentModalStore, showModal] = useStore(
    CustomerDrawerKey.UPLOAD_DOCUMENT,
    [false]
  );
  const [isModalOpen] = uploadDocumentModalStore;

  const documentDirectUploadCategoryChoices = useUploadOptions();

  const onSuccess = useCallback(() => {
    setIsUploading(false);
    showModal([false]);
  }, [showModal]);

  const handleCallback = useCallback(
    async ({ file, category, title }: FieldValues) => {
      setIsUploading(true);
      let fileBase64: string | undefined;

      if (file) {
        fileBase64 = await fileToBase64(file.rawFile);
      } else {
        throw new Error('Missing file provided by the callback');
      }

      uploadDocument.mutate(
        { customerId, data: { category, fileBase64, title } },
        { onSuccess }
      );
    },
    [customerId, onSuccess, uploadDocument]
  );

  const [hasValidFile, setHasValidFile] = useState(false);
  const errorMessage = useMemo(() => {
    if (!hasValidFile) {
      return translate(
        'customer.actions.addDocumentModal.upload.fileSizeError',
        { value: String(MAX_UPLOAD_FILE_SIZE / 1_000_000) }
      );
    }

    return '';
  }, [hasValidFile, translate]);

  const onDrop = useCallback((files: FileProps[]): void => {
    const [file] = files;
    setHasValidFile(file.size <= MAX_UPLOAD_FILE_SIZE);
  }, []);

  return (
    <ActionModal
      slug="cancel"
      isOpen={isModalOpen}
      title={
        <Typography variant="h6">
          {translate(`customer.actions.addDocumentModal.title`)}
        </Typography>
      }
      saveLabel={translate('ra.action.confirm')}
      onClose={onSuccess}
      callback={handleCallback}
      loading={isUploading}
      disableSaveButton={!hasValidFile}
    >
      <Content>
        <Wrapper>
          <InputLabel>
            <Typography variant="body1" fontWeight={500}>
              {translate(
                `customer.actions.addDocumentModal.documentType.label`
              )}
            </Typography>
            <Typography variant="body2">
              {translate('customer.actions.addDocumentModal.documentType.help')}
            </Typography>
          </InputLabel>
          <SelectInput
            choices={documentDirectUploadCategoryChoices}
            source="category"
            optionText="name"
            placeholder={translate(
              'customer.actions.addDocumentModal.documentType.placeholder'
            )}
            defaultValue={DocumentDirectUploadCategory.FinancingCertificate}
            label={false}
          />

          <InputLabel>
            <Typography variant="body1" fontWeight={500}>
              {translate(
                `customer.actions.addDocumentModal.documentTitle.label`
              )}
            </Typography>
            <Typography variant="body2">
              {translate(
                'customer.actions.addDocumentModal.documentTitle.help'
              )}
            </Typography>
            <TextInput
              source="title"
              label={false}
              placeholder={translate(
                'customer.actions.addDocumentModal.documentTitle.placeholder'
              )}
              hiddenLabel
            />
          </InputLabel>
        </Wrapper>

        <FileInput
          error={errorMessage}
          source="file"
          label="customer.actions.addDocumentModal.documentFile.title"
          helpText={translate(
            'customer.actions.addDocumentModal.documentFile.help'
          )}
          onDrop={onDrop}
        />
      </Content>
    </ActionModal>
  );
};
