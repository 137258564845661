import { useEffect, useState } from 'react';

import {
  Button,
  HttpError,
  useDataProvider,
  useEditContext,
  useGetIdentity,
  useLocaleState,
  useNotify,
  useStore,
  useTranslate,
} from 'react-admin';
import { styled } from '@mui/material';
import { ActionModal } from 'components/action-modal/ActionModal';
import { InvestorAutocomplete } from 'components/investor-autocomplete/InvestorAutocomplete';
import { RealEstateModalKey } from 'features/realEstates/constants';
import { DocumentCategory } from 'types/document.enum';
import { useBookRealEstateAction } from 'features/realEstates/list/hooks/useBookRealEstateAction';
import {
  useCanUnbook,
  useUnbookRealEstateAction,
} from 'features/realEstates/list/hooks/useUnbookRealEstateAction';
import { HeliosDataProvider } from 'providers/admin/dataProvider';
import { BookingDetails } from 'types/cgpCustomMethods.types';
import { formatDate } from 'utils/i18n/i18n.utils';

export const Content = styled('div')`
  width: 100%;
`;

export type PreBookingStore = {
  isOpen: boolean;
  realEstate?: {
    address?: string;
    id: string;
    isBooked: boolean;
  };
};

export const CGPPreBookingModal: React.FC = () => {
  const translate = useTranslate();
  const [locale] = useLocaleState(); // formatDate
  const dataProvider = useDataProvider<HeliosDataProvider>();
  const notify = useNotify();

  const [booking, setBooking] = useState<BookingDetails>();
  const [canUnbook, setCanUnbook] = useState<boolean>(false);
  const defineCanUnbook = useCanUnbook();
  const [{ isOpen, realEstate }, setStore] = useStore<PreBookingStore>(
    RealEstateModalKey.PRE_BOOKING,
    {
      isOpen: false,
    }
  );
  const { record } = useEditContext(
    realEstate ? { record: realEstate } : undefined
  );
  const { mutate: bookRealestate } = useBookRealEstateAction(
    record?.id as string
  );
  const { mutate: unbookRealestate } = useUnbookRealEstateAction(
    record?.id as string
  );
  const { identity } = useGetIdentity();

  useEffect(() => {
    if (!record?.id) {
      return;
    }
    dataProvider
      .getBookingInfo(record?.id)
      .then((response) => {
        setBooking(response.data);
        setCanUnbook(defineCanUnbook(booking));
      })
      .catch((err: HttpError) => {
        if (err.status === 404) {
          setCanUnbook(false);
          return;
        }
        notify(err.body?.message ?? '', { type: 'error' });
        throw err;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  const handleCGPPreBooking = (values: { customer: string }) => {
    bookRealestate(
      {
        clientId: values.customer,
        userId: identity?.id || '',
      },
      {
        onSuccess: () => {
          setStore({ isOpen: false });
        },
      }
    );
  };

  const handleCGPUnbook = () => {
    unbookRealestate(undefined, {
      onSuccess: () => {
        setStore({ isOpen: false });
      },
    });
  };

  return (
    <ActionModal
      slug="pre-booking"
      title={translate('realEstate.action.book.title', {
        property: realEstate?.address,
      })}
      isOpen={isOpen}
      onClose={() => setStore({ isOpen: false })}
      saveLabel={translate('realEstate.action.book.submit')}
      disableSaveButton={record?.isBooked}
      callback={handleCGPPreBooking}
    >
      <Content>
        {record?.isBooked && booking ? (
          <>
            <p>
              {translate('realEstate.action.book.already_booked', {
                endDate: formatDate({ date: booking.endsAt, locale }),
                interpolation: { escapeValue: false },
              })}
            </p>
            {canUnbook ? (
              <>
                <p>
                  {translate('realEstate.action.unbook.clientName', {
                    clientName:
                      booking.client.firstName + ' ' + booking.client.lastName,
                  })}
                </p>
                <p>{translate('realEstate.action.unbook.subtitle')}</p>
                <Button
                  onClick={handleCGPUnbook}
                  color="warning"
                  variant="contained"
                  label={translate('realEstate.action.unbook.label')}
                />
              </>
            ) : null}
          </>
        ) : (
          <>
            <p>{translate('realEstate.action.book.subtitle')}</p>
            <InvestorAutocomplete
              label={translate('realEstate.action.book.input_customer')}
              source="customer"
              showMissingDocuments
              missingDocumentTypes={[DocumentCategory.SearchMandate]}
              testId="offer-purchase-input-customer"
              isExternal={true}
            />
          </>
        )}
      </Content>
    </ActionModal>
  );
};
