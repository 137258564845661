import { useCallback, useMemo, useState } from 'react';

import { ListBase, useListController, useTranslate } from 'react-admin';
import { GridCellParams } from '@mui/x-data-grid';
import { DataTable } from 'components/data-table/DataTable';
import { getColumns } from 'utils/columns/getColumns.utils';
import { FilteredListLayout } from 'ui/layouts/filtered-list-layout/FilteredListLayout';
import { SearchBar } from 'ui/molecules/search-bar/SearchBar';
import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';
import { PermissionName } from 'types/permission.types';

import { SEARCH_DEBOUNCE_TIME } from 'constants/common';

import { useUsersColumns } from './hooks/useGetUsersColumns';
import { AddUserModalForm } from './components/modals/AddUserModalForm';
import { EditUserModalForm } from './components/modals/EditUserModalForm';
import { ConfirmDeleteUserModal } from './components/modals/confirmDeleteUserModal';
import { UsersFilters } from './components/filters/UsersFilters';
import { getDefaultUserFilters } from './components/filters/UsersFilters.utils';

const PER_PAGE = 20;

export const UsersList: React.FC = () => {
  const { hasPermission, canManageUsers } = useHeliosPermissions();
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false);
  const [editId, setEditId] = useState<string | undefined>(undefined);
  const columnsList = useUsersColumns();
  const translate = useTranslate();
  const {
    filterValues,
    setFilters,
    data: users,
    total,
  } = useListController({
    debounce: SEARCH_DEBOUNCE_TIME,
    perPage: PER_PAGE,
  });

  const canEditUser =
    hasPermission(PermissionName.UpdateUser) ||
    hasPermission(PermissionName.UpdateUserExternal);
  const canCreateUser =
    hasPermission(PermissionName.CreateUser) ||
    hasPermission(PermissionName.CreateUserExternal);

  const columns = useMemo(
    () => (users ? getColumns(columnsList, translate) : []),
    [users, translate, columnsList]
  );

  const rows = useMemo(() => {
    return users
      ? users.map((user) => {
          return {
            ...user,
            roles: user.roles
              ? user.roles.map((r: string) => translate('roles.' + r))
              : [],
          };
        })
      : [];
  }, [users, translate]);

  const openModal = useCallback(() => {
    setOpenModalCreate(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpenModalCreate(false);
    setEditId(undefined);
  }, []);

  const openModalConfirm = useCallback(() => {
    setOpenModalConfirmDelete(true);
  }, []);

  const closeModalConfirmDelete = useCallback(() => {
    setOpenModalConfirmDelete(false);
    setEditId(undefined);
  }, []);

  const cancelUserDelete = useCallback(() => {
    setOpenModalConfirmDelete(false);
  }, []);

  const handleCellClick = ({ id }: GridCellParams) => {
    setEditId(id as string);
  };

  const searchBar = useMemo(
    () => (
      <SearchBar
        title={translate('user.search.title')}
        placeholder={translate('user.search.placeholder')}
        setFilters={setFilters}
        filterValues={filterValues}
      />
    ),
    [filterValues, setFilters, translate]
  );

  const table = useMemo(
    () => (
      <DataTable onCellClick={handleCellClick} columns={columns} rows={rows} />
    ),
    [columns, rows]
  );

  return canManageUsers ? (
    <>
      <ListBase
        perPage={PER_PAGE}
        filterDefaultValues={getDefaultUserFilters()}
      >
        <FilteredListLayout
          main={table}
          title={translate('user.list', { count: total ?? 0 })}
          {...(canCreateUser && {
            addTitle: translate('users.add'),
            onAddClick: openModal,
          })}
          filters={<UsersFilters />}
          searchBar={searchBar}
        />
      </ListBase>
      <AddUserModalForm
        open={openModalCreate}
        onClose={closeModal}
        onSuccess={closeModal}
      />
      {!!(editId && canEditUser) && (
        <>
          <EditUserModalForm
            id={editId}
            onClose={closeModal}
            openModalConfirmDelete={openModalConfirm}
          />
          <ConfirmDeleteUserModal
            onClose={cancelUserDelete}
            open={openModalConfirmDelete}
            id={editId}
            onSuccess={closeModalConfirmDelete}
          />
        </>
      )}
    </>
  ) : null;
};
