/* eslint-disable max-lines */
import React from 'react';

import { NumberInput, SelectInput, useTranslate } from 'react-admin';
import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import { PriceInput } from 'ui/atoms/price-input/PriceInput';
import { PercentInput } from 'ui/atoms/percent-input/PercentInput';
import { AccordionForm } from 'ui/molecules/accordion-form/AccordionForm';

import { STRATEGY_DATA } from 'constants/realizations';

import { sourceToLabelMap } from '../EditFormContent.utils';
import { EditFormContentProps } from '../editFormContent.types';

export const FinancialModelForm: React.FC<EditFormContentProps> = ({
  defaultExpanded,
  disabled = false,
}) => {
  const translate = useTranslate();

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate('realization.form.financialModel')}
    >
      <Fieldset>
        <PriceInput
          disabled={disabled}
          source="finances.renovationAmount"
          label={sourceToLabelMap.get('finances.renovationAmount')}
          margin="none"
          variant="outlined"
        />
        <PriceInput
          disabled={disabled}
          source="finances.annualRent"
          label={sourceToLabelMap.get('finances.annualRent')}
          margin="none"
          variant="outlined"
        />
        <PriceInput
          disabled={disabled}
          source="finances.annualCharges"
          label={sourceToLabelMap.get('finances.annualCharges')}
          margin="none"
          variant="outlined"
        />
      </Fieldset>

      <Fieldset>
        <PriceInput
          source="finances.projectAmount"
          label={sourceToLabelMap.get('finances.projectAmount')}
          margin="none"
          variant="outlined"
          disabled={disabled}
        />
        <PercentInput
          source="finances.grossReturn"
          label={sourceToLabelMap.get('finances.grossReturn')}
          margin="none"
          variant="outlined"
          disabled={disabled}
        />
        <PercentInput
          source="finances.netReturn"
          label={sourceToLabelMap.get('finances.netReturn')}
          margin="none"
          variant="outlined"
          disabled={disabled}
        />
      </Fieldset>

      <Fieldset>
        <PriceInput
          source="finances.monthlyAmount"
          label={sourceToLabelMap.get('finances.monthlyAmount')}
          margin="none"
          variant="outlined"
          disabled={disabled}
        />
        <PriceInput
          source="finances.monthlyCashflow"
          label={sourceToLabelMap.get('finances.monthlyCashflow')}
          margin="none"
          variant="outlined"
          disabled={disabled}
        />
        <NumberInput
          source="finances.rentingDelay"
          label={sourceToLabelMap.get('finances.rentingDelay')}
          margin="none"
          variant="outlined"
          disabled={disabled}
        />
        <SelectInput
          disabled={disabled}
          source="finances.type"
          choices={STRATEGY_DATA}
          label={sourceToLabelMap.get('finances.type')}
          margin="none"
          variant="outlined"
        />
      </Fieldset>
    </AccordionForm>
  );
};
