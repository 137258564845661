import { useCallback, useMemo } from 'react';

import { isArray } from 'lodash';
import { usePermissions } from 'react-admin';
import { RoleName } from 'types/role.types';
import { Permission, PermissionName, Scope } from 'types/permission.types';
import { ExternalUsersRoles } from './externalUsers';

export type UseHeliosPermissionsReturnType = {
  isLoading: boolean;
  permissions: (RoleName | Permission)[];
  isAdmin: boolean;
  isDev: boolean;
  isAdvisor: boolean;
  isAdvisorManager: boolean;
  isExternalUser: boolean;
  isHunterManager: boolean;
  isHunter: boolean;
  isMandator: boolean;
  isAssetManagementConsultant: boolean;
  canAddPositioning: boolean;
  canListPositions: boolean;
  canManageUsers: boolean;
  canManageCustomers: boolean;
  canManageRoles: boolean;
  canManageOrganizations: boolean;
  hasOnlyRole: (role: RoleName) => boolean;
  hasPermission: (permission: PermissionName) => boolean;
  getScope: (permission: PermissionName) => Scope | undefined;
};

export const useHeliosPermissions = (): UseHeliosPermissionsReturnType => {
  const { permissions, isLoading, ...rest } =
    usePermissions<(RoleName | Permission)[]>();

  const permissionsLoaded = useMemo(
    () => !isLoading && isArray(permissions),
    [isLoading, permissions]
  );

  const hasRole = useCallback(
    (role: RoleName) => permissionsLoaded && permissions.includes(role),
    [permissionsLoaded, permissions]
  );

  const hasOnlyRole = useCallback(
    (role: RoleName) => {
      if (!permissionsLoaded) {
        return false;
      }
      const perms = permissions.filter((r) => r === RoleName.User);
      return perms.length === 1 && perms[0] === role;
    },
    [permissionsLoaded, permissions]
  );

  const hasPermission = useCallback(
    (permissionName: PermissionName) => {
      return (
        permissionsLoaded &&
        !!permissions.find((p) => (p as Permission).name === permissionName)
      );
    },
    [permissions, permissionsLoaded]
  );

  const getScope = useCallback(
    (permissionName: PermissionName) => {
      if (permissionsLoaded) {
        const permission = permissions.find(
          (p) => (p as Permission).name === permissionName
        ) as Permission | undefined;
        return permission?.scope;
      }
    },
    [permissions, permissionsLoaded]
  );

  const isExternalUser = Boolean(
    ExternalUsersRoles.find((role) => hasRole(role))
  );

  const canAddPositioning = useMemo(
    () =>
      permissionsLoaded &&
      hasPermission(PermissionName.CreateRealEstatePositionings),
    [permissionsLoaded, hasPermission]
  );

  const canManageCustomers = useCallback(
    () => permissionsLoaded && hasPermission(PermissionName.ListCustomer),
    [permissionsLoaded, hasPermission]
  );

  const canManageRoles = useCallback(
    () => permissionsLoaded && hasPermission(PermissionName.ListRoles),
    [permissionsLoaded, hasPermission]
  );

  const canManageUsers = useCallback(
    () => permissionsLoaded && hasPermission(PermissionName.ListUser),
    [permissionsLoaded, hasPermission]
  );

  const canManageOrganizations = useCallback(
    () =>
      permissionsLoaded &&
      hasPermission(PermissionName.ListOrganization) &&
      !isExternalUser,
    [hasPermission, permissionsLoaded, isExternalUser]
  );

  const canListPositions = useCallback(
    () => permissionsLoaded && !isExternalUser,
    [permissionsLoaded, isExternalUser]
  );

  return {
    isLoading,
    permissions,
    ...rest,
    canAddPositioning,
    canListPositions: canListPositions(),
    canManageCustomers: canManageCustomers(),
    canManageOrganizations: canManageOrganizations(),
    canManageRoles: canManageRoles(),
    canManageUsers: canManageUsers(),
    getScope,
    hasOnlyRole,
    hasPermission,
    isAdmin: hasRole(RoleName.Admin),
    isAdvisor: hasRole(RoleName.Advisor),
    isAdvisorManager: hasRole(RoleName.AdvisorManager),
    isAssetManagementConsultant: hasRole(RoleName.AssetManagementConsultant),
    isDev: hasRole(RoleName.Dev),
    isExternalUser,
    isHunter: hasRole(RoleName.Hunter),
    isHunterManager: hasRole(RoleName.HunterManager),
    isMandator: hasRole(RoleName.Mandator),
  };
};
