import { styled } from '@mui/material';

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== '$gap',
})<{ $gap?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 32px;

  gap: ${({ $gap }) => $gap ?? '24px'};

  background-color: ${({ theme }) => theme.palette.background.paper};

  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 8px;
`;
