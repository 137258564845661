import { RealEstate } from 'types/realEstate.types';

export type TransformForSaveProps = Partial<
  Omit<RealEstate, 'financialModel'>
> & {
  financialModel: Partial<RealEstate['financialModel']>;
  exclusiveEndDate?: Date | null;
  exclusiveEndDateCheckbox?: boolean;
  shortTermRentalCheckbox?: boolean;
};
export const transformForSave = (
  data: TransformForSaveProps
): TransformForSaveProps => {
  return {
    ...data,
    exclusiveEndDate: data.exclusiveEndDateCheckbox
      ? data.exclusiveEndDate
      : null,
    financialModel: {
      ...data.financialModel,
      ...(!data.shortTermRentalCheckbox && {
        shortTermNightlyRent: null,
        shortTermOccupancyRate: null,
      }),
      evaluationRate: parseFloat(
        ((data.financialModel?.evaluationRate || 0) / 100).toFixed(3)
      ),
    },
  };
};

type TransformForDisplayProps = Partial<Omit<RealEstate, 'financialModel'>> & {
  financialModel: Partial<RealEstate['financialModel']>;
  hasFurnitureBudget: boolean;
  exclusiveEndDateCheckbox?: boolean;
  shortTermRentalCheckbox?: boolean;
};
export const transformForDisplay = (
  data: TransformForDisplayProps
): TransformForDisplayProps => {
  return {
    ...data,
    exclusiveEndDateCheckbox: !!data.exclusiveEndDate,
    financialModel: {
      ...data.financialModel,
      evaluationRate: data.financialModel?.evaluationRate
        ? parseFloat((data.financialModel.evaluationRate * 100).toFixed(2))
        : null,
    },
    shortTermRentalCheckbox:
      !!data.financialModel?.shortTermNightlyRent ||
      !!data.financialModel?.shortTermOccupancyRate,
  };
};

type TransformForDuplicationProp = Pick<
  RealEstate,
  '_id' | 'address' | 'lang' | 'type'
>;

type TransformForDuplicationReturn = TransformForDuplicationProp | undefined;
export const transformForDuplication = (
  record: TransformForDuplicationProp | undefined
): TransformForDuplicationReturn | undefined => {
  return record
    ? {
        _id: record._id,
        address: record.address,
        lang: record.lang,
        type: record.type,
      }
    : undefined;
};
