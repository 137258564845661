import { useTranslate } from 'react-admin';
import { GridColDef } from 'types/mui.types';
import { Scope } from 'types/permission.types';
import { RoleName } from 'types/role.types';
import { Chip } from 'ui/molecules/chip/Chip';

const ScopeColorMap: Record<
  Scope,
  'warning' | 'primary' | 'secondary' | 'default'
> = {
  [Scope.All]: 'warning',
  [Scope.Organization]: 'primary',
  [Scope.Own]: 'secondary',
  [Scope.None]: 'default',
};
const getRoleGridColDef = (roleName: RoleName): GridColDef => ({
  field: roleName,
  headerName: `shared.${roleName}`,
  renderCell: (params) => (
    <Chip
      color={ScopeColorMap[params.value as Scope]}
      label={params.value ? params.value.slice(0, 3) : 'NON'}
      size="small"
    />
  ),
});

const ROLE_COLUMNS = [
  RoleName.Admin,
  RoleName.Hunter,
  RoleName.HunterManager,
  RoleName.Advisor,
  RoleName.AdvisorManager,
  RoleName.AssetManagementConsultant,
  RoleName.RentalBuildingManager,
  RoleName.Mandator,
  RoleName.Animator,
];

export const useGetPermissionColumns = (): GridColDef[] => {
  const translate = useTranslate();
  return [
    {
      field: 'id',
      headerName: 'permissions.header',
      sortable: true,
      valueFormatter: ({ value }) => translate(`permissions.${value}.label`),
      width: 500,
    },
    ...ROLE_COLUMNS.map((r) => getRoleGridColDef(r)),
  ] as GridColDef[];
};
