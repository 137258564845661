/* eslint-disable no-restricted-imports */
import { useCallback } from 'react';

import {
  SaveHandler,
  useEditContext,
  useNotify,
  useRedirect,
} from 'react-admin';
import { Form } from 'components/form/Form';
import { Realization } from 'types/realization.types';
import { ErrorResponse } from 'types/common.types';

import { RealizationHeader } from '../../../components/realization-header/RealizationHeader';
import { RealizationBreadCrumbs } from '../../../components/breadcrumbs/RealizationBreadCrumbs';
import { ActionButtons } from '../action-buttons/ActionButtons';
import { EditFormContent } from './edit-form-content/EditFormContent';

export const EditForm: React.FC = () => {
  const { record, save } = useEditContext();
  const redirect = useRedirect();

  const notify = useNotify();

  const onSuccess = useCallback(
    (data: Realization) => {
      notify('realization.edit.success', { type: 'success' });
      redirect('edit', 'realizations', data.id);
    },
    [notify, redirect]
  );

  const edit = useCallback(
    async (values: Realization, saveEdit?: SaveHandler<Realization>) => {
      if (saveEdit) {
        await saveEdit(values, {
          onError: (error) => {
            const message = (error as ErrorResponse)?.body?.message;

            notify(message || 'realization.edit.error', { type: 'error' });
          },
          onSuccess,
        });
      }
    },
    [notify, onSuccess]
  );

  return (
    <Form
      warnWhenUnsavedChanges
      mode="onChange"
      onSubmit={(data) => edit(data as Realization, save)}
      defaultValues={{}}
      record={record}
    >
      <RealizationHeader
        breadcrumbs={
          record ? (
            <RealizationBreadCrumbs
              realizationId={record.id.toString()}
              title={record.title}
            />
          ) : undefined
        }
        realizationActions={<ActionButtons />}
      />
      <EditFormContent />
    </Form>
  );
};
