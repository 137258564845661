import * as React from 'react';

import { useLocation } from 'react-router-dom';
import { segmentPage } from 'libs/segment/segment';

export const usePageTracking = (): void => {
  const location = useLocation();

  React.useEffect(() => {
    segmentPage(location.pathname);
  }, [location.pathname]);
};
