import { useMemo } from 'react';

import { CheckboxInput } from 'components/checkbox-input/CheckboxInput';
import { DateInput } from 'components/custom-input/date-input/DateInput';
import {
  CommonInputProps,
  SelectInput,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { FormDivider } from 'ui/atoms/form-divider/FormDivider';
import { AccordionForm } from 'ui/molecules/accordion-form/AccordionForm';
import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import { SelectArrayInput } from 'ui/organisms/select-array-input/SelectArrayInput';
import { useCustomerPermissions } from 'features/customers/hooks/useCustomerPermissions';
import { CustomerEditFormatted } from 'types/customer.types';
import { UserAutoComplete } from 'components/user-autocomplete/UserAutoComplete';
import { RoleName } from 'types/role.types';

import {
  getFinancingCertificateStatusChoiceList,
  getMandateStatusChoiceList,
  getPermissionChoiceList,
  getTransactionStatusChoiceList,
} from './CrmForm.utils';

export const CrmForm: React.FC<{ defaultExpanded: boolean }> = ({
  defaultExpanded,
}) => {
  const translate = useTranslate();
  const customer = useRecordContext<CustomerEditFormatted>();
  const { checkCanEditCustomer } = useCustomerPermissions();
  const canEditCustomer = checkCanEditCustomer(customer);

  const { permissionChoices } = useMemo(
    () => ({
      permissionChoices: getPermissionChoiceList(),
    }),
    []
  );

  const {
    financingCertificateStatusChoices,
    mandateStatusChoices,
    transactionStatusChoices,
  } = useMemo(
    () => ({
      financingCertificateStatusChoices:
        getFinancingCertificateStatusChoiceList(translate),
      mandateStatusChoices: getMandateStatusChoiceList(translate),
      transactionStatusChoices: getTransactionStatusChoiceList(translate),
    }),
    [translate]
  );

  const commonProps: Partial<CommonInputProps & { disabled: boolean }> = {
    disabled: true,
    margin: 'none',
    variant: 'outlined',
  };

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={`📂 ${translate('customer.form.crm')}`}
    >
      <UserAutoComplete
        label="shared.advisor"
        source="ownedBy"
        roles={[RoleName.Advisor, RoleName.Mandator]}
        disabled={!canEditCustomer}
      />
      <FormDivider />
      <Fieldset>
        <DateInput
          source="createdAt"
          {...commonProps}
          label={translate('shared.registrationDate')}
        />
        <SelectInput
          source="mandateStatus"
          choices={mandateStatusChoices}
          label="customer.mandateStatusLabel"
          {...commonProps}
        />
        <SelectInput
          source="transactionStatus"
          choices={transactionStatusChoices}
          label="customer.transactionStatusLabel"
          {...commonProps}
        />
      </Fieldset>
      <FormDivider />
      <Fieldset>
        <CheckboxInput
          label="customer.capacity"
          {...commonProps}
          source="hasBorrowingCapacity"
          disabled={!canEditCustomer}
        />
        <SelectInput
          source="financingCertificate.status"
          choices={financingCertificateStatusChoices}
          label="customer.financingCertificateStatusLabel"
          {...commonProps}
        />
        <DateInput
          source="financingCertificate.date"
          {...commonProps}
          label={translate('customer.financingCertificateDate')}
        />
      </Fieldset>
      <SelectArrayInput
        source="roles"
        choices={permissionChoices}
        label="user.input.role"
        fullWidth
        {...commonProps}
      />
    </AccordionForm>
  );
};
