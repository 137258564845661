import { useCallback } from 'react';

import { Filters as BaseFilters } from 'components/filters/Filters';
import {
  AutocompleteArrayInput,
  ReferenceArrayInput,
  useTranslate,
} from 'react-admin';
import { DateLocalizationProvider } from 'components/form/DateLocalizationProvider';
import { CollapsibleSection } from 'ui/molecules/collapsible-section/CollapsibleSection';
import { UserWithAdvisor } from 'types/user.types';
import { formatFullName } from 'utils/strings/strings.utils';
import { DateInput } from 'components/custom-input/date-input/DateInput';

const renderOptionUser = ({ firstName, lastName }: UserWithAdvisor) =>
  formatFullName(firstName, lastName);

const filterSearchToQuery = (search: string) => ({ search });

export const Filters: React.FC = () => {
  const translate = useTranslate();

  const enableGetChoice = useCallback(
    ({ search }: { search: string }) => search?.length >= 3,
    []
  );

  return (
    <BaseFilters defaultValues={{}}>
      <DateLocalizationProvider>
        <CollapsibleSection
          label={translate('activities.filter.date')}
          isDefaultExpanded
        >
          <CollapsibleSection.GroupSpacer>
            <DateInput
              label={translate('shared.fromDate')}
              source="fromDate"
              helperText={false}
            />
            <DateInput
              addADay
              label={translate('shared.toDate')}
              source="toDate"
              helperText={false}
            />
          </CollapsibleSection.GroupSpacer>
        </CollapsibleSection>
        <CollapsibleSection
          label={translate('activities.filter.customers')}
          isDefaultExpanded
        >
          <CollapsibleSection.GroupSpacer>
            <ReferenceArrayInput
              label="customers"
              source="customerIds"
              reference="customers/available"
              perPage={20}
              enableGetChoices={enableGetChoice}
            >
              <AutocompleteArrayInput
                variant="outlined"
                debounce={500}
                helperText={translate('positioningForm.searchCustomer.helper')}
                label={translate('positioning.filter.customers')}
                filterToQuery={filterSearchToQuery}
                optionText={renderOptionUser}
              />
            </ReferenceArrayInput>
          </CollapsibleSection.GroupSpacer>
        </CollapsibleSection>
      </DateLocalizationProvider>
    </BaseFilters>
  );
};
