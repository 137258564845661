import { styled } from '@mui/material';
import MUICardContent from '@mui/material/CardContent';

export const CardContent = styled(MUICardContent)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(1)};
  &:last-child {
    padding-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;
