import React from 'react';

import { Box } from '@mui/material';
import { useTransactionPermissions } from 'features/transactions/show/hooks/useTransactionsPermissions';
import { useStore } from 'react-admin';
import { TransactionModalKey } from 'types/transaction.types';
import {
  PrimaryActionItem,
  SplitButton,
} from 'ui/molecules/split-button/SplitButton';
import { useFormContext } from 'react-hook-form';

export const ActionButton: React.FC = () => {
  const [, showCancelModal] = useStore(TransactionModalKey.CANCEL, false);
  const [, showTransferModal] = useStore(
    TransactionModalKey.TRANSFER_TRANSACTION,
    false
  );
  const {
    formState: { isDirty, isSubmitting },
  } = useFormContext();
  const { canUpdateProject: canCancelOrUpdate, canTransferTransaction } =
    useTransactionPermissions();

  const displayCancelModal = () => showCancelModal(true);
  const displayTransferModal = () => showTransferModal(true);

  if (!canCancelOrUpdate && !canTransferTransaction) {
    return <></>;
  }

  const primaryActionItem = {
    disabled: !isDirty || isSubmitting,
    label: 'ra.action.save',
    type: 'submit',
  } as PrimaryActionItem;

  const secondaryActionItems = [
    {
      callback: displayCancelModal,
      color: 'error',
      disabled: false,
      id: 'cancel',
      label: 'transaction.show.action.cancel',
    },

    {
      callback: displayTransferModal,
      color: 'primary',
      disabled: !canTransferTransaction,
      id: 'transfer',
      label: 'transaction.show.action.transfer',
    },
  ];

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={1}
      data-testid="transaction-action-button-desktop"
    >
      <SplitButton
        primaryActionItem={primaryActionItem}
        items={secondaryActionItems}
      />
    </Box>
  );
};
