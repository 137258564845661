import { styled } from '@mui/material';
import Link from '@mui/material/Link';

export const Container = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0;
  width: 100%;
`;

export const Header = styled('div')`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;
  padding: 0;
  width: 100%;
`;

export const TitleWithIcon = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 0 8px;
`;

export const Content = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding: 0;
  width: 100%;
`;

export const ContentBlock = styled('div')`
  display: flex;
  gap: 8px;
  &.column {
    flex-direction: column;
  }
`;

export const ContentDate = styled('div')`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 0;
`;

export const LeftDivider = styled('div')`
  background: ${({ theme }) => theme.palette.colors.deepPurple[50]};
  border-radius: 0 8px 8px 0;
  height: 100%;
  width: 4px;
`;

export const TitleText = styled('div')<{ $isSmall?: boolean }>`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0;
  &.right {
    align-items: ${({ $isSmall }) => ($isSmall ? 'flex-start' : 'flex-end')};
  }
`;

export const Preview = styled('div')<{ $isSmall?: boolean }>`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${({ $isSmall }) => ($isSmall ? '' : 'margin-left: auto;')}
  padding: 0;
`;

export const DownloadLink = styled(Link)`
  align-items: center;
  display: flex;
  gap: 8px;
`;
