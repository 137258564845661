import React from 'react';

import { Typography } from '@mui/material';
import { useTranslate } from 'react-admin';
import { DateField } from 'ui/atoms/date-field/DateField';

interface CustomerHeaderDetailsProps {
  registrationDate: string;
}

export const CustomerHeaderDetails: React.FC<CustomerHeaderDetailsProps> = ({
  registrationDate,
}) => {
  const translate = useTranslate();
  return (
    <Typography variant="caption">
      {translate('shared.registeredOn')}{' '}
      <DateField
        data-testid="customer-header-details-registration-date"
        date={registrationDate}
        options={{ dateStyle: 'short' }}
      />
    </Typography>
  );
};
