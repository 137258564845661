import { styled } from '@mui/material';
import { FileInput as RAFileInput } from 'react-admin';

export const FileInputStyled = styled(RAFileInput)`
  & .RaFileInput-dropZone {
    padding: 48px 0;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 4px;
  }

  & .RaFileInputPreview-removeButton {
    display: none;
  }
`;

export const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
`;
export const InputLabel = styled('div')`
  display: flex;
  flex-direction: column;
`;
export const InputPreview = styled('div')`
  display: flex;
  align-content: flex-start;
  color: ${({ theme }) => theme.palette.primary.main};
`;
