import { ReactElement } from 'react';

type InputLabelPropMui = {
  InputLabelProps: {
    shrink: boolean;
  };
};

export const getInputLabelPropMui = (
  label?: string | ReactElement | false
): InputLabelPropMui | null => {
  return label === false
    ? {
        InputLabelProps: {
          shrink: false,
        },
      }
    : null;
};
