import { CommonInputProps } from 'react-admin';
import { styled } from '@mui/material';
import { Form } from 'components/form/Form';

export const commonInputStylesProps: Partial<CommonInputProps> = {
  margin: 'none',
  variant: 'outlined',
};

export const Row = styled('div')`
  display: flex;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    gap: 24px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    flex-direction: column;
    word-break: break-all;
  }
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
  overflow: hidden;
` as unknown as typeof Form; // Allows us to use the Generic Type <T> from Form

export const FormBody = styled('div')`
  flex: 1 1 auto;
  overflow-y: auto;
`;

export const FormFooter = styled('div')`
  display: flex;
  flex: 0 0 auto;
`;

export const ActionsRow = styled(Row)`
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  align-items: center;
  flex: 1;
  padding-top: 24px;
`;
