import jwtDecode from 'jwt-decode';
import { UserIdentity } from 'react-admin';

import { ACCESS_TOKEN_STORAGE_KEY, getTokenByKey } from './tokenStorage';

export const getPermissions = (): Promise<string[]> => {
  const token = getTokenByKey(ACCESS_TOKEN_STORAGE_KEY);
  if (!token) {
    return Promise.reject();
  }

  const identity = jwtDecode(token) as UserIdentity;
  const permissions =
    identity.permissions && Array.isArray(identity.permissions)
      ? identity.permissions
      : [];

  return identity.roles
    ? Promise.resolve(permissions.concat(identity.roles))
    : Promise.reject();
};
