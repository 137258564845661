import { useCallback, useEffect } from 'react';

import { createScriptElement } from 'utils/dom/createScript';

const SCRIPT_TAG = `https://maps.googleapis.com/maps/api/js?key=
${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
const GAScriptId = 'google-places-script';

export const useGooglePlacesScript = ({
  onLoad,
}: {
  onLoad: () => void;
}): void => {
  const handleOnLoad = useCallback(() => {
    onLoad();
  }, [onLoad]);

  useEffect(() => {
    let script = document.getElementById(GAScriptId);
    if (script) {
      handleOnLoad();
      return () => {
        /**/
      };
    }

    script = createScriptElement({
      async: true,
      id: GAScriptId,
      onLoad: handleOnLoad,
      src: SCRIPT_TAG,
    });

    document.body.appendChild(script);
  }, [handleOnLoad]);
};
