import * as yup from 'yup';
import { AnyObjectSchema } from 'yup';
import {
  EstimatedRenovation,
  InvoiceMethod,
  InvoiceRecipient,
  ReasonNoWork,
} from 'types/project.types';
import { validateRegistrationNumber } from 'utils/form/form.utils';

import { CANCEL_REASONS_DATA } from 'constants/transactions';

export interface IdName {
  id: string;
  name: string;
}

const cancellationReasonSchema = yup
  .string()
  .nullable()
  .oneOf(CANCEL_REASONS_DATA.map((cancelReason: IdName) => cancelReason.id))
  .required('transaction.form.reasonFieldRequired');

const additionalCancellationReasonsSchema = yup
  .array()
  .of(cancellationReasonSchema)
  .test('exclusive', 'transaction.form.sameAsReasonField', function (values) {
    const { cancellationReason } = this.parent;
    if (!cancellationReason) {
      return true;
    }
    return !(values ?? []).includes(cancellationReason);
  });

const cancellationCommentarySchema = yup.string().nullable().optional();

export const transactionCancelForm = yup.object().shape({
  additionalCancellationReasons: additionalCancellationReasonsSchema,
  cancellationCommentary: cancellationCommentarySchema,
  cancellationReason: cancellationReasonSchema,
});

export const cgpTransactionUpdateTodoForm = (
  isFileRequired: boolean
): AnyObjectSchema =>
  yup.object().shape({
    date: yup
      .string()
      .required('transaction.form.dateFieldRequired')
      .nullable(),
    ...(isFileRequired
      ? { file: yup.object().required('transaction.form.fileFieldRequired') }
      : null),
  });

const invoiceDataForm = yup.object().shape({
  address: yup.string().required('project.form.invoiceData.addressRequired'),
  company: yup.string().when(['invoiceRecipient'], {
    is: (invoiceRecipient: InvoiceRecipient) =>
      invoiceRecipient !== InvoiceRecipient.PrivateIndividual,
    then: yup
      .string()
      .required('project.form.invoiceData.companyRequired')
      .nullable(),
  }),
  firstName: yup.string().when(['invoiceRecipient'], {
    is: (invoiceRecipient: InvoiceRecipient) =>
      invoiceRecipient === InvoiceRecipient.PrivateIndividual,
    then: yup
      .string()
      .required('project.form.invoiceData.firstNameRequired')
      .nullable(),
  }),
  invoiceRecipient: yup
    .string()
    .oneOf(Object.values(InvoiceRecipient))
    .nullable()
    .required('project.form.invoiceData.invoiceRecipientRequired'),
  lastName: yup.string().when(['invoiceRecipient'], {
    is: (invoiceRecipient: InvoiceRecipient) =>
      invoiceRecipient === InvoiceRecipient.PrivateIndividual,
    then: yup
      .string()
      .required('project.form.invoiceData.lastNameRequired')
      .nullable(),
  }),
  siret: yup.string().when(['invoiceRecipient'], {
    is: (invoiceRecipient: InvoiceRecipient) =>
      invoiceRecipient === InvoiceRecipient.LegalEntityFrance,
    then: yup
      .string()
      .test(
        'siret',
        'project.form.invoiceData.siretInvalid',
        validateRegistrationNumber
      )
      .required('project.form.invoiceData.siretRequired')
      .nullable(),
  }),
  tvaIntra: yup.number().when(['invoiceRecipient'], {
    is: (invoiceRecipient: InvoiceRecipient) =>
      invoiceRecipient === InvoiceRecipient.LegalEntityEurope,
    otherwise: yup.number().optional().nullable(),
    then: yup
      .number()
      .required('project.form.invoiceData.tvaIntraRequired')
      .nullable(),
  }),
});

export const projectForm = yup.object().shape({
  internalNote: yup.string().nullable(),
  renovation: yup.object().shape({
    comments: yup
      .string()
      .nullable()
      .when(['estimatedRenovation', 'reasonNoWork'], {
        is: (
          estimatedRenovation: EstimatedRenovation,
          reasonNoWork: ReasonNoWork
        ) =>
          [EstimatedRenovation.No, EstimatedRenovation.Furnitures].includes(
            estimatedRenovation
          ) && reasonNoWork === ReasonNoWork.Other,
        then: yup.string().required('project.form.commentsRequired').nullable(),
      }),
    estimatedRenovation: yup
      .string()
      .oneOf(Object.values(EstimatedRenovation))
      .nullable()
      .required('project.form.estimatedRenovation'),
    invoiceData: yup.object().when(['estimatedRenovation'], {
      is: (estimatedRenovation: EstimatedRenovation) =>
        [EstimatedRenovation.No, EstimatedRenovation.Furnitures].includes(
          estimatedRenovation
        ),
      otherwise: invoiceDataForm,
      then: yup.object().shape({}).nullable().default(null),
    }),
    invoiceMethod: yup
      .string()
      .oneOf([InvoiceMethod.Choice1, InvoiceMethod.Choice2])
      .required('project.form.invoiceMethodRequired')
      .when(['estimatedRenovation'], {
        is: (estimatedRenovation: EstimatedRenovation) =>
          [EstimatedRenovation.No, EstimatedRenovation.Furnitures].includes(
            estimatedRenovation
          ),
        then: yup
          .string()
          .oneOf([InvoiceMethod.None])
          .required('project.form.invoiceMethodShouldBeNone'),
      }),
    reasonNoWork: yup
      .string()
      .nullable()
      .when(['estimatedRenovation'], {
        is: (estimatedRenovation: EstimatedRenovation) =>
          [EstimatedRenovation.No, EstimatedRenovation.Furnitures].includes(
            estimatedRenovation
          ),
        then: yup
          .string()
          .oneOf(Object.values(ReasonNoWork))
          .required('project.form.reasonNoWorkRequired')
          .nullable(),
      }),
  }),
});
