import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RealEstate } from 'types/realEstate.types';

export interface FormattedChangeEntry {
  added: object;
  updated: object;
  deleted: object;
  updatedAt: string | Date;
  author: string;
}

export interface FormattedRealEstateChangeEntry extends FormattedChangeEntry {
  added: RealEstate;
  updated: RealEstate;
  deleted: RealEstate;
}

export const HistoryItemLine: React.FC<{
  title: string;
  value: string;
}> = ({ title, value }) => {
  return (
    <p>
      <Typography variant="subtitle1">{title}:</Typography>
      <Typography variant="body2" style={{ whiteSpace: 'break-spaces' }}>
        {typeof value === 'object' ? JSON.stringify(value, null, 2) : value}
      </Typography>
    </p>
  );
};

export const HistoryItemLineGroup: React.FC<{
  title: string;
  items: [string, string][];
}> = ({ title, items }) => {
  return (
    <AccordionDetails>
      <Typography variant="h5">{title}</Typography>
      <>
        {items.map(([key, value]) => (
          <HistoryItemLine key={key} title={key} value={value} />
        ))}
      </>
    </AccordionDetails>
  );
};

export const HistoryItem: React.FC<{
  entry: FormattedChangeEntry;
}> = ({ entry }) => {
  const { added, updated, deleted, author } = entry;

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {author ? `Par: ${author}, ` : ''}Date:{' '}
        {entry.updatedAt.toLocaleString()}
      </AccordionSummary>
      {Object.keys(added).length > 0 && (
        <HistoryItemLineGroup title="Ajout" items={Object.entries(added)} />
      )}
      {Object.keys(deleted).length > 0 && (
        <HistoryItemLineGroup
          title="Suppression"
          items={Object.entries(deleted)}
        />
      )}
      {Object.keys(updated).length > 0 && (
        <HistoryItemLineGroup
          title="Modification"
          items={Object.entries(updated)}
        />
      )}
    </Accordion>
  );
};

export const RealEstateHistoryItem: React.FC<{
  entry: FormattedRealEstateChangeEntry;
}> = ({ entry }) => {
  return <HistoryItem entry={entry} />;
};
