import React, { useState } from 'react';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useNotify, useTranslate } from 'react-admin';
import Link from '@mui/material/Link';
import { CircularProgress } from '@mui/material';
import { CustomerDocumentType } from 'types/customer.types';

interface DocumentLinkProps {
  gridParams: {
    row: {
      type: CustomerDocumentType;
      title: string;
      category: string;
      S3ID: string;
    };
    value?: string;
  };
  onClick?: (event: React.MouseEvent<HTMLElement>) => Promise<void | {
    data: File;
  }>;
}

export const DocumentLink: React.FC<DocumentLinkProps> = ({
  gridParams,
  onClick,
}) => {
  const { row } = gridParams;
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const notify = useNotify();

  const onDocumentClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsLoading(true);

    onClick?.(event)
      ?.catch(() => {
        notify(translate('ra.notification.http_error'), {
          type: 'error',
        });
      })
      .finally(() => setIsLoading(false));
  };

  return row.type === CustomerDocumentType.Mandate ? (
    <>
      {isLoading ? (
        <CircularProgress
          size={18}
          role="loader"
          data-testid="mandate-document-link-loader"
        />
      ) : (
        <InsertDriveFileIcon color="primary" />
      )}
      <Link
        href="#"
        variant="body2"
        onClick={onDocumentClick}
        data-testid="mandate-document-link"
      >
        {translate('customer.documentsSection.mandateName')}
      </Link>
    </>
  ) : (
    <>
      <InsertDriveFileIcon color="primary" />
      <a
        target="_blank"
        href={`https://s3.eu-west-1.amazonaws.com/upload-documents.masteos.com/${row?.S3ID}`}
        rel="noreferrer"
      >
        {row.title || row.category}
      </a>
    </>
  );
};
