import { Theme, styled } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { STATUS_POSITIONING_COLORS_AND_LABELS } from 'features/positioning/constants';
import { Positioning, PositioningStatus } from 'types/positioning.types';
import { Tooltip } from 'ui/molecules/tooltip/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Chip } from 'ui/molecules/chip/Chip';
import { Translate } from 'react-admin';
import { formatChipListValue } from 'utils/columns/valueFormatters.utils';

const StyledChipListContainer = styled('div')`
  max-width: 100%;
`;

export const renderStatusColumns = (
  param: GridRenderCellParams<PositioningStatus, Positioning>,
  translate: Translate,
  theme: Theme
): React.ReactElement | null => {
  const {
    value: status,
    row: { previousStatus },
  } = param;

  if (!status) {
    return null;
  }

  const [chipProps] = formatChipListValue(
    translate,
    theme,
    STATUS_POSITIONING_COLORS_AND_LABELS
  )({ ...param, value: [status] });

  if (previousStatus) {
    return (
      <Tooltip
        title={translate('positioning.previousStatus', {
          previousStatus: translate(`positioning.status.${previousStatus}`),
        })}
      >
        <StyledChipListContainer>
          <Chip
            icon={
              <InfoOutlinedIcon
                fontSize="small"
                className="infoIcon"
                style={{
                  color: 'white',
                }}
              />
            }
            {...chipProps}
          />
        </StyledChipListContainer>
      </Tooltip>
    );
  }

  return <Chip {...chipProps} />;
};
