import {
  HttpError,
  useDataProvider,
  useGetIdentity,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { ActionMutationReturn } from 'types/common.types';
import { useMutation } from 'react-query';
import { HeliosDataProvider } from 'providers/admin/dataProvider';
import { PermissionName, Scope } from 'types/permission.types';
import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';
import { BookingDetails } from 'types/cgpCustomMethods.types';

export const useCanUnbook = (): ((booking?: BookingDetails) => boolean) => {
  const { getScope } = useHeliosPermissions();
  const { identity } = useGetIdentity();

  return (booking?: BookingDetails) => {
    const rightScope = getScope(PermissionName.UnbookRealEstateCgp);
    const isOwnBooking = booking?.userId === identity?.id;
    return rightScope === Scope.All || isOwnBooking;
  };
};

export type BookRealEstateMutationReturn = ActionMutationReturn<
  void,
  unknown,
  void,
  unknown
>;

export const useUnbookRealEstateAction = (
  propertyId?: string
): BookRealEstateMutationReturn => {
  const record = useRecordContext(
    propertyId ? { record: { id: propertyId } } : undefined
  );
  const dataProvider = useDataProvider<HeliosDataProvider>();
  const notify = useNotify();

  const mutation = useMutation(
    ['unbookRealEstate', record?.id, dataProvider],
    () => {
      return dataProvider
        ?.unbookRealEstate(record?.id)
        .then(() => {
          notify('realEstate.action.unbook.success', { type: 'success' });
        })
        .catch((err: HttpError) => {
          notify(err.body?.message ?? '', { type: 'error' });
          throw err;
        });
    }
  );

  return { ...mutation };
};
