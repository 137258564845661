import React, { useCallback, useMemo } from 'react';

import { Box, Button, ButtonGroup } from '@mui/material';
import { useRecordContext, useStore, useTranslate } from 'react-admin';
import {
  CustomerDrawerKey,
  CustomerEditFormatted,
  CustomerModalKey,
  MandateLocal,
} from 'types/customer.types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { LoadingDropdown } from 'ui/molecules/dropdown/Dropdown';
import { useCustomerPermissions } from 'features/customers/hooks/useCustomerPermissions';
// eslint-disable-next-line max-len
import { useGenerateSearchMandateAction } from 'features/customers/details/edit/component/generate-search-mandate-drawer/useGenerateSearchMandateAction.hook';

export interface CustomerAction {
  slug:
    | 'generateSearchMandate-FR_fr'
    | 'generateSearchMandate-ES_es'
    | 'generateSearchMandate-ES_en'
    | 'generateSearchMandate-ES_fr';
  openModal?: () => void;
  name: string;
  callback?: (data?: any) => Promise<unknown> | void; // eslint-disable-line
}

enum ModalType {
  SEARCH_MANDATE = 'SEARCH_MANDATE',
  ADD_DOCUMENT = 'ADD_DOCUMENT',
}

export const ActionButton: React.FC = () => {
  const translate = useTranslate();
  const customer = useRecordContext<CustomerEditFormatted>();
  const { checkCanEditCustomer, canEditCustomerBroughtBy } =
    useCustomerPermissions();
  const [, showSearchMandateDrawer] = useStore(
    CustomerDrawerKey.GENERATE_SEARCH_MANDATE,
    [false, 'FR_fr'] as [boolean, string]
  );
  const [, showUploadDocumentDrawer] = useStore(
    CustomerDrawerKey.UPLOAD_DOCUMENT
  );
  const [, setChangeBroughtByModal] = useStore(
    CustomerModalKey.UPDATE_CUSTOMER_BROUGHT_BY
  );

  const handleShowModal = useCallback(
    (modalType: ModalType, local?: string) => {
      if (modalType === ModalType.SEARCH_MANDATE && local) {
        showSearchMandateDrawer([true, local]);
      } else {
        showUploadDocumentDrawer([true]);
      }
    },
    [showUploadDocumentDrawer, showSearchMandateDrawer]
  );

  const { isLoading } = useGenerateSearchMandateAction();

  const canEditCustomer = checkCanEditCustomer(customer);

  const dropdownActions = useMemo(() => {
    return [
      {
        disable: true,
        name: 'customer.actions.categoryDocumentButton',
        permissions: [canEditCustomer],
        testID: 'upload-document-label',
      },
      {
        callback: () => handleShowModal(ModalType.ADD_DOCUMENT),
        icon: UploadFileIcon,
        name: 'customer.actions.addDocument',
        permissions: [canEditCustomer],
        slug: 'addDocument',
        testID: 'upload-document-button',
      },
      {
        disable: true,
        name: 'customer.actions.categoryMandateButton',
        permissions: [],
        testID: 'category-mandate-label',
      },
      {
        callback: () =>
          handleShowModal(ModalType.SEARCH_MANDATE, MandateLocal.FR_fr),
        icon: ReceiptIcon,
        name: 'customer.actions.generateSearchMandate.label_FR_fr',
        permissions: [],
        slug: 'generateSearchMandate-FR_fr',
        testID: 'generate-fr-fr-mandate-button',
      },
      {
        disable: true,
        name: 'customer.actions.categoryAdmin',
        permissions: [canEditCustomerBroughtBy],
        testID: 'upload-document-label',
      },
      {
        callback: () => setChangeBroughtByModal(true),
        icon: ManageAccountsIcon,
        name: 'customer.actions.updateBroughtBy.title',
        permissions: [canEditCustomerBroughtBy],
        slug: 'updateBroughtBy',
        testID: 'updateBroughtBy-button',
      },
    ].filter(
      (item) => item.permissions.length === 0 || item.permissions.some(Boolean)
    );
  }, [
    canEditCustomer,
    canEditCustomerBroughtBy,
    handleShowModal,
    setChangeBroughtByModal,
  ]);

  return (
    <Box display="flex" flexDirection="row" gap={1}>
      <LoadingDropdown
        loading={isLoading}
        onSelect={(action) => (action as CustomerAction).callback?.()}
        items={dropdownActions}
        keyExtractor={(action) => (action as CustomerAction).name}
        renderItem={(action) => translate((action as CustomerAction).name)}
      >
        <ButtonGroup variant="outlined" disabled={isLoading}>
          <Button variant="outlined">
            {translate('customer.actions.titleButton')}
          </Button>
          <Button size="small">
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      </LoadingDropdown>
    </Box>
  );
};
