import React from 'react';

import { useRecordContext, useTranslate } from 'react-admin';
import { SaveButton } from 'components/save-button/SaveButton';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { truncate } from 'utils/strings/truncate';
import { ExternalCustomerDetail } from 'types/external-customer.types';
// eslint-disable-next-line max-len
import { useEditExternalCustomerPermissions } from 'features/external-customers/details/edit/hooks/useEditExternalCustomerPermissions';

import { ActionButton } from './ActionButton';

export const ActionButtons: React.FC = () => {
  const externalCustomer = useRecordContext<ExternalCustomerDetail>();
  const { canEditExternalCustomer } =
    useEditExternalCustomerPermissions(externalCustomer);
  const translate = useTranslate();

  const isSmall = useMediaQuery(
    (t: Theme) => `(max-width:${t.breakpoints.values.sm}px)`
  );

  const saveButtonLabel = isSmall
    ? truncate(translate('ra.action.save'), 6)
    : translate('ra.action.save');

  return (
    <Box display="flex" flexDirection="row" gap={1}>
      <ActionButton />
      {Boolean(canEditExternalCustomer) && (
        <SaveButton
          data-testid="external-customer-edit-save-button"
          label={saveButtonLabel}
        />
      )}
    </Box>
  );
};
