import { useMemo } from 'react';

import { getRealEstatePositioningColumns } from 'features/positioning/list/utils/getPositioningColumns';
import {
  ListBase,
  ListContext,
  ShowBase,
  ShowContext,
  useStore,
  useTranslate,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { Button, useTheme } from '@mui/material';
import { RealEstateStatus } from 'types/realEstate.enum';
// eslint-disable-next-line max-len
import { AddPositioningModalForm } from 'features/positioning/list/components/modals/add-positionning/AddPositioningModalForm';
import { DataTable } from 'components/data-table/DataTable';
// eslint-disable-next-line max-len
import { CancelPositioningModal } from 'features/positioning/list/components/modals/cancel-positionning/CancelPositioningModal';
// eslint-disable-next-line max-len
import { ConfirmPositioningModal } from 'features/positioning/list/components/modals/confirm-positionning/ConfirmPositioningModal';
import { RePositioningModal } from 'features/positioning/list/components/modals/re-positioning/RePositioningModal';
import { PositioningCancelModal } from 'types/positioning.types';
import { PropertyBreadCrumbs } from 'features/realEstates/details/components/breadcrumbs/PropertyBreadCrumbs';
import { PropertyHeader } from 'features/realEstates/details/components/property-header/PropertyHeader';
// eslint-disable-next-line max-len
import { GeneratePurchaseOfferModal } from 'features/realEstates/details/edit/components/action-modal/GeneratePurchaseOfferModal';
import {
  ContentContainer,
  ContentTableContainer,
} from 'components/headerTabs/HeaderTabs.styles';
import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';

export const RealEstatePositioning: React.FC = () => {
  const { id } = useParams();
  const { canAddPositioning } = useHeliosPermissions();
  const translate = useTranslate();
  const theme = useTheme();

  const [, showCancelModal] = useStore<PositioningCancelModal>(
    'positioning.cancel_modal'
  );

  const [modalAddPositioningIsVisible, showModalAddPositioning] = useStore(
    'positioning.create.show_add_modal',
    false
  );

  const displayModal = () => showModalAddPositioning(true);
  const hideModal = () => showModalAddPositioning(false);

  const columns = useMemo(
    () => getRealEstatePositioningColumns(translate, theme, showCancelModal),
    [theme, translate, showCancelModal]
  );

  return (
    <ContentContainer>
      <ShowBase>
        <ShowContext.Consumer>
          {({ record }) => {
            return (
              <>
                <PropertyHeader
                  breadcrumbs={<PropertyBreadCrumbs propertyId={record?.id} />}
                  propertyActions={
                    canAddPositioning ? (
                      <Button
                        data-testid="add-positioning-button"
                        variant="contained"
                        onClick={displayModal}
                        disabled={record?.status !== RealEstateStatus.AVAILABLE}
                      >
                        {translate('positioning.add.buttonLabel')}
                      </Button>
                    ) : undefined
                  }
                />
                {!!record && <GeneratePurchaseOfferModal />}
              </>
            );
          }}
        </ShowContext.Consumer>
      </ShowBase>

      <ListBase
        resource="positionings"
        disableSyncWithLocation
        key="real-estate-positionings"
        filter={{ realEstateId: id }}
        filterDefaultValues={{ realEstateId: id }}
        sort={{ field: 'status', order: 'ASC' }}
      >
        <ListContext.Consumer>
          {({ data, isLoading, refetch, isFetching }) => (
            <>
              <ContentTableContainer>
                <DataTable
                  loading={isLoading || isFetching || !columns}
                  columns={columns}
                  rows={data || []}
                  autoHeight
                  density="standard"
                  noRowsMessage={translate(
                    'property.positioningList.noResults'
                  )}
                />
              </ContentTableContainer>

              <CancelPositioningModal onSuccess={refetch} />
              <ConfirmPositioningModal onSuccess={refetch} />
              <RePositioningModal onSuccess={refetch} />
            </>
          )}
        </ListContext.Consumer>

        <AddPositioningModalForm
          open={modalAddPositioningIsVisible}
          onClose={hideModal}
        />
      </ListBase>
    </ContentContainer>
  );
};
