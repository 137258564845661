import { useCallback } from 'react';

import {
  useRedirect,
  useNotify,
  SaveHandler,
  useEditContext,
} from 'react-admin';
import { yupResolver } from '@hookform/resolvers/yup';
import { MobileFooter } from 'components/mobile-footer/MobileFooter';
import {
  formatFullStreet,
  sanitizeData,
  sanitizeRecord,
} from 'utils/form/form.utils';
import { getInternalNoteTemplate } from 'features/realEstates/utils/getInternalNoteTemplate';
import { Form } from 'components/form/Form';
// eslint-disable-next-line max-len
import { RealEstate } from 'types/realEstate.types';
import { ErrorResponse } from 'types/common.types';
import { PropertyHeader } from 'features/realEstates/details/components/property-header/PropertyHeader';
import {
  transformForDisplay,
  transformForSave,
} from 'features/realEstates/utils/payloadTransforms';
import { ActionButtons } from 'features/realEstates/details/edit/components/action-buttons/ActionButtons';
import { getDefaultValues } from 'features/realEstates/details/edit/RealEstateEdit.utils';
import { propertyEditFormValidator } from 'features/realEstates/details/edit/RealEstateEdit.validator';
import { PropertyBreadCrumbs } from 'features/realEstates/details/components/breadcrumbs/PropertyBreadCrumbs';

import { EditFormContent } from './edit-form-content/EditFormContent';

export const EditForm: React.FC = () => {
  const { record, save } = useEditContext();
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = useCallback(
    (data: RealEstate) => {
      notify('realEstate.edit.success', { type: 'success' });
      redirect('edit', 'real-estates', data._id);
    },
    [notify, redirect]
  );

  const handleTransform = useCallback(
    (data: RealEstate) =>
      transformForSave(
        sanitizeData({
          ...data,
          address: {
            ...data.address,
            street: data.geocode?.street || formatFullStreet(data.address),
          },
          internalNote: data.internalNote || getInternalNoteTemplate(data.lang),
        })
      ),
    []
  );

  const edit = useCallback(
    async (values: RealEstate, saveEdit?: SaveHandler<RealEstate>) => {
      if (saveEdit) {
        await saveEdit(values, {
          onError: (error) => {
            const message = (error as ErrorResponse)?.body?.message;
            if (message?.startsWith('Address not found in geocode provider')) {
              return { geocode: message };
            }
            notify(message || 'realEstate.edit.error', { type: 'error' });
          },
          onSuccess,
          transform: () => handleTransform(values),
        });
      }
    },
    [handleTransform, notify, onSuccess]
  );

  return (
    <Form
      resolver={yupResolver(propertyEditFormValidator)}
      warnWhenUnsavedChanges
      mode="onChange"
      onSubmit={(data) => edit(data as RealEstate, save)}
      defaultValues={getDefaultValues()}
      record={record ? transformForDisplay(sanitizeRecord(record)) : undefined}
    >
      <PropertyHeader
        breadcrumbs={
          record ? (
            <PropertyBreadCrumbs propertyId={record.id.toString()} />
          ) : undefined
        }
        propertyActions={<ActionButtons />}
      />
      <EditFormContent />
      <MobileFooter>
        <ActionButtons />
      </MobileFooter>
    </Form>
  );
};
