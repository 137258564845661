import { isArray } from 'lodash';
import { Bookmark, BookmarksListItemFormatted } from 'types/bookmark.types';
import { formatFullName } from 'utils/strings/strings.utils';

export const formatBookmarksList = (
  bookmarksList: Bookmark[] = []
): BookmarksListItemFormatted[] => {
  return isArray(bookmarksList)
    ? bookmarksList.map(({ customer, ...rest }) => ({
        ...rest,
        customerFullName: formatFullName(customer.firstName, customer.lastName),
        customerId: customer.id,
      }))
    : [];
};
