import React from 'react';

// eslint-disable-next-line max-len
import { UploadCounterSignedOfferModal } from 'features/realEstates/details/edit/components/action-modal/UploadCounterSignedOfferModal';
import { AddRealEstateModal } from 'features/realEstates/list/components/modals/AddRealEstateModal';
// eslint-disable-next-line max-len
import { TransferRealEstateModal } from 'components/action-modal/TransferRealEstate/TransferRealEstateModal';
import { EditContainer } from 'components/detail/edit-container/EditContainer';

import { ArchiveActionModal } from './components/action-modal/ArchiveActionModal';
import { ActionContextProvider } from './context/ActionContextProvider';
import { DownloadBankPdfActionModal } from './components/action-modal/DownloadBankPdfActionModal';
import { GeneratePurchaseOfferModal } from './components/action-modal/GeneratePurchaseOfferModal';
import { EditForm } from './components/edit-form/EditForm';

export const RealEstateEdit: React.FC = () => {
  return (
    <EditContainer component="div" mutationMode="pessimistic">
      <ActionContextProvider>
        <EditForm />
        <ArchiveActionModal />
        <TransferRealEstateModal />
        <AddRealEstateModal />
        <DownloadBankPdfActionModal />
        <GeneratePurchaseOfferModal />
        <UploadCounterSignedOfferModal />
      </ActionContextProvider>
    </EditContainer>
  );
};
