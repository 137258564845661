import React from 'react';

import {
  useTranslate,
  AutocompleteArrayInput,
  ReferenceArrayInput,
} from 'react-admin';
import { RoleName } from 'types/role.types';
import { filterSearchToQuery } from 'utils/filters/filters';
import { formatFullName } from 'utils/strings/strings.utils';

export const HuntersAutocomplete: React.FC = () => {
  const translate = useTranslate();

  return (
    <ReferenceArrayInput
      label="Hunter"
      source="hunters"
      perPage={100}
      reference="users/filter"
      filter={{
        roles: [RoleName.Hunter, RoleName.Mandator],
      }}
    >
      <AutocompleteArrayInput
        variant="outlined"
        debounce={500}
        label={translate('shared.hunter')}
        noOptionsText={translate('shared.filters.noOptions')}
        filterToQuery={filterSearchToQuery}
        optionText={(opt) => formatFullName(opt.firstName, opt.lastName)}
      />
    </ReferenceArrayInput>
  );
};
