import { styled } from '@mui/material';
import { SimpleFormIterator as SimpleFormIteratorRA } from 'react-admin';

export const TerritoriesContainer = styled('div')`
  width: 100%;
`;

export const SimpleFormIterator = styled(SimpleFormIteratorRA)`
  & .RaSimpleFormIterator-buttons > .RaSimpleFormIterator-add {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    & .RaSimpleFormIterator-inline {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      & .MuiFormControl-root {
        grid-column: auto;
      }
    }
  }
`;
