import { InputAdornment } from '@mui/material';
import PercentIcon from '@mui/icons-material/Percent';
import { NumberInput, NumberInputProps } from 'react-admin';

export const PercentInput: React.FC<NumberInputProps> = (props) => {
  return (
    <NumberInput
      {...props}
      step={0.1}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <PercentIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
