import * as yup from 'yup';

import { ROLES } from 'constants/user';

export const rolesSchema = yup
  .array()
  .of(yup.string().test(isValidRole))
  .min(1)
  .required();

export const userForm = yup.object().shape({
  cityManagerId: yup
    .string()
    .when('roles', (roles: string[], schema) => {
      if (Array.isArray(roles) && roles.includes('hunter')) {
        return schema.required('City manager is required');
      }
    })
    .nullable(),
  email: yup.string().email().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  organization: yup.object().shape({
    id: yup.string().required('organization is a required field'),
  }),
  roles: rolesSchema,
  professionalExtraData: yup
    .object()
    .default(undefined)
    .shape({
      company: yup
        .string()
        .when('roles', (roles: string[], schema) => {
          if (
            Array.isArray(roles) &&
            roles.includes('assetManagementConsultant')
          ) {
            return schema.required('Company is required for this role');
          }
        })
        .nullable(),
      registrationAt: yup
        .string()
        .when('roles', (roles: string[], schema) => {
          if (
            Array.isArray(roles) &&
            roles.includes('assetManagementConsultant')
          ) {
            return schema.required(
              'Registration place is required for this role'
            );
          }
        })
        .nullable(),
      registrationNumber: yup
        .string()
        .when('roles', (roles: string[], schema) => {
          if (
            Array.isArray(roles) &&
            roles.includes('assetManagementConsultant')
          ) {
            return schema.required(
              'Registration number is required for this role'
            );
          }
        })
        .nullable(),
    })
    .nullable(),
});

function isValidRole(value: unknown): boolean {
  return (
    ROLES.some((role) => role.value === value) || typeof value === 'string'
  );
}
