import { useNotify, Translate } from 'react-admin';
import { CellType, GridColDef } from 'types/mui.types';
import {
  formatColumnValueHunter,
  formatColumnValueType,
  formatColumnValueTypeLocation,
} from 'features/realEstates/list/utils/columnsValueFormatters';
import {
  renderCellExternalLinksUrl,
  renderCellHighlighted,
  renderCellStatus,
  renderCellPositioningCount,
  renderCellBookmarkCount,
} from 'features/realEstates/list/utils/columnsRenderCell';
import { formatYesNoOrAnyValue } from 'utils/columns/valueFormatters.utils';

export const getRealEstatesColumns = (
  translate: Translate,
  notify: ReturnType<typeof useNotify>
): GridColDef[] => {
  return [
    {
      field: 'link',
      headerName: 'shared.link',
      renderCell: renderCellExternalLinksUrl(notify),
      sortable: false,
      width: 80,
    },
    {
      field: 'status',
      headerName: 'realEstate.statusLabel',
      renderCell: renderCellStatus(translate),
      width: 130,
    },
    {
      field: 'hunter.fullName',
      headerName: 'shared.hunter',
      renderCell: renderCellHighlighted(),
      valueFormatter: formatColumnValueHunter,
      width: 200,
    },
    {
      field: 'organizationName',
      headerName: 'shared.organization',
      width: 200,
    },
    {
      field: 'address.street',
      headerName: 'shared.address.street',
      renderCell: renderCellHighlighted(),
      width: 200,
    },
    {
      field: 'address.city',
      headerName: 'shared.address.city',
      renderCell: renderCellHighlighted(),
      width: 140,
    },
    {
      field: 'positioningCount',
      headerName: 'realEstate.positioningCount.columnLabel',
      renderCell: renderCellPositioningCount(),
      sortable: false,
      width: 70,
    },
    {
      field: 'bookmarkCount',
      headerName: 'realEstate.bookmarkCount.columnLabel',
      renderCell: renderCellBookmarkCount(),
      sortable: false,
      width: 70,
    },
    {
      cellType: CellType.PRICE,
      field: 'financialModel.projectAmount',
      headerName: 'financial.totalBudget',
      width: 130,
    },
    {
      cellType: CellType.PERCENTAGE,
      field: 'financialModel.grossReturn',
      headerName: 'financial.grossReturn',
      width: 130,
    },
    {
      cellType: CellType.PERCENTAGE,
      field: 'financialModel.netReturn',
      headerName: 'financial.netReturn',
      width: 130,
    },
    {
      cellType: CellType.PRICE,
      field: 'financialModel.salesPrice',
      headerName: 'financial.salesPrice',
      width: 130,
    },
    {
      field: 'surface',
      headerName: 'realEstate.surface',
      width: 130,
    },
    {
      field: 'type',
      headerName: 'realEstate.propertyType',
      valueFormatter: formatColumnValueType(translate),
      width: 150,
    },
    {
      field: 'totalLots',
      headerName: 'realEstate.totalLots',
      width: 130,
    },
    {
      field: 'roomNumber',
      headerName: 'realEstate.roomNumber',
      width: 130,
    },
    {
      field: 'dpe',
      headerName: 'realEstate.dpe',
      width: 130,
    },
    {
      cellType: CellType.DATE_TIME,
      field: 'createdAt',
      headerName: 'realEstate.createdAt',
      width: 200,
    },
    {
      cellType: CellType.DATE_TIME,
      field: 'lastPublishedAt',
      headerName: 'shared.publicationDate',
      width: 200,
    },
    {
      field: 'locationType',
      headerName: 'realEstate.locationType',
      valueFormatter: formatColumnValueTypeLocation(translate),
      width: 130,
    },
    {
      field: 'groundFloor',
      headerName: 'realEstate.groundFloor',
      sortable: false,
      valueFormatter: formatYesNoOrAnyValue,
      width: 130,
    },
  ] as GridColDef[];
};
