import {
  HttpError,
  useDataProvider,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { useMutation } from 'react-query';
import { HeliosDataProvider } from 'providers/admin/dataProvider';
import { ActionMutationReturn } from 'types/common.types';
import { TransferRealEstatePayload } from 'components/action-modal/TransferRealEstate/TransferRealEstateModal';

import { useRealEstateDetailPermissions } from './useRealEstateDetailPermissions';

export type TransferRealEstateMutationReturn = ActionMutationReturn<
  void,
  unknown,
  TransferRealEstatePayload,
  unknown
>;

export const useTransferRealEstateAction =
  (): TransferRealEstateMutationReturn => {
    const refresh = useRefresh();
    const dataProvider = useDataProvider<HeliosDataProvider>();
    const notify = useNotify();
    const translate = useTranslate();
    const { canTransferRealEstate } = useRealEstateDetailPermissions();

    const mutation = useMutation(
      ['transfer', dataProvider],
      (variables: TransferRealEstatePayload) => {
        return dataProvider
          ?.transferRealEstate(variables)
          .then((response: unknown) => {
            if (response) {
              refresh();
              notify('realEstate.action.transfer.success', {
                type: 'success',
              });
            }
          })
          .catch((err: HttpError) => {
            let message = 'ra.notification.http_error';
            switch (err.status) {
              case 403:
                message = `${translate(`ra.notification.not_authorized`)} - ${
                  err.message
                }`;
                break;
              case 400:
                message = `${translate(`ra.notification.bad_item`)} - ${
                  err.message
                }`;
                break;
              case 404:
                message = translate(`ra.notification.item_doesnt_exist`);
                break;
              default:
                break;
            }

            notify(message, {
              type: 'error',
            });
          });
      }
    );
    return { ...mutation, isAllowed: canTransferRealEstate };
  };
