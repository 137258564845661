import React from 'react';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Form as RAForm, useTranslate } from 'react-admin';
import { yupResolver } from '@hookform/resolvers/yup';
import { addPositioningForm } from 'features/positioning/list/validators/positioning.validator';
import { ChipList } from 'ui/molecules/chips-list/ChipList';
import { PositioningStatus } from 'types/positioning.types';
import { STATUS_POSITIONING_COLORS_AND_LABELS } from 'features/positioning/constants';
import { InvestorAutocomplete } from 'components/investor-autocomplete/InvestorAutocomplete';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const Form = styled(RAForm)`
  box-shadow: none;
`;

const StatusContainer = styled(Box)`
  border: 1px solid ${({ theme }) => theme.palette.grey[400]};
  border-radius: 5px;
  padding: 8px 12px;
  margin-top: 5px;
`;

const RECOMMENDATION_COLORS_AND_LABELS =
  STATUS_POSITIONING_COLORS_AND_LABELS[PositioningStatus.Recommendation];

export interface PositioningFormProps {
  initialData: { realEstateId: string };
  handleSubmit?: (
    data: Record<string, unknown>
  ) => Promise<{ customerId: string } | undefined>;
}

export const AddPositioningForm: React.FC<PositioningFormProps> = ({
  handleSubmit,
  initialData,
}) => {
  const translate = useTranslate();

  const chipStatus = {
    ...RECOMMENDATION_COLORS_AND_LABELS,
    label: translate(RECOMMENDATION_COLORS_AND_LABELS.label),
  };
  return (
    <Form
      defaultValues={{ ...initialData }}
      onSubmit={handleSubmit}
      warnWhenUnsavedChanges
      id="positioning-form"
      resolver={yupResolver(addPositioningForm)}
    >
      <Container>
        <InvestorAutocomplete
          showMissingDocuments
          showPositionings
          source="customerId"
        />
        <StatusContainer>
          <ChipList list={[chipStatus]} />
        </StatusContainer>
      </Container>
    </Form>
  );
};
