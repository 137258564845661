import { RealEstateType, SupportedLanguage } from 'types/realEstate.enum';

import { CustomerCivility } from './customer.types';
import { RealizationStatus } from './realization.enum';
import { DocumentDirectUploadCategory } from './document.enum';

export interface UpdateRealizationStatusBody {
  status: RealizationStatus;
}
export interface ArchiveBody {
  archivingReason: string;
}

export interface DownloadBankPdfBody {
  loanDownPayment: number;
  loanInterestRate: number;
  loanDuration: number;
}

export interface DuplicatedBody {
  address: {
    street?: string;
  };
  annualCharges?: number | null;
  annualIncome?: number | null;
  internalNote: string;
  lang: SupportedLanguage;
  netSeller?: number | null;
  realEstateId: string;
  renovationAmount?: number | null;
  roomCount: number;
  surface: number;
  type: RealEstateType;
}

export interface UploadImageBody {
  id: string;
  base64: string;
}

export interface UploadImageResponse {
  awsId: string;
  imageId?: string;
}

export interface UploadFileBody {
  fileBase64: string;
  category: DocumentDirectUploadCategory;
  title: string;
}

export interface UploadProfilePictureBody {
  fileBase64: string;
}

export interface UploadSignatureBody {
  fileBase64: string;
}

export interface UploadCounterSignedOfferBody {
  fileBase64Url: string;
  offerDate: Date;
  customerId: string;
  realNetSeller: number;
  realAgencyCommission: number;
  salesAgreementEstimatedDate: Date;
  authenticatedDeedReceptionEstimatedDate: Date;
}

export interface GeneratePurchaseOfferBody {
  realEstateId?: string;
  secondInvestor?: {
    firstName: string;
    lastName: string;
    birthDate: Date;
    birthCity: string;
    address: string;
  };
  observations?: string;
  customerId: string;
  netSeller: number;
  agencyCommission: number;
}

export enum ResetPasswordOrigin {
  HeliosUserCreation = 'HELIOS_USER_CREATION',
  HeliosUser = 'HELIOS_USER',
}

export interface ForgotPasswordBody {
  email: string;
  language?: string;
  origin: ResetPasswordOrigin;
}

export interface ResetPasswordBody {
  code: string;
  email: string;
}
export interface ResetPasswordResponseBody {
  hash: string;
}

export interface RenewPasswordBody {
  password: string;
  email: string;
  hash: string;
}

export interface GenerateSearchMandateBody {
  countryCode: string;
  language: string;
  civility?: CustomerCivility;
  firstName: string;
  lastName: string;
  address?: string;
  birthDate?: Date;
  birthCity?: string;
  downPayment?: number;
  budgetMax: number;
  email: string;
}

export interface UpdateBroughtByBody {
  broughtBy: string;
}
