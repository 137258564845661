import * as React from 'react';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useTranslate } from 'react-admin';
import { capitalize, Typography } from '@mui/material';

export type PrimaryActionItem = {
  callback?: () => void;
  disabled?: boolean;
  label: string;
  type: 'button' | 'submit';
};

export type SplitButtonItem = {
  callback: () => void;
  disabled?: boolean;
  id: string;
  label: string;
  color?: string;
};

export const SplitButton: React.FC<{
  primaryActionItem: PrimaryActionItem;
  items: SplitButtonItem[];
}> = ({ primaryActionItem, items }) => {
  const translate = useTranslate();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    items[index].callback();
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup variant="contained" ref={anchorRef} disableElevation>
        <Button
          onClick={primaryActionItem.callback}
          type={primaryActionItem.type}
          disabled={primaryActionItem.disabled}
        >
          {translate(primaryActionItem.label)}
        </Button>
        <Button
          size="small"
          onClick={handleToggle}
          data-testid="dropdown-button"
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {items.map((option, index) => (
                    <MenuItem
                      key={option.id}
                      disabled={option.disabled}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      <Typography color={option.color || 'default'}>
                        {capitalize(translate(option.label))}
                      </Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
