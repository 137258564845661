import { BrowserRouter as Router } from 'react-router-dom';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { AppCss } from 'App.styles';
import 'react-international-phone/style.css';

import { themeProvider } from './providers/admin/themeProvider';
import { Admin } from './providers/admin/Admin';

export const App = (): JSX.Element => {
  return (
    <ThemeProvider theme={createTheme(themeProvider)}>
      <CssBaseline />
      <AppCss />
      <Router>
        <SnackbarProvider maxSnack={4}>
          <Admin />
        </SnackbarProvider>
      </Router>
    </ThemeProvider>
  );
};
