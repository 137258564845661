import React from 'react';

import { Modal, ModalFooterButtonsContainer } from 'ui/organisms/modal/Modal';
import { Button } from 'ui/atoms/button/Button';
import { useDelete, useNotify, useTranslate } from 'react-admin';
import { BaseUser } from 'types/user.types';

interface ConfirmDeleteUserModalProps {
  onClose: () => void;
  open: boolean;
  onSuccess?: (id: string) => void;
  id: string;
}

export const ConfirmDeleteUserModal: React.FC<ConfirmDeleteUserModalProps> = ({
  onClose,
  id,
  onSuccess,
  open,
}) => {
  const translate = useTranslate();
  const [deleteOne] = useDelete<BaseUser, Error>(
    'users',
    { id },
    {
      onError: (error) => {
        notify(error?.message || 'user.edit.error', {
          type: 'error',
        });
      },
      onSuccess: () => {
        onSuccess && onSuccess(id);
        notify('user.form.delete.successToaster', { type: 'success' });
      },
    }
  );
  const notify = useNotify();

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={translate('users.confirmDelete')}
      footer={
        <ModalFooterButtonsContainer>
          <Button data-testid="cancel-button" variant="text" onClick={onClose}>
            {translate('ra.action.cancel')}
          </Button>
          <Button
            variant="text"
            onClick={() => deleteOne()}
            data-testid="confirm-button"
          >
            {translate('ra.action.delete')}
          </Button>
        </ModalFooterButtonsContainer>
      }
    ></Modal>
  );
};
