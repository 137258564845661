import { Translate } from 'react-admin';
import { Bookmark, BookmarksListItemFormatted } from 'types/bookmark.types';
import { CellType, GridColDef } from 'types/mui.types';
import { getColumns } from 'utils/columns/getColumns.utils';
import { renderCellLinkCustomer } from 'utils/columns/renderCells.utils';

export const getRealEstateBookmarkColumns = (
  translate: Translate
): GridColDef<Bookmark>[] => {
  const columns: GridColDef<BookmarksListItemFormatted>[] = [
    {
      cellType: CellType.DATE_TIME,
      field: 'createdAt',
      headerName: 'shared.dateTime',
      width: 200,
    },
    {
      field: 'customerFullName',
      headerName: 'shared.customer',
      renderCell: ({ row: { customerId }, value }) =>
        renderCellLinkCustomer(customerId, value),
      sortable: false,
      width: 200,
    },
  ];

  return getColumns(columns, translate);
};
