import React from 'react';

import { ActionModal } from 'components/action-modal/ActionModal';
import {
  FormDataConsumer,
  useNotify,
  useStore,
  useTranslate,
  useUpdate,
} from 'react-admin';
import {
  Positioning,
  PositioningCancelModal,
  PositioningCancellationReason,
} from 'types/positioning.types';
import { FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextArea } from 'ui/atoms/textarea/TextArea';
// eslint-disable-next-line max-len
import { AutocompleteArrayInputWithCheckboxes } from 'components/custom-autocomplete/autocomplete-array-input-with-checkboxes/AutocompleteArrayInputWithCheckboxes';

import {
  getAdvisorCancellationReasonChoices,
  getCancelledStatus,
} from './cancelPositioningModal.utils';
import { cancelPositioningSchema } from './cancel-positioning.validator';

type CancelPositioningModalProps = {
  onSuccess?: () => void;
};

export const CancelPositioningModal: React.FC<CancelPositioningModalProps> = ({
  onSuccess,
}) => {
  const notify = useNotify();
  const translate = useTranslate();
  const [
    {
      isOpen: isModalOpen,
      positioningId,
      customerFullName,
      currentStatus,
      advisorCancellationReasonOther,
      advisorCancellationReasons,
    },
    showModal,
  ] = useStore<PositioningCancelModal>('positioning.cancel_modal', {
    advisorCancellationReasonOther: undefined,
    advisorCancellationReasons: [],
    currentStatus: undefined,
    customerFullName: undefined,
    isOpen: false,
    positioningId: undefined,
  });

  const [update] = useUpdate<Positioning>(
    'positionings',
    { data: { status: getCancelledStatus(currentStatus) } },
    {
      onError: (error) => {
        notify(error as string, { type: 'error' });
      },
      onSuccess: () => {
        notify(translate('positioning.cancel.success', { customerFullName }), {
          type: 'success',
        });
        showModal({ isOpen: false });
      },
    }
  );

  const handleCloseModal = () => {
    showModal({ isOpen: false });
  };

  const handleOnConfirm = (data: FieldValues) => {
    update(
      'positionings',
      {
        data: {
          advisorCancellationReasonOther: data.cancelReasons?.includes(
            PositioningCancellationReason.Other
          )
            ? data.cancellationReasonOther
            : null,
          advisorCancellationReasons: data.cancelReasons || [],
          status: getCancelledStatus(currentStatus),
        },
        id: positioningId,
      },
      {
        onSuccess: () => onSuccess?.(),
      }
    );
  };

  return (
    <ActionModal
      title={translate('positioning.cancel.modalTitle', { customerFullName })}
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      callback={handleOnConfirm}
      saveLabel={translate('ra.action.confirm')}
      slug="positioning-cancel"
      resolver={yupResolver(cancelPositioningSchema)}
      defaultValues={{
        cancelReasons: advisorCancellationReasons,
        cancellationReasonOther: advisorCancellationReasonOther,
      }}
    >
      <FormDataConsumer>
        {({ formData }) => (
          <>
            <AutocompleteArrayInputWithCheckboxes
              blurOnSelect={false}
              choices={getAdvisorCancellationReasonChoices(translate)}
              disableCloseOnSelect
              filterSelectedOptions={false}
              fullWidth
              label="positioning.cancel.reasonsPlaceholder"
              isRequired
              source="cancelReasons"
              variant="outlined"
            />
            {!!formData.cancelReasons?.includes(
              PositioningCancellationReason.Other
            ) && (
              <TextArea
                multiline
                minRows={3}
                maxRows={10}
                label={translate('positioning.cancel.reasonOtherPlaceholder')}
                fullWidth
                source="cancellationReasonOther"
                variant="outlined"
              />
            )}
          </>
        )}
      </FormDataConsumer>
    </ActionModal>
  );
};
