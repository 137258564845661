import React, { useMemo } from 'react';

import {
  HttpError,
  NumberInput,
  useEditContext,
  useNotify,
  useStore,
  useTranslate,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { styled, Typography } from '@mui/material';
import { ActionModal } from 'components/action-modal/ActionModal';
import { FileInput } from 'components/custom-input/file-input/FileInput';
import { fileToBase64 } from 'components/image-input/utils/imageInput.formater.utils';
import { yupResolver } from '@hookform/resolvers/yup';
// eslint-disable-next-line max-len
import { useUploadCounterSignedOfferAction } from 'features/realEstates/details/edit/hooks/useUploadCounterSignedOfferAction';
import { RealEstateModalKey } from 'features/realEstates/constants';
import { Content } from 'features/realEstates/details/edit/components/action-modal/GeneratePurchaseOfferModal';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import { DateInput as BaseDateInput } from 'components/custom-input/date-input/DateInput';
import * as yup from 'yup';
import { RealEstateStatus } from 'types/realEstate.enum';
import { InvestorAutocomplete } from 'components/investor-autocomplete/InvestorAutocomplete';
import { DocumentCategory } from 'types/document.enum';

const TitleWithIcon = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  gap: 8px;
`;
const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const InputLabel = styled('div')`
  display: flex;
  flex-direction: column;
`;
const DateInput = styled(BaseDateInput)`
  width: 100%;
`;

export interface UploadCounterSignedOfferModalStore {
  isOpen: boolean;
  realEstate?: {
    status: RealEstateStatus;
    id: string;
  };
}

export const UploadCGPCounterSignedOfferModal: React.FC = () => {
  const notify = useNotify();
  const translate = useTranslate();
  const [{ isOpen, realEstate }, showModal] =
    useStore<UploadCounterSignedOfferModalStore>(
      RealEstateModalKey.UPLOAD_COUNTER_SIGNED_OFFER,
      { isOpen: false }
    );
  const { record } = useEditContext(
    realEstate ? { record: realEstate } : undefined
  );

  const isRealEstateAvailable = record?.status === RealEstateStatus.AVAILABLE;

  const uploadAction = useUploadCounterSignedOfferAction(record?.id);

  const validator = yup.object().shape({
    ...(isRealEstateAvailable && {
      agencyCommission: yup
        .number()
        .required('transaction.form.agencyCommissionRequired')
        .moreThan(0, 'transaction.form.nonNull')
        .typeError('transaction.form.nonNull'),
      customerId: yup.string().required('transaction.form.customerRequired'),
      netSeller: yup
        .number()
        .required('transaction.form.netSellerRequired')
        .moreThan(0, 'transaction.form.nonNull')
        .typeError('transaction.form.nonNull'),
    }),
    date: yup
      .string()
      .required('transaction.form.dateFieldRequired')
      .nullable(),
    salesAgreementEstimatedDate: yup
      .string()
      .required('transaction.form.dateFieldRequired'),
    authenticatedDeedReceptionEstimatedDate: yup
      .string()
      .required('transaction.form.dateFieldRequired'),
    file: yup.object().required('transaction.form.fileFieldRequired'),
  });

  const handleCloseModal = () => {
    showModal({ isOpen: false });
  };

  const handleOnError = (err: unknown) => {
    const error = err as HttpError;
    const message =
      error.status === 403
        ? `transaction.action.${error.message}`
        : 'ra.notification.http_error';

    notify(message, {
      type: 'error',
    });
  };

  const handleCallback = async ({
    date,
    file,
    customerId,
    netSeller,
    agencyCommission,
    salesAgreementEstimatedDate,
    authenticatedDeedReceptionEstimatedDate,
  }: FieldValues) => {
    const base64 = await fileToBase64(file.rawFile);

    uploadAction.mutate(
      {
        customerId,
        fileBase64Url: base64,
        offerDate:
          typeof date === 'string'
            ? new Date(date).toISOString()
            : date.toISOString(),
        realAgencyCommission: agencyCommission,
        realNetSeller: netSeller,
        salesAgreementEstimatedDate,
        authenticatedDeedReceptionEstimatedDate,
      },
      { onError: handleOnError, onSuccess: handleCloseModal }
    );
  };

  const ModalTitle = useMemo(
    () => (
      <TitleWithIcon>
        <HistoryEduIcon />
        <Typography variant="h6">
          {translate(`transaction.show.documents.upload_offre_achat.title`)}
        </Typography>
      </TitleWithIcon>
    ),
    [translate]
  );

  return (
    <ActionModal
      slug="cancel"
      resolver={yupResolver(validator)}
      isOpen={isOpen}
      title={ModalTitle}
      saveLabel={translate('ra.action.confirm')}
      onClose={handleCloseModal}
      callback={handleCallback}
      defaultValues={{
        file: undefined,
        offerDate: undefined,
      }}
    >
      <Content>
        <FileInput
          source="file"
          label="transaction.action.upload.file_label"
          helpText="transaction.action.upload.file_helpText"
        />

        <Wrapper>
          <InputLabel>
            <Typography variant="body1" fontWeight={500}>
              {translate(
                `transaction.show.documents.upload_offre_achat.subtitle`
              )}{' '}
              *
            </Typography>
            <Typography variant="body2">
              {translate('transaction.action.upload.date_helpText')}
            </Typography>
          </InputLabel>
          <DateInput label="" source="date" />

          <DateInput
            label={translate(
              'transaction.action.upload.salesAgreementEstimatedDate'
            )}
            source="salesAgreementEstimatedDate"
          />
          <DateInput
            label={translate(
              'transaction.action.upload.authenticatedDeedReceptionEstimatedDate'
            )}
            source="authenticatedDeedReceptionEstimatedDate"
          />
        </Wrapper>

        {!!isRealEstateAvailable && (
          <>
            <Wrapper>
              <InputLabel>
                <Typography variant="body1" fontWeight={500}>
                  {translate(
                    `transaction.show.documents.upload_offre_achat.customer`
                  )}
                </Typography>
              </InputLabel>

              <InvestorAutocomplete
                isExternal
                label=""
                missingDocumentTypes={[DocumentCategory.SearchMandate]}
                showMissingDocuments
                source="customerId"
                data-testid="form-input-customer-id"
              />
            </Wrapper>

            <Wrapper>
              <NumberInput
                margin="none"
                variant="outlined"
                label="transaction.show.documents.upload_offre_achat.netSeller"
                source="netSeller"
                data-testid="form-input-net-seller"
              />
            </Wrapper>

            <Wrapper>
              <NumberInput
                margin="none"
                variant="outlined"
                label="transaction.show.documents.upload_offre_achat.agencyCommission"
                source="agencyCommission"
                data-testid="form-input-agency-commission"
              />
            </Wrapper>
          </>
        )}
      </Content>
    </ActionModal>
  );
};
