export enum RealEstateModalKey {
  PUBLISH_CONFIRMATION = 'realEstate.edit.show_publish_confirmation_modal',
  TRANSFER_REAL_ESTATE = 'realEstate.edit.show_transfer_real_estate_modal',
  ARCHIVE = 'realEstate.edit.show_archive_modal',
  DOWNLOAD_BANK_PDF = 'realEstate.edit.show_downloadBankPdf_modal',
  ADD = 'realEstate.edit.show_add_modal',
  ADD_FORM = 'realEstate.edit.show_add_form_modal',
  GENERATE_PURCHASE_OFFER = 'realEstate.edit.show_generate_purchase_offer_modal',
  UPLOAD_COUNTER_SIGNED_OFFER = 'realEstate.edit.show_upload_counter_signed_offer_modal',
  PRE_BOOKING = 'realEstate.edit.show_pre_booking_modal',
}
