import React from 'react';

import { usePermissions, useRedirect } from 'ra-core';
import { PermissionName } from 'types/permission.types';
import { LoadingPage } from 'react-admin';
import { Box } from '@mui/material';
interface WithPermissionCheckProps {
  permission: PermissionName;
  redirectTo: string;
}

export const withPermissionCheck = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P & WithPermissionCheckProps> => {
  return function WithPermissionCheck({ permission, redirectTo }, ...props) {
    const { permissions, isLoading } = usePermissions<PermissionName[]>();
    const redirect = useRedirect();

    if (permissions && !permissions.includes(permission)) {
      redirect(redirectTo);
    }

    return isLoading ? (
      <Box flex={1}>
        <LoadingPage />
      </Box>
    ) : (
      <Component {...(props as P)} />
    );
  };
};
