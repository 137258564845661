import React, { MouseEvent } from 'react';

import { useRecordContext, useTranslate } from 'react-admin';
import { Radio } from '@mui/material';

import { RadioOptionProps } from './RadioOption.types';

export const RadioOption: React.FC<RadioOptionProps> = ({
  preventDefault = false,
  recordField = 'name',
  selectedOption,
  ...props
}) => {
  const record = useRecordContext();
  const translate = useTranslate();
  const label = translate(record[recordField]);
  const eventPreventDefault = (event: MouseEvent<HTMLDivElement>) =>
    preventDefault && event.preventDefault();

  return !record[recordField] ? null : (
    <div onClick={eventPreventDefault}>
      <Radio
        checked={selectedOption === record.id}
        inputProps={{ 'aria-label': label }}
        name={label}
        onChange={() => null}
        value={label}
        {...props}
      />
      <span>{label}</span>
    </div>
  );
};
