import { FunctionComponent } from 'react';

import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  Form,
  SaveButton,
  useTranslate,
  PasswordInput,
  useDataProvider,
} from 'react-admin';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthCard } from 'ui/organisms/auth-card/AuthCard';
import { FieldValues } from 'react-hook-form';
import { RenewPasswordBody } from 'types/customMethods.types';
import { Box } from '@mui/material';
import { HeliosDataProvider } from 'providers/admin/dataProvider';

import { passwordSchema } from '../resetPasswordValidation';

export const StepRenewPassword: FunctionComponent = () => {
  const [searchParams] = useSearchParams({});

  const navigate = useNavigate();
  const email = searchParams.get('email');
  const hash = searchParams.get('hash');
  const translate = useTranslate();
  const dataProvider = useDataProvider<HeliosDataProvider>();
  const handleSubmit = async (data: FieldValues) => {
    try {
      await dataProvider.renewPassword(data as RenewPasswordBody);
      return navigate({
        pathname: '/login',
        search: createSearchParams({ email: data.email }).toString(),
      });
    } catch (e: unknown) {
      return { login: translate('forgotPassword.genericError') };
    }
  };

  return (
    <AuthCard title={translate('forgotPassword.stepRenewPassword.title')}>
      <Form
        onSubmit={handleSubmit}
        defaultValues={{ email: email, hash: hash }}
        resolver={yupResolver(passwordSchema)}
      >
        <Box mb={2}>
          <PasswordInput
            label="forgotPassword.stepRenewPassword.passwordLabel"
            variant="outlined"
            source="password"
            size="medium"
            margin="none"
            fullWidth
            helperText={translate(
              'forgotPassword.stepRenewPassword.passwordMinLength'
            )}
            inputProps={{
              role: 'input',
            }}
            initiallyVisible
          />
        </Box>
        <SaveButton
          disabled={false}
          variant="contained"
          type="submit"
          size="large"
          fullWidth
          label="submit"
          icon={<></>}
          data-testid="forgot-password-renew-submit"
        >
          {translate('forgotPassword.stepRenewPassword.submit')}
        </SaveButton>
      </Form>
    </AuthCard>
  );
};
