import { NumberInput, useStore, useTranslate } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { ActionModal } from 'components/action-modal/ActionModal';
import { useRealEstateActions } from 'features/realEstates/details/edit/hooks/useRealEstateActions';
import { yupResolver } from '@hookform/resolvers/yup';
import { propertyDownloadBankPdfForm } from 'features/realEstates/details/edit/RealEstateEdit.validator';
import { PriceInput } from 'ui/atoms/price-input/PriceInput';
import { PercentInput } from 'ui/atoms/percent-input/PercentInput';
import { RealEstateModalKey } from 'features/realEstates/constants';

export const DownloadBankPdfActionModal: React.FC = () => {
  const translate = useTranslate();
  const [isModalOpen, showModal] = useStore(
    RealEstateModalKey.DOWNLOAD_BANK_PDF,
    false
  );

  const { downloadBankPdf } = useRealEstateActions();

  const handleCloseModal = () => {
    showModal(false);
  };

  const handleCallback = ({
    loanDownPayment,
    loanDuration,
    loanInterestRate,
  }: FieldValues) => {
    downloadBankPdf.mutate({ loanDownPayment, loanDuration, loanInterestRate });
    handleCloseModal();
  };

  return (
    <ActionModal
      slug="downloadBankPdf"
      resolver={yupResolver(propertyDownloadBankPdfForm)}
      isOpen={isModalOpen}
      title={translate('realEstate.action.downloadBankPdf.form_title')}
      saveLabel={translate('realEstate.action.downloadBankPdf.submit')}
      onClose={handleCloseModal}
      callback={handleCallback}
    >
      <PriceInput
        fullWidth
        label="realEstate.action.downloadBankPdf.input_loanDownPayment"
        variant="outlined"
        source="loanDownPayment"
      />
      <NumberInput
        fullWidth
        label="realEstate.action.downloadBankPdf.input_loanDuration"
        variant="outlined"
        source="loanDuration"
      />
      <PercentInput
        fullWidth
        label="realEstate.action.downloadBankPdf.input_loanInterestRate"
        variant="outlined"
        source="loanInterestRate"
      />
    </ActionModal>
  );
};
