import { AccordionForm } from 'ui/molecules/accordion-form/AccordionForm';
import { SelectInput, useTranslate } from 'react-admin';
import { TextArea } from 'ui/atoms/textarea/TextArea';
import { ImageInput } from 'components/image-input/ImageInput';
import { Fieldset } from 'ui/molecules/fieldset/Fieldset';

import { DPE_DATA } from 'constants/real-estates';

import { EditFormContentProps } from '../editFormContent.types';
import { sourceToLabelMap } from '../EditFormContent.utils';

export const AfterRenovationForm: React.FC<EditFormContentProps> = ({
  defaultExpanded,
  disabled = false,
}) => {
  const translate = useTranslate();

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate('realization.form.afterRenovation')}
    >
      <TextArea
        margin="none"
        minRows={1}
        maxRows={10}
        label={sourceToLabelMap.get('afterRenovation.description')}
        fullWidth
        source="afterRenovation.description"
        variant="outlined"
        disabled={disabled}
      />
      <SelectInput
        disabled={disabled}
        margin="none"
        variant="outlined"
        choices={DPE_DATA}
        translateChoice={false}
        source="afterRenovation.dpe"
        label={sourceToLabelMap.get('afterRenovation.dpe')}
      />
      <Fieldset title="image" />

      <ImageInput
        disabled={disabled}
        source="afterRenovation.images"
        isForRealization={true}
      />
      <TextArea
        margin="none"
        minRows={1}
        maxRows={10}
        label={sourceToLabelMap.get('afterRenovation.esthetics')}
        fullWidth
        source="afterRenovation.esthetics"
        variant="outlined"
        disabled={disabled}
      />
      <TextArea
        margin="none"
        minRows={1}
        maxRows={10}
        label={sourceToLabelMap.get('afterRenovation.layout')}
        fullWidth
        source="afterRenovation.layout"
        variant="outlined"
        disabled={disabled}
      />
      <TextArea
        margin="none"
        minRows={1}
        maxRows={10}
        label={sourceToLabelMap.get('afterRenovation.performances')}
        fullWidth
        source="afterRenovation.performances"
        variant="outlined"
        disabled={disabled}
      />
    </AccordionForm>
  );
};
