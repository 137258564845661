import {
  useDataProvider,
  useRefresh,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';
import { ActionMutationReturn } from 'types/common.types';
import { PublishValidationError } from 'types/realEstate.types';
import { HeliosDataProvider } from 'providers/admin/dataProvider';

import { useRealEstateDetailPermissions } from './useRealEstateDetailPermissions';
import { handlePublishActionErrors } from './usePublishActions.utils';

export type DoPublishMutationReturn = ActionMutationReturn<
  void,
  unknown,
  void,
  unknown
>;

export type AskPublishMutationReturn = ActionMutationReturn<
  void,
  unknown,
  void,
  unknown
>;

interface PublishActions {
  doPublish: DoPublishMutationReturn;
  askPublish: AskPublishMutationReturn;
}

export const usePublishActions = (
  formContext?: ReturnType<typeof useFormContext>
): PublishActions => {
  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext();
  const dataProvider = useDataProvider<HeliosDataProvider>();

  const { canAskPublish, canDoPublish } = useRealEstateDetailPermissions();

  const handleResponse = async (mutationKey: 'doPublish' | 'askPublish') => {
    try {
      const response = await dataProvider?.[mutationKey](record?.id as string);
      if (response) {
        refresh();
        notify(`realEstate.action.${mutationKey}.success`, {
          type: 'success',
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      switch (err.status) {
        case 400:
          handlePublishActionErrors(
            (err as PublishValidationError).body,
            formContext
          );
          break;
        case 403:
          notify(`realEstate.action.${mutationKey}.${err.message}`, {
            type: 'error',
          });
          break;
        default:
          notify('ra.notification.http_error', {
            type: 'error',
          });
      }

      throw err;
    }
  };

  const askPublish = useMutation<void, unknown, void, unknown>(
    ['askPublish', record?.id, dataProvider],
    () => handleResponse('askPublish')
  );

  const doPublish = useMutation<void, unknown, void, unknown>(
    ['doPublish', record?.id, dataProvider],
    () => handleResponse('doPublish')
  );

  return {
    askPublish: { ...askPublish, isAllowed: canAskPublish },
    doPublish: { ...doPublish, isAllowed: canDoPublish },
  };
};
