import { useEffect, useState } from 'react';

import { HeliosDataProvider } from 'providers/admin/dataProvider';
import { useDataProvider, useNotify } from 'react-admin';
import { RealEstate } from 'types/realEstate.types';

type ChangeEntriesHook = {
  data: {
    data: RealEstate;
    updatedBy: string;
  }[];
  loadMore: () => void;
  loading: boolean;
};

export const useGetRealEstateChangeEntries = (
  realEstateId?: string
): ChangeEntriesHook => {
  const dataProvider = useDataProvider<HeliosDataProvider>();
  const notify = useNotify();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<{ data: RealEstate; updatedBy: string }[]>(
    []
  );
  const [page, setPage] = useState(0);

  useEffect(() => {
    dataProvider
      .getChangeEntries(realEstateId || '', page)
      .then((response: any) => {
        setData((oldData) => [...oldData, ...response.data]);
      })
      .catch(() => {
        notify('An error occured during loading', {
          type: 'error',
        });
      })
      .finally(() => setLoading(false));
  }, [dataProvider, page, realEstateId, notify]);

  const loadMore = () => {
    setPage(page + 1);
  };

  return { data, loadMore, loading };
};
