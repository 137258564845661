import { URI } from 'constants/uri';

export const PageTrackName = {
  [URI.customers]: 'Helios view customer listing page',
  [URI.activities]: 'Helios view activities listing page',
  [URI.organizations]: 'Helios view organization page',
  [URI.organizations + '/create']: 'Helios create organization page',
  [URI.help]: 'Helios view help page',
  [URI.home]: 'Helios view properties listing page',
  [URI.login]: 'Helios view login page',
  [URI.positionings]: 'Helios view positionings listing page',
  [URI.realEstates]: 'Helios view properties listing page',
  [URI.roles]: 'Helios view roles page',
  [URI.transactions]: 'Helios view transactions listing page',
  [URI.users]: 'Helios view users page',
  [URI.realizations]: 'Helios view realizations page',
  customer: 'Helios view customer page',
  realEstate: 'Helios view real-estate detail page',
  realEstatePositionings: 'Helios view real-estate positionings page',
  transaction: 'Helios view transaction detail page',
  [URI.realEstatesCGP]: 'Helios view CGP RealEstate',
  [URI.transactionsCGP]: 'Helios view CGP Transaction',
  [URI.externalCustomers]: 'Helios view External Customers',
} as const;
