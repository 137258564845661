import { Box, Button, ButtonGroup } from '@mui/material';
import { useTranslate } from 'react-admin';
import { RealizationAction } from 'types/realization.types';
import { LoadingDropdown } from 'ui/molecules/dropdown/Dropdown';
import { useFormContext } from 'react-hook-form';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { PermissionName } from 'types/permission.types';
import { SaveButton } from 'components/save-button/SaveButton';
import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';

// eslint-disable-next-line no-restricted-imports
import { useRealizationActions } from '../../hooks/useRealizationActions';

export const ActionButtons: React.FC = () => {
  const translate = useTranslate();

  const formContext = useFormContext();
  const { hasPermission } = useHeliosPermissions();
  const { dropdownActions } = useRealizationActions(formContext);

  const loadingItem = dropdownActions.find((item) => item.loading);

  const { clearErrors } = formContext;

  const onSaveButtonClick = () => {
    clearErrors();
  };

  const showLoader = loadingItem?.loading || formContext.formState.isSubmitting;

  return (
    <Box display="flex" flexDirection="row" gap={1}>
      <LoadingDropdown
        loading={showLoader}
        onSelect={(action) => (action as RealizationAction).callback?.()}
        items={dropdownActions}
        keyExtractor={(action) => (action as RealizationAction).name}
        renderItem={(action) => translate((action as RealizationAction).name)}
      >
        <ButtonGroup variant="outlined" disabled={showLoader}>
          <Button variant="outlined">
            {translate(loadingItem?.name ?? 'realization.action.label')}
          </Button>
          <Button size="small">
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      </LoadingDropdown>

      {!!hasPermission(PermissionName.UpdateRealization) && (
        <SaveButton
          isFetching={!!showLoader}
          onClick={onSaveButtonClick}
          label={translate('ra.action.save')}
        />
      )}
    </Box>
  );
};
