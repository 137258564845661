import { format } from 'date-fns';

/**
 * This method takes the input date and return a new Date object forcing UTC timezone.
 *
 * How it works : The input date in the example will be 1970-01-01T00:00:00.000+02:00
 * The method uses format() from date-fns to change the date to ISO format 'yyyy-MM-ddTHH:mm:ss.SSSxxx'
 * but the formatting forces the UTC format by replacing xxx with 'Z'. Because date-fns is using the browser timezone,
 * the output will be 1970-01-01T00:00:00.000Z instead of 1970-01-01T00:00:00.000+02:00.
 * Since it's now an ISO date string forcing UTC, we can recreate a JavaScript Date object with it.
 *
 * @param date {Date} Date with current timezone
 * @return {Date} New Date object with same date but Forced UTC timezone
 */
export const toAbsoluteUTCDate = (date: Date): Date => {
  return new Date(format(date.getTime(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"));
};
