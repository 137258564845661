import { Chip as MuiChip, styled } from '@mui/material';

import { ChipProps } from './Chip.types';
import { shouldForwardProp } from './Chip.utils';

export const Chip = styled(MuiChip, { shouldForwardProp })<{
  bgColor?: ChipProps['bgColor'];
  textColor?: ChipProps['textColor'];
}>`
  ${({ bgColor }) => (bgColor ? `background-color: ${bgColor};` : '')};
  ${({ textColor }) => (textColor ? `color: ${textColor};` : '')};
  text-overflow: ellipsis;
`;
