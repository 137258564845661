import React from 'react';

import { useNotify, useTranslate } from 'react-admin';
import { Button } from 'ui/atoms/button/Button';

import { CopyToClipboardButtonProps } from './CopyToClipboardButton.types';

export const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  toCopy: text,
}) => {
  const notify = useNotify();
  const translate = useTranslate();

  const copyToClipboard = () => {
    if (text) {
      navigator.clipboard.writeText(text);
      notify('shared.copied');
    }
  };

  return (
    <Button disabled={!text} variant="text" onClick={copyToClipboard}>
      {translate('shared.copy')}
    </Button>
  );
};
