import { useCallback } from 'react';

import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';
import {
  AutocompleteInput,
  AutocompleteInputProps,
  RaRecord,
  useGetIdentity,
  useGetList,
  useInput,
} from 'react-admin';
import { PermissionName, Scope } from 'types/permission.types';
import { Organization } from 'types/organizations.types';

export const OrganizationInput: React.FC<
  AutocompleteInputProps<Organization>
> = (props) => {
  const { field: advisorField } = useInput({ source: 'advisor' });
  const { getScope } = useHeliosPermissions();
  const { identity } = useGetIdentity();

  const { isLoading, isFetching, data } = useGetList<RaRecord>(
    'organizations',
    {
      sort: { field: 'name', order: 'ASC' },
    }
  );

  const getOptionDisabled = useCallback(
    (option: Organization) => {
      if (
        getScope(PermissionName.UpdateProjectTransferOwnership) ===
        Scope.Organization
      ) {
        return option.id !== identity?.organization;
      }
      return false;
    },
    [getScope, identity]
  );

  const handleOnChange = () => {
    advisorField.onChange('');
  };

  return (
    <AutocompleteInput<Organization>
      {...props}
      choices={data?.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      })}
      isLoading={isLoading}
      isFetching={isFetching}
      onChange={handleOnChange}
      variant="outlined"
      getOptionDisabled={getOptionDisabled}
    />
  );
};
