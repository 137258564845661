import { validatePhoneNumberWithOptions } from 'utils/form/form.utils';
import * as yup from 'yup';

export const firstNameSchema = yup
  .string()
  .nullable()
  .required('shared.inputErrors.firstNameRequired');
export const lastNameSchema = yup
  .string()
  .nullable()
  .required('shared.inputErrors.lastNameRequired');
export const phoneNumberSchema = yup
  .string()
  .test(
    'is-international-phone-number',
    'shared.inputErrors.invalidPhoneNumber',
    (params) => validatePhoneNumberWithOptions(params, true)
  );
export const addressSchema = yup.object().shape({
  street: yup.string().nullable().required('shared.location.addressRequired'),
});
