import React from 'react';

import { SaveButton } from 'components/save-button/SaveButton';
import { useEditContext, useSaveContext, useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Box, Button, ButtonGroup, Theme, useMediaQuery } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useRealEstateActions } from 'features/realEstates/details/edit/hooks/useRealEstateActions';
import { LoadingDropdown } from 'ui/molecules/dropdown/Dropdown';
import { RealEstate, RealEstateAction } from 'types/realEstate.types';
import { useRealEstateDetailPermissions } from 'features/realEstates/details/edit/hooks/useRealEstateDetailPermissions';
import { PublishConfirmationModal } from 'features/realEstates/components/modals/PublishConfirmationModal';

import { PublishButton } from './PublishButton';

export const ActionButtons: React.FC = () => {
  const editContext = useEditContext<RealEstate>();
  const saveContext = useSaveContext();
  const translate = useTranslate();
  const formContext = useFormContext();
  const { dropdownActions, doPublish, askPublish } = useRealEstateActions(
    formContext,
    editContext,
    saveContext
  );
  const { canEditRealEstate } = useRealEstateDetailPermissions();

  const loadingItem = dropdownActions.find((item) => item.loading);

  const { clearErrors } = formContext;

  const isSmall = useMediaQuery(
    (theme: Theme) => `(max-width:${theme.breakpoints.values.lg}px)`
  );

  const onSaveButtonClick = () => {
    askPublish.reset();
    doPublish.reset();
    clearErrors();
  };

  const showLoader =
    loadingItem?.loading ||
    formContext.formState.isSubmitting ||
    editContext.saving;

  return (
    <Box display="flex" flexDirection="row" gap={1}>
      <LoadingDropdown
        loading={showLoader}
        onSelect={(action) => (action as RealEstateAction).callback?.()}
        items={dropdownActions}
        keyExtractor={(action) => (action as RealEstateAction).name}
        renderItem={(action) => translate((action as RealEstateAction).name)}
      >
        <ButtonGroup variant="outlined" disabled={showLoader}>
          <Button variant="outlined">
            {translate(loadingItem?.name ?? 'realEstate.action.label')}
          </Button>
          <Button size="small">
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      </LoadingDropdown>

      {!isSmall && <PublishButton />}

      {!!canEditRealEstate && (
        <SaveButton
          isFetching={!!showLoader}
          onClick={onSaveButtonClick}
          label={translate('ra.action.save')}
        />
      )}
      <PublishConfirmationModal />
    </Box>
  );
};
