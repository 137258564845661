import React from 'react';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useTranslate } from 'react-admin';

import { AlertInfo, AlertTitle, AlertWarning } from './Disclaimer.styles';

export type DisclaimerProps = {
  title: string;
  content: string;
  type: 'warning' | 'info';
};

const components = {
  info: AlertInfo,
  warning: AlertWarning,
};

export const Disclaimer: React.FC<DisclaimerProps> = ({
  title,
  content,
  type,
}) => {
  const translate = useTranslate();

  const Component = components[type];

  return (
    <Component role={type}>
      <AlertTitle>
        <WarningAmberIcon />
        {translate(title).toUpperCase()}
      </AlertTitle>

      {translate(content)}
    </Component>
  );
};
