import { useCallback } from 'react';

import { TextFieldProps, TextField } from '@mui/material';
import { useInput, useTranslate } from 'react-admin';
import { DatePicker } from '@mui/x-date-pickers';

import { DateBaseProps } from './DateInput.types';
import { transformHelperText } from './dateInput.utils';

export const DateInput: React.FC<DateBaseProps> = ({
  source,
  helperText,
  margin,
  addADay,
  ...rest
}) => {
  const translate = useTranslate();
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useInput({
    source,
  });

  const errorText = error?.message ? translate(error?.message) : null;

  const renderInput = useCallback(
    (params: TextFieldProps) => {
      const hasError = !!errorText || params.error;

      return (
        <TextField
          {...params}
          error={hasError}
          helperText={
            hasError
              ? errorText || translate('shared.form.date.errors.format')
              : transformHelperText(helperText)
          }
          variant="outlined"
          size="small"
          margin={margin}
        />
      );
    },
    [errorText, helperText, margin, translate]
  );

  const onDateChange = (date: Date | null) => {
    if (addADay) {
      date?.setHours(23, 59, 59);
    }
    onChange(date);
  };

  return (
    <DatePicker
      {...rest}
      value={value ? new Date(value) : null}
      onChange={onDateChange}
      renderInput={renderInput}
    />
  );
};
