import { Form as RaForm, FormProps } from 'react-admin';
import { SubmitHandler, FieldValues } from 'react-hook-form';

import { DateLocalizationProvider } from './DateLocalizationProvider';

export type ExtendedFormProps<RecordType extends FieldValues> = Omit<
  FormProps,
  'onSubmit'
> & {
  // Allow us to use the Generic Type <T> from Form so we can type the onSubmit function
  onSubmit?: SubmitHandler<RecordType>;
};

export const Form = <T extends FieldValues>(
  props: ExtendedFormProps<T>
): JSX.Element => {
  return (
    <DateLocalizationProvider>
      <RaForm {...(props as FormProps)} />
    </DateLocalizationProvider>
  );
};
