import {
  AnalyticsBrowser,
  AnalyticsBrowserSettings,
  InitOptions,
  JSONObject,
} from '@segment/analytics-next';
import { throttle } from 'lodash';

import { URI } from 'constants/uri';

import { PageTrackName } from './pageTrackName.const';
import { TrackName } from './segment.enums';

let client: AnalyticsBrowser | null = null;
const segmentIntegrations: Record<string, boolean | JSONObject | undefined> =
  {};

function configureSegmentIntegrations() {
  // set the session_id for amplitude
  // fix sessionId -1 on amplitude: see https://segment.com/docs/connections/destinations/catalog/amplitude/
  const amplitudeIntegration: JSONObject = {
    // eslint-disable-next-line no-magic-numbers
    session_id: Math.floor(Date.now() / 1000),
  };

  // map Amplitude for legacy reason
  segmentIntegrations['Amplitude'] = amplitudeIntegration;
  // map on Actions Amplitude for analytics.js 2.0
  segmentIntegrations['Actions Amplitude'] = amplitudeIntegration;
}

export function segmentInit(): AnalyticsBrowser | null {
  const API_KEY = process.env.REACT_APP_SEGMENT_API_KEY;
  const API_HOST = process.env.REACT_APP_SEGMENT_API_HOST;
  const CDN_HOST = process.env.REACT_APP_SEGMENT_CDN_HOST;
  const isProd = process.env.REACT_APP_ENV === 'production';

  if (!API_KEY) {
    if (isProd) {
      throw new Error('Missing the REACT_APP_SEGMENT_API_KEY env value');
    } else {
      return null;
    }
  }

  const analyticsOptions: InitOptions = {
    integrations: {},
  };
  const analyticsSettings: AnalyticsBrowserSettings = {
    writeKey: API_KEY,
  };

  configureSegmentIntegrations();

  if (API_HOST && analyticsOptions.integrations) {
    analyticsOptions.integrations['Segment.io'] = {
      apiHost: API_HOST,
    };
  }

  if (CDN_HOST) {
    analyticsSettings.cdnURL = CDN_HOST;
  }

  client = AnalyticsBrowser.load(analyticsSettings, analyticsOptions);
  return client;
}

export interface SegmentIdentifyProps {
  email: string;
  firstName: string;
  organization: string;
  isMandatary: boolean;
  lastName: string;
  sub: string;
}

export async function segmentIdentify({
  email,
  firstName,
  organization,
  isMandatary,
  lastName,
  sub,
}: SegmentIdentifyProps): Promise<void> {
  const isDev = process.env.REACT_APP_ENV !== 'production';
  const identifyData = {
    email,
    firstName,
    id: sub,
    isMandatary,
    lastName,
    organization,
  };

  if (isDev) {
    console.info('[Tracking] Identify', identifyData);
  }

  await client?.identify(identifyData.id, identifyData, {
    integrations: segmentIntegrations,
  });
}

async function segmentPageNoThrottle(
  pathname: string,
  data: Record<string, string | number | boolean> = {}
): Promise<void> {
  let pageName;
  const isDev = process.env.REACT_APP_ENV !== 'production';

  if (pathname === '/') {
    return;
  } else if (PageTrackName[pathname as URI]) {
    pageName = PageTrackName[pathname as URI];
  } else if (pathname.startsWith(`${URI.customers}?`)) {
    pageName = PageTrackName[URI.customers];
  } else if (pathname.startsWith(`${URI.customers}/`)) {
    pageName = PageTrackName.customer;
  } else if (
    pathname.startsWith(`${URI.transactions}/`) &&
    pathname.endsWith('/show')
  ) {
    pageName = PageTrackName.transaction;
  } else if (
    pathname.startsWith(`${URI.realEstates}/`) &&
    pathname.endsWith('/positionings')
  ) {
    pageName = PageTrackName.realEstatePositionings;
  } else if (pathname.startsWith(`${URI.realEstates}/`)) {
    pageName = PageTrackName.realEstate;
  } else if (pathname.startsWith(`${URI.realizations}/`)) {
    pageName = PageTrackName.realEstate;
  } else if (pathname.startsWith(`${URI.transactionsCGP}/`)) {
    pageName = PageTrackName[URI.transactionsCGP];
  } else if (pathname.startsWith(`${URI.realEstatesCGP}/`)) {
    pageName = PageTrackName[URI.realEstatesCGP];
  } else if (pathname.startsWith(`${URI.externalCustomers}/`)) {
    pageName = PageTrackName[URI.externalCustomers];
  } else {
    console.error('Page track name missing for this pathname:', pathname);
  }

  if (isDev) {
    console.info('[Tracking] Page', { data, pageName });
  }

  await client?.page(pageName, data, {
    integrations: segmentIntegrations,
  });
}

// To avoid to launch the track many times for the same page
const throttled = throttle(
  (pathname: string, data: Record<string, string | number | boolean>) =>
    segmentPageNoThrottle(pathname, data),
  100,
  { leading: true, trailing: false }
);

export async function segmentPage(
  pathname: string,
  data: Record<string, string | number | boolean> = {}
): Promise<void> {
  throttled(pathname, data);
}

export async function segmentTrack(
  eventName: TrackName,
  data: Record<string, string | number | boolean> = {}
): Promise<void> {
  const isDev = process.env.REACT_APP_ENV !== 'production';

  if (isDev) {
    console.info('[Tracking] Track', { data, eventName });
  }

  await client?.track(eventName, { ...data, source: 'helios' });
}

export async function segmentReset(): Promise<void> {
  const isDev = process.env.REACT_APP_ENV !== 'production';

  if (isDev) {
    console.info('[Tracking] Reset');
  }

  await client?.reset();
}
