import React from 'react';

import { useRecordContext, useStore, useTranslate } from 'react-admin';
import { CustomerEditFormatted, CustomerModalKey } from 'types/customer.types';
import { Button } from 'ui/atoms/button/Button';
import { SaveButton } from 'components/save-button/SaveButton';
import { useCustomerPermissions } from 'features/customers/hooks/useCustomerPermissions';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { getShortOrRegularTranslateKey } from 'utils/strings/getShortOrRegularTranslateKey';
import { truncate } from 'utils/strings/truncate';

import { ActionButton } from './action-button/ActionButton';

export const ActionButtons: React.FC = () => {
  const customer = useRecordContext<CustomerEditFormatted>();
  const { checkCanEditCustomer, checkCanSeeContactDetails } =
    useCustomerPermissions();
  const translate = useTranslate();
  const [, setContactInformationModal] = useStore(
    CustomerModalKey.CONTACT_INFORMATION
  );

  const openContactInformationModal = () => {
    setContactInformationModal([true]);
  };

  const isSmall = useMediaQuery(
    (t: Theme) => `(max-width:${t.breakpoints.values.sm}px)`
  );

  const saveButtonLabel = isSmall
    ? truncate(translate('ra.action.save'), 6)
    : translate('ra.action.save');

  return (
    <Box display="flex" flexDirection="row" gap={1}>
      <ActionButton />
      <Box>
        <Button
          disabled={!checkCanSeeContactDetails(customer)}
          onClick={openContactInformationModal}
          variant="outlined"
        >
          {translate(
            getShortOrRegularTranslateKey(
              'customer.contactInformation.button',
              isSmall
            )
          )}
        </Button>
      </Box>
      {!!checkCanEditCustomer(customer) && (
        <SaveButton
          data-testid="customer-edit-save-button"
          label={saveButtonLabel}
        />
      )}
    </Box>
  );
};
