import { useRecordContext, useStore, useTranslate } from 'react-admin';
import { RealEstateModalKey } from 'features/realEstates/constants';
import { ActionModal } from 'components/action-modal/ActionModal';
import { RealEstate } from 'types/realEstate.types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTransferRealEstateAction } from 'features/realEstates/details/edit/hooks/useTransferRealEstateAction';

import { OrganizationInput } from './form/OrganizationInput';
import { UserInput } from './form/UserInput';
import { transferValidator } from './form/transfer.validator';

export const TransferRealEstateModal: React.FC = () => {
  const translate = useTranslate();
  const [isModalOpen, showModal] = useStore(
    RealEstateModalKey.TRANSFER_REAL_ESTATE,
    false
  );

  const { mutate: transfer, isLoading } = useTransferRealEstateAction();

  const handleCallback = (payload: TransferRealEstatePayload) => {
    transfer(payload);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    showModal(false);
  };

  const realEstate = useRecordContext<RealEstate>();
  const defaultValues: TransferRealEstatePayload = {
    contactId: realEstate.contact?.id ?? '',
    hunterId: realEstate.hunter.id,
    id: realEstate.id.toString(),
    organizationId: realEstate.organization,
  };

  return (
    <ActionModal<TransferRealEstatePayload>
      defaultValues={defaultValues}
      resolver={yupResolver(transferValidator)}
      slug="transferRealEstate"
      isOpen={isModalOpen}
      title={translate('realEstate.action.transfer.formTitle')}
      saveLabel={translate('realEstate.action.transfer.submit')}
      onClose={handleCloseModal}
      disableSaveButton={isLoading}
      callback={handleCallback}
    >
      <OrganizationInput
        label="realEstate.action.transfer.fields.organizationId"
        source="organizationId"
      />
      <UserInput
        source="hunterId"
        label="realEstate.action.transfer.fields.hunterId"
      />
      <UserInput
        source="contactId"
        label="realEstate.action.transfer.fields.contactId"
      />
    </ActionModal>
  );
};

export interface TransferRealEstatePayload {
  id: string;
  organizationId: string;
  hunterId: string;
  contactId: string;
}
