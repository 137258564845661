export enum RealizationStatus {
  ARCHIVED = 'ARCHIVED',
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
}

export enum InvestmentStrategy {
  Patrimonial = 'PATRIMONIAL',
  Yield = 'YIELD',
}
