import React from 'react';

import { Trans } from 'react-i18next';
import { Link, Typography } from '@mui/material';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { FileField, useTranslate } from 'react-admin';

import { LINK_TO_COMPRESS_FILE } from 'constants/files';

import {
  FileInputStyled,
  InputLabel,
  InputPreview,
  Wrapper,
} from './FileInput.styles';
import { FileInputProps } from './FileInput.types';

export const FileInput: React.FC<FileInputProps> = ({
  helpText,
  label,
  error,
  source,
  onDrop,
}) => {
  const translate = useTranslate();

  const Placeholder = (
    <p>
      <UploadFileIcon color="primary" /> <br />
      <Trans
        mt={4}
        i18nKey="shared.file-input.placeholder"
        components={{
          strong: (
            <Typography
              color={(theme) => theme.palette.primary.main}
              component="span"
            />
          ),
        }}
      />
      <Typography variant="body2" fontWeight="normal" mt={2}>
        {translate('shared.file-input.subtitle')}
      </Typography>
    </p>
  );

  return (
    <Wrapper>
      <InputLabel>
        <Typography variant="body1" fontWeight={500}>
          {translate(label)}
        </Typography>
        <Typography variant="body2">
          <Trans i18nKey={helpText} />
        </Typography>
        <Link
          href={LINK_TO_COMPRESS_FILE}
          mt={1}
          target="_blank"
          variant="caption"
        >
          {translate('shared.file-input.linkToCompressTitle')}
        </Link>
      </InputLabel>
      <FileInputStyled
        label={false}
        source={source}
        placeholder={Placeholder}
        options={{ onDrop }}
        accept="image/*,application/pdf,doc,docx,application/msword"
      >
        <>
          <InputPreview>
            <InsertDriveFileOutlinedIcon />
            <FileField source="src" title="title" target="_blank" />
          </InputPreview>
          {!!error && (
            <Typography variant="subtitle2" color="error">
              {error}
            </Typography>
          )}
        </>
      </FileInputStyled>
    </Wrapper>
  );
};
