import { GridValueFormatterParams } from '@mui/x-data-grid';
import { isString, isArray } from 'lodash';
import { Translate } from 'react-admin';
import {
  ChipListListProps,
  ChipListProps,
} from 'ui/molecules/chips-list/ChipList';
import { YesNoOrAny } from 'types/common.types';
import { Theme } from '@mui/material';
import { isNullOrUndefined } from 'utils/object/object.utils';
import { TerritoryStatus } from 'types/organizations.types';

const isYes = (value: string | boolean | null) =>
  value === true ||
  value === 'true' ||
  (isString(value) && value?.toLowerCase() === 'yes');

const isNo = (value: string | boolean | null) =>
  value === false ||
  value === 'false' ||
  (isString(value) && value?.toLowerCase() === 'no');

const isAny = (value: string | boolean | null) =>
  isString(value) && value.toLowerCase() === 'any';

export const formatYesNoOrAnyValue = (
  value: string | boolean | null
): YesNoOrAny | null => {
  if (isYes(value)) {
    return YesNoOrAny.YES;
  }

  if (isNo(value)) {
    return YesNoOrAny.NO;
  }

  if (isAny(value)) {
    return YesNoOrAny.ANY;
  }

  return null;
};

const YES_NO_OR_ANY_TYPE_TO_CHIP_LIST_PROPS = {
  [YesNoOrAny.YES]: {
    color: 'green',
    label: 'shared.yes',
  },
  [YesNoOrAny.NO]: {
    color: 'red',
    label: 'shared.no',
  },
  [YesNoOrAny.ANY]: {
    label: 'shared.any',
  },
};

type ValueListCommonType = {
  label?: string;
};

type ValueListValueType =
  | (ValueListCommonType & {
      color?: string;
      bgColor?: undefined;
      textColor?: undefined;
    })
  | (ValueListCommonType & {
      color?: undefined;
      bgColor?: string;
      textColor?: string;
    });

export type ValueListType = Record<string, ValueListValueType>;

export const formatChipListValue =
  (translate: Translate, theme: Theme, valueList: ValueListType = {}) =>
  (param: GridValueFormatterParams): ChipListProps['list'] => {
    const value = param.value || [];

    if (!isArray(value) || !value.length) {
      return [];
    }

    return value
      .filter((x: unknown) => !isNullOrUndefined(x))
      .map((key: string) => {
        const { color, bgColor, textColor, label = key } = valueList[key] || {};

        const paletteColor = color ? theme.palette.colors?.[color]?.[50] : null;
        const paletteTxtColor = color
          ? theme.palette.colors?.[color]?.[900]
          : null;

        return {
          bgColor: bgColor || paletteColor,
          label: translate(label),
          textColor: textColor || paletteTxtColor,
        };
      });
  };

export const formatYesNoOrAnyValueForChipList =
  (translate: Translate, theme: Theme) =>
  (param: GridValueFormatterParams): ChipListProps['list'] => {
    const formattedValue = formatYesNoOrAnyValue(param.value);

    if (!formattedValue) {
      return [];
    }

    return formatChipListValue(
      translate,
      theme,
      YES_NO_OR_ANY_TYPE_TO_CHIP_LIST_PROPS
    )({ ...param, value: [formattedValue] });
  };

export const formatOrganizationTerritoriesChip =
  (theme: Theme) =>
  ({ value }: GridValueFormatterParams): ChipListListProps => {
    return value.map((v: { name: string; status: TerritoryStatus }) => ({
      bgColor:
        theme.palette[TERRITORY_STATUS_TO_CHIP_LIST_PROPS[v.status]].light ||
        'grey',
      label: v.name,
    }));
  };

const TERRITORY_STATUS_TO_CHIP_LIST_PROPS: Record<
  TerritoryStatus,
  'success' | 'primary' | 'warning' | 'error'
> = {
  [TerritoryStatus.ACTIVE]: 'success',
  [TerritoryStatus.DISABLED]: 'error',
  [TerritoryStatus.ACTIVE_WITH_END_DATE]: 'warning',
  [TerritoryStatus.ACTIVE_SOON]: 'primary',
};
