import crudRestProvider from 'ra-data-simple-rest';
import { fetchUtils } from 'react-admin';
import {
  get,
  httpClientAuth,
  post,
  put,
} from 'utils/http-client-auth/httpClientAuth';
import {
  ArchiveBody,
  DownloadBankPdfBody,
  DuplicatedBody,
  ForgotPasswordBody,
  GeneratePurchaseOfferBody,
  GenerateSearchMandateBody,
  RenewPasswordBody,
  ResetPasswordBody,
  ResetPasswordResponseBody,
  UploadFileBody,
  UploadCounterSignedOfferBody,
  UploadImageBody,
  UploadImageResponse,
  UpdateBroughtByBody,
  UpdateRealizationStatusBody,
  UploadProfilePictureBody,
  UploadSignatureBody,
} from 'types/customMethods.types';
import { FormSpecificities, RealEstate } from 'types/realEstate.types';
import {
  TransactionCancelBody,
  UpdateTodoBody,
} from 'types/transactionCustomMethods.types';
import { Documents, Transaction } from 'types/transaction.types';
import { TransferRealEstatePayload } from 'components/action-modal/TransferRealEstate/TransferRealEstateModal';
import { TransferTransactionPayload } from 'components/action-modal/TransferTransaction/TransferTransactionModal';
import {
  BookRealEstateBody,
  BookingDetails,
  GenerateCgpPublicLinkResponse,
} from 'types/cgpCustomMethods.types';

import { getGetListMethod } from './dataProvider.utils';
import { CustomerGEDDocuments } from 'types/customer.types';

const apiUrl = process.env.REACT_APP_API_URL;
const identityApiUrl = process.env.REACT_APP_IDENTITY_API_URL;

if (!apiUrl) {
  throw Error('REACT_APP_API_URL is not defined');
}

const restMethods = crudRestProvider(apiUrl, httpClientAuth);

const transactionCustomMethods = {
  cancelTransaction: (id: string, body: TransactionCancelBody) =>
    post<void>(`${apiUrl}/transactions/${id}/actions/cancel`, {
      body: JSON.stringify(body),
    }),
  downloadDocument: (id: string) =>
    get<File>(`${apiUrl}/documents/${id}/file`, {
      responseType: 'file',
      shouldDownload: true,
    }),

  updateTodo: (id: string, body: UpdateTodoBody) =>
    post<Transaction>(`${apiUrl}/transactions/${id}/actions/update-step`, {
      body: JSON.stringify(body),
    }),

  viewDocument: (id: string) =>
    get<File>(`${apiUrl}/documents/${id}/file`, {
      headers: {
        Accept: 'application/pdf',
        'Content-Type': 'application/pdf',
      },
      responseType: 'file',
    }),
};

const customerCustomMethods = {
  generateExternalCustomerSearchMandate: (
    externalCustomerId: string,
    body: GenerateSearchMandateBody
  ) =>
    post<void>(
      `${apiUrl}/external-customers/${externalCustomerId}/actions/generate-search-mandate`,
      {
        body: JSON.stringify(body),
      }
    ),
  generateSearchMandate: (
    customerId: string,
    body: GenerateSearchMandateBody
  ) =>
    post<void>(
      `${apiUrl}/customers/${customerId}/actions/generate-search-mandate`,
      {
        body: JSON.stringify(body),
      }
    ),
  updateBroughtBy: (customerId: string, body: UpdateBroughtByBody) =>
    put<void>(`${apiUrl}/customers/${customerId}/actions/brought-by`, {
      body: JSON.stringify(body),
    }),
  uploadDocument: (customerId: string, body: UploadFileBody) =>
    post<void>(`${apiUrl}/customers/${customerId}/actions/upload-document`, {
      body: JSON.stringify(body),
    }),
  uploadProfilePicture: (customerId: string, body: UploadProfilePictureBody) =>
    post<void>(`${apiUrl}/users/${customerId}/actions/upload-profile-picture`, {
      body: JSON.stringify(body),
    }),
  uploadSignature: (body: UploadSignatureBody) =>
    post<void>(`${apiUrl}/user-signatures`, {
      body: JSON.stringify(body),
    }),
  getCustomersDocuments: (customerId: string) =>
    get<CustomerGEDDocuments>(`${apiUrl}/customers/${customerId}/documents`),
};

const realEstateCustomMethods = {
  archive: (id: string, body: ArchiveBody) =>
    put<void>(`${apiUrl}/real-estates/${id}/action/archive`, {
      body: JSON.stringify(body),
    }),
  askPublish: (id: string) =>
    put<void>(`${apiUrl}/real-estates/${id}/action/publish-request`),
  doPublish: (id: string) =>
    put<void>(`${apiUrl}/real-estates/${id}/action/publish-validation`),
  downloadBankPdf: (id: string, body: DownloadBankPdfBody) =>
    get<void>(
      `${apiUrl}/real-estates/${id}/action/download-bank-pdf?${fetchUtils.queryParameters(
        body
      )}`,
      { responseType: 'file' }
    ),
  downloadPropertyPdf: (id: string) =>
    get<void>(`${apiUrl}/real-estates/${id}/action/download-property-pdf`, {
      responseType: 'file',
    }),
  duplicate: (
    id: string,
    body: DuplicatedBody
  ): Promise<{ data: Partial<RealEstate> }> =>
    post<Partial<RealEstate>>(`${apiUrl}/real-estates/${id}/action/duplicate`, {
      body: JSON.stringify(body),
    }),
  formSpecificities: (countryCode: string) =>
    get<FormSpecificities>(
      `${apiUrl}/real-estates/form-specificities/${countryCode}`
    ),
  generatePurchaseOffer: (
    id: string,
    body: GeneratePurchaseOfferBody
  ): Promise<unknown> =>
    post<Documents>( // on peut faire meilleur comme type pour document
      `${apiUrl}/real-estates/${id}/action/generate-purchase-offer`,
      {
        body: JSON.stringify(body),
      }
    ),
  getChangeEntries: (id: string, page = 0) =>
    get(`${apiUrl}/change-entries/real-estates/${id}?page=${page}`),
  transferProject: ({ id, ...body }: TransferTransactionPayload) =>
    post<RealEstate>(`${apiUrl}/transactions/${id}/actions/transfer`, {
      body: JSON.stringify(body),
    }),
  transferRealEstate: ({ id, ...body }: TransferRealEstatePayload) =>
    put<RealEstate>(`${apiUrl}/real-estates/${id}/action/transfer`, {
      body: JSON.stringify(body),
    }),
  uploadCounterSignedOffer: (
    id: string,
    body: UploadCounterSignedOfferBody
  ): Promise<{ data: void }> =>
    post<void>(
      `${apiUrl}/real-estates/${id}/action/upload-counter-signed-offer`,
      {
        body: JSON.stringify(body),
      }
    ),
  uploadImage: (id: string, body: UploadImageBody) =>
    post<UploadImageResponse>(`${apiUrl}/real-estates/${id}/upload-image`, {
      body: JSON.stringify(body),
    }),
};

const identityCustomMethods = {
  forgotPassword: (body: ForgotPasswordBody) =>
    post<void>(`${identityApiUrl}/forgot-password`, {
      body: JSON.stringify(body),
    }),
  renewPassword: (body: RenewPasswordBody) =>
    post<void>(`${identityApiUrl}/renew-password`, {
      body: JSON.stringify(body),
    }),
  resetPassword: (body: ResetPasswordBody) =>
    post<ResetPasswordResponseBody>(`${identityApiUrl}/reset-password`, {
      body: JSON.stringify(body),
    }),
};

const organizationCustomMethods = {
  postTerritory: (
    organizationId: string,
    body: { endDate?: Date; effectiveDate: Date; territoryId: number }
  ) =>
    post(`${apiUrl}/organizations/${organizationId}/territory`, {
      body: JSON.stringify(body),
    }),
  putTerritory: (
    organizationId: string,
    territoryId: string,
    body: { endDate: Date; effectiveDate: Date; territoryId: number }
  ) =>
    put(`${apiUrl}/organizations/${organizationId}/territory/${territoryId}`, {
      body: JSON.stringify(body),
    }),
};

const realizationCustomMethods = {
  updateRealizationStatus: (id: string, body: UpdateRealizationStatusBody) =>
    put<void>(`${apiUrl}/realizations/${id}/action/change-status`, {
      body: JSON.stringify(body),
    }),
  uploadRealizationImage: (id: string, body: UploadImageBody) =>
    post<UploadImageResponse>(`${apiUrl}/realizations/${id}/upload-image`, {
      body: JSON.stringify(body),
    }),
};

const cgpCustomMethods = {
  bookRealEstate: (id: string, { userId, clientId }: BookRealEstateBody) =>
    post<void>(`${apiUrl}/real-estates-cgp/${id}/action/book`, {
      body: JSON.stringify({ clientId, userId }),
    }),
  cancelBookingRealEstate: (id: string) =>
    post<void>(`${apiUrl}/real-estates-cgp/${id}/action/cancel-booking`),
  generatePublicLink: (id: string) =>
    post<GenerateCgpPublicLinkResponse>(
      `${apiUrl}/real-estates-cgp/${id}/action/generate-link`
    ),
  getBookingInfo: (id: string) =>
    get<BookingDetails>(`${apiUrl}/real-estates-cgp/${id}/booking-info`),
  unbookRealEstate: (id: string) =>
    post<void>(`${apiUrl}/real-estates-cgp/${id}/action/unbook`),
};

export const dataProvider = {
  ...restMethods,
  ...realEstateCustomMethods,
  ...transactionCustomMethods,
  ...identityCustomMethods,
  ...customerCustomMethods,
  ...organizationCustomMethods,
  ...realizationCustomMethods,
  ...cgpCustomMethods,
  getList: getGetListMethod(restMethods),
};

export type HeliosDataProvider = typeof dataProvider;
