import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import { SelectInput, useTranslate, NumberInput } from 'react-admin';
import { AccordionForm } from 'ui/molecules/accordion-form/AccordionForm';
import { FormGroup } from '@mui/material';
import { CheckboxInput } from 'components/checkbox-input/CheckboxInput';

import { PROPERTY_KIND_DATA } from 'constants/real-estates';

import { EditFormContentProps } from '../editFormContent.types';
import { sourceToLabelMap } from '../EditFormContent.utils';

export const ProjectForm: React.FC<EditFormContentProps> = ({
  defaultExpanded,
  disabled = false,
}) => {
  const translate = useTranslate();

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate('realization.form.project')}
    >
      <Fieldset>
        <SelectInput
          disabled={disabled}
          source="type"
          choices={PROPERTY_KIND_DATA}
          label={sourceToLabelMap.get('type')}
          margin="none"
          variant="outlined"
        />
        <NumberInput
          disabled={disabled}
          source="surface"
          label={sourceToLabelMap.get('surface')}
          margin="none"
          variant="outlined"
        />
        <NumberInput
          disabled={disabled}
          source="totalLots"
          label={sourceToLabelMap.get('totalLots')}
          margin="none"
          variant="outlined"
        />
        <FormGroup row>
          <CheckboxInput
            disabled={disabled}
            label={sourceToLabelMap.get('condominium')}
            source="condominium"
          />
        </FormGroup>
      </Fieldset>
    </AccordionForm>
  );
};
