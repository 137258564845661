import { useInput, TextInputProps, TextInput } from 'react-admin';
import { defaultCountries, usePhoneInput } from 'react-international-phone';
import { InputAdornment } from '@mui/material';

import { PhoneInputCountrySelector } from './phone-input-country-selector';

export const PhoneInput: React.FC<TextInputProps> = ({
  source,
  label,
  ...rest
}: TextInputProps) => {
  const { field } = useInput({ source });
  const { handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      countries: defaultCountries,
      defaultCountry: 'fr',
      onChange: (data) => {
        field.onChange(data.phone);
      },
      value: field.value,
    });

  return (
    <TextInput
      style={{ margin: '0px' }}
      variant="outlined"
      source={source}
      label={label}
      placeholder="Phone number"
      onChange={handlePhoneValueChange}
      type="tel"
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            style={{ marginLeft: '-8px', marginRight: '2px' }}
          >
            <PhoneInputCountrySelector
              value={country.iso2}
              onChange={setCountry}
            />
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};
