import { isArray } from 'lodash';
import {
  Organization,
  OrganizationFormatted,
  TerritoryStatus,
} from 'types/organizations.types';

export const formatOrganizationsList = (
  organizationList: Organization[] = []
): OrganizationFormatted[] => {
  return isArray(organizationList)
    ? organizationList.map(({ territories, ...rest }: Organization) => ({
        ...rest,
        territories: territories
          .filter(({ status }) => status !== TerritoryStatus.DISABLED)
          .map((t) => ({
            name: t.name,
            status: t.status,
          })),
      }))
    : [];
};
