import React, { useMemo } from 'react';

import { SelectInput, TextInput, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';
import { DateInput } from 'components/custom-input/date-input/DateInput';
import { FormDivider } from 'ui/atoms/form-divider/FormDivider';
import { PriceInput } from 'ui/atoms/price-input/PriceInput';
import { Button } from 'ui/atoms/button/Button';
import { GenerateSearchMandateBody } from 'types/customMethods.types';
import { yupResolver } from '@hookform/resolvers/yup';
import { SaveButton } from 'components/save-button/SaveButton';
import { GooglePlacesAutocomplete } from 'components/google-places-autocomplete/GooglePlacesAutocomplete';
import { toAbsoluteUTCDate } from 'utils/date';
import { CustomerCivility } from 'types/customer.types';
import { getCivilityChoiceList } from 'utils/form/form.utils';

import { generateExternalCustomerSearchMandateSchema } from './GenerateExternalCustomerSearchMandateDrawerForm.utils';
import {
  ActionsRow,
  commonInputStylesProps,
  FormBody,
  FormContainer,
  FormFooter,
  Row,
} from './GenerateExternalCustomerSearchMandateDrawerForm.styles';

export interface GenerateSearchMandateDrawerFormRecord {
  address: {
    street?: string;
  };
  birthCity?: string;
  birthDate?: string;
  downPayment?: number;
  email: string;
  firstName: string;
  lastName: string;
  search: {
    budgetMax: number;
  };
  civility: CustomerCivility;
}

type GenerateSearchMandateDrawerFieldValues = Omit<
  GenerateSearchMandateDrawerFormRecord,
  'birthDate'
> & { birthDate: Date | undefined };

export interface GenerateSearchMandateDrawerFormProps {
  record: GenerateSearchMandateDrawerFormRecord | undefined;
  local: string;
  isFetching?: boolean;
  handleSubmit: (data: GenerateSearchMandateBody) => Promise<void>;
  handleCancel: () => void;
}

export const GenerateExternalCustomerSearchMandateDrawerForm: React.FC<
  GenerateSearchMandateDrawerFormProps
> = ({ record, local, handleSubmit, handleCancel, isFetching }) => {
  const translate = useTranslate();
  const civilityChoices = useMemo(() => getCivilityChoiceList(), []);
  const [country, language] = local.split('_');

  const onSubmit = async (data: GenerateSearchMandateDrawerFieldValues) => {
    const payloadFR =
      country !== 'FR'
        ? {}
        : {
            address: data.address.street,
            birthCity: data.birthCity,
            birthDate: data.birthDate
              ? toAbsoluteUTCDate(data.birthDate)
              : undefined,
            downPayment: data.downPayment,
          };

    await handleSubmit({
      ...payloadFR,
      budgetMax: data.search.budgetMax,
      civility: data.civility,
      countryCode: country,
      email: data.email,
      firstName: data.firstName,
      language,
      lastName: data.lastName,
    });
  };

  return (
    <FormContainer
      record={record}
      onSubmit={onSubmit}
      mode="onChange"
      reValidateMode="onChange"
      resolver={yupResolver(generateExternalCustomerSearchMandateSchema)}
    >
      <FormBody>
        <Typography variant="h6">
          {`👤 ${translate('customer.form.profile')}`}
        </Typography>
        <br />
        <SelectInput
          source="civility"
          choices={civilityChoices}
          label="customer.civilityLabel"
          {...commonInputStylesProps}
        />
        <Row>
          <TextInput
            source="firstName"
            label="user.input.firstname"
            {...commonInputStylesProps}
            fullWidth
          />
          <TextInput
            source="lastName"
            label="user.input.lastname"
            {...commonInputStylesProps}
            fullWidth
          />
        </Row>
        {country === 'FR' && (
          <>
            <GooglePlacesAutocomplete
              source="address"
              defaultValue={record?.address?.street}
              {...commonInputStylesProps}
            />
            <Row>
              <DateInput
                source="birthDate"
                {...commonInputStylesProps}
                label={translate('customer.birthDate')}
              />
              <TextInput
                source="birthCity"
                label="customer.birthCity"
                {...commonInputStylesProps}
                fullWidth
              />
            </Row>
          </>
        )}
        <FormDivider />
        <Typography variant="h6" style={{ marginBottom: 20 }}>
          {`🔍 ${translate('customer.form.searchCriteria')}`}
        </Typography>
        <Row>
          {country === 'FR' && (
            <PriceInput
              source="downPayment"
              label="customer.downPayment"
              {...commonInputStylesProps}
            />
          )}
          <PriceInput
            source="search.budgetMax"
            label="customer.searchSettings.budgetMax"
            fullWidth={country !== 'FR'}
            {...commonInputStylesProps}
          />
        </Row>
      </FormBody>
      <FormFooter>
        <ActionsRow>
          <Button
            onClick={handleCancel}
            variant="outlined"
            data-testid="generate-search-mandate-cancel"
          >
            {translate('shared.cancel')}
          </Button>
          <SaveButton
            variant="contained"
            type="submit"
            label={translate('customer.generateSearchMandate.submit')}
            isFetching={isFetching}
            data-testid="generate-search-mandate-submit"
            enableIfNotDirty
            disabledIfNotValid
          />
        </ActionsRow>
      </FormFooter>
    </FormContainer>
  );
};
