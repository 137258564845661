import { NumberField, useLocaleState } from 'react-admin';

export const PercentField = ({
  percent,
  ...rest
}: {
  percent: number;
}): React.ReactElement => {
  const [locale] = useLocaleState();

  return (
    <NumberField
      {...rest}
      locales={locale}
      options={{
        maximumFractionDigits: 2,
        style: 'percent',
      }}
      source="percent"
      record={{ percent: percent / 100 }}
    />
  );
};
