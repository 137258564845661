export enum URI {
  customers = '/customers',
  externalCustomers = '/external-customers',
  organizations = '/organizations',
  help = '/help-center',
  home = '/',
  login = '/login',
  activities = '/activities',
  positionings = '/positionings',
  realEstates = '/real-estates',
  realEstatesCGP = '/real-estates-cgp',
  realizations = '/realizations',
  roles = '/roles',
  transactions = '/transactions',
  transactionsCGP = '/transactions-cgp',
  users = '/users',
}
