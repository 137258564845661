import { styled } from '@mui/material';
import { SelectArrayInput as RaSelectArrayInput } from 'react-admin';

export const SelectArrayInput = styled(RaSelectArrayInput)`
  .MuiFormLabel-filled {
    background-color: white;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: -3px;
  }
`;
