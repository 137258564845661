import { AutocompleteValue } from 'ui/molecules/autocomplete-input/AutocompleteInput';
import { getObjectKeys } from 'utils/object/object.utils';

import type { RawGooglePlaceSuggestion } from './GooglePlacesAutocomplete.types';

export const formatPlacesOptions = (
  places: RawGooglePlaceSuggestion[]
): AutocompleteValue<RawGooglePlaceSuggestion>[] => {
  return places.map((place: RawGooglePlaceSuggestion) => {
    return {
      label: place?.description,
      rawData: place,
      value: place?.description,
    };
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasError = (error?: Record<string, any>): boolean => {
  if (!error) {
    return false;
  }
  return getObjectKeys(error).some((key) => !!error[key]);
};
