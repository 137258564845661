import React from 'react';

import { useNotify, useRedirect, useTranslate } from 'react-admin';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Header } from 'ui/organisms/header/Header';
import { MobileFooter } from 'components/mobile-footer/MobileFooter';

import { organizationAddForm } from '../validators/organization.validator';
import { ActionButtons } from '../components/action-button/ActionButton';
import { OrganizationForm } from '../components/organization-form/OrganizationForm';
import { Create, Form } from '../styles/organizations.styles';

export const OrganizationsCreate: React.FC = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify('organization.create.success', { type: 'success' });
    redirect('list', 'organizations');
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onError = (err: any) => {
    notify(err?.body?.message || 'organization.create.error', {
      type: 'error',
    });
  };

  return (
    <Create
      component="div"
      resource="organizations"
      mutationOptions={{ onError, onSuccess }}
    >
      <Form
        id="organization-form"
        mode="onBlur"
        resolver={yupResolver(organizationAddForm)}
      >
        <Header
          title={translate('organization.add')}
          actionButtons={<ActionButtons />}
        />
        <OrganizationForm />
        <MobileFooter>
          <ActionButtons />
        </MobileFooter>
      </Form>
    </Create>
  );
};
