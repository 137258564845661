import {
  Box,
  Divider,
  IconButton,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { StatusChip } from 'ui/atoms/status-chip/StatusChip';
import { Theme } from '@mui/system';

const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  width: 100%;

  position: sticky;
  top: ${({ theme }) => theme.mixins.toolbar.minHeight}px;
  padding: 16px 0 0;
  z-index: 100;
  background-color: ${({ theme }) => theme.palette.background.default};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    position: relative;
    top: 0;
  }

  & .MuiDivider-root {
    margin-top: 8px;
  }
`;

const HeaderTitle = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  gap: 16px;
  width: 100%;

  & a {
    color: ${({ theme }) => theme.palette.common.black};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: 0;
  }
`;

const HeaderDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 4px;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    flex-direction: row;

    & .MuiTypography-caption:not(:last-child):after {
      content: ' -';
    }
  }
`;

const HeaderMetrics = styled(Box)`
  display: grid;
  grid-template-columns: repeat(5, auto);
  padding: 8px 0 0;
  gap: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    grid-template-columns: repeat(2, auto);
  }
`;

const HeaderActionButtons = styled(Box)`
  margin-left: auto;
`;

interface HeaderProps {
  address?: JSX.Element;
  title: string;
  status?: string;
  linkTo?: string;
  actionButtons?: JSX.Element;
  details?: JSX.Element;
  metrics?: JSX.Element;
  breadcrumbs?: JSX.Element;
  testId?: string;
}

export const Header: React.FC<HeaderProps> = ({
  title,
  status,
  linkTo,
  actionButtons,
  address,
  details,
  metrics,
  breadcrumbs,
  testId,
}) => {
  const isExtraSmall = useMediaQuery(
    (theme: Theme) => `(max-width:${theme.breakpoints.values.sm}px)`
  );
  return (
    <HeaderContainer role="heading" data-testid={testId}>
      {!!breadcrumbs && breadcrumbs}
      <HeaderTitle>
        <>
          <Typography variant="h6">{title}</Typography>
          {!!linkTo && (
            <a href={linkTo} target="_blank" rel="noopener noreferrer">
              <IconButton aria-label="link" color="inherit">
                <LaunchIcon />
              </IconButton>
            </a>
          )}
          {!!status && <StatusChip status={status} />}
          {!!(actionButtons && !isExtraSmall) && (
            <HeaderActionButtons display="flex" gap={2}>
              {actionButtons}
            </HeaderActionButtons>
          )}
        </>
      </HeaderTitle>
      {!!address && <HeaderDetails>{address}</HeaderDetails>}
      {!!details && <HeaderDetails>{details}</HeaderDetails>}
      {!!metrics && <HeaderMetrics>{metrics}</HeaderMetrics>}
      <Divider flexItem variant="fullWidth" />
    </HeaderContainer>
  );
};
