import * as yup from 'yup';
import { MasteosSupportedLanguage } from 'types/realEstate.enum';

export const addRealizationFormValidator = yup.object().shape({
  realEstateId: yup.string().required('realization.inputErrors.realEstateId'),
  title: yup.string().required('realization.inputErrors.title'),
  titleLang: yup
    .string()
    .oneOf(Object.values(MasteosSupportedLanguage))
    .required('realization.inputErrors.titleLang'),
});
