import React, { useCallback } from 'react';

import { Modal, ModalFooterButtonsContainer } from 'ui/organisms/modal/Modal';
import { FormOwnProps, useTranslate } from 'react-admin';
import { FieldValues, Resolver } from 'react-hook-form';
import { Button } from '@mui/material';
import { LoadingButton } from 'ui/molecules/loading-button/LoadingButton';

import { Form } from '../form/Form';

export interface ActionModalProps<T = FieldValues> {
  slug: string;
  isOpen: boolean;
  onClose: () => void;
  title: JSX.Element | string;
  saveLabel: string;
  children: JSX.Element | JSX.Element[];
  resolver?: Resolver<FieldValues, unknown>;
  callback: (data: T) => void;
  defaultValues?: FormOwnProps['defaultValues'];
  disableSaveButton?: boolean;
  loading?: boolean;
  autoWidth?: boolean;
}

export const ActionModal = <T extends FieldValues = FieldValues>({
  slug,
  isOpen,
  onClose,
  callback,
  title,
  saveLabel,
  resolver,
  defaultValues,
  children,
  disableSaveButton = false,
  loading = false,
  autoWidth = false,
}: ActionModalProps<T>): React.ReactElement => {
  const translate = useTranslate();

  const handleOnClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const handleOnSave = useCallback(
    (data: T) => {
      callback && callback(data);
    },
    [callback]
  );

  const ModalFooter = (
    <ModalFooterButtonsContainer>
      <Button variant="text" onClick={handleOnClose}>
        {translate('ra.action.cancel')}
      </Button>
      <LoadingButton
        data-testid={`action-modal-submit-button${
          disableSaveButton ? '-disable' : ''
        }`}
        type="submit"
        variant="text"
        form={slug}
        loading={loading}
        disabled={disableSaveButton}
      >
        {saveLabel}
      </LoadingButton>
    </ModalFooterButtonsContainer>
  );

  return (
    <Modal
      title={title}
      open={isOpen}
      onClose={handleOnClose}
      footer={ModalFooter}
      autoWidth={autoWidth}
    >
      <Form
        onSubmit={handleOnSave}
        id={slug}
        resolver={resolver}
        defaultValues={defaultValues || {}}
      >
        {children}
      </Form>
    </Modal>
  );
};
