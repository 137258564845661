import { Translate } from 'react-admin';
import { CellType, GridColDef } from 'types/mui.types';
import {
  renderCellCurrentStep,
  renderCellExternalLinks,
  renderCellStatus,
} from 'features/cgp-transactions/list/utils/columnsRenderCell';
import { renderCellHighlighted } from 'features/realEstates/list/utils/columnsRenderCell';
import { TransactionListItemFormatted } from 'types/transaction.types';
import {
  renderCellLinkCustomer,
  renderCellLinkRealEstate,
} from 'utils/columns/renderCells.utils';

const getLinksColumn: (
  translate: Translate
) => GridColDef<TransactionListItemFormatted> = (translate: Translate) => ({
  field: 'link',
  headerName: 'shared.link',
  renderCell: renderCellExternalLinks(translate),
  sortable: false,
  width: 80,
});

const getTitleColumn: () => GridColDef<TransactionListItemFormatted> = () => ({
  field: 'title',
  headerName: 'shared.address.label',
  width: 500,
});

export const getAddressLinkColumn: () => GridColDef<TransactionListItemFormatted> =
  () => ({
    field: 'realEstate.address.street',
    headerName: 'shared.address.street',
    renderCell: ({ row }) =>
      renderCellLinkRealEstate(row.property.id, row.title),
    sortable: false,
    width: 200,
  });

export const getCustomerLinkColumn: () => GridColDef<TransactionListItemFormatted> =
  () => ({
    field: 'realEstate.user',
    headerName: 'transaction.investorNameLabel',
    renderCell: ({ row }) =>
      renderCellLinkCustomer(row['user.id'], row['user.fullName']),
    sortable: false,
    width: 200,
  });

export const getStatusColumn: (
  translate: Translate
) => GridColDef<TransactionListItemFormatted> = (translate: Translate) => ({
  field: 'status',
  headerName: 'transaction.statusLabel',
  renderCell: renderCellStatus(translate),
  width: 100,
});

export const getCurrentStepColumn: (
  translate: Translate
) => GridColDef<TransactionListItemFormatted> = (translate: Translate) => ({
  field: 'currentStep',
  headerName: 'transaction.currentStepLabel',
  renderCell: renderCellCurrentStep(translate),
  sortable: false,
  width: 150,
});

const getUserFullNameColumn: () => GridColDef<TransactionListItemFormatted> =
  () => ({
    field: 'user.fullName',
    headerName: 'transaction.investorNameLabel',
    renderCell: renderCellHighlighted(),
    width: 200,
  });

export const getCounterSignedPurchaseOfferDateColumn: () => GridColDef<TransactionListItemFormatted> =
  () => ({
    cellType: CellType.DATE,
    field: 'offerSignedAt',
    headerName: 'transaction.offreAchatSignedDate',
    width: 120,
  });

export const getCreatedAtColumn: () => GridColDef<TransactionListItemFormatted> =
  () => ({
    cellType: CellType.DATE,
    field: 'createdAt',
    headerName: 'transaction.createdAt',
    width: 120,
  });

export const getRealNetSellerPriceColumn: () => GridColDef<TransactionListItemFormatted> =
  () => ({
    cellType: CellType.PRICE,
    field: 'realNetSeller',
    headerName: 'transaction.realNetSellerLabel',
    width: 120,
  });

export const getRealMasteosCommissionPriceColumn: () => GridColDef<TransactionListItemFormatted> =
  () => ({
    cellType: CellType.PRICE,
    field: 'realMasteosCommission',
    headerName: 'transaction.realMasteosCommissionLabel',
    width: 120,
  });

export const getFurnitureAndWorkPriceColumn: () => GridColDef<TransactionListItemFormatted> =
  () => ({
    cellType: CellType.PRICE,
    field: 'furnituresAndWork',
    headerName: 'transaction.furnituresAndWork',
    width: 120,
  });

export const getAdvisorFullNameColumn: () => GridColDef<TransactionListItemFormatted> =
  () => ({
    field: 'advisor.fullName',
    headerName: 'transaction.advisorNameLabel',
    renderCell: renderCellHighlighted(),
    width: 200,
  });

const getUserEmailColumn: () => GridColDef<TransactionListItemFormatted> =
  () => ({
    field: 'user.email',
    headerName: 'transaction.investorEmailLabel',
    renderCell: renderCellHighlighted(),
    width: 210,
  });
export const getOrganizationNameColumn: () => GridColDef<TransactionListItemFormatted> =
  () => ({
    field: 'organizationName',
    headerName: 'shared.mandator.label',
    width: 200,
  });

export const getTransactionIdColumn: () => GridColDef<TransactionListItemFormatted> =
  () => ({
    field: 'id',
    headerName: 'transaction.id',
    renderCell: renderCellHighlighted(),
    width: 210,
  });

export const getCGPTransactionsColumns = (
  translate: Translate
): GridColDef[] => {
  return [
    getLinksColumn(translate),
    getTitleColumn(),
    getStatusColumn(translate),
    getCurrentStepColumn(translate),
    getUserFullNameColumn(),
    getCounterSignedPurchaseOfferDateColumn(),
    getRealNetSellerPriceColumn(),
    getRealMasteosCommissionPriceColumn(),
    getFurnitureAndWorkPriceColumn(),
    getAdvisorFullNameColumn(),
    getOrganizationNameColumn(),
    getUserEmailColumn(),
    getCreatedAtColumn(),
    getTransactionIdColumn(),
  ];
};
