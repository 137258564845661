import React from 'react';

import { useTranslate } from 'react-admin';

import { TextContainer } from './ErrorStepBottomBar.styles';
import { Container } from './ErrorStepBottomBarNoRemainingErrors.styles';

export const ErrorStepBottomBarNoRemainingErrors: React.FC = () => {
  const translate = useTranslate();

  return (
    <Container data-testid="ErrorStepBottomBarNoRemainingErrors">
      <div></div>
      <TextContainer>
        {translate('errorStepBottomBar.noRemainingError')}
      </TextContainer>
      <div></div>
    </Container>
  );
};
