export enum DocumentDirectUploadCategory {
  Kbis = 'KBIS',
  IdentityCard = 'IDENTITY_CARD',
  ProofOfAddress = 'PROOF_OF_ADDRESS',
  BankAccountDetails = 'BANK_ACCOUNT_DETAILS',
  JobContract = 'JOB_CONTRACT',
  Payslip = 'PAYSLIP',
  TaxNotice = 'TAX_NOTICE',
  Other = 'OTHER',
  DesignPlan = 'DESIGN_PLAN',
  WorksiteReport = 'WORKSITE_REPORT',
  WorksiteImages = 'WORKSITE_IMAGES',
  ThermalDiagnosis = 'THERMAL_DIAGNOSIS',
  CustomerAcceptanceReport = 'CUSTOMER_ACCEPTANCE_REPORT',
  Doe = 'DOE',
  RaaBis = 'RAA_BIS',
  InstalledEquipmentList = 'INSTALLED_EQUIPMENT_LIST',
  PropertySheet = 'PROPERTY_SHEET',
  GlobalContract = 'GLOBAL_CONTRACT',
  PostSaleDiagnostics = 'POST_SALE_DIAGNOSTICS',
  HunterDocument = 'HUNTER_DOCUMENT',
  ManagementMandate = 'MANAGEMENT_MANDATE',
  TransactionInvoice = 'TRANSACTION_INVOICE',
  CustomerInvoice = 'CUSTOMER_INVOICE',
  FinancingCertificate = 'FINANCING_CERTIFICATE',
}

export enum DocumentCategory {
  ActeAuthentiqueVente = 'ACTE_AUTHENTIQUE_VENTE',
  AvisValeurLocative = 'AVIS_VALEUR_LOCATIVE',
  BauxLocataire = 'BAUX_LOCATAIRE',
  Compromis = 'COMPROMIS',
  ContratMarche = 'CONTRAT_MARCHE',
  ContratMariage = 'CONTRAT_MARIAGE',
  ContratPret = 'CONTRAT_PRET',
  DevisDefinitif = 'DEVIS_DEFINITIF',
  DevisEstimatif = 'DEVIS_ESTIMATIF',
  DiagnosticAmiante = 'DIAGNOSTIC_AMIANTE',
  DiagnosticElectricite = 'DIAGNOSTIC_ELECTRICITE',
  DiagnosticGaz = 'DIAGNOSTIC_GAZ',
  DiagnosticPerformanceEnergetique = 'DIAGNOSTIC_PERFORMANCE_ENERGETIQUE',
  DiagnosticPlomb = 'DIAGNOSTIC_PLOMB',
  DossierSyntheses = 'DOSSIER_SYNTHESES',
  Encaissement = 'ENCAISSEMENT',
  Factures = 'FACTURES',
  FacturesPartiesCommunes = 'FACTURES_PARTIES_COMMUNES',
  FinancingCertificate = 'FINANCING_CERTIFICATE',
  IntentionLetter = 'INTENTION_LETTER',
  LettreConfort = 'LETTRE_CONFORT',
  LivretFamille = 'LIVRET_FAMILLE',
  OffreAchatContresignee = 'OFFRE_ACHAT_CONTRESIGNEE',
  OffrePret = 'OFFRE_PRET',
  Other = 'OTHER',
  PACS = 'PACS',
  PieceIdentite = 'PIECE_IDENTITE',
  Procuration = 'PROCURATION',
  PvFinChantier = 'PV_FIN_CHANTIER',
  PvReception = 'PV_RECEPTION',
  Quittances = 'QUITTANCES',
  Quote = 'QUOTE',
  RAA = 'RAA',
  RapportExpertiseStructure = 'RAPPORT_EXPERTISE_STUCTURE',
  RapportExpertiseTalt = 'RAPPORT_EXPERTISE_TALT',
  RapportExpertiseToiture = 'RAPPORT_EXPERTISE_TOITURE',
  ReglementCopropriete = 'REGLEMENT_COPROPRIETE',
  ReleveMatriceCadastrale = 'RELEVE_MATRICE_CADASTRALE',
  ReleveSinistre = 'RELEVE_SINISTRE',
  SalesMandate = 'SALES_MANDATE',
  SearchMandate = 'SEARCH_MANDATE',
  StatutSociete = 'STATUT_SOCIETE',
  SyndicPvAg = 'SYNDIC_PV_AG',
  TaxeFonciere = 'TAXE_FONCIERE',
  Unknown = 'UNKNOWN',
}
