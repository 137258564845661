import React from 'react';

import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
  useStore,
  useTranslate,
} from 'react-admin';
import { ActionModal } from 'components/action-modal/ActionModal';
import { Territory } from 'types/organizations.types';
import { styled } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { HeliosDataProvider } from 'providers/admin/dataProvider';
import { organizationTerritorySchema } from 'features/organizations/validators/organization.validator';
import { formatTerritoriesOptionText } from 'features/organizations/utils/formatTerritoriesOptionText';

export const FormContainer = styled('div')`
  display: grid;
  gap: 24px;
  margin-top: 32px;
  margin-bottom: 24px;
`;
export const TerritoryAddModal: React.FC = () => {
  const notify = useNotify();
  const translate = useTranslate();
  const record = useRecordContext();
  const refresh = useRefresh();

  const dataProvider = useDataProvider<HeliosDataProvider>();

  const [isModalOpen, showModal] = useStore('territory.show_add_modal', false);
  const handleCloseModal = () => {
    showModal(false);
  };

  const handleOnSave = async ({
    endDate,
    effectiveDate,
    territoryId,
  }: {
    endDate?: Date;
    effectiveDate: Date;
    territoryId: string;
  }) => {
    try {
      await dataProvider.postTerritory(record.id as string, {
        effectiveDate: effectiveDate,
        endDate: endDate,
        territoryId: +territoryId,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      notify(e.message, {
        type: 'error',
      });
      throw new Error(e.message);
    }

    notify(translate('territory.add.success'), {
      type: 'success',
    });

    refresh();
    showModal(false);
  };

  const getDisabledChoice = (choice: Territory) =>
    !!choice.organization && choice.organization !== record?.id;

  return (
    <ActionModal
      title={translate('territory.add.modalTitle')}
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      callback={handleOnSave}
      saveLabel={translate('ra.action.confirm')}
      slug="organization-territory-add"
      resolver={yupResolver(organizationTerritorySchema)}
      defaultValues={{}}
    >
      <FormContainer>
        <ReferenceInput
          reference="organizations/territories"
          source="territoryId"
        >
          <AutocompleteInput
            label="createOrganization.territories.territoryLabel"
            variant="outlined"
            optionText={formatTerritoriesOptionText}
            getOptionDisabled={getDisabledChoice}
          />
        </ReferenceInput>
        <DateInput
          source="effectiveDate"
          variant="outlined"
          label="createOrganization.territories.effectiveDateLabel"
          defaultValue={null}
          isRequired
        />
        <DateInput
          source="endDate"
          variant="outlined"
          label="createOrganization.territories.endDateLabel"
          defaultValue={null}
        />
      </FormContainer>
    </ActionModal>
  );
};
