import React, { useState } from 'react';

import { useFormContext } from 'react-hook-form';
import {
  UploadCounterSignedOfferMutationReturn,
  useUploadCounterSignedOfferAction,
} from 'features/realEstates/details/edit/hooks/useUploadCounterSignedOfferAction';

import {
  ArchiveMutationReturn,
  useArchiveAction,
} from '../hooks/useArchiveAction';
import {
  AskPublishMutationReturn,
  DoPublishMutationReturn,
  usePublishActions,
} from '../hooks/usePublishActions';
import {
  DownloadBankPdfMutationReturn,
  useDownloadBankPdfAction,
} from '../hooks/useDownloadBankPdfAction';
import {
  DownloadPropertyPdfMutationReturn,
  useDownloadPropertyPdfAction,
} from '../hooks/useDownloadPropertyPdfAction';
import {
  GeneratePurchaseOfferMutationReturn,
  useGeneratePurchaseOfferAction,
} from '../hooks/useGeneratePurchaseOfferAction';
import {
  TransferRealEstateMutationReturn,
  useTransferRealEstateAction,
} from '../hooks/useTransferRealEstateAction';

export type ActionContextProps = {
  archive: ArchiveMutationReturn;
  askPublish: AskPublishMutationReturn;
  doPublish: DoPublishMutationReturn;
  downloadBankPdf: DownloadBankPdfMutationReturn;
  downloadPropertyPdf: DownloadPropertyPdfMutationReturn;
  generatePurchaseOffer: GeneratePurchaseOfferMutationReturn;
  setFormContext: React.Dispatch<ReturnType<typeof useFormContext>>;
  transferRealEstate: TransferRealEstateMutationReturn;
  uploadCounterSignedOffer: UploadCounterSignedOfferMutationReturn;
  doDisplayError: boolean;
};

export const ActionContext = React.createContext<ActionContextProps>(
  {} as ActionContextProps
);

/**
 * Exposes all custom action mutation ( and their status: 'idle', 'loading', 'errors' ... under the ActionContext )
 */
export const ActionContextProvider: React.FC<{
  children: JSX.Element | JSX.Element[];
}> = ({ children }) => {
  const [formContext, setFormContext] =
    useState<ReturnType<typeof useFormContext>>();
  const { askPublish, doPublish } = usePublishActions(formContext);

  const actions: ActionContextProps = {
    archive: useArchiveAction(),
    askPublish,
    doDisplayError: !!askPublish.error || !!doPublish.error,
    doPublish,
    downloadBankPdf: useDownloadBankPdfAction(),
    downloadPropertyPdf: useDownloadPropertyPdfAction(),
    generatePurchaseOffer: useGeneratePurchaseOfferAction(),
    setFormContext,
    transferRealEstate: useTransferRealEstateAction(),
    uploadCounterSignedOffer: useUploadCounterSignedOfferAction(),
  };

  return (
    <ActionContext.Provider value={actions}>{children}</ActionContext.Provider>
  );
};
