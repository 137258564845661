import { UseMutationResult } from 'react-query';

export {};

// TODO: doc I'll add some doc later to ease the understanding at first glance
declare global {
  type RequiredPart<T, Part extends keyof T> = T & Required<Pick<T, Part>>;
  type Nullable<T> = T | null | undefined;
  type ValueOf<T> = T[keyof T];
  type KeysOfValueType<T, V> = {
    [K in keyof T]: T[K] extends V ? K : never;
  }[keyof T];
  type PickKeysOfValueType<T, K> = { [P in KeysOfValueType<T, K>]: K };
}

export enum UploadState {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}

export type ActionMutationReturn<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown
> = UseMutationResult<TData, TError, TVariables, TContext> & {
  isAllowed?: boolean;
};

export enum YesNoOrAny {
  ANY = 'ANY',
  YES = 'YES',
  NO = 'NO',
}

export interface ErrorResponse {
  body: { message: string };
}
