import { useLocaleState, useRecordContext, useTranslate } from 'react-admin';
import { CircularProgress, Link, Typography } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EuroIcon from '@mui/icons-material/Euro';
import { MetricBlock } from 'ui/molecules/metric-block/MetricBlock';
import { PriceField } from 'ui/atoms/price-field/PriceField';
import { PercentField } from 'ui/atoms/percent-field/PercentField';
import {
  getHeaderTimeStampString,
  getBackendSupportedLanguageFromLocale,
} from 'utils/i18n/i18n.utils';
import { Header } from 'ui/organisms/header/Header';
import { formatFullName } from 'utils/strings/strings.utils';
import { useGenerateTitle } from 'features/realEstates/details/edit/hooks/useGenerateTitle';
import { RealEstateWithOperationalMetadata } from 'types/realEstate.types';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PlaceIcon from '@mui/icons-material/Place';
import { RealEstateStatus } from 'types/realEstate.enum';
import { Transaction } from 'types/transaction.types';
import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';
import { PermissionName } from 'types/permission.types';

import { URI } from 'constants/uri';

import { PropertyHeaderTabs } from './tabs/PropertyHeaderTabs';

type PropertyHeaderProps = {
  breadcrumbs?: JSX.Element;
  propertyActions?: JSX.Element;
};

export const PropertyHeader: React.FC<PropertyHeaderProps> = ({
  breadcrumbs,
  propertyActions,
}) => {
  const record = useRecordContext<
    RealEstateWithOperationalMetadata & { transaction?: Transaction }
  >();
  const translate = useTranslate();
  const [locale] = useLocaleState();
  const { hasPermission } = useHeliosPermissions();

  const backendSupportedLanguage =
    getBackendSupportedLanguageFromLocale(locale);

  const title = useGenerateTitle();

  if (!record) {
    return <CircularProgress />;
  }

  const {
    createdAt,
    updatedAt,
    financialModel,
    hunter,
    id,
    status,
    transaction,
    positioningCount,
    bookmarkCount,
    zone,
  } = record;

  const timeStampString = getHeaderTimeStampString({
    createdAt,
    locale,
    translate,
    updatedAt,
  });

  const publicUrl = `${process.env.REACT_APP_MASTEOS_PUBLIC_URL}/property/${id}`;
  const realEstateTransactionUrl = `${URI.transactions}/${transaction?.id}/show`;

  const canSeeLinkTransaction =
    status === RealEstateStatus.TRANSACTION &&
    !!transaction?.id &&
    hasPermission(PermissionName.ViewProject);

  const propertyDetails = (
    <>
      <Typography variant="caption">
        {translate('shared.addedBy', {
          fullName: formatFullName(hunter.firstName, hunter.lastName),
        })}
      </Typography>
      <Typography variant="caption">{timeStampString}</Typography>
    </>
  );

  const propertyAddress = (
    <Typography variant="caption">
      {`${record.address.street}, ${record.address.postalCode}`}
    </Typography>
  );

  const propertyMetrics = (
    <>
      {!!financialModel && (
        <>
          <MetricBlock
            icon={<EuroIcon />}
            text={translate('financial.totalBudget')}
            value={<PriceField price={financialModel.projectAmount} />}
          />
          <MetricBlock
            icon={<AccountBalanceWalletIcon />}
            text={translate('financial.grossReturn')}
            value={<PercentField percent={financialModel.grossReturn || 0} />}
          />

          {!!canSeeLinkTransaction && (
            <Link
              underline="hover"
              color="inherit"
              href={realEstateTransactionUrl}
              data-testid="property-header-transaction-link"
            >
              <MetricBlock
                icon={<ReceiptLongIcon />}
                text={translate('transaction.breadcrumb.transactionShow')}
                value={translate(
                  `transaction.status.${transaction.status.toLowerCase()}`
                )}
              />
            </Link>
          )}
        </>
      )}
      {!!zone && (
        <MetricBlock
          style={{ marginLeft: !financialModel ? 0 : 15 }}
          icon={<PlaceIcon />}
          text={translate('shared.zone')}
          value={zone.translations[backendSupportedLanguage].name}
        />
      )}
    </>
  );

  return (
    <>
      <Header
        testId="property-header"
        address={propertyAddress}
        title={title}
        status={translate(`realEstate.status.${status.toLowerCase()}`)}
        linkTo={publicUrl}
        actionButtons={propertyActions}
        details={propertyDetails}
        metrics={propertyMetrics}
        breadcrumbs={breadcrumbs}
      />
      <PropertyHeaderTabs
        propertyId={id}
        positioningCount={positioningCount}
        bookmarkCount={bookmarkCount}
      />
    </>
  );
};
