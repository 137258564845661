import { Theme } from '@mui/material';
import { GridValueFormatterParams } from '@mui/x-data-grid';
import { Translate } from 'react-admin';
import { FinancingCertificateStatus } from 'types/financing-certificate.types';
import { ChipListProps } from 'ui/molecules/chips-list/ChipList';
import { formatChipListValue } from 'utils/columns/valueFormatters.utils';

const FINANCING_CERTIFICATE_STATUS_TO_CHIP_LIST_PROPS = {
  [FinancingCertificateStatus.FINANCING_CERTIFICATE_STATUS_TO_VERIFY]: {
    color: 'lightBlue',
    label: `customer.financingCertificateStatus.${FinancingCertificateStatus.FINANCING_CERTIFICATE_STATUS_TO_VERIFY}`,
  },
  [FinancingCertificateStatus.FINANCING_CERTIFICATE_STATUS_UNKNOWN]: {
    color: 'deepPurple',
    label: `customer.financingCertificateStatus.${FinancingCertificateStatus.FINANCING_CERTIFICATE_STATUS_UNKNOWN}`,
  },
  [FinancingCertificateStatus.FINANCING_CERTIFICATE_STATUS_EXPIRED]: {
    color: 'red',
    label: `customer.financingCertificateStatus.${FinancingCertificateStatus.FINANCING_CERTIFICATE_STATUS_EXPIRED}`,
  },
};

export const formatFinancingCertificateStatusColumnValue =
  (translate: Translate, theme: Theme) =>
  (param: GridValueFormatterParams): ChipListProps['list'] => {
    return formatChipListValue(
      translate,
      theme,
      FINANCING_CERTIFICATE_STATUS_TO_CHIP_LIST_PROPS
    )({ ...param, value: [param.value] });
  };
