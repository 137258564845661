import React, { useState } from 'react';

import { useFormContext } from 'react-hook-form';

import {
  UpdateStatusMutationReturn,
  useUpdateStatusAction,
} from '../hooks/useUpdateStatusAction';

export type ActionContextProps = {
  updateStatusAction: UpdateStatusMutationReturn;
  setFormContext: React.Dispatch<ReturnType<typeof useFormContext>>;
  doDisplayError: boolean;
};

export const ActionContext = React.createContext<ActionContextProps>(
  {} as ActionContextProps
);

export const ActionContextProvider: React.FC<{
  children: JSX.Element | JSX.Element[];
}> = ({ children }) => {
  const [formContext, setFormContext] =
    useState<ReturnType<typeof useFormContext>>();

  const updateStatusAction = useUpdateStatusAction(formContext);

  const actions: ActionContextProps = {
    doDisplayError: !!updateStatusAction.error,
    setFormContext,
    updateStatusAction,
  };

  return (
    <ActionContext.Provider value={actions}>{children}</ActionContext.Provider>
  );
};
