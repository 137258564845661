import { useRecordContext, useTranslate } from 'react-admin';
import { CircularProgress } from '@mui/material';
import { Header } from 'ui/organisms/header/Header';
import { Realization } from 'types/realization.types';

type RealizationHeaderProps = {
  breadcrumbs?: JSX.Element;
  realizationActions?: JSX.Element;
};

export const RealizationHeader: React.FC<RealizationHeaderProps> = ({
  breadcrumbs,
  realizationActions,
}) => {
  const record = useRecordContext<Realization>();
  const translate = useTranslate();

  if (!record) {
    return <CircularProgress />;
  }

  const { status, title, id } = record;

  const publicUrl = `${process.env.REACT_APP_MASTEOS_PUBLIC_URL}/property/sold/${id}`;

  return (
    <Header
      testId="realization-header"
      title={title}
      status={translate(`realization.status.${status.toLowerCase()}`)}
      linkTo={publicUrl}
      actionButtons={realizationActions}
      breadcrumbs={breadcrumbs}
    />
  );
};
