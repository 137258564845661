import { RoleName } from 'types/role.types';

import { ROLES } from 'constants/user';

export const getUserRoleChoices = (
  existingRoles: RoleName[] = []
): { label: string; value: RoleName }[] => {
  const roles = [...ROLES].sort((a, b) => (a.label > b.label ? 1 : -1));

  for (const existingRole of existingRoles) {
    if (roles.findIndex((role) => role.value === existingRole) === -1) {
      roles.push({
        label: existingRole,
        value: existingRole,
      });
    }
  }

  return roles;
};

export const userRoleOptionText = ({
  label,
  value,
}: {
  label: string;
  value: string;
}): React.ReactNode | string => {
  if (ROLES.find((role) => role.value === value)) {
    return `roles.${label}`;
  }
  return <i>{label}</i>;
};
