import { HeliosDataProvider } from 'providers/admin/dataProvider';
import {
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { useMutation } from 'react-query';
import { ActionMutationReturn } from 'types/common.types';
import { Transaction } from 'types/transaction.types';
import { UpdateTodoBody } from 'types/transactionCustomMethods.types';

type UseUpdateTodoActionVariables = Pick<
  UpdateTodoBody,
  'date' | 'fileBase64Url'
> & { stepId: UpdateTodoBody['stepId'] | 'default' };

export type UpdateTodoMutationReturn = ActionMutationReturn<
  void,
  unknown,
  UseUpdateTodoActionVariables,
  unknown
>;

export const useUpdateTodoAction = (): UpdateTodoMutationReturn => {
  const record = useRecordContext<Transaction>();
  const refresh = useRefresh();
  const dataProvider = useDataProvider<HeliosDataProvider>();
  const notify = useNotify();

  const mutation = useMutation(
    ['updateTransactionTodo', record?.id, dataProvider],
    (variables: UseUpdateTodoActionVariables) => {
      if (variables.stepId === 'default') {
        return Promise.reject('à traduire : stepId est obligatoire');
      }
      return dataProvider
        ?.updateTodo(record?.id, variables as UpdateTodoBody)
        .then((response: unknown) => {
          if (response) {
            refresh();
            notify('transaction.action.upload.success', {
              type: 'success',
            });
          }
        });
    }
  );
  return { ...mutation, isAllowed: true };
};
