import { useState, useCallback } from 'react';

import { Box, Chip, Theme, Toolbar, styled } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { LayoutProps, useAuthState } from 'react-admin';
import { AppBar } from 'ui/molecules/app-bar/AppBar';
import { Menu } from 'components/menu/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ProfileDropdown } from 'components/profile-dropdown/ProfileDropdown';
import { usePageTracking } from 'hooks/use-page-tracking/usePageTracking';

const Container = styled(Box)`
  display: flex;
  position: relative;
`;

const Main = styled(Box)`
  flex-grow: 1;
  padding: 0 16px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const MainContent = styled(Box)`
  flex-grow: 1;
  display: flex;
`;

const BurgerIcon = styled(MenuIcon)`
  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    display: none;
  }
`;

export const AppLayout: React.FC<LayoutProps> = ({ children }) => {
  usePageTracking();
  const isSmall = useMediaQuery(
    (theme: Theme) => `(max-width:${theme.breakpoints.values.lg}px)`
  );
  const { authenticated } = useAuthState();

  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const toggleMenu = useCallback(() => {
    setOpenMenu((prevState) => !prevState);
  }, []);

  const open = isSmall ? openMenu : true;
  const variant = isSmall ? undefined : 'permanent';
  const devEnv =
    process.env?.REACT_APP_ENV !== 'production'
      ? process.env?.REACT_APP_ENV
      : false;

  return (
    <Container>
      <AppBar>
        <ProfileDropdown />
        <div style={{ flex: 1 }} />
        {!!devEnv && (
          <Chip size="small" label={devEnv.toUpperCase()} color="warning" />
        )}
        {!!authenticated && (
          <BurgerIcon onClick={toggleMenu} data-testid="openMenuButton" />
        )}
      </AppBar>
      {!!authenticated && (
        <Menu open={open} onClose={toggleMenu} variant={variant} />
      )}
      <Main component="main">
        {!!authenticated && <Toolbar />}
        <MainContent>{children}</MainContent>
      </Main>
    </Container>
  );
};
