import React from 'react';

import {
  HttpError,
  useNotify,
  useRecordContext,
  useStore,
  useTranslate,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { Typography } from '@mui/material';
import {
  TodosStep,
  Transaction,
  TransactionModalKey,
} from 'types/transaction.types';
import { ActionModal } from 'components/action-modal/ActionModal';
import { FileInput } from 'components/custom-input/file-input/FileInput';
import { fileToBase64 } from 'components/image-input/utils/imageInput.formater.utils';
import { formatTodosStepId } from 'features/cgp-transactions/list/utils/formatCGPTransactionList.util';
import { ICON_MAPPING } from 'features/cgp-transactions/show/constants';
import { useUpdateTodoAction } from 'features/cgp-transactions/show/hooks/useUpdateTodoAction';
import { cgpTransactionUpdateTodoForm } from 'features/cgp-transactions/validators/cgp-transaction.validator';
import { yupResolver } from '@hookform/resolvers/yup';

import { Content } from '../cancel-modal/CancelModal.styles';
import { TitleWithIcon } from '../documents-section/DocumentsSection.styles';
import { DateInput, InputLabel, Wrapper } from './UpdateTodoModal.styles';

const withoutFileInput = [
  'email_envoye',
  'validate_sending_notary',
  'upload_encaissement',
  'sale_agreement_estimation',
  'authenticated_deed_reception_estimation',
];

export const UpdateTodoModal: React.FC = () => {
  const notify = useNotify();
  const translate = useTranslate();
  const record = useRecordContext<Transaction>();
  const [uploadModalStore, showModal] = useStore<
    [boolean, TodosStep | 'default']
  >(TransactionModalKey.UPDATE, [false, 'default']);

  const [isModalOpen, todoId] = uploadModalStore;

  const currentTodo = record.todos.find((todo) => todo.id === todoId);

  const currentDocument = currentTodo?.documents?.[0];
  const type = formatTodosStepId(todoId);

  const uploadAction = useUpdateTodoAction();

  const handleCloseModal = () => {
    showModal([false, 'default']);
  };

  const handleOnError = (err: unknown) => {
    const error = err as HttpError;
    const message =
      error.status === 403
        ? `transaction.action.${error.message}`
        : 'ra.notification.http_error';

    notify(message, {
      type: 'error',
    });
  };

  const handleCallback = async ({ date, file }: FieldValues) => {
    let base64: string | undefined;
    if (file) {
      base64 = await fileToBase64(file.rawFile);
    }
    uploadAction.mutate(
      {
        date:
          typeof date === 'string'
            ? new Date(date).toISOString()
            : date.toISOString(),
        fileBase64Url: base64,
        stepId: todoId,
      },
      {
        onError: handleOnError,
        onSuccess: handleCloseModal,
      }
    );
  };

  const Icon = ICON_MAPPING[type];
  const ModalTitle = (
    <TitleWithIcon>
      {Icon}
      <Typography variant="h6">
        {translate(`transaction.show.documents.${type}.title`)}
      </Typography>
    </TitleWithIcon>
  );

  return (
    <ActionModal
      slug="cancel"
      resolver={yupResolver(
        cgpTransactionUpdateTodoForm(type === 'upload_offre_achat')
      )}
      isOpen={isModalOpen}
      title={ModalTitle}
      saveLabel={translate('ra.action.confirm')}
      onClose={handleCloseModal}
      callback={handleCallback}
      defaultValues={{
        date: currentTodo?.updateDoc,
        file: undefined,
      }}
    >
      <Content>
        <Wrapper>
          <InputLabel>
            <Typography variant="body1" fontWeight={500}>
              {translate(`transaction.show.documents.${type}.subtitle`)} *
            </Typography>
            <Typography variant="body2">
              {translate('transaction.action.upload.date_helpText')}
            </Typography>
          </InputLabel>
          <DateInput label="" source="date" />
        </Wrapper>

        {!withoutFileInput.includes(type) && (
          <FileInput
            source="file"
            label="transaction.action.upload.file_label"
            helpText={`transaction.action.upload.file_helpText${
              currentDocument ? '_edit' : ''
            }`}
          />
        )}
      </Content>
    </ActionModal>
  );
};
