import React, { useEffect, useState } from 'react';

import { ListBase, useGetIdentity, useRedirect } from 'react-admin';
import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';

import { URI } from 'constants/uri';

import { Content } from './Content';

const PER_PAGE = 20;

export const Activities: React.FC = () => {
  const redirect = useRedirect();

  const { isMandator, isAssetManagementConsultant, isLoading } =
    useHeliosPermissions();
  const { identity } = useGetIdentity();

  const organizationId = identity?.organization;

  const [resourceName, setResourceName] = useState<string>('positionings');

  useEffect(() => {
    if (isLoading || isMandator) {
      return;
    }

    if (isAssetManagementConsultant) {
      redirect(URI.externalCustomers);
      return;
    }

    redirect(URI.realEstates);
  }, [isLoading, isMandator, isAssetManagementConsultant, redirect]);

  const onResourceChange = (_event: React.SyntheticEvent, resource: string) => {
    setResourceName(resource);
  };

  return !organizationId ? null : (
    <ListBase
      disableSyncWithLocation
      perPage={PER_PAGE}
      resource={resourceName}
      filter={{ organizationId }}
      sort={{
        field:
          resourceName === 'positionings' ? 'statusChangedAt' : 'createdAt',
        order: 'DESC',
      }}
      data-testid="activities-list"
    >
      <Content onResourceChange={onResourceChange} />
    </ListBase>
  );
};
