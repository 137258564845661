import { Filters } from 'components/filters/Filters';

import { getDefaultUserFilters } from './UsersFilters.utils';
import { FilterBody } from './filters-body/FiltersBody';

export const UsersFilters: React.FC = () => {
  return (
    <Filters defaultValues={getDefaultUserFilters()}>
      <FilterBody />
    </Filters>
  );
};
