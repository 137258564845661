import { Box, styled } from '@mui/material';

export const FormContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 24px;
  row-gap: 16px;

  & .Mui-disabled {
    cursor: not-allowed;
  }
`;
