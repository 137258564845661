import { useMemo } from 'react';

import {
  ListBase,
  ListContext,
  ShowBase,
  ShowContext,
  useTranslate,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { DataTable } from 'components/data-table/DataTable';
import { PropertyBreadCrumbs } from 'features/realEstates/details/components/breadcrumbs/PropertyBreadCrumbs';
import { PropertyHeader } from 'features/realEstates/details/components/property-header/PropertyHeader';
import {
  ContentContainer,
  ContentTableContainer,
} from 'components/headerTabs/HeaderTabs.styles';

import { getRealEstateBookmarkColumns } from './getRealEstateBookmarkColumns';
import { formatBookmarksList } from './formatBookmarksList.utils';

export const RealEstateBookmarks: React.FC = () => {
  const { id } = useParams();
  const translate = useTranslate();

  const columns = useMemo(
    () => getRealEstateBookmarkColumns(translate),
    [translate]
  );

  return (
    <ContentContainer>
      <ShowBase>
        <ShowContext.Consumer>
          {({ record }) => {
            return (
              <PropertyHeader
                breadcrumbs={
                  <PropertyBreadCrumbs propertyId={record?.id.toString()} />
                }
              />
            );
          }}
        </ShowContext.Consumer>
      </ShowBase>

      <ListBase
        resource="bookmarks"
        disableSyncWithLocation
        key="real-estate-bookmarks"
        filter={{ realEstateId: id }}
        filterDefaultValues={{ realEstateId: id }}
        sort={{ field: 'createdAt', order: 'ASC' }}
      >
        <ListContext.Consumer>
          {({ data, isLoading, isFetching }) => (
            <ContentTableContainer>
              <DataTable
                loading={isLoading || isFetching || !columns}
                columns={columns}
                rows={formatBookmarksList(data || [])}
                autoHeight
                density="standard"
                noRowsMessage={translate('property.bookmarkList.noResults')}
              />
            </ContentTableContainer>
          )}
        </ListContext.Consumer>
      </ListBase>
    </ContentContainer>
  );
};
