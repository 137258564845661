import { useRecordContext, useTranslate } from 'react-admin';
import { AccordionForm } from 'ui/molecules/accordion-form/AccordionForm';
import { TextArea } from 'ui/atoms/textarea/TextArea';
import { getInternalNoteTemplate } from 'features/realEstates/utils/getInternalNoteTemplate';

import { RealEstateEditAccordeonFormProps } from '../EditFormContent.types';
import { sourceToLabelMap } from '../EditFormContent.utils';

export const InternalNoteForm: React.FC<RealEstateEditAccordeonFormProps> = ({
  defaultExpanded,
  disabled = false,
}) => {
  const record = useRecordContext();
  const translate = useTranslate();
  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate('realEstate.form.internalNote')}
    >
      <TextArea
        fullWidth
        disabled={disabled}
        defaultValue={getInternalNoteTemplate(record.lang)}
        label={sourceToLabelMap.get('internalNote')}
        source="internalNote"
        maxRows={undefined}
      />
    </AccordionForm>
  );
};
