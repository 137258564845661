import { EditContext, EditControllerResult, useNotify } from 'react-admin';
import { ErrorResponse } from 'types/common.types';
import { Customer } from 'types/customer.types';
import { EditContainer } from 'components/detail/edit-container/EditContainer';

import { transformForDisplay } from './utils/transformForDisplay.utils';
import { GenerateSearchMandateDrawer } from './component/generate-search-mandate-drawer/GenerateSearchMandateDrawer';
import { getOverridesFromUrlParams } from './utils/getOverridesFromUrlParams.utils';
import { overrideCustomerEditFormatted } from './utils/overrideCustomerEditFormatted.utils';
import { UploadDocumentModal } from './component/upload-document-modal/UploadDocumentModal';
import { ContactInformationModal } from './component/contact-information-modal/ContactInformationModal';
import { EditForm } from './component/edit-form/EditForm';
import { ChangeBroughtByModal } from './component/change-brought-by-modal/ChangeBroughtByModal';

export const CustomerEdit: React.FC = () => {
  const notify = useNotify();

  const onSuccess = () => {
    notify('customer.edit.success', { type: 'success' });
  };

  const onError = (err: unknown) => {
    notify((err as ErrorResponse)?.body?.message || 'customer.edit.error', {
      type: 'error',
    });
  };

  const handleTransform = (data: Customer) => {
    if (data.ownedBy) {
      return {
        ...data,
        advisor: {
          id: data.ownedBy,
        },
      };
    }
    return data;
  };

  return (
    <EditContainer
      component="div"
      mutationMode="pessimistic"
      transform={handleTransform}
      mutationOptions={{ onError, onSuccess }}
    >
      <EditContext.Consumer>
        {({ record }: EditControllerResult<Customer>) => {
          const formattedRecord = record
            ? transformForDisplay(record)
            : undefined;
          const overriddenFormattedRecord = formattedRecord
            ? overrideCustomerEditFormatted(
                formattedRecord,
                getOverridesFromUrlParams()
              )
            : undefined;

          if (!formattedRecord?.id) {
            return null;
          }

          return (
            <>
              <EditForm record={formattedRecord} />
              <GenerateSearchMandateDrawer record={overriddenFormattedRecord} />
              <ContactInformationModal
                phoneNumber={record?.phoneNumber}
                email={record?.email}
              />

              <ChangeBroughtByModal customerId={formattedRecord.id} />
              <UploadDocumentModal customerId={formattedRecord.id} />
            </>
          );
        }}
      </EditContext.Consumer>
    </EditContainer>
  );
};
