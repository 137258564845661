import { Chip, IconButton, Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { TFunction } from 'i18next';
import { useRealEstatePublicLink } from 'hooks/use-public-link/usePublicLink';

import { CURRENT_STEP_COLORS, STATUS_COLORS } from 'constants/transactions';

export const renderCellStatus = (
  translate: TFunction
): ((p: GridRenderCellParams) => React.ReactElement) => {
  const CellStatus = (p: GridRenderCellParams): React.ReactElement => {
    if (!p.value) {
      return <></>;
    }

    const value = p.value.toLowerCase();
    const label = translate(`transaction.status.${value}`);
    return <Chip label={label} size="small" color={STATUS_COLORS[value]} />;
  };

  CellStatus.displayName = 'CellStatus';

  return CellStatus;
};

export const renderCellCurrentStep = (
  translate: TFunction
): ((p: GridRenderCellParams) => React.ReactElement) => {
  const CellStatus = (p: GridRenderCellParams): React.ReactElement => {
    if (!p.value) {
      return <></>;
    }
    const { value } = p;

    const label = translate(`transaction.currentStep.${value}`);
    return (
      <Chip
        label={label}
        size="small"
        color={CURRENT_STEP_COLORS[value]}
        variant="outlined"
      />
    );
  };

  CellStatus.displayName = 'CellCurrentStep';

  return CellStatus;
};

export const renderCellExternalLinks = (
  translate: TFunction
): ((p: GridRenderCellParams) => React.ReactElement) => {
  const CellLinkUrl = (params: GridRenderCellParams): React.ReactElement => {
    const { generateAndOpenPublicLink } = useRealEstatePublicLink();

    const openPublicLink = () => {
      generateAndOpenPublicLink(params.row.property.id);
    };

    return (
      <Tooltip title={translate('transaction.realEstateLink').toString()}>
        <IconButton
          aria-label="link"
          color="inherit"
          onClick={openPublicLink}
          size="small"
        >
          <HomeOutlinedIcon />
        </IconButton>
      </Tooltip>
    );
  };

  CellLinkUrl.displayName = 'CellCellLinkUrl';

  return CellLinkUrl;
};
