import React from 'react';

import { Theme, useMediaQuery } from '@mui/material';
import { useRedirect, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import {
  Container,
  InfoIcon,
  Tab,
  Tabs,
} from 'components/headerTabs/HeaderTabs.styles';
import { getShortOrRegularTranslateKey } from 'utils/strings/getShortOrRegularTranslateKey';

import { URI } from 'constants/uri';

import { ExternalCustomerHeaderTabsProps } from './ExternalCustomerHeaderTabs.types';

export const ExternalCustomerHeaderTabs: React.FC<
  ExternalCustomerHeaderTabsProps
> = ({ externalCustomerId }) => {
  const translate = useTranslate();
  const { pathname } = useLocation();
  const redirect = useRedirect();
  const isSmall = useMediaQuery(
    (t: Theme) => `(max-width:${t.breakpoints.values.sm}px)`
  );

  const editExternalCustomerUrl = `${URI.externalCustomers}/${externalCustomerId}`;

  const onChange = (_event: React.SyntheticEvent, path: string) => {
    redirect(path);
  };

  return (
    <Container>
      <Tabs
        value={pathname}
        onChange={onChange}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab
          value={editExternalCustomerUrl}
          label={translate(
            getShortOrRegularTranslateKey(`customer.edit.tabs.edition`, isSmall)
          )}
          icon={<InfoIcon />}
          iconPosition="start"
        />
      </Tabs>
    </Container>
  );
};
