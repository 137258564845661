import { segmentTrack } from './segment';
import { TrackName } from './segment.enums';

export async function trackPublishRealEstate(): Promise<void> {
  await segmentTrack(TrackName.Publish_property_confirmation);
}

export async function trackAddRealEstate(): Promise<void> {
  await segmentTrack(TrackName.Add_property_confirmation);
}

export async function trackDuplicateRealEstate(): Promise<void> {
  await segmentTrack(TrackName.Duplicate_property_confirmation);
}
