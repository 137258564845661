import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import { useTranslate } from 'react-admin';
import { AccordionForm } from 'ui/molecules/accordion-form/AccordionForm';
import { TextArea } from 'ui/atoms/textarea/TextArea';
import { ImageInput } from 'components/image-input/ImageInput';

import { RealEstateEditAccordeonFormProps } from '../EditFormContent.types';
import { sourceToLabelMap } from '../EditFormContent.utils';

export const AccessForm: React.FC<RealEstateEditAccordeonFormProps> = ({
  defaultExpanded,
  disabled = false,
}) => {
  const translate = useTranslate();

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate('realEstate.form.access')}
    >
      <Fieldset>
        <TextArea
          fullWidth
          disabled={disabled}
          label={sourceToLabelMap.get('access.description')}
          source="access.description"
        />
        <TextArea
          fullWidth
          disabled={disabled}
          label={sourceToLabelMap.get('neighborhood.description')}
          source="neighborhood.description"
        />
      </Fieldset>
      <Fieldset title={translate('realEstate.fieldset.imagesNeighborhood')} />
      <ImageInput disabled={disabled} source="neighborhood.images" />
    </AccordionForm>
  );
};
