import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';
import { PermissionName } from 'types/permission.types';

export type CustomerPermissionsType = {
  canCreateExternalCustomer: boolean;
  canManageAllCustomers: boolean;
};

export const useExternalCustomerPermissions = (): CustomerPermissionsType => {
  const { hasPermission, canManageCustomers } = useHeliosPermissions();

  const canCreateExternalCustomer = hasPermission(
    PermissionName.CreateExternalCustomer
  );

  return {
    canCreateExternalCustomer,
    canManageAllCustomers: canManageCustomers,
  };
};
