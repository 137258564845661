import { FC, MouseEvent, useCallback, useMemo } from 'react';

import {
  ListBase,
  RecordContext,
  useListController,
  useTranslate,
} from 'react-admin';
import { DataTable } from 'components/data-table/DataTable';
import { getColumns } from 'utils/columns/getColumns.utils';
import { FilteredListLayout } from 'ui/layouts/filtered-list-layout/FilteredListLayout';
import { GridCellParams, MuiEvent } from '@mui/x-data-grid';
import { SearchBar } from 'ui/molecules/search-bar/SearchBar';
import { RealEstateItemView, SellTarget } from 'types/realEstate.types';

import { formatRealEstateList } from './utils/formatRealEstateList.utils';
import { RealEstatesFilters } from './components/filters/RealEstatesFilters';
import { getRealEstatesDefaultFilters } from './components/filters/RealEstatesFilters.utils';
import { getRealEstatesColumnsCGP } from './utils/getRealEstatesColumnsCGP';
import { UploadCGPCounterSignedOfferModal } from './components/modals/UploadCGPCounterSignedOfferModal';
import { GenerateCGPPurchaseOfferModal } from './components/modals/GenerateGCPPurchaseOfferModal';
import { CGPPreBookingModal } from './components/modals/CGPPreBookingModal';

const PER_PAGE = 20;

export const RealEstatesListCGP: FC = () => {
  const translate = useTranslate();

  const { dpeGrades } = getRealEstatesDefaultFilters();

  const columnsList = getRealEstatesColumnsCGP(translate);

  const {
    data: realEstates,
    total,
    setFilters,
    filterValues,
  } = useListController<RealEstateItemView>({
    filterDefaultValues: { dpeGrades },
    perPage: PER_PAGE,
  });

  const columns = useMemo(
    () => (realEstates ? getColumns(columnsList, translate) : []),
    [realEstates, translate, columnsList]
  );

  const rows = useMemo(
    () => (realEstates ? formatRealEstateList(realEstates) : []),
    [realEstates]
  );

  const handleCellClick = useCallback(
    (_params: GridCellParams, event: MuiEvent<MouseEvent>) => {
      event.preventDefault();
    },
    []
  );

  const searchBar = useMemo(
    () => (
      <SearchBar
        title={translate('realEstate.search.title')}
        placeholder={translate('realEstate.search.placeholder')}
        setFilters={setFilters}
        filterValues={filterValues}
      />
    ),
    [filterValues, setFilters, translate]
  );

  const table = useMemo(
    () => (
      <DataTable
        disableSelectionOnClick
        onCellClick={handleCellClick}
        columns={columns}
        rows={rows}
        rowAsLink
      />
    ),
    [columns, handleCellClick, rows]
  );

  return (
    <>
      <ListBase
        perPage={PER_PAGE}
        filterDefaultValues={{
          dpeGrades,
        }}
      >
        <FilteredListLayout
          main={table}
          filters={<RealEstatesFilters mode={SellTarget.CGP} />}
          searchBar={searchBar}
          title={translate('realEstate.listCGP', { count: total ?? 0 })}
        />
      </ListBase>
      <RecordContext.Provider value={{ id: null }}>
        <GenerateCGPPurchaseOfferModal />
        <UploadCGPCounterSignedOfferModal />
        <CGPPreBookingModal />
      </RecordContext.Provider>
    </>
  );
};
