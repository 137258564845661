import * as yup from 'yup';
import { getYear, isDate, isValid } from 'date-fns';
import { RealEstateStatus } from 'types/realEstate.enum';

import {
  ARCHIVING_REASONS_DATA,
  DPE_DATA,
  GES_DATA,
  LANGS_DATA,
  PROPERTY_KIND_DATA,
} from 'constants/real-estates';

export interface IdName {
  id: string;
  name: string;
}

export const addressSchema = yup.object().shape({
  street: yup.string().nullable().required('shared.location.addressRequired'),
});

export const contactSchema = yup.object().shape({
  _id: yup.string().nullable().required('realEstate.form.contactError'),
});

export const propertyLangSchema = yup
  .string()
  .oneOf(LANGS_DATA.map((lang: IdName) => lang.id))
  .required('realEstate.form.langRequired');

export const propertyKindSchema = yup
  .string()
  .oneOf(PROPERTY_KIND_DATA.map((propertyKind: IdName) => propertyKind.id))
  .nullable()
  .required('realEstate.form.propertyKind.notInList');

export const archivingReasonSchema = yup
  .string()
  .oneOf(
    ARCHIVING_REASONS_DATA.map((archivingReason: IdName) => archivingReason.id)
  )
  .nullable()
  .required('realEstate.form.reasonFieldRequired');

export const roomNumberSchema = yup
  .number()
  .integer('realEstate.form.roomNumberInteger')
  .nullable(true)
  .transform((_, val) => (val === Number(val) ? val : null));

export const renovationDetailsSchema = yup.array().nullable();

export const floorSchema = yup
  .number()
  .integer('realEstate.form.floorInteger')
  .nullable(true)
  .transform((_, val) => (val === Number(val) ? val : null));

export const synthesisSchema = yup
  .object()
  .when('status', (status: RealEstateStatus) => {
    let themeSchema = yup.string().nullable();
    let descriptionSchema = yup
      .string()
      .max(300, 'realEstate.synthesis.error.descriptionMaxLength')
      .nullable();

    if (
      status !== RealEstateStatus.DRAFT &&
      status !== RealEstateStatus.ARCHIVED
    ) {
      themeSchema = themeSchema.required(
        'realEstate.synthesis.error.themeRequired'
      );
      descriptionSchema = descriptionSchema.required(
        'realEstate.synthesis.error.descriptionRequired'
      );
    }

    return yup
      .array()
      .of(
        yup.object().shape({
          description: descriptionSchema,
          theme: themeSchema,
        })
      )
      .min(4);
  });

export const DPESchema = yup
  .string()
  .oneOf(DPE_DATA.map((data: IdName) => data.id))
  .nullable(true);

export const GESSchema = yup
  .string()
  .oneOf(GES_DATA.map((data: IdName) => data.id))
  .nullable(true);

export const loanDownPaymentSchema = yup
  .number()
  .min(0)
  .transform((_, val) => (val === Number(val) ? val : undefined))
  .required('realEstate.action.downloadBankPdf.loanDownPaymentRequired');

export const loanInterestRateSchema = yup
  .number()
  .min(0)
  .transform((_, val) => (val === Number(val) ? val : undefined))
  .required('realEstate.action.downloadBankPdf.loanInterestRateRequired');

export const loanDurationSchema = yup
  .number()
  .min(0)
  .transform((_, val) => (val === Number(val) ? val : undefined))
  .required('realEstate.action.downloadBankPdf.loanDurationRequired');

export const strictlyPositiveNumberSchema = yup
  .number()
  .moreThan(0, 'shared.creation.error.nonNull')
  .required('shared.creation.error.nonNull')
  .typeError('shared.creation.error.nonNull');

export const positiveNumberSchema = yup
  .number()
  .required('shared.creation.error.abovezero')
  .typeError('shared.creation.error.abovezero');

export const financialModelSchema = yup.object().shape({
  annualCharges: strictlyPositiveNumberSchema,
  annualRentIncome: strictlyPositiveNumberSchema,
  netSeller: strictlyPositiveNumberSchema,
  renovationAmount: positiveNumberSchema,
});

export const exclusiveEndDateSchema = yup
  .mixed()
  .test('exclusiveEndDateValidator', '', function (value, { createError }) {
    if (!this.parent.exclusiveEndDateCheckbox) {
      return true;
    }

    if (!value) {
      return createError({
        message: 'realEstate.form.exclusiveDateEnd.errors.noEmpty',
      });
    }

    const currentDate = isDate(value) ? value : new Date(value);

    return !isValid(currentDate) || getYear(currentDate).toString().length < 4
      ? createError({ message: 'shared.form.date.errors.format' })
      : true;
  });

export const shortTermRentalSchema = (
  field: 'shortTermOccupancyRate' | 'shortTermNightlyRent'
): yup.NumberSchema =>
  yup
    .number()
    .test(
      'shortTermValidator',
      `realEstate.publishValidation.fields.financialModel.${field}`,
      (_value, ctx) => {
        // _value is always undefined instead of having the real value. So we get it from the context
        return (
          !ctx.parent.shortTermRentalCheckbox ||
          !!ctx.parent.financialModel[field]
        );
      }
    );

// Purchase Offer Action
export const agencyCommissionSchema = yup
  .number()
  .min(0)
  .transform((_, val) => (val === Number(val) ? val : undefined))
  .required('realEstate.action.generatePurchaseOffer.requiredAgencyCommission');

export const customerWithMandateSchema = yup
  .string()
  .nullable()
  .required('realEstate.action.generatePurchaseOffer.requiredCustomer');

export const netSellerSchema = yup
  .number()
  .min(0)
  .transform((_, val) => (val === Number(val) ? val : undefined))
  .required('realEstate.action.generatePurchaseOffer.requiredNetSeller');
