import { useMemo } from 'react';

import { ListBase, ListContext, ShowBase, useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import { DataTable } from 'components/data-table/DataTable';
import { CustomerHeader } from 'features/customers/details/components/header/CustomerHeader';
import {
  ContentContainer,
  ContentTableContainer,
} from 'components/headerTabs/HeaderTabs.styles';

import { getCustomerBookmarkColumns } from './getCustomerBookmarkColumns';

export const CustomerBookmarks: React.FC = () => {
  const { id } = useParams();
  const translate = useTranslate();

  const columns = useMemo(
    () => getCustomerBookmarkColumns(translate),
    [translate]
  );

  return (
    <ContentContainer>
      <ShowBase>
        <CustomerHeader />
      </ShowBase>

      <ListBase
        resource="bookmarks"
        disableSyncWithLocation
        key="customer-bookmarks"
        filter={{ customerIds: [id] }}
        filterDefaultValues={{ customerIds: [id] }}
        sort={{ field: 'createdAt', order: 'DESC' }}
      >
        <ListContext.Consumer>
          {({ data, isLoading, isFetching }) => (
            <ContentTableContainer>
              <DataTable
                loading={isLoading || isFetching || !columns}
                columns={columns}
                rows={data || []}
                autoHeight
                density="standard"
                noRowsMessage={translate('customer.bookmarkList.noResults')}
              />
            </ContentTableContainer>
          )}
        </ListContext.Consumer>
      </ListBase>
    </ContentContainer>
  );
};
