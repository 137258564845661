import { useRef, useState } from 'react';

import Avatar from '@mui/material/Avatar';
import {
  ImageField,
  ImageInput,
  useAuthState,
  useGetIdentity,
  useGetOne,
  useLogout,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin';
import {
  DriveFileRenameOutline,
  Logout,
  Language,
  Image,
} from '@mui/icons-material';
import {
  Collapse,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  styled,
} from '@mui/material';
import { LocalesButton } from 'components/localesButton/LocalesButton';
import { ActionModal } from 'components/action-modal/ActionModal';
import { FieldValues } from 'react-hook-form';
import {
  fileToBase64,
  urlToSrc,
} from 'components/image-input/utils/imageInput.formater.utils';
import { useUploadProfilePicture } from 'features/customers/details/edit/hooks/useUploadProfilePicture';
import { BaseUser } from 'types/user.types';
import { useUploadSignature } from './hooks/useUploadSignature';

const StyledAvatar = styled(Avatar)`
  height: 24px;
  vertical-align: middle;
  width: 24px;
  cursor: pointer;
`;

const MenuWrapper = styled('div')`
  min-width: 300px;
`;

export const ProfileDropdown: React.FC = () => {
  const { identity } = useGetIdentity();
  const refresh = useRefresh();

  const { data: baseUser } = useGetOne<BaseUser>(
    'users',
    { id: identity?.id ? String(identity.id) : '' },
    { enabled: Boolean(identity) }
  );

  const [isUploading, setIsUploading] = useState(false);
  const logout = useLogout();
  const notify = useNotify();
  const anchorRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenAvatarModal, setIsOpenAvatarModal] = useState<boolean>(false);
  const [isOpenSignatureModal, setIsOpenSignatureModal] =
    useState<boolean>(false);
  const t = useTranslate();
  const { authenticated } = useAuthState();
  const uploadProfilePicture = useUploadProfilePicture();
  const uploadSignature = useUploadSignature();
  const handleUpdateAvatar = () => {
    setIsOpen(false);
    setIsOpenAvatarModal(true);
  };
  const handleUpdateSignature = () => {
    setIsOpen(false);
    setIsOpenSignatureModal(true);
  };

  const handleClick = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
    setIsOpenAvatarModal(false);
    setIsOpenSignatureModal(false);
  };

  const onUpdateAvatar = async ({ avatar }: FieldValues) => {
    if (!identity?.id) {
      return;
    }

    if (!avatar) {
      throw new Error('Missing file provided by the callback');
    }

    setIsUploading(true);
    const fileBase64 = await fileToBase64(avatar.rawFile);

    uploadProfilePicture.mutate(
      { customerId: String(identity.id), data: { fileBase64 } },
      {
        onError: () => {
          notify('customer.actions.addProfilePicture.upload.error', {
            type: 'error',
          });
          setIsUploading(false);
          setIsOpenAvatarModal(false);
        },
        onSuccess: () => {
          notify('customer.actions.addProfilePicture.upload.success', {
            type: 'success',
          });
          setIsUploading(false);
          setIsOpenAvatarModal(false);
          refresh();
        },
      }
    );
  };

  const onUpdateSignature = async ({ signature }: FieldValues) => {
    if (!identity?.id) {
      return;
    }

    if (!signature) {
      throw new Error('Missing file provided by the callback');
    }

    setIsUploading(true);
    const fileBase64 = await fileToBase64(signature.rawFile);

    uploadSignature.mutate(
      { data: { fileBase64 } },
      {
        onError: () => {
          notify('customer.actions.addSignature.upload.error', {
            type: 'error',
          });
          setIsUploading(false);
          setIsOpenSignatureModal(false);
        },
        onSuccess: () => {
          notify('customer.actions.addSignature.upload.success', {
            type: 'success',
          });
          setIsUploading(false);
          setIsOpenSignatureModal(false);
          refresh();
        },
      }
    );
  };

  return (
    <>
      <StyledAvatar
        ref={anchorRef}
        onClick={handleClick}
        src={urlToSrc(baseUser?.avatarS3ID, { width: 100 }) || undefined}
      />
      <Menu open={isOpen} anchorEl={anchorRef.current} onClose={onClose}>
        <MenuWrapper>
          {!!authenticated && (
            <>
              <div style={{ padding: '0 12px 12px 12px' }}>
                <Typography variant="h6">{t('profile.my_info')}</Typography>
                <Typography>{identity?.fullName}</Typography>
                <Typography>{identity?.email}</Typography>
                <Typography variant="h6">{t('profile.my_role')}</Typography>
                <Typography>{identity?.roles?.join(', ')}</Typography>
              </div>
              <Divider />
              <MenuItem onClick={handleUpdateAvatar}>
                <ListItemIcon>
                  <Image />
                </ListItemIcon>
                {t('users.updateAvatar')}
              </MenuItem>
              <MenuItem onClick={handleUpdateSignature}>
                <ListItemIcon>
                  <DriveFileRenameOutline />
                </ListItemIcon>
                {t('users.updateSignature')}
              </MenuItem>
            </>
          )}
          <Collapse in={true} timeout="auto" unmountOnExit>
            <LocalesButton>
              <MenuItem>
                <ListItemIcon>
                  <Language />
                </ListItemIcon>
                {t('profile.language')}
              </MenuItem>
            </LocalesButton>
          </Collapse>
          {authenticated ? (
            <MenuItem onClick={logout}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              {t('profile.logout')}
            </MenuItem>
          ) : null}
        </MenuWrapper>
      </Menu>
      {!!isOpenAvatarModal && (
        <ActionModal
          isOpen={isOpenAvatarModal}
          onClose={onClose}
          title={t('users.confirmUpdateAvatar')}
          saveLabel={t('ra.action.save')}
          slug="update-user-avatar"
          callback={onUpdateAvatar}
          loading={isUploading}
        >
          <ImageInput source="avatar" accept="image/*" label={false}>
            <ImageField source="src" title="title" />
          </ImageInput>
        </ActionModal>
      )}
      {!!isOpenSignatureModal && (
        <ActionModal
          isOpen={isOpenSignatureModal}
          onClose={onClose}
          title={t('users.confirmSignature')}
          saveLabel={t('ra.action.save')}
          slug="update-user-signature"
          callback={onUpdateSignature}
          loading={isUploading}
        >
          <ImageInput source="signature" accept="image/*" label={false}>
            <ImageField source="src" title="title" />
          </ImageInput>
        </ActionModal>
      )}
    </>
  );
};
