import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';
import { PermissionName } from 'types/permission.types';

interface CGPTransactionsPermissionsType {
  canListAllransactions: boolean;
}

export const useCGPTransactionsPermissions =
  (): CGPTransactionsPermissionsType => {
    const { hasPermission } = useHeliosPermissions();

    const canListAllransactions = hasPermission(PermissionName.ListProject);

    return {
      canListAllransactions,
    };
  };
