import { styled } from '@mui/material';

export const Container = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 24px;

  .address,
  .investor,
  .property {
    width: 100%;
    display: grid;
    gap: 24px;
  }

  .address {
    grid-template-columns: 1fr;
  }
  .investor {
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
      grid-template-columns: 1fr;
    }
  }
  .property {
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
      grid-template-columns: 1fr;
    }
  }
  .observations {
    grid-template-columns: 1fr;
  }
`;
