import React, { useCallback, useState } from 'react';

import {
  TextInput,
  useRecordContext,
  useStore,
  useTranslate,
} from 'react-admin';
import { ActionModal } from 'components/action-modal/ActionModal';
import { PriceInput } from 'ui/atoms/price-input/PriceInput';
import { RealEstateModalKey } from 'features/realEstates/constants';
import { styled } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { propertyPurchaseOfferForm } from 'features/realEstates/details/edit/RealEstateEdit.validator';
import { useGenerateCGPPurchaseOfferAction } from 'features/realEstates/list/hooks/useGeneratePurchaseOfferAction';
import { FieldValues } from 'react-hook-form';
import { BaseUser } from 'types/user.types';
import { InvestorAutocomplete } from 'components/investor-autocomplete/InvestorAutocomplete';
import { DocumentCategory } from 'types/document.enum';
import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import { CheckboxInput } from 'components/checkbox-input/CheckboxInput';
import { DateInput } from 'components/custom-input/date-input/DateInput';
import { GooglePlacesAutocomplete } from 'components/google-places-autocomplete/GooglePlacesAutocomplete';

export const Content = styled('div')`
  width: 100%;
`;

export type GeneratePurchaseOfferModalStore = {
  isOpen: boolean;
  user?: BaseUser;
  realEstate?: {
    id: string;
    financialModel: { agencySellerCommission?: number; netSeller: number };
  };
};

export const GenerateCGPPurchaseOfferModal: React.FC = () => {
  const translate = useTranslate();
  const [showSecondInvestorForm, setShowSecondInvestorForm] = useState(false);
  const [{ isOpen, user, realEstate }, showModal] =
    useStore<GeneratePurchaseOfferModalStore>(
      RealEstateModalKey.GENERATE_PURCHASE_OFFER,
      {
        isOpen: false,
      }
    );

  const record = useRecordContext(
    realEstate
      ? {
          record: realEstate,
        }
      : undefined
  );

  const generatePurchaseOffer = useGenerateCGPPurchaseOfferAction(record);

  const handleSecondInvestorCheckbox = useCallback(
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      setShowSecondInvestorForm(checked);
    },
    []
  );

  const handleCloseModal = useCallback(() => {
    showModal({ isOpen: false });
    setShowSecondInvestorForm(false);
  }, [showModal]);

  const onComplete = () => {
    handleCloseModal();
  };

  const handleCallback = ({
    customer,
    agencyCommission,
    netSeller,
    observations,
    secondInvestor,
  }: FieldValues) => {
    generatePurchaseOffer.mutate(
      {
        agencyCommission,
        customerId: customer,
        netSeller,
        observations,
        secondInvestor:
          secondInvestor && Object.keys(secondInvestor).length
            ? secondInvestor
            : undefined,
      },
      {
        onError: onComplete,
        onSuccess: onComplete,
      }
    );
  };

  return (
    <ActionModal
      slug="generatePurchaseOffer"
      resolver={yupResolver(propertyPurchaseOfferForm)}
      isOpen={isOpen}
      title={translate('realEstate.action.generatePurchaseOffer.form_title')}
      saveLabel={translate('realEstate.action.generatePurchaseOffer.submit')}
      onClose={handleCloseModal}
      callback={handleCallback}
      defaultValues={{
        agencyCommission: record.financialModel?.agencySellerCommission,
        customer: user?.id,
        netSeller: record.financialModel?.netSeller,
        second_investor_toggle: false,
      }}
    >
      <Content>
        <Fieldset nbColumn={1}>
          <InvestorAutocomplete
            label={translate(
              'realEstate.action.generatePurchaseOffer.input_customer'
            )}
            source="customer"
            showMissingDocuments
            missingDocumentTypes={[DocumentCategory.SearchMandate]}
            testId="offer-purchase-input-customer"
            isExternal={true}
          />
          <CheckboxInput
            helperText={false}
            source="second_investor_toggle"
            label={translate(
              'realEstate.action.generatePurchaseOffer.secondInvestor_checkbox'
            )}
            onChange={handleSecondInvestorCheckbox}
            data-testid="offer-purchase-secondInvestor_toggle"
          />
        </Fieldset>

        {!!showSecondInvestorForm && (
          <>
            <Fieldset
              title={translate(
                'realEstate.action.generatePurchaseOffer.secondInvestor_label'
              )}
              nbColumn={1}
              data-testid="offer-purchase-secondInvestor_fieldSet"
            >
              <Fieldset nbColumn={2}>
                <TextInput
                  helperText={false}
                  source="secondInvestor.firstName"
                  variant="outlined"
                  label={translate('user.input.firstname')}
                />
                <TextInput
                  helperText={false}
                  source="secondInvestor.lastName"
                  variant="outlined"
                  label={translate('user.input.lastname')}
                />
              </Fieldset>
              <Fieldset nbColumn={2}>
                <DateInput
                  helperText={false}
                  source="secondInvestor.birthDate"
                  label={translate('customer.birthDate')}
                />
                <TextInput
                  helperText={false}
                  source="secondInvestor.birthCity"
                  variant="outlined"
                  label={translate('customer.birthCity')}
                />
              </Fieldset>
              <Fieldset nbColumn={1}>
                <GooglePlacesAutocomplete
                  transform={(v) => v}
                  source="secondInvestor.address"
                  size="small"
                  label={translate('user.input.address')}
                />
              </Fieldset>
            </Fieldset>
          </>
        )}

        <Fieldset
          title={translate(
            'realEstate.action.generatePurchaseOffer.selling_label'
          )}
          nbColumn={1}
        >
          <PriceInput
            fullWidth
            label={translate(
              'realEstate.action.generatePurchaseOffer.input_netSeller'
            )}
            helperText={false}
            variant="outlined"
            source="netSeller"
            data-testid="offer-purchase-input-netSeller"
          />

          <PriceInput
            fullWidth
            label={translate(
              'realEstate.action.generatePurchaseOffer.input_agencyCommission'
            )}
            helperText={false}
            variant="outlined"
            source="agencyCommission"
            data-testid="offer-purchase-input-agencyCommission"
          />

          <TextInput
            multiline
            fullWidth
            label={translate(
              'realEstate.action.generatePurchaseOffer.observations_label'
            )}
            source="observations"
            variant="outlined"
            rows={5}
          />
        </Fieldset>
      </Content>
    </ActionModal>
  );
};
