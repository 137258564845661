import { IRealEstatesFilters } from './RealEstateFilters.types';

export const getRealEstatesDefaultFilters = (
  defaultHunters: string[] = []
): IRealEstatesFilters => {
  return {
    address: undefined,
    cities: [],
    countries: [],
    dpeGrades: [0, 6],
    furnishedValues: [],
    grossReturn: undefined,
    hunters: defaultHunters,
    includeMissingDpe: true,
    placeId: undefined,
    postalCodes: [],
    projectCost: undefined,
    publicationDate: {
      from: undefined,
      to: undefined,
    },
    regions: [],
    rentalSituations: [],
    rentingTypes: [],
    status: [],
    types: [],
    withShortTermRental: [],
    workBudget: undefined,
  };
};
