import { useCallback, useMemo } from 'react';

import { useGetIdentity, useRecordContext } from 'react-admin';
import { RealEstateStatus, RealEstateType } from 'types/realEstate.enum';
import { PermissionName, Scope } from 'types/permission.types';
import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';
import { RealEstate } from 'types/realEstate.types';

export type RealEstateDetailPermissionsType = {
  canEditRealEstate: boolean;
  canArchive: boolean;
  canAskPublish?: boolean;
  canDoPublish?: boolean;
  canGeneratePurchaseOffer: boolean;
  canUploadCounterSignedOffer?: boolean;
  canUpdatePublicationDetails?: boolean;
  canUpdateRenovationQuote?: boolean;
  canUpdateAnnualRentIncome?: boolean;
  canTransferRealEstate?: boolean;
};

/**
 * Hook to read the real-estate detail permissions.
 *
 * Must be used within a `<RecordContext>` as it depends on useRecordContext
 * @see useRecordContext
 */

export const useRealEstateDetailPermissions =
  (): RealEstateDetailPermissionsType => {
    const record: RealEstate | undefined = useRecordContext<RealEstate>();
    const { hasPermission, getScope } = useHeliosPermissions();
    const { identity } = useGetIdentity();

    const hasPermissionOnRecord = useCallback(
      (permission: PermissionName) => {
        if (!identity || !record) {
          return;
        }

        if (!hasPermission(permission)) {
          return false;
        }

        const scope = getScope(permission);

        switch (scope) {
          case Scope.All:
            return true;
          case Scope.Organization:
            return record.organization === identity.organization;
          case Scope.Own:
            return record.hunter?.id === identity.id;
          default:
            console.error(`Could not apply scope ${scope}`);
            return false;
        }
      },
      [record, identity, getScope, hasPermission]
    );

    const isPublishableRealEstate = [
      RealEstateStatus.DRAFT,
      RealEstateStatus.IN_REVIEW,
      RealEstateStatus.ARCHIVED,
    ].includes(record?.status);

    const canEditRealEstate = hasPermission(PermissionName.UpdateRealEstate);

    const canTransferRealEstate = !!hasPermissionOnRecord(
      PermissionName.UpdateRealEstateTransferOwnership
    );

    const canArchive = useMemo(
      () =>
        ![
          RealEstateStatus.ARCHIVED,
          RealEstateStatus.TRANSACTION,
          RealEstateStatus.SOLD,
        ].includes(record?.status) &&
        !!hasPermissionOnRecord(PermissionName.UpdateRealEstateArchive),
      [record?.status, hasPermissionOnRecord]
    );

    const canUpdatePublicationDetails = hasPermissionOnRecord(
      PermissionName.UpdateRealEstatePublicationDetails
    );

    const canUpdateRenovationQuote =
      hasPermissionOnRecord(PermissionName.UpdateRealEstateRenovationQuote) &&
      record.status !== RealEstateStatus.DRAFT;

    const canAskPublishArchived =
      hasPermissionOnRecord(
        PermissionName.UpdateRealEstateRequestPublishArchived
      ) && record.status === RealEstateStatus.ARCHIVED;

    const canAskPublishDraft =
      hasPermissionOnRecord(
        PermissionName.UpdateRealEstateRequestPublishDraft
      ) && record.status === RealEstateStatus.DRAFT;

    const canAskPublish = canAskPublishArchived || canAskPublishDraft;

    const canDoPublishAppartment =
      hasPermissionOnRecord(PermissionName.UpdateRealEstatePublishAppartment) &&
      record?.type === RealEstateType.Apartment &&
      isPublishableRealEstate;
    const canDoPublishBuilding =
      hasPermissionOnRecord(PermissionName.UpdateRealEstatePublishBuilding) &&
      record?.type === RealEstateType.Building &&
      isPublishableRealEstate;
    const canDoPublishHouse =
      hasPermissionOnRecord(PermissionName.UpdateRealEstatePublishHouse) &&
      record?.type === RealEstateType.House &&
      isPublishableRealEstate;

    const canDoPublish =
      canDoPublishAppartment || canDoPublishBuilding || canDoPublishHouse;

    const canGeneratePurchaseOffer =
      record?.status === RealEstateStatus.AVAILABLE &&
      !!hasPermissionOnRecord(PermissionName.UploadCounterSignedOffer);

    const canUploadCounterSignedOffer =
      hasPermissionOnRecord(PermissionName.UploadCounterSignedOffer) &&
      [RealEstateStatus.TRANSACTION, RealEstateStatus.AVAILABLE].includes(
        record?.status
      );

    const canUpdateAnnualRentIncome = hasPermission(
      PermissionName.UpdateRealEstateAnnualRentIncome
    );

    return {
      canArchive,
      canAskPublish,
      canDoPublish,
      canEditRealEstate,
      canGeneratePurchaseOffer,
      canTransferRealEstate,
      canUpdateAnnualRentIncome,
      canUpdatePublicationDetails,
      canUpdateRenovationQuote,
      canUploadCounterSignedOffer,
    };
  };
