import React from 'react';

import { ActionModal } from 'components/action-modal/ActionModal';
import { useNotify, useStore, useTranslate, useUpdate } from 'react-admin';
import {
  Positioning,
  PositioningConfirmModal,
  PositioningStatus,
} from 'types/positioning.types';
import { Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ChipList } from 'ui/molecules/chips-list/ChipList';
import { STATUS_POSITIONING_COLORS_AND_LABELS } from 'features/positioning/constants';

import { RePositioningModalProps } from './RePositioningModal.types';
import { InfoStyled, SubTitle } from './RePositioningModal.styles';
import { extractError } from './rePositioningModal.utils';

const RECOMMENDATION_COLORS_AND_LABELS =
  STATUS_POSITIONING_COLORS_AND_LABELS[PositioningStatus.Recommendation];

export const RePositioningModal: React.FC<RePositioningModalProps> = ({
  onSuccess,
}) => {
  const notify = useNotify();
  const translate = useTranslate();
  const [{ isOpen: isModalOpen, positioningId, customerFullName }, showModal] =
    useStore<PositioningConfirmModal>('positioning.repositioning_modal', {
      isOpen: false,
      positioningId: undefined,
    });

  const [update] = useUpdate<Positioning, Error>(
    'positionings',
    {
      data: { status: PositioningStatus.Recommendation },
      id: positioningId,
    },
    {
      onError: (error) => {
        notify(extractError(error.message), { type: 'error' });
      },
      onSuccess: () => {
        notify(
          translate('positioning.repositioning.success', { customerFullName }),
          {
            type: 'success',
          }
        );
        showModal({ isOpen: false });
        onSuccess && onSuccess();
      },
    }
  );

  const handleCloseModal = () => {
    showModal({ isOpen: false });
  };

  const handleOnConfirm = async () => {
    await update();
  };

  const chipStatus = {
    ...RECOMMENDATION_COLORS_AND_LABELS,
    label: translate(RECOMMENDATION_COLORS_AND_LABELS.label),
  };

  return (
    <ActionModal
      title={translate('positioning.repositioning.modalTitle')}
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      callback={handleOnConfirm}
      saveLabel={translate('ra.action.confirm')}
      slug={`positioning-repositioning-${positioningId}`}
      autoWidth
    >
      <div>
        <SubTitle variant="body2">
          {translate('positioning.repositioning.modalMsg')}
        </SubTitle>

        <InfoStyled>
          <InfoOutlinedIcon fontSize="small" />
          <Typography variant="body2">
            {translate('positioning.repositioning.info')}
          </Typography>
        </InfoStyled>
        <ChipList list={[chipStatus]} />
      </div>
    </ActionModal>
  );
};
