import React, { useState, useMemo, useCallback } from 'react';

import { Box, Menu, MenuItem, styled } from '@mui/material';
import { withLoader } from 'ui/hoc/withLoader';
import { SvgIconComponent } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

export interface DropdownProps<T> {
  items: T[];
  renderItem: (item: T) => JSX.Element | string;
  keyExtractor: (item: T) => string;
  onSelect: (item: T) => void;
  value?: T;
}

const Activator = styled('div')`
  min-width: fit-content;
  width: fit-content;
  cursor: pointer;
  padding: 0;
  width: 100%;
`;

export const Dropdown = <T,>(
  props: React.PropsWithChildren<DropdownProps<T>>
): JSX.Element => {
  const { children, items, renderItem, keyExtractor, onSelect, value } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = (): void => {
    setAnchorEl(null);
  };

  const selectItem = useCallback(
    (item: T) => {
      onSelect && onSelect(item);
      closeMenu();
    },
    [onSelect]
  );

  const itemsList = useMemo(
    () =>
      items.map((item: T) => {
        const key = keyExtractor(item);
        const IconComponent = (item as { icon?: SvgIconComponent }).icon;

        return (
          <MenuItem
            key={key}
            data-testid={(item as { testID?: string }).testID || key}
            onClick={() => selectItem(item)}
            selected={!!value && key === keyExtractor(value)}
            disabled={!!(item as { disable?: boolean }).disable}
          >
            {!!IconComponent && (
              <IconComponent sx={{ color: grey[600], marginRight: '12px' }} />
            )}
            {renderItem(item)}
          </MenuItem>
        );
      }),
    [items, keyExtractor, renderItem, selectItem, value]
  );

  return (
    <Box>
      <Activator
        onClick={openMenu}
        color="inherit"
        aria-controls="language-menu"
        aria-label=""
        aria-haspopup="true"
      >
        {children}
      </Activator>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        {itemsList}
      </Menu>
    </Box>
  );
};

export const LoadingDropdown = withLoader<DropdownProps<unknown>>(Dropdown);
