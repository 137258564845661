import React from 'react';

import { IconButton } from '@mui/material';
import { Tooltip } from 'ui/molecules/tooltip/Tooltip';
import { useTranslate } from 'react-admin';
import { Check, TaskAlt, UploadFile } from '@mui/icons-material';

import { ReplayCircleFilledStyled } from './ActionButtonGroup.styles';

export type FirstActionButtonProps = {
  canCreateOffer: boolean;
  handleOnCreateOffer: () => void;
  canBeRepositioned: boolean;
  handleRepositioning: () => void;
  isConfirmDisabled: boolean;
  handleOnConfirm: () => void;
  hasPermissionToUpdate: boolean;
};

export const FirstActionButton: React.FC<FirstActionButtonProps> = ({
  canCreateOffer,
  handleOnCreateOffer,
  canBeRepositioned,
  handleRepositioning,
  isConfirmDisabled,
  handleOnConfirm,
  hasPermissionToUpdate,
}) => {
  const translate = useTranslate();

  if (canBeRepositioned) {
    return hasPermissionToUpdate ? (
      <IconButton
        onClick={handleRepositioning}
        data-testid="repositioning-button"
      >
        <Tooltip title={translate('positioning.actions.info.repositioning')}>
          <ReplayCircleFilledStyled />
        </Tooltip>
      </IconButton>
    ) : (
      <IconButton
        className="actionButtonGroupDisabledButton"
        data-testid="repositioning-button-disabled"
      >
        <Tooltip title={translate('positioning.actions.info.repositioning')}>
          <ReplayCircleFilledStyled color="disabled" />
        </Tooltip>
      </IconButton>
    );
  }

  if (canCreateOffer) {
    return hasPermissionToUpdate ? (
      <IconButton
        onClick={handleOnCreateOffer}
        data-testid="create-offer-button"
      >
        <Tooltip
          title={translate('realEstate.action.generatePurchaseOffer.label')}
        >
          <UploadFile />
        </Tooltip>
      </IconButton>
    ) : (
      <IconButton
        className="actionButtonGroupDisabledButton"
        data-testid="create-offer-button-disabled"
      >
        <Tooltip
          title={translate('realEstate.action.generatePurchaseOffer.label')}
        >
          <UploadFile color="disabled" />
        </Tooltip>
      </IconButton>
    );
  }

  if (isConfirmDisabled || !hasPermissionToUpdate) {
    return (
      <IconButton
        className="actionButtonGroupDisabledButton"
        data-testid="confirm-button-disabled"
      >
        <Tooltip title={translate('positioning.actions.info.confirmDisabled')}>
          <Check color="disabled" />
        </Tooltip>
      </IconButton>
    );
  }

  return (
    <IconButton onClick={handleOnConfirm} data-testid="confirm-button">
      <TaskAlt color="success" />
    </IconButton>
  );
};
