import React from 'react';

import { FieldValues } from 'react-hook-form';
import { Button } from '@mui/material';
import { Modal, ModalFooterButtonsContainer } from 'ui/organisms/modal/Modal';
import { LoadingButton } from 'ui/molecules/loading-button/LoadingButton';
import {
  Create,
  useCreateController,
  useNotify,
  useRefresh,
  useStore,
  useTranslate,
} from 'react-admin';
import { Customer, CustomerModalKey } from 'types/customer.types';
import { formatAddCustomerError } from 'features/customers/list/utils/formatAddCustomerError.utils';
import { subSeconds } from 'date-fns';

import { AddCustomerModalStore } from './AddCustomerModal.types';
import { AddCustomerForm } from './AddCustomerForm';

export const AddCustomerModal: React.FC = () => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();

  const { save, isLoading, saving } = useCreateController<Customer, Error>({
    resource: 'customers',
  });

  const [{ isOpen, title }, setAddCustomerModalStore] =
    useStore<AddCustomerModalStore>(CustomerModalKey.CREATE_CUSTOMER, {
      isOpen: false,
    });

  const onClose = () => {
    setAddCustomerModalStore({ isOpen: false, title: '' });
  };

  const handleSubmit = (data: FieldValues) => {
    if (save) {
      save(data, {
        onError: (err) => {
          notify(formatAddCustomerError(err), {
            messageArgs: {
              advisor: err.body.exception?.context?.advisorName,
            },
            type: 'error',
          });
        },
        onSuccess: (resp: Customer) => {
          const isAttachedAdvisor =
            new Date(resp.createdAt) < subSeconds(new Date(), 5);
          refresh();
          notify(
            isAttachedAdvisor
              ? 'customer.invite.success'
              : 'customer.create.success',
            {
              type: 'success',
            }
          );
        },
      });
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={title ?? ''}
      footer={
        <ModalFooterButtonsContainer>
          <Button variant="text" onClick={onClose}>
            {translate('ra.action.cancel')}
          </Button>
          <LoadingButton
            loading={isLoading || saving}
            variant="text"
            type="submit"
            form="customer-create-form"
            data-testid="customer-create-submit-button"
          >
            {translate('ra.action.add')}
          </LoadingButton>
        </ModalFooterButtonsContainer>
      }
    >
      <Create>
        <AddCustomerForm onSubmit={handleSubmit} />
      </Create>
    </Modal>
  );
};
