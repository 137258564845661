import { HeliosDataProvider } from 'providers/admin/dataProvider';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { useMutation } from 'react-query';
import { ActionMutationReturn } from 'types/common.types';
import { UpdateBroughtByBody } from 'types/customMethods.types';

export const useUpdateBroughtBy = (): ActionMutationReturn<
  unknown,
  unknown,
  { customerId: string; data: UpdateBroughtByBody },
  unknown
> => {
  const refresh = useRefresh();
  const dataProvider = useDataProvider<HeliosDataProvider>();
  const notify = useNotify();

  return useMutation(
    ['updateBroughtBy', dataProvider],
    (params: { customerId: string; data: UpdateBroughtByBody }) => {
      return dataProvider
        .updateBroughtBy(params.customerId, params.data)
        .then((response: unknown) => {
          if (response) {
            refresh();
            notify('customer.actions.updateBroughtBy.success', {
              type: 'success',
            });
          }
        })
        .catch(() => {
          notify('customer.actions.updateBroughtBy.error', {
            type: 'error',
          });
        });
    }
  );
};
