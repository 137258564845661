import { Customer, CustomerEditFormatted } from 'types/customer.types';
import { formatCustomerAddress } from 'utils/form/form.utils';

export interface CustomerOverride
  extends Pick<
    Customer,
    | 'address'
    | 'birthCity'
    | 'birthDate'
    | 'civility'
    | 'downPayment'
    | 'email'
    | 'firstName'
    | 'lastName'
  > {
  search: {
    budgetMax: Customer['search']['budgetMax'];
  };
}

export const overrideCustomerEditFormatted = (
  customerEditFormatted: CustomerEditFormatted,
  overrides: Partial<CustomerOverride>
): CustomerEditFormatted => {
  const streetOoverride = overrides?.address
    ? formatCustomerAddress(overrides.address)
    : customerEditFormatted.address.street;
  return {
    ...customerEditFormatted,
    ...overrides,
    address: {
      ...(overrides?.address
        ? overrides.address
        : customerEditFormatted.address),
      street: streetOoverride,
    },
    search: {
      ...customerEditFormatted.search,
      ...overrides?.search,
    },
  };
};
