import { useTranslate } from 'react-admin';
import { LocationType, RealEstateType } from 'types/realEstate.enum';

export interface PropertyTitleFields {
  city: string;
  surface?: number;
  totalLots?: number;
  type: RealEstateType;
  locationType?: LocationType;
}

export const useRealEstateTitle = ({
  city,
  surface,
  totalLots,
  type,
  locationType,
}: PropertyTitleFields): string => {
  const translate = useTranslate();

  if (!city) {
    return '';
  }

  const titleType = translate(`realEstate.type.${type.toLowerCase()}`);
  const surfaceTitle = surface ? `${surface} m2` : '';

  if (
    locationType &&
    locationType.toLowerCase() === LocationType.Colocation.toLowerCase()
  ) {
    return `${translate(
      'realEstate.location.type.colocation'
    )} - ${surfaceTitle} - ${city}`;
  }

  if (
    type &&
    type.toLowerCase() === RealEstateType.Building.toLowerCase() &&
    totalLots
  ) {
    const totalLotsTitle = `${totalLots} ${translate('shared.lot', {
      count: totalLots,
    })}`;

    return [titleType, totalLotsTitle, city].filter(Boolean).join(' - ');
  }

  return `${titleType} - ${surfaceTitle} - ${city}`;
};
