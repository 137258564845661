import { RoleName } from 'types/role.types';
import { UsersFiltersType } from 'types/user.types';

import { ROLES } from 'constants/user';

export const getDefaultUserFilters = (): UsersFiltersType => ({
  roles: ROLES.map(({ value }) => value).filter(
    (role) => role !== RoleName.User
  ),
});
