import { RoleName } from 'types/role.types';

import { ROLES } from 'constants/user';

export const getRoleChoices = (
  t?: (key: string) => string
): {
  id: RoleName;
  name: string;
}[] =>
  ROLES.map(({ value, label }) => ({
    id: value,
    name: t ? t(`roles.${label}`) : label,
  }));
