import { useContext, useEffect, useMemo, useState } from 'react';

import { useDataProvider, useEditContext, useInput } from 'react-admin';
import { RealEstateStatus, RealEstateType } from 'types/realEstate.enum';
import {
  FormSpecificitiesType,
  getFormSpecificities,
} from 'features/realEstates/details/edit/utils/getFormSpecificities';
import { FormSpecificities } from 'types/realEstate.types';
import { HeliosDataProvider } from 'providers/admin/dataProvider';
import { useRealEstateDetailPermissions } from 'features/realEstates/details/edit/hooks/useRealEstateDetailPermissions';
import { ErrorStepBottomBar } from 'components/form/error-step-bottom-bar/ErrorStepBottomBar';
import {
  ActionContext,
  ActionContextProps,
} from 'features/realEstates/details/edit/context/ActionContextProvider';
import { Disclaimer } from 'ui/molecules/disclaimer/Disclaimer';
import { DisclaimerContainer } from 'ui/molecules/disclaimer/Disclaimer.styles';

import { LocalizationForm } from './localization-form/LocalizationForm';
import { FinancialModelForm } from './financial-model-form/FinancialModelForm';
import { ProjectForm } from './project-form/ProjectForm';
import { RenovationForm } from './renovation-form/RenovationForm';
import { OverviewForm } from './overview-form/OverviewForm';
import { AccessForm } from './access-form/AccessForm';
import { MiscForm } from './misc-form/MiscForm';
import { MarketDataForm } from './market-data-form/MarketDataForm';
import { SynthesisForm } from './synthesis-form/SynthesisForm';
import { InternalNoteForm } from './internal-note-form/InternalNoteForm';
import { ContactForm } from './contact-form/ContactForm';
import { sourceToLabelMap } from './EditFormContent.utils';
import { FormContainer } from './EditFormContent.styles';

export const EditFormContent: React.FC = () => {
  const { record } = useEditContext();

  const dataProvider = useDataProvider<HeliosDataProvider>();
  const [formSpecificities, setFormSpecificities] =
    useState<FormSpecificitiesType | null>(null);
  const { canUpdatePublicationDetails, canUpdateRenovationQuote } =
    useRealEstateDetailPermissions();

  const { doDisplayError } = useContext<ActionContextProps>(ActionContext);

  const {
    field: { value: typeOfProperty },
  } = useInput({
    source: 'type',
  });

  const shouldDisplayIDRDisclaimer = typeOfProperty === RealEstateType.Building;

  useEffect(() => {
    dataProvider
      .formSpecificities(record.address.countryCode.toLowerCase())
      .then((res: { data: FormSpecificities }) => {
        if (res.data) {
          const {
            canSeeDpe,
            canSeeGes,
            overrideDpeValues,
            canSeeAnnualHomeOwnerInsurance,
          } = getFormSpecificities(res.data);

          setFormSpecificities({
            canSeeAnnualHomeOwnerInsurance,
            canSeeDpe,
            canSeeGes,
            overrideDpeValues,
          });
        }
      });
  }, [dataProvider, record.address.countryCode]);

  const expandAll = [
    RealEstateStatus.DRAFT,
    RealEstateStatus.IN_REVIEW,
    RealEstateStatus.ARCHIVED,
  ].includes(record.status);

  const formComponents = useMemo(() => {
    if (canUpdatePublicationDetails === undefined) {
      return [];
    }
    return [
      {
        Component: InternalNoteForm,
        defaultExpanded: false,
        disabled: !canUpdatePublicationDetails,
      },
      {
        Component: SynthesisForm,
        defaultExpanded: canUpdatePublicationDetails,
        disabled: !canUpdatePublicationDetails,
      },
      {
        Component: ProjectForm,
        defaultExpanded: canUpdatePublicationDetails && expandAll,
        disabled: !canUpdatePublicationDetails,
      },
      {
        Component: LocalizationForm,
        defaultExpanded: canUpdatePublicationDetails && expandAll,
        disabled: !canUpdatePublicationDetails,
      },
      {
        Component: FinancialModelForm,
        canSeeAnnualHomeOwnerInsurance:
          formSpecificities?.canSeeAnnualHomeOwnerInsurance,
        defaultExpanded: canUpdatePublicationDetails && expandAll,
        disabled: !canUpdatePublicationDetails,
      },
      {
        Component: OverviewForm,
        defaultExpanded: canUpdatePublicationDetails && expandAll,
        disabled: !canUpdatePublicationDetails,
      },
      {
        Component: ContactForm,
        defaultExpanded: canUpdatePublicationDetails && expandAll,
        disabled: !canUpdatePublicationDetails,
      },
      {
        Component: MiscForm,
        defaultExpanded: canUpdatePublicationDetails && expandAll,
        disabled: !canUpdatePublicationDetails,
      },
      {
        Component: RenovationForm,
        canSeeDpe: formSpecificities?.canSeeDpe,
        canSeeGes: formSpecificities?.canSeeGes,
        defaultExpanded: !canUpdateRenovationQuote && expandAll,
        disabled: !canUpdatePublicationDetails,
        overrideDpeValues: formSpecificities?.overrideDpeValues,
      },
      {
        Component: AccessForm,
        defaultExpanded: canUpdatePublicationDetails && expandAll,
        disabled: !canUpdatePublicationDetails,
      },
      {
        Component: MarketDataForm,
        defaultExpanded: canUpdatePublicationDetails && expandAll,
        disabled: !canUpdatePublicationDetails,
      },
    ];
  }, [
    expandAll,
    formSpecificities?.canSeeAnnualHomeOwnerInsurance,
    formSpecificities?.canSeeDpe,
    formSpecificities?.canSeeGes,
    formSpecificities?.overrideDpeValues,
    canUpdatePublicationDetails,
    canUpdateRenovationQuote,
  ]);

  return (
    <FormContainer>
      {!!shouldDisplayIDRDisclaimer && (
        <DisclaimerContainer sx={{ paddingTop: '22px' }}>
          <Disclaimer
            type="warning"
            title="disclaimer.idr.title"
            content="disclaimer.idr.content"
          />
        </DisclaimerContainer>
      )}
      {formComponents.map(({ Component, ...props }) => (
        <Component key={Component.name} {...props} />
      ))}

      <ErrorStepBottomBar
        visible={doDisplayError}
        sourceToLabelMap={sourceToLabelMap}
      />
    </FormContainer>
  );
};
