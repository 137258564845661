import { HeliosDataProvider } from 'providers/admin/dataProvider';
import { useDataProvider } from 'react-admin';
import { useMutation } from 'react-query';
import { ActionMutationReturn } from 'types/common.types';
import { UploadSignatureBody } from 'types/customMethods.types';

export const useUploadSignature = (): ActionMutationReturn<
  void,
  unknown,
  { data: UploadSignatureBody },
  unknown
> => {
  const dataProvider = useDataProvider<HeliosDataProvider>();

  return useMutation(
    ['uploadSignature', dataProvider],
    async (params: { data: UploadSignatureBody }) => {
      await dataProvider.uploadSignature(params.data);
    }
  );
};
