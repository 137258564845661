import { getValidToken } from './refreshToken';

export const checkAuth = async (): Promise<void> => {
  try {
    await getValidToken();
    return Promise.resolve();
  } catch (e) {
    return Promise.reject({ message: 'checkAuth.error.expiredToken' });
  }
};
