import React from 'react';

import { FormGroup } from '@mui/material';
import { CollapsibleSection } from 'ui/molecules/collapsible-section/CollapsibleSection';
import { RangeGroup } from 'ui/molecules/range-group/RangeGroup';
import { useListContext } from 'react-admin';

import { RangeKeysOfRealEstateFilters } from './FiltersRangeGroup.types';

export interface RangeGroupProps {
  source: RangeKeysOfRealEstateFilters;
  label: string;
}

export const FiltersRangeGroup: React.FC<RangeGroupProps> = ({
  source,
  label,
}) => {
  const { filterValues } = useListContext();
  return (
    <CollapsibleSection
      label={label}
      isDefaultExpanded={
        !!(filterValues[source]?.min || filterValues[source]?.max)
      }
    >
      <CollapsibleSection.GroupSpacer>
        <FormGroup>
          <RangeGroup source={source} testId={source} />
        </FormGroup>
      </CollapsibleSection.GroupSpacer>
    </CollapsibleSection>
  );
};
