import { Dpe } from 'types/realEstate.enum';

export const dpeColorMap = new Map<Dpe, string>([
  [Dpe.A, '#01C249'],
  [Dpe.B, '#7BC201'],
  [Dpe.C, '#BBC201'],
  [Dpe.D, '#FCCD26'],
  [Dpe.E, '#FCA626'],
  [Dpe.F, '#F86800'],
  [Dpe.G, '#E30C1C'],
]);
