import { styled } from '@mui/material/styles';
import {
  Create as RACreate,
  Edit as RAEdit,
  Form as RAForm,
} from 'react-admin';

export const Create = styled(RACreate)`
  width: 100%;

  .RaEdit-card {
    width: 100%;
  }
`;

export const Edit = styled(RAEdit)`
  width: 100%;

  .RaEdit-card {
    width: 100%;
  }
`;

export const Form = styled(RAForm)`
  box-shadow: none;
  padding-top: 10px;
`;

export const FormContainer = styled('div')`
  display: grid;
  gap: 24px;
  margin-top: 32px;
  margin-bottom: 24px;
`;
