import {
  CancellationReasons,
  cancellationReasons,
  CancelReasonsCategory,
} from '../types/transaction.types';

export const STATUS_COLORS: {
  [n: string]: 'default' | 'secondary' | 'primary' | 'success' | 'warning';
} = {
  canceled: 'default',
  finished: 'success',
  ongoing: 'secondary',
};

export const CURRENT_STEP_COLORS: {
  [n: string]:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'error';
} = {
  email_envoye: 'secondary',
  offer: 'primary',
  upload_compromis_signe: 'secondary',
  upload_encaissement: 'default',
  upload_offre_achat: 'primary',
  upload_offre_pret: 'warning',
  upload_raa: 'error',
  validate_sending_notary: 'warning',
};

const categoryOrder = {
  [CancelReasonsCategory.INVESTORS_REASONS]: 1,
  [CancelReasonsCategory.FINANCING]: 2,
  [CancelReasonsCategory.PROPERTY]: 3,
  [CancelReasonsCategory.WORKS]: 4,
  [CancelReasonsCategory.SELLER]: 5,
  [CancelReasonsCategory.OTHER]: 6,
};

export const CANCEL_REASONS_DATA = [...cancellationReasons]
  .sort(({ category: a }, { category: b }) =>
    categoryOrder[a] > categoryOrder[b] ? 1 : -1
  )
  .map(
    ({
      id,
      category,
    }: {
      id: CancellationReasons;
      category: CancelReasonsCategory;
    }) => ({
      category: `transaction.action.cancel.category_${category}`,
      id,
      name: `transaction.action.cancel.reasons_${id}`,
    })
  );
