import React from 'react';

import { useNotify, EditContext, useRedirect } from 'react-admin';
import { Header } from 'ui/organisms/header/Header';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { MobileFooter } from 'components/mobile-footer/MobileFooter';

import { organizationAddForm } from '../validators/organization.validator';
import { OrganizationForm } from '../components/organization-form/OrganizationForm';
import { ActionButtons } from '../components/action-button/ActionButton';
import { Edit, Form } from '../styles/organizations.styles';
import { TerritoryAddModal } from '../components/territory-modal/TerritoryAddModal';
import { TerritoryEditModal } from '../components/territory-modal/TerritoryEditModal';

export const OrganizationEdit: React.FC = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify('organization.update.success', { type: 'success' });
    redirect('list', 'organizations');
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onError = (err: any) => {
    notify(err?.body?.message || 'organization.update.error', {
      type: 'error',
    });
  };

  return (
    <Edit
      component="div"
      resource="organizations"
      mutationMode="pessimistic"
      mutationOptions={{ onError, onSuccess }}
    >
      <EditContext.Consumer>
        {({ record }) => (
          <>
            <Form
              id="organization-form"
              mode="onBlur"
              resolver={yupResolver(organizationAddForm)}
            >
              <Header title={record.name} actionButtons={<ActionButtons />} />
              <OrganizationForm mode="edit" />
              <MobileFooter>
                <ActionButtons />
              </MobileFooter>
            </Form>
            <TerritoryAddModal />
            <TerritoryEditModal />
          </>
        )}
      </EditContext.Consumer>
    </Edit>
  );
};
