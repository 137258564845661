import React from 'react';

import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import {
  useCreate,
  useNotify,
  useRefresh,
  useStore,
  useTranslate,
} from 'react-admin';
import { Modal, ModalFooterButtonsContainer } from 'ui/organisms/modal/Modal';
import { Button } from 'ui/atoms/button/Button';
import { LoadingButton } from 'ui/molecules/loading-button/LoadingButton';
import { formatFullName } from 'utils/strings/strings.utils';
import { ErrorResponse } from 'types/common.types';

import {
  AddPositioningForm,
  PositioningFormProps,
} from './components/AddPositioningForm';

interface AddPositioningModalFormProps {
  open: boolean;
  onClose: () => void;
  onSuccess?: (id: string) => void;
}

export const AddPositioningModalForm: React.FC<
  AddPositioningModalFormProps
> = ({ open, onClose }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const [, showModal] = useStore('positioning.create.show_add_modal');

  const { id: realEstateId } = useParams();

  const [create, { isLoading }] = useCreate(
    'positioning',
    {},
    {
      onSuccess: (data) => {
        refresh();
        const { firstName, lastName } = data.customer;
        notify(
          translate(`positioningForm.success`, {
            fullName: formatFullName(firstName, lastName),
          }),
          {
            type: 'success',
          }
        );
        showModal(false);
      },
    }
  );

  const handleSubmit: PositioningFormProps['handleSubmit'] = async (
    createDto: Record<string, unknown>
  ) => {
    try {
      await create(
        'positionings',
        { data: createDto },
        { returnPromise: true }
      );
    } catch (error: unknown) {
      const message = (error as ErrorResponse)?.body?.message;
      if (message.startsWith('positioning document duplicate')) {
        return {
          customerId: 'positioningForm.errorDuplicate',
        };
      }
      notify(message, { type: 'error' });
    }
  };

  if (!realEstateId) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={translate('positioningForm.title')}
      autoWidth
      footer={
        <ModalFooterButtonsContainer>
          <Button variant="text" onClick={onClose}>
            {translate('ra.action.cancel')}
          </Button>
          <LoadingButton
            loading={isLoading}
            variant="text"
            type="submit"
            form="positioning-form"
            data-testid="positioning-form-submit-button"
          >
            {translate('ra.action.add')}
          </LoadingButton>
        </ModalFooterButtonsContainer>
      }
    >
      <>
        <Typography variant="body2" marginBottom="20px">
          {translate('positioningForm.description')}
        </Typography>

        <AddPositioningForm
          handleSubmit={handleSubmit}
          initialData={{ realEstateId: realEstateId }}
        />
      </>
    </Modal>
  );
};
