/* eslint-disable max-lines */
import { RealEstate } from './realEstate.types';
import { BaseUser } from './user.types';
import { Organization } from './organizations.types';

export enum TodosStep {
  EMAIL_ENVOYE = 'EMAIL_ENVOYE',
  SALE_AGREEMENT_ESTIMATION = 'SALE_AGREEMENT_ESTIMATION',
  AUTHENTICATED_DEED_RECEPTION_ESTIMATION = 'AUTHENTICATED_DEED_RECEPTION_ESTIMATION',
  UPLOAD_ENCAISSEMENT = 'UPLOAD_ENCAISSEMENT',
  UPLOAD_COMPROMIS_SIGNE = 'UPLOAD_COMPROMIS_SIGNE',
  UPLOAD_OFFRE_ACHAT = 'UPLOAD_OFFRE_ACHAT',
  UPLOAD_OFFRE_PRÊT = 'UPLOAD_OFFRE_PRÊT',
  UPLOAD_RAA = 'UPLOAD_RAA',
  VALIDATE_SENDING_NOTARY = 'VALIDATE_SENDING_NOTARY',
}

export type Documents = {
  id: string;
  category?: string;
  signedAt?: string;
};

export type Todos = {
  id: TodosStep;
  state: 'done' | 'todo' | 'skip';
  updateDoc: string;
  updatedAt: string;
  estimatedDate?: string;
  documents: Documents[];
};

export type Steps = {
  estimatedAuthenticatedDeedReception: Todos;
  estimatedSalesAgreement: Todos;
};

export type AggregateOrganization<T extends { organization: string }> = Omit<
  T,
  'organization'
> & { organization: Organization };

export type Transaction = {
  id: string;

  user: BaseUser;
  advisor: BaseUser;
  backoffice?: BaseUser;

  buyerNotary?: string;
  cancellationReason?: CancellationReasons;
  additionalCancellationReasons?: CancellationReasons[];
  cancellationCommentary?: string;

  createdAt: Date;

  documents: Documents[];

  property: RealEstate;

  realAgencySellerCommission?: number;
  realMasteosCommission?: number;
  realNetSeller?: number;
  soldAt?: Date;

  status: TransactionStatus;

  title: string;

  todos: Todos[];
  steps: Steps;

  updatedAt?: Date;

  vendorNotary?: string;

  organization: string;

  // Kalitics
  renovationProjectId?: string;

  secondInvestor: {
    firstName: string;
    lastName: string;
  };
  observations: string;
};

export type TransactionListItem = AggregateOrganization<Transaction>;

export enum CancelReasonsCategory {
  INVESTORS_REASONS = 'investorsReasons',
  FINANCING = 'financing',
  PROPERTY = 'property',
  WORKS = 'works',
  SELLER = 'Seller',
  OTHER = 'other',
}

export const cancellationReasons = [
  {
    category: CancelReasonsCategory.INVESTORS_REASONS,
    id: 'externalInfluence',
  },
  {
    category: CancelReasonsCategory.INVESTORS_REASONS,
    id: 'customerBypassedMasteos',
  },
  {
    category: CancelReasonsCategory.INVESTORS_REASONS,
    id: 'investedOutsideOfMasteos',
  },
  {
    category: CancelReasonsCategory.FINANCING,
    id: 'debtRatioExceeded',
  },
  {
    category: CancelReasonsCategory.INVESTORS_REASONS,
    id: 'lackOfMaturity',
  },
  { category: CancelReasonsCategory.FINANCING, id: 'bankBehavior' },
  { category: CancelReasonsCategory.INVESTORS_REASONS, id: 'lossOfTrust' },
  {
    category: CancelReasonsCategory.FINANCING,
    id: 'incompatibleProfessionalStatus',
  },
  {
    category: CancelReasonsCategory.INVESTORS_REASONS,
    id: 'investorSituationChange',
  },
  {
    category: CancelReasonsCategory.SELLER,
    id: 'sellerSituationChange',
  },
  {
    category: CancelReasonsCategory.FINANCING,
    id: 'insufficientDownPayment',
  },
  {
    category: CancelReasonsCategory.INVESTORS_REASONS,
    id: 'professionalSituationChange',
  },
  {
    category: CancelReasonsCategory.PROPERTY,
    id: 'badFitForCommonAreas',
  },
  { category: CancelReasonsCategory.INVESTORS_REASONS, id: 'unfitInvestor' },
  {
    category: CancelReasonsCategory.PROPERTY,
    id: 'badFitForNeighborhood',
  },
  {
    category: CancelReasonsCategory.INVESTORS_REASONS,
    id: 'purchasedPrimaryResidence',
  },
  {
    category: CancelReasonsCategory.PROPERTY,
    id: 'differencesBetweenPropertyPresentationAndProperty',
  },
  {
    category: CancelReasonsCategory.INVESTORS_REASONS,
    id: 'purchaseOfOtherInvestmentProducts',
  },
  {
    category: CancelReasonsCategory.PROPERTY,
    id: 'doubtsOnRentalDemand',
  },
  {
    category: CancelReasonsCategory.INVESTORS_REASONS,
    id: 'unvalidatedMoving',
  },
  {
    category: CancelReasonsCategory.PROPERTY,
    id: 'conditionOfThePropertyRentalStatus',
  },
  {
    category: CancelReasonsCategory.FINANCING,
    id: 'proportionOfRenovationTooHigh',
  },
  {
    category: CancelReasonsCategory.PROPERTY,
    id: 'issueIdentifiedByTheNotary',
  },
  { category: CancelReasonsCategory.FINANCING, id: 'unInsurable' },
  {
    category: CancelReasonsCategory.WORKS,
    id: 'estimateIncreased',
  },
  {
    category: CancelReasonsCategory.FINANCING,
    id: 'usuryRateExceeded',
  },
  {
    category: CancelReasonsCategory.WORKS,
    id: 'insufficientImprovementInEnergyPerformanceRating',
  },
  {
    category: CancelReasonsCategory.SELLER,
    id: 'conditionsNoLongerSuitable',
  },
  {
    category: CancelReasonsCategory.PROPERTY,
    id: 'issueIdentifiedInThePvAg',
  },
  { category: CancelReasonsCategory.OTHER, id: 'badNotary' },
  {
    category: CancelReasonsCategory.PROPERTY,
    id: 'negotiationForWorksImpossible',
  },
  {
    category: CancelReasonsCategory.SELLER,
    id: 'dishonestAgent',
  },
  {
    category: CancelReasonsCategory.PROPERTY,
    id: 'rentRegulationNotRespected',
  },
  {
    category: CancelReasonsCategory.OTHER,
    id: 'interactionWithMasteos',
  },
  {
    category: CancelReasonsCategory.PROPERTY,
    id: 'shortTermRentalImpossible',
  },
  {
    category: CancelReasonsCategory.OTHER,
    id: 'exitAfterRecommendationFromAdvisor',
  },
  {
    category: CancelReasonsCategory.PROPERTY,
    id: 'unattractivePricePerSquareMeter',
  },
  { category: CancelReasonsCategory.OTHER, id: 'negotiation' },
  {
    category: CancelReasonsCategory.SELLER,
    id: 'noLongerWantsToSell',
  },
  {
    category: CancelReasonsCategory.PROPERTY,
    id: 'shortTermRentalProjectNotStrongEnough',
  },
  { category: CancelReasonsCategory.OTHER, id: 'preemption' },
  {
    category: CancelReasonsCategory.SELLER,
    id: 'receivedABetterOffer',
  },
] as const;

export type CancellationReasons = typeof cancellationReasons[number]['id'];

export type TransactionsFiltersType = {
  advisor: string[];
  customer: string[];
  status: TransactionStatus | null;
};

export enum TransactionStatus {
  ONGOING = 'ONGOING',
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
}

export enum TransactionModalKey {
  CANCEL = 'transaction.show.show_cancel_modal',
  UPDATE = 'transaction.show.show_update_modal',
  TRANSFER_TRANSACTION = 'realEstate.edit.show_transfer_transaction_modal',
}

export type TransactionListItemFormatted = Omit<
  Transaction,
  'user' | 'advisor' | 'todos' | 'organization'
> & {
  'advisor.fullName': string;
  currentStep: string;
  furnituresAndWork: number;
  organizationName: string;
  title: string;
  'user.email': string;
  'user.fullName': string;
  'user.id': string;
};

export type CGPTransactionListItem = Omit<
  TransactionListItem,
  'advisor.fullName'
>;

export type CGPTransactionListItemFormatted = Omit<
  Transaction,
  'user' | 'advisor' | 'todos' | 'organization'
> & {
  currentStep: string;
  furnituresAndWork: number;
  organizationName: string;
  title: string;
  'user.email': string;
  'user.fullName': string;
  'user.id': string;
};
