import React, { useState } from 'react';

import {
  AutocompleteInput,
  TextInput,
  useStore,
  useTranslate,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { ActionModal } from 'components/action-modal/ActionModal';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CancellationReasons,
  TransactionModalKey,
} from 'types/transaction.types';
import { transactionCancelForm } from 'features/transactions/validators/transaction.validator';
import { Typography } from '@mui/material';
import { RadioOption } from 'components/custom-autocomplete/custom-autocomplete-options/radio-option/RadioOption';
import { useCancelAction } from 'features/transactions/show/hooks/useCancelAction';
import {
  getBasicAutocompleteGroupByFunction,
  getBasicAutocompleteInputTextFunction,
  getBasicAutocompleteMatchSuggestionFunction,
} from 'components/custom-autocomplete/custom-autocomplete.utils';
// eslint-disable-next-line max-len
import { AutocompleteArrayInputWithCheckboxes } from 'components/custom-autocomplete/autocomplete-array-input-with-checkboxes/AutocompleteArrayInputWithCheckboxes';

import { CANCEL_REASONS_DATA } from 'constants/transactions';

import { Content, Section } from './CancelModal.styles';

interface CancelTransactionAutocompleteInput {
  id: string;
  category: string;
  name: string;
}

export const CancelModal: React.FC = () => {
  const translate = useTranslate();
  const [isModalOpen, showModal] = useStore(TransactionModalKey.CANCEL, false);
  const [cancellationReasonSelected, setCancellationReasonSelected] = useState<
    string | undefined
  >();
  const [
    additionalCancellationReasonsSelected,
    setAdditionalCancellationReasonsSelected,
  ] = useState<CancellationReasons[]>([]);

  const cancelTransaction = useCancelAction();

  const handleCloseModal = () => {
    setAdditionalCancellationReasonsSelected([]);
    setCancellationReasonSelected(undefined);
    showModal(false);
  };

  const handleCallback = ({
    additionalCancellationReasons,
    cancellationCommentary,
    cancellationReason,
  }: FieldValues) => {
    cancelTransaction.mutate({
      additionalCancellationReasons,
      cancellationCommentary,
      cancellationReason,
    });
    handleCloseModal();
  };

  const onChangeSelectInput = (value: string) =>
    setCancellationReasonSelected(value);

  const autocompleteGetOptionDisabled = ({
    id,
  }: CancelTransactionAutocompleteInput) => cancellationReasonSelected === id;

  const autocompleteOnChange = (options: CancellationReasons[]) =>
    setAdditionalCancellationReasonsSelected(options);

  return (
    <ActionModal
      slug="cancel"
      resolver={yupResolver(transactionCancelForm)}
      isOpen={isModalOpen}
      title={translate('transaction.action.cancel.title')}
      saveLabel={translate('ra.action.confirm')}
      onClose={handleCloseModal}
      callback={handleCallback}
      defaultValues={{}}
    >
      <Content>
        <Section>
          <Typography variant="subtitle2" fontWeight={500}>
            {translate('transaction.action.cancel.reasonTitle')}
          </Typography>
          <AutocompleteInput<CancelTransactionAutocompleteInput>
            choices={CANCEL_REASONS_DATA}
            emptyValue={0}
            filterSelectedOptions={false}
            fullWidth
            groupBy={getBasicAutocompleteGroupByFunction(translate)}
            helperText="transaction.action.cancel.reasonHelperText"
            inputText={getBasicAutocompleteInputTextFunction(translate)}
            label="transaction.action.cancel.label"
            limitTags={1}
            matchSuggestion={getBasicAutocompleteMatchSuggestionFunction(
              translate
            )}
            onChange={onChangeSelectInput}
            optionText={
              <RadioOption
                selectedOption={cancellationReasonSelected}
                preventDefault
              />
            }
            source="cancellationReason"
            variant="outlined"
          />
        </Section>
        <Section>
          <Typography variant="subtitle2" fontWeight={500}>
            {translate('transaction.action.cancel.additionalReasonsTitle')}
          </Typography>
          <AutocompleteArrayInputWithCheckboxes<CancelTransactionAutocompleteInput>
            choices={CANCEL_REASONS_DATA}
            disabled={!cancellationReasonSelected}
            fullWidth
            getOptionDisabled={autocompleteGetOptionDisabled}
            helperText="transaction.action.cancel.reasonsHelperText"
            label="transaction.action.cancel.labelAdditionalReasons"
            checkboxOptionProps={{
              preventDefault: true,
              selectedOptions: [
                ...additionalCancellationReasonsSelected,
                cancellationReasonSelected,
              ],
            }}
            isRequired
            onChange={autocompleteOnChange}
            source="additionalCancellationReasons"
            variant="outlined"
          />
        </Section>
        <Section>
          <Typography variant="subtitle2" fontWeight={500}>
            {translate('transaction.action.cancel.commentaryTitle')}
          </Typography>
          <Typography variant="caption">
            {translate('transaction.action.cancel.commentaryDescription')}
          </Typography>
          <TextInput
            fullWidth
            multiline
            label="transaction.action.cancel.commentaryTitle"
            placeholder={translate(
              'transaction.action.cancel.placeholderCommentaryReasons'
            )}
            disabled={!cancellationReasonSelected}
            source="cancellationCommentary"
            rows={5}
          />
        </Section>
      </Content>
    </ActionModal>
  );
};
