import { Paper, Tabs as MuiTabs, Tab as MuiTab, styled } from '@mui/material';
import HouseOutlined from '@mui/icons-material/HouseOutlined';
import StarBorderOutlined from '@mui/icons-material/StarBorderOutlined';
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined';
import MultipleStopOutlined from '@mui/icons-material/MultipleStopOutlined';

const ICON_SIZE = 19;
const LABEL_MARGIN_TOP = 2;
const TAB_HEIGHT = 42 - LABEL_MARGIN_TOP;
const ICON_STYLE = `
height: ${ICON_SIZE}px;
margin-top: -2px;
width: ${ICON_SIZE}px;
`;

export const Container = styled(Paper)`
  margin-bottom: 16px;
  margin-top: 24px;
`;

export const Tabs = styled(MuiTabs)`
  min-height: ${TAB_HEIGHT}px;
`;

export const Tab = styled(MuiTab)`
  min-height: ${TAB_HEIGHT}px;
`;

export const InfoIcon = styled(HouseOutlined)`
  ${ICON_STYLE}
`;

export const PositioningIcon = styled(StarBorderOutlined)`
  ${ICON_STYLE}
`;

export const BookmarkIcon = styled(FavoriteBorderOutlined)`
  ${ICON_STYLE}
`;

export const TransactionIcon = styled(MultipleStopOutlined)`
  ${ICON_STYLE}
`;

export const ContentContainer = styled('div')`
  width: 100%;
  max-height: calc(100vh - 110px);
  height: calc(100vh - 110px);

  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    margin-left: 64px;
    margin-right: 64px;
  }
`;

export const ContentTableContainer = styled('div')`
  grid-area: table;
`;
