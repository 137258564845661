export const ACCESS_TOKEN_STORAGE_KEY = 'access_token';
export const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';

export type AuthTokens = {
  access_token: string;
  refresh_token: string;
};

export function persistTokens({
  access_token,
  refresh_token,
}: AuthTokens): AuthTokens {
  localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, access_token);
  localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refresh_token);

  return { access_token, refresh_token };
}

export function getTokenByKey(key: string): string {
  return localStorage.getItem(key) ?? '';
}

export function getTokensFromStorage(): AuthTokens {
  const access_token = getTokenByKey(ACCESS_TOKEN_STORAGE_KEY);
  const refresh_token = getTokenByKey(REFRESH_TOKEN_STORAGE_KEY);
  return { access_token, refresh_token };
}

export function clearTokensStorage(): void {
  localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
  localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
}
