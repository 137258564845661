import { PositioningStatus } from 'types/positioning.types';

export const STATUS_POSITIONING_COLORS_AND_LABELS = {
  [PositioningStatus.Archived]: {
    label: `positioning.status.${PositioningStatus.Archived}`,
  },
  [PositioningStatus.Cancelled]: {
    color: 'terminal',
    label: `positioning.status.canceled_short`,
  },
  [PositioningStatus.Confirmed]: {
    bgColor: '#1976D2',
    label: `positioning.status.${PositioningStatus.Confirmed}`,
    textColor: 'white',
  },
  [PositioningStatus.Pending]: {
    bgColor: '#ED6C02',
    label: `positioning.status.${PositioningStatus.Pending}`,
    textColor: 'white',
  },
  [PositioningStatus.Transaction]: {
    bgColor: '#ED6C02',
    label: `positioning.status.${PositioningStatus.Transaction}`,
    textColor: 'white',
  },
  [PositioningStatus.Recommendation]: {
    bgColor: '#FFB400',
    label: `positioning.status.${PositioningStatus.Recommendation}`,
    textColor: 'white',
  },
  [PositioningStatus.PrePositioningCancelled]: {
    color: 'terminal',
    label: `positioning.status.canceled_short`,
  },
};

export const DAYS_BEFORE_AUTO_CANCEL = 7;
