import * as yup from 'yup';
import {
  firstNameSchema,
  lastNameSchema,
  phoneNumberSchema,
  addressSchema,
} from 'features/external-customers/validators/external-customers.validator';

export const externalCustomerEditFormValidator = yup.object().shape({
  address: addressSchema,
  email: yup.string().email().required(),
  firstName: firstNameSchema,
  internalNote: yup.string().nullable(),
  lastName: lastNameSchema,
  phoneNumber: phoneNumberSchema.nullable(),
});
