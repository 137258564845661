import { useMemo } from 'react';

import {
  ListBase,
  useListController,
  useNotify,
  useStore,
  useTranslate,
} from 'react-admin';
import { DataTable } from 'components/data-table/DataTable';
import { getColumns } from 'utils/columns/getColumns.utils';
import { FilteredListLayout } from 'ui/layouts/filtered-list-layout/FilteredListLayout';
import { PermissionName } from 'types/permission.types';
import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';
import { RealizationModalKey } from 'types/realization.types';

import { formatRealizationsList } from './utils/formatRealizationsList.utils';
import { getRealizationsColumns } from './utils/getRealizationsColumns.utils';
import {
  AddRealizationModal,
  AddRealizationModalStore,
} from './components/modals/add-realization/AddRealizationModal';

const PER_PAGE = 20;

export const RealizationsList: React.FC = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const { hasPermission } = useHeliosPermissions();

  const columnsList = getRealizationsColumns(translate, notify);

  const { data: realizations, total } = useListController({
    perPage: PER_PAGE,
  });

  const columns = useMemo(
    () => (realizations ? getColumns(columnsList, translate) : []),
    [realizations, translate, columnsList]
  );

  const rows = useMemo(
    () => formatRealizationsList(realizations) ?? [],
    [realizations]
  );

  const table = useMemo(
    () => <DataTable columns={columns} rows={rows} rowAsLink />,
    [columns, rows]
  );

  const [_addRealizationModalStore, setAddRealizationModalStore] =
    useStore<AddRealizationModalStore>(RealizationModalKey.CREATE_REALIZATION, {
      isOpen: false,
    });

  const canCreateRealization = hasPermission(PermissionName.CreateRealization);

  const openAddRealizationModal = () => {
    setAddRealizationModalStore({
      isOpen: true,
    });
  };

  return (
    <ListBase perPage={PER_PAGE}>
      <FilteredListLayout
        main={table}
        title={translate('realization.list', { count: total ?? 0 })}
        {...(canCreateRealization && {
          addTitle: translate('realization.add'),
          onAddClick: openAddRealizationModal,
        })}
      />
      <AddRealizationModal />
    </ListBase>
  );
};
