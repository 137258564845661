import { FieldErrors } from 'react-hook-form';

import { FlattenFieldError } from './ErrorStepBottomBar.types';

export function getFlattenErrors(
  errors: FieldErrors,
  parentPath?: string
): FlattenFieldError[] {
  const errorKeys = Object.keys(errors);
  const flattenErrors: FlattenFieldError[] = [];
  for (const errorKey of errorKeys) {
    const path = parentPath ? `${parentPath}.${errorKey}` : errorKey;
    const message = errors[errorKey]?.message;
    const ref = errors[errorKey]?.ref;
    if (typeof message === 'string') {
      !!ref &&
        flattenErrors.push({
          message,
          path,
          ref,
        });
    } else {
      flattenErrors.push(
        ...getFlattenErrors(errors[errorKey] as FieldErrors, path)
      );
    }
  }
  return flattenErrors;
}
