import { detailedDiff } from 'deep-object-diff';

import { RealEstate } from 'types/realEstate.types';
import { formatDate } from 'utils/i18n/i18n.utils';
import { FormattedRealEstateChangeEntry } from './EntryElement';

export const formatChangeEntries = (
  entries: { data: RealEstate; updatedBy: string }[]
): FormattedRealEstateChangeEntry[] => {
  const diffs: FormattedRealEstateChangeEntry[] = [];

  for (let i = 0; i < entries.length; i++) {
    const entry = entries[i];
    const previousEntry = entries[i + 1];

    if (previousEntry) {
      const diff = detailedDiff(entry.data, previousEntry.data) as Pick<
        FormattedRealEstateChangeEntry,
        'added' | 'updated' | 'deleted'
      >;

      const completeEntry: FormattedRealEstateChangeEntry = {
        ...diff,
        updatedAt: formatDate({ locale: 'fr-FR', date: entry.data.updatedAt }),
        author: entry.updatedBy,
      };

      diffs.push(completeEntry);
    }
  }

  return diffs;
};

export const filterChangeEntries = (
  entries: FormattedRealEstateChangeEntry[]
): FormattedRealEstateChangeEntry[] => {
  const filteredEntries = entries.filter((entry) => {
    if (
      Object.keys(entry.updated).length === 1 &&
      Object.keys(entry.updated).includes('updatedAt')
    ) {
      return false;
    }

    return (
      Object.keys(entry.added).length > 0 ||
      Object.keys(entry.updated).length > 0 ||
      Object.keys(entry.deleted).length > 0
    );
  });

  return filteredEntries;
};
