import React, { useCallback, useState } from 'react';

import * as yup from 'yup';
import { useStore, useTranslate } from 'react-admin';
import { CustomerModalKey } from 'types/customer.types';
import { BaseUser } from 'types/user.types';
import { ActionModal } from 'components/action-modal/ActionModal';
import { Typography } from '@mui/material';
import { Content } from 'ui/organisms/drawer/Drawer.styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues } from 'react-hook-form';
import { useUpdateBroughtBy } from 'features/customers/details/edit/hooks/useUpdateBroughtBy';
import { UserAutoComplete } from 'components/user-autocomplete/UserAutoComplete';

export type ChangeBroughtByModalProps = {
  customerId: string;
  broughtBy?: BaseUser;
};
export const ChangeBroughtByModal: React.FC<ChangeBroughtByModalProps> = ({
  customerId,
}) => {
  const translate = useTranslate();
  const [isUploading, setIsUploading] = useState(false);
  const updateBroughtBy = useUpdateBroughtBy();
  const [open, setModalStore] = useStore(
    CustomerModalKey.UPDATE_CUSTOMER_BROUGHT_BY,
    false
  );

  const onSuccess = useCallback(() => {
    setIsUploading(false);
    setModalStore(false);
  }, [setModalStore]);

  const handleCallback = useCallback(
    async ({ broughtBy: userId }: FieldValues) => {
      setIsUploading(true);

      updateBroughtBy.mutate(
        { customerId, data: { broughtBy: userId } },
        { onSuccess }
      );
    },
    [customerId, onSuccess, updateBroughtBy]
  );

  const onClose = () => {
    setModalStore(false);
  };

  return (
    <ActionModal
      isOpen={open}
      title={
        <Typography variant="h6">
          {translate('customer.actions.updateBroughtBy.title')}
        </Typography>
      }
      callback={handleCallback}
      loading={isUploading}
      resolver={yupResolver(
        yup.object().shape({
          broughtBy: yup.string().required().nullable(),
        })
      )}
      onClose={onClose}
      saveLabel={translate('ra.action.save')}
      slug="updateBroughtBy"
    >
      <Content>
        <UserAutoComplete label="customer.broughtBy" source="broughtBy" />
      </Content>
    </ActionModal>
  );
};
