import { MouseEvent } from 'react';

import { IconButton, styled } from '@mui/material';

import { ActionChipProps } from './ActionChip.types';
import { Chip } from '../chip/Chip';

export const LabelContainer = styled('span')`
  display: inline-flex;
  align-items: center;
  width: 100%;
`;

export const LabelText = styled('span')`
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ActionChip: React.FC<ActionChipProps> = ({
  actionIcon,
  onActionClick,
  ...props
}) => {
  const handleOnClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onActionClick();
  };

  return (
    <Chip
      {...props}
      data-testid="action-chip"
      label={
        <LabelContainer>
          <LabelText>{props.label}</LabelText>
          <IconButton
            data-testid="action-chip-button"
            onClick={handleOnClick}
            size="small"
          >
            {actionIcon}
          </IconButton>
        </LabelContainer>
      }
    />
  );
};
