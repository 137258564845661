/* eslint-disable max-lines */
import { YesNoOrAny } from './common.types';
import { RealEstateType } from './realEstate.enum';
import { BaseUser } from './user.types';
import { RoleName } from './role.types';
import { ManagementMandateStatus } from './management-mandate.types';
import { FinancingCertificateStatus } from './financing-certificate.types';
import { DocumentCategory } from './document.enum';

export type Customer = BaseUser & {
  advisor?: CustomerAdvisor;
  account?: CustomerAccount;
  address: CustomerAddress;
  birthDate?: string;
  birthCity?: string;
  bookmarkCount?: number;
  positioningCount?: number;
  civility: CustomerCivility;
  createdAt: string;
  documents: CustomerDocuments;
  downPayment?: number;
  financingCertificateStatus: FinancingCertificateStatus;
  investBudget?: CustomerInvestBudget;
  lang: string;
  permissions: RoleName[];
  search: CustomerSearch;
  transactionStatus: CustomerTransactionStatus;
  updatedAt: string;
  utm: CustomerUTM;
  phoneNumber?: string;
  ownedBy?: string;
  broughtBy?: string;
  organization?: string;
};

export type CustomerListItem = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  createdAt: Date;
  deletedAt?: Date;
  investBudget?: string;
  search: CustomerSearch;
  utm: CustomerUTM;
  account: CustomerAccount;
  advisor: CustomerAdvisor;
  hasBorrowingCapacity: boolean;
  financingCertificateStatus: FinancingCertificateStatus;
  mandateStatus: ManagementMandateStatus;
  owner: BaseUser;
  broughtBy: BaseUser;
  organization: {
    id: string;
    name: string;
    siret: string;
    isMasteosCore: boolean;
  };
};

export type CustomerAppointment = {
  firstAppointmentDate: Date;
  firstAppointmentStatus: CustomerFirstAppointmentStatus;
};

export enum CustomerTransactionStatus {
  ONGOING = 'ongoing',
  NONE = 'none',
  INTEGRITY_ERROR = 'integrityError',
}

export enum CustomerFirstAppointmentStatus {
  NO_SHOW = 'rdv no-show',
  NO_APPOINTMENT = 'pas de rendez-vous',
  CANCELLED = 'rendez-vous annulé',
  PAST_APPOINTMENT = 'rendez-vous passé',
  SCHEDULED = 'rendez-vous prévu',
}

export type CustomerSearch = {
  budgetMax: number;
  budgetMin: number;
  countryCode: string;
  hasNeedRenovation: YesNoOrAny;
  propertyOnTheGroundFloor: YesNoOrAny;
  propertyKind: RealEstateType[];
  propertyRented: YesNoOrAny;
  propertyRoomCount: CustomerSearchRoomCountFilter[];
  regions: string[];
  sharedProperty: YesNoOrAny;
  timing: number;
  mandate?: CustomerSearchMandate;
};

export enum CustomerDocumentType {
  Mandate = 'Mandat',
  FinancingCertificate = 'FinancingCertificate',
}

export type CustomerDocuments = {
  financingCertificate?: {
    S3ID: string;
    createdAt: Date;
    id: string;
    toVerify: boolean;
    updatedAt: Date;
  };
  mandate?: {
    createdAt: Date;
    id: string;
    toVerify: boolean;
    updatedAt: Date;
    pandadocID: string;
    expiresAt: Date;
    signedAt?: Date;
    S3ID: string;
  };
};

export type CustomerGEDDocument =
  | (CustomerDocuments['financingCertificate'] & {
      category: CustomerDocumentType.FinancingCertificate;
    })
  | (CustomerDocuments['mandate'] & { category: CustomerDocumentType.Mandate })
  | {
      S3ID: string;
      createdAt: Date;
      id: string;
      title: string;
      category: DocumentCategory;
    };

export type CustomerGEDDocuments = CustomerGEDDocument[];

export type CustomerAddress = {
  city?: string;
  countryName?: string;
  countryCode?: string;
  latitude?: number;
  longitude?: number;
  postalCode?: string;
  street?: string;
};

type CustomerSearchMandate = {
  capacity: number;
  status: ManagementMandateStatus;
};

export enum CustomerInvestBudget {
  Below_300K = 'BELOW_300K',
  Between_300K_700K = 'BETWEEN_300K_700K',
  Above_700K = 'ABOVE_700K',
  Unknown = 'UNKNOWN',
}

export enum CustomerSource {
  Advertising = 'advertising',
  Blog = 'blog',
  MasteosCollaborator = 'masteosCollaborator',
  Other = 'other',
  PartnerAndAffiliate = 'partnerAndAffiliate',
  SearchEngine = 'searchEngine',
  SocialMedia = 'socialMedia',
  TvRadioPress = 'tvRadioPress',
  WordOfMouth = 'wordOfMouth',
}

export enum CustomerCivility {
  MR = 'MR',
  MRS = 'MRS',
  UNKNOWN = 'UNKNOWN',
}

type CustomerUTM = {
  content: string;
  term: string;
  campaign: string;
  source: string;
  medium: string;
};

export type CustomerListFormatted = Omit<
  CustomerListItem,
  | 'firstName'
  | 'lastName'
  | 'search'
  | 'utm'
  | 'account'
  | 'advisor'
  | 'appointments'
  | 'organization'
  | 'owner'
  | 'broughtBy'
> & {
  'account.affiliateEmail'?: string;
  'account.affiliateId'?: string;
  'advisor.fullName'?: string;
  'owner.fullName'?: string;
  'broughtBy.fullName'?: string;
  organizationName: string;
  fullName: string;
  'mandate.capacity'?: number;
  'searchSettings.budgetMax': CustomerSearch['budgetMax'];
  'searchSettings.budgetMin': CustomerSearch['budgetMin'];
  'searchSettings.flatSharing': CustomerSearch['sharedProperty'];
  'searchSettings.groundFloor': CustomerSearch['propertyOnTheGroundFloor'];
  'searchSettings.propertyKind': CustomerSearch['propertyKind'];
  'searchSettings.propertyRented': CustomerSearch['propertyRented'];
  'searchSettings.propertyRoomCount': CustomerSearch['propertyRoomCount'];
  'searchSettings.regions': CustomerSearch['regions'];
  'searchSettings.renovationNeeded': CustomerSearch['hasNeedRenovation'];
  'searchSettings.timing': CustomerSearch['timing'];
  'utm.campaign': CustomerUTM['campaign'];
  'utm.content': CustomerUTM['content'];
  'utm.medium': CustomerUTM['medium'];
  'utm.source': CustomerUTM['source'];
  'utm.term': CustomerUTM['term'];
};

export type CustomerEditFormatted = Omit<
  Customer,
  'financingCertificateStatus' | 'advisor'
> & {
  address: Customer['address'] & { street?: string };
  'financingCertificate.status': FinancingCertificateStatus;
  'financingCertificate.date': Date | undefined;
};

export enum CustomerSearchRoomCountFilter {
  One = 'ONE',
  Two = 'TWO',
  Three = 'THREE',
  Four = 'FOUR',
  Five = 'FIVE',
  SixOrMore = 'SIX_OR_MORE',
}

export type CustomerAdvisor = {
  id: string;
  firstName: string;
  lastName: string;
};

type CustomerAccount = {
  affiliateEmail: string;
  affiliateId: string;
};

export enum CustomerDrawerKey {
  GENERATE_SEARCH_MANDATE = 'customer.show.show_generate_localized_search_mandate_drawer',
  GENERATE_EXTERNAL_CUSTOMER_SEARCH_MANDATE = 'customer.show.show_generate_localized_search_mandate_drawer',
  UPLOAD_DOCUMENT = 'customer.show.show_upload_document_modal',
}

export enum MandateLocal {
  FR_fr = 'FR_fr',
}

export enum CustomerModalKey {
  CREATE_CUSTOMER = 'customer.create.create_customer_modal',
  UPDATE_CUSTOMER_BROUGHT_BY = 'customer.update.update_customer_broughtBy_modal',
  CONTACT_INFORMATION = 'customer.show.show_contact_information_modal',
}
