import { Translate } from 'react-admin';

export function getLabelFromErrorPath(
  path: string,
  translate: Translate,
  sourceToLabelMap: Map<string, string>
): string {
  const regex = /(?:\.\d+\.)/g;
  const indexes: string[] = [];
  const source = path.replace(regex, (match) => {
    indexes.push(match.replace(/\./g, ''));
    return '.';
  });

  const label = sourceToLabelMap.get(source);

  const translatedLabelOrRawSource = label ? translate(label) : source;

  return indexes.length
    ? `${translatedLabelOrRawSource} ${indexes
        .map((i) => `(${+i + 1})`)
        .join(' ')}`
    : translatedLabelOrRawSource;
}

type GetErrorStepTextParam = {
  path: string;
  message: string;
  errorIndex: number;
  errorsLength: number;
  smallVersion: boolean;
  translate: Translate;
  sourceToLabelMap: Map<string, string>;
};

export function getErrorStepText({
  path,
  message,
  smallVersion,
  errorIndex,
  errorsLength,
  translate,
  sourceToLabelMap,
}: GetErrorStepTextParam): string {
  const text = `${errorIndex + 1}/${errorsLength} : ${getLabelFromErrorPath(
    path,
    translate,
    sourceToLabelMap
  )} - ${translate(message)}`;

  return smallVersion ? text : `${translate('shared.error')} ${text}`;
}
