import { ReactNode } from 'react';

export const transformHelperText = (
  value: ReactNode
): ReactNode | string | null => {
  if (value === false) {
    return null;
  }
  // If empty value add empty space to force display <p></p> like other inputs
  return value || ' ';
};
