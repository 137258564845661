import React, { useCallback } from 'react';

import { ReferenceInput, AutocompleteInput, useTranslate } from 'react-admin';
import { filterSearchToQuery } from 'utils/filters/filters';
import { formatFullName } from 'utils/strings/strings.utils';
import { HeliosCustomerOption } from 'types/positioning.types';
import { DocumentCategory } from 'types/document.enum';

import { InvestorOption } from './InvestorOption';

export const InvestorAutocomplete: React.FC<{
  source: string;
  label?: string;
  missingDocumentTypes?: DocumentCategory[];
  showPositionings?: boolean;
  showMissingDocuments?: boolean;
  testId?: string;
  isExternal?: boolean;
}> = ({
  source,
  label,
  showMissingDocuments,
  missingDocumentTypes,
  showPositionings,
  testId,
  isExternal,
}) => {
  const translate = useTranslate();

  const getOptionDisabled = useCallback(
    (params: HeliosCustomerOption) => {
      const { meta } = params;
      if (!meta) {
        return false;
      }
      const filteredMissingDocuments = missingDocumentTypes
        ? meta.missingDocuments.filter((doc) =>
            missingDocumentTypes.includes(doc)
          )
        : [];

      const checkPositioning = showPositionings && meta.hasMaxPositioning;

      return filteredMissingDocuments.length >= 1 || !!checkPositioning;
    },
    [missingDocumentTypes, showPositionings]
  );

  const enableGetChoice = useCallback(
    ({ search }: { search: string }) => search?.length >= 3,
    []
  );

  return (
    <ReferenceInput
      perPage={20}
      reference={isExternal ? 'external-customers' : 'customers/available'}
      enableGetChoices={enableGetChoice}
      source={source}
    >
      <AutocompleteInput
        variant="outlined"
        getOptionDisabled={getOptionDisabled}
        helperText={translate('positioningForm.searchCustomer.helper')}
        TextFieldProps={{
          label: label ?? translate('positioningForm.searchCustomer.label'),
        }}
        filterToQuery={filterSearchToQuery}
        optionText={
          <InvestorOption
            showMissingDocuments={showMissingDocuments}
            showPositionings={showPositionings}
            missingDocumentTypes={missingDocumentTypes}
          />
        }
        inputText={(opt) =>
          opt.fullName || formatFullName(opt.firstName, opt.lastName)
        }
        debounce={500}
        data-testid={testId ?? 'investor-autocomplete'}
      />
    </ReferenceInput>
  );
};
