import { Typography } from '@mui/material';

export type OrganizationOptionProps = {
  name: string;
  siret: string;
};

export const OrganizationOption: React.FC<OrganizationOptionProps> = ({
  name,
  siret,
}) => {
  return (
    <div>
      <Typography variant="body2">{name}</Typography>

      <Typography variant="caption">{`siret: ${siret}`}</Typography>
    </div>
  );
};
