import {
  EstimatedRenovation,
  InvoiceMethod,
  InvoiceRecipient,
  NoWorkReasonChoicesCategory,
  ReasonNoWork,
} from '../types/project.types';

export const ESTIMATED_RENOVATION_CHOICES = [
  {
    id: EstimatedRenovation.No,
    name: 'project.form.estimatedRenovationChoices.no',
  },
  {
    id: EstimatedRenovation.Furnitures,
    name: 'project.form.estimatedRenovationChoices.furnitures',
  },
  {
    id: EstimatedRenovation.Renovation,
    name: 'project.form.estimatedRenovationChoices.renovation',
  },
  {
    id: EstimatedRenovation.RenovationFurnitures,
    name: 'project.form.estimatedRenovationChoices.renovationFurnitures',
  },
];

export const REASON_NO_WORK_CHOICES = [
  {
    category: NoWorkReasonChoicesCategory.Finance,
    id: ReasonNoWork.AmountOfWork,
  },
  {
    category: NoWorkReasonChoicesCategory.Finance,
    id: ReasonNoWork.OverallBudget,
  },
  {
    category: NoWorkReasonChoicesCategory.Finance,
    id: ReasonNoWork.BillingMethod,
  },
  {
    category: NoWorkReasonChoicesCategory.WorkFlexibility,
    id: ReasonNoWork.TooLongDelayBeforeStarting,
  },
  {
    category: NoWorkReasonChoicesCategory.WorkFlexibility,
    id: ReasonNoWork.WorkTimeTooLong,
  },
  {
    category: NoWorkReasonChoicesCategory.Investor,
    id: ReasonNoWork.PreferToGoExternal,
  },
  {
    category: NoWorkReasonChoicesCategory.Investor,
    id: ReasonNoWork.NotRequireWork,
  },
  {
    category: NoWorkReasonChoicesCategory.Investor,
    id: ReasonNoWork.NeedMoreChoice,
  },
  {
    category: NoWorkReasonChoicesCategory.Investor,
    id: ReasonNoWork.WorkTooAmbitious,
  },
  {
    category: NoWorkReasonChoicesCategory.Masteos,
    id: ReasonNoWork.ExternalInfluence,
  },
  {
    category: NoWorkReasonChoicesCategory.Masteos,
    id: ReasonNoWork.UncertaintyOnFinalEcd,
  },
  {
    category: NoWorkReasonChoicesCategory.Masteos,
    id: ReasonNoWork.HunterDirectsToExternalWork,
  },
  {
    category: NoWorkReasonChoicesCategory.Other,
    id: ReasonNoWork.Other,
  },
];

export const INVOICE_METHOD_CHOICES = [
  { disabled: true, id: InvoiceMethod.None },
  { id: InvoiceMethod.Choice1 },
  { id: InvoiceMethod.Choice2 },
];

export const INVOICE_RECIPIENT_CHOICES = [
  { id: InvoiceRecipient.LegalEntityEurope },
  { id: InvoiceRecipient.LegalEntityFrance },
  { id: InvoiceRecipient.LegalEntityOthers },
  { id: InvoiceRecipient.PrivateIndividual },
];
