import { styled } from '@mui/material';

export const DisclaimerContainer = styled('div')`
  grid-column: span 3;
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  margin-bottom: 16px;
  gap: 24px;
`;

export const AlertBase = styled('div')`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 16px;
  gap: 16px;
  margin-top: -40px;
`;

export const AlertWarning = styled(AlertBase)`
  background-color: ${({ theme }) => theme.palette.colors.red[50]};
  border: 1px solid ${({ theme }) => theme.palette.colors.red[900]};
  color: ${({ theme }) => theme.palette.colors.red[900]};
`;

export const AlertInfo = styled(AlertBase)`
  background-color: ${({ theme }) => theme.palette.colors.lightBlue[50]};
  border: 1px solid ${({ theme }) => theme.palette.colors.lightBlue[900]};
  color: ${({ theme }) => theme.palette.colors.lightBlue[900]};
`;

export const AlertTitle = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;
