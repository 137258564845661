import React from 'react';

import { DateInput } from 'components/custom-input/date-input/DateInput';
import {
  CommonInputProps,
  TextInput,
  useTranslate,
  useRecordContext,
  NumberInput,
} from 'react-admin';
import { FormDivider } from 'ui/atoms/form-divider/FormDivider';
import { AccordionForm } from 'ui/molecules/accordion-form/AccordionForm';
import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import { GooglePlacesAutocomplete } from 'components/google-places-autocomplete/GooglePlacesAutocomplete';
import { PhoneInput } from 'ui/atoms/phone-input/phone-input';
import { ExternalCustomerDetail } from 'types/external-customer.types';
// eslint-disable-next-line max-len
import { useEditExternalCustomerPermissions } from 'features/external-customers/details/edit/hooks/useEditExternalCustomerPermissions';
import { formatFullStreet } from 'utils/form/form.utils';

const commonProps: Partial<CommonInputProps> = {
  margin: 'none',
  variant: 'outlined',
};

export const ProfileForm: React.FC<{ defaultExpanded: boolean }> = ({
  defaultExpanded,
}) => {
  const externalCustomer = useRecordContext<ExternalCustomerDetail>();
  const translate = useTranslate();
  const { canEditExternalCustomer } =
    useEditExternalCustomerPermissions(externalCustomer);

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={`👤 ${translate('customer.form.profile')}`}
    >
      <Fieldset>
        <TextInput
          required
          source="firstName"
          label="user.input.firstname"
          disabled={!canEditExternalCustomer}
          {...commonProps}
        />
        <TextInput
          required
          source="lastName"
          label="user.input.lastname"
          disabled={!canEditExternalCustomer}
          {...commonProps}
        />
        <TextInput
          required
          source="email"
          label="user.input.email"
          disabled={true}
          {...commonProps}
        />
      </Fieldset>
      <Fieldset>
        <PhoneInput
          source="phoneNumber"
          label="shared.phoneNumber"
          disabled={!canEditExternalCustomer}
          {...commonProps}
        />
        <DateInput
          source="birthDate"
          {...commonProps}
          disabled={!canEditExternalCustomer}
          label={translate('customer.birthDate')}
        />
        <TextInput
          source="birthCity"
          label="customer.birthCity"
          disabled={!canEditExternalCustomer}
          {...commonProps}
        />
      </Fieldset>
      <GooglePlacesAutocomplete
        {...commonProps}
        source="address"
        defaultValue={
          externalCustomer?.address
            ? formatFullStreet({
                city: externalCustomer.address.city || '',
                country: externalCustomer.address.country || '',
                street: externalCustomer.address.street || '',
              })
            : ''
        }
        disabled={!canEditExternalCustomer}
      />
      <FormDivider />
      <Fieldset>
        <NumberInput
          source="downPayment"
          label="customer.downPayment"
          disabled={!canEditExternalCustomer}
          {...commonProps}
        />
        <NumberInput
          source="maxBudget"
          label="customer.searchSettings.budgetMax"
          disabled={!canEditExternalCustomer}
          {...commonProps}
        />
      </Fieldset>
    </AccordionForm>
  );
};
