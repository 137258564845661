import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';
import { useGetIdentity } from 'react-admin';
import { ExternalCustomerDetail } from 'types/external-customer.types';
import { PermissionName, Scope } from 'types/permission.types';

export const useEditExternalCustomerPermissions = (externalCustomerDetail: {
  owner: Pick<ExternalCustomerDetail['owner'], 'id'>;
}): { canEditExternalCustomer: boolean } => {
  const { hasPermission, getScope } = useHeliosPermissions();
  const { identity } = useGetIdentity();

  const checkCanEditExternalCustomer = () => {
    if (
      !identity?.id ||
      !hasPermission(PermissionName.UpdateExternalCustomer)
    ) {
      return false;
    }

    const scope = getScope(PermissionName.UpdateExternalCustomer);

    return (
      scope === Scope.All ||
      (scope === Scope.Own && externalCustomerDetail.owner.id === identity?.id)
    );
  };

  return { canEditExternalCustomer: checkCanEditExternalCustomer() };
};
