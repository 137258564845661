import React from 'react';

import { useRedirect, useTranslate } from 'react-admin';
import { SaveButton } from 'components/save-button/SaveButton';
import { Box } from '@mui/material';
import { LoadingButton } from 'ui/molecules/loading-button/LoadingButton';

export const ActionButtons: React.FC = () => {
  const translate = useTranslate();
  const redirect = useRedirect();

  const handleCancelClick = () => {
    redirect('list', 'organizations');
  };

  return (
    <Box display="flex" flexDirection="row" gap={1}>
      <LoadingButton
        title={translate('ra.action.cancel')}
        variant="outlined"
        onClick={handleCancelClick}
      >
        {translate('ra.action.cancel')}
      </LoadingButton>
      <SaveButton
        label={translate('ra.action.save')}
        data-testid="organization-form-submit-button"
      />
    </Box>
  );
};
