import * as React from 'react';

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useTranslate } from 'react-admin';

import { URI } from 'constants/uri';

type RealizationBreadCrumbsProps = {
  realizationId: string;
  title: string;
};

export const RealizationBreadCrumbs: React.FC<RealizationBreadCrumbsProps> = ({
  realizationId,
  title,
}) => {
  const translate = useTranslate();

  const realizationUrl = `${URI.realizations}/${realizationId}`;

  return (
    <Breadcrumbs aria-label="breadcrumb" separator=">">
      <Typography variant="body2" key="title">
        <Link underline="hover" color="inherit" href={URI.realizations}>
          {translate('realization.breadcrumb.realizationList')}
        </Link>
      </Typography>
      <Typography variant="body2" key="edition">
        <Link
          underline="hover"
          color="inherit"
          href={realizationUrl}
          data-testid="realization-breadcrumbs-link"
        >
          {translate('realization.breadcrumb.edition')} &quot;{title}&quot;
        </Link>
      </Typography>
    </Breadcrumbs>
  );
};
