import * as yup from 'yup';
import {
  firstNameSchema,
  lastNameSchema,
  phoneNumberSchema,
} from 'features/customers/validators/customer.validator';

export const customerEditFormValidator = yup.object().shape({
  firstName: firstNameSchema,
  internalNote: yup.string().nullable(),
  lastName: lastNameSchema,
  phoneNumber: phoneNumberSchema,
});
