import React from 'react';

import { Box, styled } from '@mui/material';

const Footer = styled(Box)`
  position: fixed;
  bottom: 0;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 10px;
  width: 100%;
  left: 0;
  height: 54px;
  display: flex;
  justify-content: end;
  z-index: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    display: none;
  }
`;

export const MobileFooter: React.FC<React.PropsWithChildren> = ({
  children,
}) => <Footer>{children}</Footer>;
