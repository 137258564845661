import React, { useRef, useState } from 'react';

import { useTranslate } from 'react-admin';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { IconButton, Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFormContext } from 'react-hook-form';
import { get } from 'lodash';

import { getErrorStepText } from './ErrorStepBottomBarRemainingErrors.utils';
import { TextContainer } from './ErrorStepBottomBar.styles';
import { Container } from './ErrorStepBottomBarRemainingErrors.styles';
import { ErrorStepBottomBarRemainingErrorsProps } from './ErrorStepBottomBarRemainingErrors.types';

export const ErrorStepBottomBarRemainingErrors: React.FC<
  ErrorStepBottomBarRemainingErrorsProps
> = ({ flattenErrors, sourceToLabelMap }) => {
  const translate = useTranslate();
  const isSmall = useMediaQuery(
    (t: Theme) => `(max-width:${t.breakpoints.values.sm}px)`
  );

  const { current: flattenErrorsRef } = useRef(flattenErrors);
  const [errorIndex, setErrorIndex] = useState<number>(0);

  const { setFocus, formState } = useFormContext();

  const currentErrorIsValid = !get(
    formState.errors,
    flattenErrorsRef[errorIndex].path
  );

  const flattenErrorsRefLength = flattenErrorsRef.length;

  const hasPreviousError = errorIndex > 0;
  const hasNextError = errorIndex < flattenErrorsRefLength - 1;

  const nextError = () => {
    if (hasNextError) {
      setErrorIndex((x) => x + 1);
      const currError = flattenErrorsRef[errorIndex + 1];
      if (currError) {
        setFocus(currError.path);
      }
    }
  };

  const previousError = () => {
    if (hasPreviousError) {
      setErrorIndex((x) => x - 1);
      const currError = flattenErrorsRef[errorIndex - 1];
      if (currError) {
        setFocus(currError.path);
      }
    }
  };

  const errorStepText = getErrorStepText({
    errorIndex,
    errorsLength: flattenErrorsRef.length,
    message: flattenErrorsRef[errorIndex].message,
    path: flattenErrorsRef[errorIndex].path,
    smallVersion: isSmall,
    sourceToLabelMap,
    translate,
  });

  return (
    <Container
      className={currentErrorIsValid ? 'valid' : ''}
      data-testid="ErrorStepBottomBarRemainingErrors"
    >
      <IconButton
        size="small"
        data-testid="left-arrow-button"
        onClick={previousError}
        disabled={!hasPreviousError}
      >
        <KeyboardArrowLeftIcon fontSize="large" />
      </IconButton>
      <TextContainer>{errorStepText}</TextContainer>
      <IconButton
        size="small"
        data-testid="right-arrow-button"
        onClick={nextError}
        disabled={!hasNextError}
      >
        <KeyboardArrowRightIcon fontSize="large" />
      </IconButton>
    </Container>
  );
};
