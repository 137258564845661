import Logout from '@mui/icons-material/Logout';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import AppartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import MultipleStopOutlinedIcon from '@mui/icons-material/MultipleStopOutlined';
import SwapHorizOutlined from '@mui/icons-material/SwapHorizOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import RadarOutlinedIcon from '@mui/icons-material/RadarOutlined';
import CameraOutdoorIcon from '@mui/icons-material/CameraOutdoor';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { useLogout, useRedirect, useTranslate } from 'react-admin';
import {
  Menu as MenuUi,
  MenuUiProps,
  MenuItemProps,
} from 'ui/organisms/menu/Menu';
import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';
import { PermissionName } from 'types/permission.types';
import { SvgIconComponent } from '@mui/icons-material';

import { URI } from 'constants/uri';

export type MenuProps = Pick<MenuUiProps, 'variant' | 'open' | 'onClose'>;

export const Menu: React.FC<MenuProps> = ({ open, onClose, variant }) => {
  const translate = useTranslate();
  const logout = useLogout();
  const redirect = useRedirect();
  const {
    canListPositions,
    canManageUsers,
    canManageCustomers,
    hasPermission,
    canManageRoles: canManagePermissions,
    canManageOrganizations,
    isMandator,
    isExternalUser,
  } = useHeliosPermissions();
  const [, urlParameters] = window.location.pathname.split('/');

  const menuItems: Array<
    Partial<MenuItemProps> & {
      selectedURI: string[];
      uri: string;
      Icon: SvgIconComponent;
      title: string;
    }
  > = [];

  isMandator &&
    menuItems.push({
      Icon: RocketLaunchOutlinedIcon,
      key: 'activities',
      selectedURI: [URI.activities, URI.home],
      title: translate('menu.activities'),
      uri: URI.activities,
    });

  hasPermission(PermissionName.ListRealEstate) &&
    menuItems.push({
      Icon: MapsHomeWorkOutlinedIcon,
      key: 'realEstates',
      selectedURI: !isMandator
        ? [URI.realEstates, URI.home]
        : [URI.realEstates],
      title: translate('menu.realEstates'),
      uri: URI.realEstates,
    });

  canManageCustomers &&
    menuItems.push({
      Icon: SupervisorAccountOutlinedIcon,
      key: 'customers',
      selectedURI: [URI.customers],
      title: translate('menu.customers'),
      uri: URI.customers,
    });

  canListPositions &&
    menuItems.push({
      Icon: RadarOutlinedIcon,
      key: 'positionings',
      selectedURI: [URI.positionings],
      title: translate('menu.positionings'),
      uri: URI.positionings,
    });

  hasPermission(PermissionName.ListProject) &&
    menuItems.push({
      Icon: MultipleStopOutlinedIcon,
      key: 'transactions',
      selectedURI: [URI.transactions],
      title: translate('menu.transactions'),
      uri: URI.transactions,
    });

  canManageUsers &&
    menuItems.push({
      Icon: SupervisedUserCircleOutlinedIcon,
      key: 'users',
      selectedURI: [URI.users],
      title: translate('menu.users'),
      uri: URI.users,
    });

  canManageOrganizations &&
    menuItems.push({
      Icon: AccountTreeOutlinedIcon,
      key: 'organizations',
      selectedURI: [URI.organizations],
      title: translate('menu.organizations'),
      uri: URI.organizations,
    });

  canManagePermissions &&
    menuItems.push({
      Icon: ManageAccountsOutlinedIcon,
      key: 'roles',
      selectedURI: [URI.roles],
      title: translate('menu.roles'),
      uri: URI.roles,
    });

  hasPermission(PermissionName.ListRealization) &&
    menuItems.push({
      Icon: CameraOutdoorIcon,
      key: 'realizations',
      selectedURI: [URI.realizations],
      title: translate('menu.realizations'),
      uri: URI.realizations,
    });

  // CGP - start
  hasPermission(PermissionName.ListRealEstateCGP) &&
    menuItems.push({
      Icon: AppartmentOutlinedIcon,
      key: 'realEstatesCGP',
      selectedURI: [URI.realEstatesCGP],
      title: translate('menu.realEstatesCGP'),
      uri: URI.realEstatesCGP,
    });

  hasPermission(PermissionName.ListExternalCustomer) &&
    menuItems.push({
      Icon: PermContactCalendarIcon,
      key: 'external-customers',
      selectedURI: [URI.externalCustomers],
      title: canManageCustomers
        ? translate('menu.externalCustomers')
        : translate('menu.externalCustomersCGP'),
      uri: URI.externalCustomers,
    });

  hasPermission(PermissionName.ListProjectCGP) &&
    menuItems.push({
      Icon: SwapHorizOutlined,
      key: '`transactions-cgp',
      selectedURI: [URI.transactionsCGP],
      title: hasPermission(PermissionName.ListProject)
        ? translate('menu.transactionsCGPAdmin')
        : translate('menu.transactionsCGP'),
      uri: URI.transactionsCGP,
    });
  // CGP - end

  !isExternalUser &&
    menuItems.push({
      Icon: HelpOutlineOutlinedIcon,
      key: 'help',
      selectedURI: [URI.help],
      title: translate('menu.help'),
      uri: URI.help,
    });

  const computedMenuItems: MenuItemProps[] = menuItems.map((menuItem) => ({
    ...menuItem,
    onClick: () => redirect(menuItem.uri),
    selected: menuItem.selectedURI.some((e) => e === `/${urlParameters}`),
  }));

  const footerItem = {
    Icon: Logout,
    onClick: logout,
    title: translate('profile.logout'),
  } as MenuItemProps;

  return (
    <MenuUi
      open={open}
      onClose={onClose}
      variant={variant}
      items={computedMenuItems}
      footerItem={footerItem}
    />
  );
};
