import { useEffect, useRef, useState } from 'react';

import {
  useDataProvider,
  useNotify,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { ImageCard } from 'ui/molecules/image-card/ImageCard';
import { UploadState } from 'types/common.types';
import { HeliosDataProvider } from 'providers/admin/dataProvider';

import {
  convertHeicToJpeg,
  resizeFile,
} from '../image-input/utils/imageInput.formater.utils';

type ImageInputPreviewType = {
  onDeleteClick?: (fileIdentifier: string) => void;
  id: string;
  file?: File;
  preview: string;
  onUpload?: (id: string, url: string) => void;
  isForRealization?: boolean;
};
export const ImageInputPreview: React.FC<ImageInputPreviewType> = ({
  id,
  file,
  preview,
  onDeleteClick,
  onUpload,
  isForRealization = false,
}) => {
  const translate = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider<HeliosDataProvider>();
  const record = useRecordContext();
  const prevFileId = useRef<string>('');

  const [loadingState, setLoadingState] = useState<UploadState>(
    preview ? UploadState.SUCCESS : UploadState.IDLE
  );

  useEffect(() => {
    if (file) {
      const uploadImage = async () => {
        setLoadingState(UploadState.PENDING);

        const base64 = await resizeFile(
          /^(image)+\/(heic|heif)+$/.test(file.type)
            ? ((await convertHeicToJpeg(file)) as Blob)
            : file
        );

        const { data } = isForRealization
          ? await dataProvider.uploadRealizationImage(record.id?.toString(), {
              base64: base64,
              id: id,
            })
          : await dataProvider.uploadImage(record.id?.toString(), {
              base64: base64,
              id: id,
            });

        setLoadingState(UploadState.SUCCESS);

        onUpload && onUpload(id, data.awsId);
      };
      if (loadingState === UploadState.IDLE && prevFileId.current !== id) {
        prevFileId.current = id;
        uploadImage().catch((err) => {
          notify(err.body?.message || translate('ra.notification.http_error'), {
            type: 'error',
          });
          setLoadingState(UploadState.ERROR);
        });
      }
    }
  }, [
    file,
    dataProvider,
    id,
    loadingState,
    onUpload,
    record?.id,
    notify,
    translate,
    isForRealization,
  ]);

  return (
    <ImageCard
      key={id}
      src={preview}
      id={id}
      loadingState={loadingState}
      onDeleteClick={onDeleteClick}
      data-testid="imageCard"
    />
  );
};
