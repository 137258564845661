import { PositioningCancellationReason } from 'types/positioning.types';
import * as yup from 'yup';

export const cancelPositioningSchema = yup.object().shape({
  cancelReasons: yup
    .array()
    .of(yup.string().oneOf(Object.values(PositioningCancellationReason)))
    .min(1, 'positioning.error.cancellationReasonsMin'),
  cancellationReasonOther: yup.string().when('cancelReasons', {
    is: PositioningCancellationReason.Other,
    otherwise: yup.string().nullable().notRequired(),
    then: yup
      .string()
      .nullable()
      .required('positioning.error.cancellationReasonOther'),
  }),
});
