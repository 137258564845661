import { ListBase, ListContext, ShowBase, useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import { DataTable } from 'components/data-table/DataTable';
import { CustomerHeader } from 'features/customers/details/components/header/CustomerHeader';
import {
  ContentContainer,
  ContentTableContainer,
} from 'components/headerTabs/HeaderTabs.styles';
import { formatTransactionList } from 'features/transactions/list/utils/formatTransactionList.util';
import { getColumns } from 'utils/columns/getColumns.utils';

import { getCustomerTransactionColumns } from './getCustomerTransactionColumns';

export const CustomerTransactions: React.FC = () => {
  const { id } = useParams();
  const translate = useTranslate();

  const columns = getColumns(
    getCustomerTransactionColumns(translate),
    translate
  );

  return (
    <ContentContainer>
      <ShowBase>
        <CustomerHeader />
      </ShowBase>

      <ListBase
        resource="transactions"
        disableSyncWithLocation
        key="customer-transactions"
        filter={{ customer: [id] }}
        filterDefaultValues={{ customer: [id] }}
        sort={{ field: 'createdAt', order: 'DESC' }}
      >
        <ListContext.Consumer>
          {({ data, isLoading, isFetching }) => (
            <ContentTableContainer>
              <DataTable
                loading={isLoading || isFetching || !columns}
                columns={columns}
                rows={formatTransactionList(data) || []}
                autoHeight
                density="standard"
                noRowsMessage={translate('customer.transactionList.noResults')}
              />
            </ContentTableContainer>
          )}
        </ListContext.Consumer>
      </ListBase>
    </ContentContainer>
  );
};
