import { RealEstate } from 'types/realEstate.types';
import {
  transformForSave,
  TransformForSaveProps,
} from 'features/realEstates/utils/payloadTransforms';
import { formatFullStreet, sanitizeData } from 'utils/form/form.utils';
import { getInternalNoteTemplate } from 'features/realEstates/utils/getInternalNoteTemplate';

export const handleTransform = (data: RealEstate): TransformForSaveProps =>
  transformForSave(
    sanitizeData({
      ...data,
      address: {
        ...data.address,
        street: data.geocode?.street || formatFullStreet(data.address),
      },
      internalNote: data.internalNote || getInternalNoteTemplate(data.lang),
    })
  );
