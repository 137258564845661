import React from 'react';

import { useRefresh, useStore, useTranslate } from 'react-admin';
import { CustomerDrawerKey, MandateLocal } from 'types/customer.types';
import { GenerateSearchMandateBody } from 'types/customMethods.types';
import { Drawer } from 'ui/organisms/drawer/Drawer';

import {
  GenerateExternalCustomerSearchMandateDrawerForm,
  GenerateSearchMandateDrawerFormRecord,
} from './GenerateExternalCustomerSearchMandateDrawerForm';
import { useGenerateExternalCustomerSearchMandateAction } from './useGenerateExternalCustomerSearchMandateAction.hook';

export interface GenerateExternalCustomerSearchMandateDrawerProps {
  record: GenerateSearchMandateDrawerFormRecord | undefined;
  isExternalCustomer?: boolean;
}

export const GenerateExternalCustomerSearchMandateDrawer: React.FC<
  GenerateExternalCustomerSearchMandateDrawerProps
> = ({ record }) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const [drawerStore, showDrawer] = useStore(
    CustomerDrawerKey.GENERATE_EXTERNAL_CUSTOMER_SEARCH_MANDATE,
    [false, MandateLocal.FR_fr] as [boolean, string]
  );

  const [isDrawerOpen, local] = drawerStore;

  const handleCloseDrawer = () => {
    refresh();
    showDrawer([false, MandateLocal.FR_fr]);
  };

  const { mutate, isLoading } =
    useGenerateExternalCustomerSearchMandateAction();

  const handleSubmit = async (data: GenerateSearchMandateBody) => {
    await mutate(data, {
      onSuccess: handleCloseDrawer,
    });
  };

  return (
    <Drawer
      open={isDrawerOpen}
      title={translate('customer.generateSearchMandate.title')}
      anchor="right"
      onClose={handleCloseDrawer}
      data-testid="generate-external-customer-search-mandate-drawer"
    >
      <GenerateExternalCustomerSearchMandateDrawerForm
        record={record}
        local={local}
        isFetching={isLoading}
        handleCancel={handleCloseDrawer}
        handleSubmit={handleSubmit}
      />
    </Drawer>
  );
};
