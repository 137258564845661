import React from 'react';

import { CellType, GridColDef } from 'types/mui.types';

const renderCellLink = (text: string): React.ReactElement => (
  <span color="inherit" style={{ textDecoration: 'underline' }}>
    {text}
  </span>
);

export const getActivitiesColumns = (resource: string): GridColDef[] => {
  const dateField =
    resource === 'positionings' ? 'statusChangedAt' : 'createdAt';

  return [
    {
      cellType: CellType.DATE_TIME,
      field: dateField,
      headerName: 'activities.col.date',
      width: 150,
    },
    {
      field: 'customer.fullName',
      headerName: 'activities.col.customer_fullName',
      renderCell: (row) => renderCellLink(row.value),
      width: 200,
    },
    {
      field: 'realEstate.address.street',
      headerName: 'activities.col.address_street',
      renderCell: (row) => renderCellLink(row.value),
      width: 300,
    },
    {
      field: 'realEstate.address.city',
      headerName: 'activities.col.address_city',
      width: 200,
    },
  ] as GridColDef[];
};
