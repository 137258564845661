import React from 'react';

import { TextField, TextFieldProps } from 'react-admin';
import Link from '@mui/material/Link';
import { withLabel } from 'ui/hoc/with-label/withLabel';
import { styled } from '@mui/material';

import { UrlFieldProps } from './UrlField.types';

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;

  &.disabled {
    filter: grayscale(1);
    pointer-events: none;
    cursor: not-allowed;
    text-decoration: none;
  }
`;

export const UrlField: React.FC<UrlFieldProps & TextFieldProps> = ({
  disabled,
  href,
  icon,
  onClick,
  ...rest
}) => {
  return (
    <StyledLink
      className={disabled ? 'disabled' : ''}
      href={href}
      onClick={onClick}
      variant="body2"
      target="_blank"
    >
      <>
        {!!icon && icon}
        <TextField {...rest} />
      </>
    </StyledLink>
  );
};

export const UrlFieldWithLabel = withLabel(UrlField);
