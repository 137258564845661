import React from 'react';

import { GridRenderCellParams } from '@mui/x-data-grid';
import { PriceField } from 'ui/atoms/price-field/PriceField';
import { DateField } from 'ui/atoms/date-field/DateField';
import { GridCellRenderer, CellType, TYPE_CELL_TYPE } from 'types/mui.types';
import { PercentField } from 'ui/atoms/percent-field/PercentField';
import { ChipList } from 'ui/molecules/chips-list/ChipList';
import { Link } from 'react-admin';

import { URI } from 'constants/uri';

import { dateOptions } from '../i18n/i18n.utils';

export const getDateCell = (
  params: GridRenderCellParams,
  options: Intl.DateTimeFormatOptions = {}
): React.ReactElement => (
  <DateField
    date={params.value}
    options={{ ...dateOptions, year: 'numeric', ...options }}
  />
);

export const getPriceCell = ({
  value,
}: GridRenderCellParams): React.ReactElement =>
  value ? <PriceField price={value} /> : <></>;

export const getPercentCell = ({
  value,
}: GridRenderCellParams): React.ReactElement => {
  return value ? <PercentField percent={value} /> : <></>;
};

export const getTagListCell = (
  { formattedValue }: GridRenderCellParams,
  column?: boolean
): React.ReactElement => {
  return <ChipList list={formattedValue} column={column} />;
};

export const TYPE_CELL: TYPE_CELL_TYPE = {
  [CellType.DATE]: (params) =>
    getDateCell(params, { hour: undefined, minute: undefined }),
  [CellType.DATE_TIME]: getDateCell,
  [CellType.PRICE]: getPriceCell,
  [CellType.PERCENTAGE]: getPercentCell,
  [CellType.TAG_LIST]: getTagListCell,
  [CellType.TAG_LIST_COL]: (v) => getTagListCell(v, true),
};

export const getCellByType = (
  type: Nullable<CellType>
): GridCellRenderer | undefined => {
  if (!type) {
    return undefined;
  }
  return TYPE_CELL[type];
};

export const renderCellLink = (
  to: string,
  text: string
): React.ReactElement => (
  <Link color="inherit" to={to} style={{ textDecoration: 'underline' }}>
    {text}
  </Link>
);

export const renderCellLinkCustomer = (
  customerId: string,
  customerFullName: string
): React.ReactElement =>
  renderCellLink(`${URI.customers}/${customerId}`, customerFullName);

export const renderCellLinkRealEstate = (
  realEstateId: string,
  value: string
): React.ReactElement =>
  renderCellLink(`${URI.realEstates}/${realEstateId}`, value);
