import { Box, styled } from '@mui/material';
import {
  email,
  PasswordInput,
  required,
  TextInput,
  useTranslate,
} from 'react-admin';
import { LoadingButton } from 'ui/molecules/loading-button/LoadingButton';
import { useFormState } from 'react-hook-form';
import { Link as ReactRouterLink } from 'react-router-dom';

const Link = styled(ReactRouterLink)`
  color: ${({ theme }) => theme.palette.grey[600]};
`;

export const LoginCard: React.FC = () => {
  const { isSubmitting } = useFormState();
  const translate = useTranslate();

  return (
    <>
      <TextInput
        label="auth.login.email"
        variant="outlined"
        type="email"
        source="email"
        validate={[required(), email()]}
        size="medium"
        margin="none"
        fullWidth
        inputProps={{
          role: 'input',
        }}
      />
      <PasswordInput
        label="auth.login.password"
        variant="outlined"
        source="password"
        validate={[required()]}
        size="medium"
        fullWidth
        margin="none"
        inputProps={{ autoComplete: 'current-password', role: 'input' }}
      />
      <Box mb={2}>
        <Link to="/reset-password/email">
          {translate('login.forgotPassword')}
        </Link>
      </Box>
      <LoadingButton
        disabled={isSubmitting}
        loading={isSubmitting}
        variant="contained"
        type="submit"
        size="large"
        fullWidth
      >
        {translate('auth.login.submit')}
      </LoadingButton>
    </>
  );
};
