import { ReactElement } from 'react';

import { GridRenderCellParams } from '@mui/x-data-grid';
import { Translate } from 'react-admin';
import { Positioning, PositioningStatus } from 'types/positioning.types';

const HIDE_MISSING_DOC_FOR_STATUS = [
  PositioningStatus.Confirmed,
  PositioningStatus.Transaction,
];

export const renderMissingDocuments = (
  params: GridRenderCellParams<unknown, Positioning, unknown>,
  translate: Translate
): ReactElement => (
  <div>
    {!HIDE_MISSING_DOC_FOR_STATUS.includes(params.row.status) &&
      params.row.missingDocuments.map((missingDocument: string) => (
        <div key={missingDocument}>
          {translate(`shared.document.${missingDocument}`)}
        </div>
      ))}
  </div>
);
