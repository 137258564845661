import { IdName } from 'features/realEstates/validators/realEstate.validator';
import { FormSpecificities } from 'types/realEstate.types';

export type FormSpecificitiesType = {
  canSeeDpe: boolean;
  canSeeGes: boolean;
  canSeeAnnualHomeOwnerInsurance: boolean | undefined;
  overrideDpeValues: IdName[] | undefined;
};

export const getFormSpecificities = (
  form: FormSpecificities
): FormSpecificitiesType => {
  const canSeeDpe = form?.dpe?.isVisible;
  const canSeeGes = form?.ges?.isVisible;

  const overrideDpeValues = form?.dpe?.overrideValues;

  const canSeeAnnualHomeOwnerInsurance =
    form?.annualHomeOwnerInsurance?.isVisible;

  return {
    canSeeAnnualHomeOwnerInsurance,
    canSeeDpe,
    canSeeGes,
    overrideDpeValues,
  };
};
