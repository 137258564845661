import { Typography, styled } from '@mui/material';

export const InfoStyled = styled('div')`
  display: flex;
  gap: 6px;
  flex-direction: row;
  color: ${({ theme }) => theme.palette.info.dark};
  align-items: center;
  padding: 12px 0;
`;

export const SubTitle = styled(Typography)`
  font-weight: 500;
`;
