import { styled } from '@mui/material';
import { DateInput as BaseDateInput } from 'components/custom-input/date-input/DateInput';

export const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
`;
export const InputLabel = styled('div')`
  display: flex;
  flex-direction: column;
`;
export const DateInput = styled(BaseDateInput)`
  width: 100%;
`;
