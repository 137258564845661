import { Translate } from 'react-admin';
import {
  PositioningCancellationReason,
  PositioningStatus,
} from 'types/positioning.types';

export const isCancelledOrPrePositioningCancelled = (
  status: PositioningStatus
): boolean =>
  [
    PositioningStatus.Cancelled,
    PositioningStatus.PrePositioningCancelled,
  ].includes(status);

export const getSortedReasons = (
  reasons: PositioningCancellationReason[]
): PositioningCancellationReason[] =>
  [...reasons].sort((reason) =>
    reason === PositioningCancellationReason.Other ? 1 : -1
  );

export const getTranslatedReasons = (
  reasons: PositioningCancellationReason[],
  otherReason: string | undefined,
  translate: Translate
): string[] =>
  reasons.map((reason) =>
    reason === PositioningCancellationReason.Other
      ? `${translate(
          `positioning.cancellationReason.${reason}`
        )} : ${otherReason}`
      : translate(`positioning.cancellationReason.${reason}`)
  );
