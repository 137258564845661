import { TransactionsFiltersType } from 'types/transaction.types';
import { Filters } from 'components/filters/Filters';

import { FilterBody } from './filters-body/FiltersBody';

const resetFilters: TransactionsFiltersType = {
  advisor: [],
  customer: [],
  status: null,
};

export const TransactionsFilters: React.FC = () => {
  return (
    <Filters defaultValues={resetFilters}>
      <FilterBody />
    </Filters>
  );
};
