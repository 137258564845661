import { isAfter } from 'date-fns';
import * as yup from 'yup';

export const positioiningFilterFormValidator = yup.object().shape({
  advisor: yup.array(yup.string()),
  customer: yup.array(yup.string()),
  fromDate: yup
    .date()
    .nullable()
    .max(new Date(), 'positioning.filter.error.dateFromShouldBePast'),
  toDate: yup
    .date()
    .nullable()
    .test({
      message: 'positioning.filter.error.dateToShouldBeBefore',
      name: 'date-range',
      test: (toDate, { parent }) => {
        return !(parent.fromDate && toDate && isAfter(parent.fromDate, toDate));
      },
    }),
});
