import { AccordionForm } from 'ui/molecules/accordion-form/AccordionForm';
import { useTranslate } from 'react-admin';
import { ImageInput } from 'components/image-input/ImageInput';

import { RealEstateEditAccordeonFormProps } from '../EditFormContent.types';

export const MarketDataForm: React.FC<RealEstateEditAccordeonFormProps> = ({
  defaultExpanded,
  disabled = false,
}) => {
  const translate = useTranslate();

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate('realEstate.form.marketData')}
    >
      <ImageInput disabled={disabled} source="images" />
    </AccordionForm>
  );
};
