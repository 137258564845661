import { useInput, NumberInput, NumberInputProps } from 'react-admin';
import { InputAdornment } from '@mui/material';
import EuroIcon from '@mui/icons-material/Euro';
import { getInputLabelPropMui } from 'utils/form/input.utils';

export const PriceInput: React.FC<NumberInputProps> = (props) => {
  const { fieldState } = useInput({ source: props.source });

  return (
    <NumberInput
      {...props}
      error={Boolean(props.error)}
      helperText={
        fieldState.error
          ? fieldState.error.message
          : props.helperText ?? (
              <span dangerouslySetInnerHTML={defaultInnerHTML} />
            )
      }
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <EuroIcon />
          </InputAdornment>
        ),
      }}
      {...getInputLabelPropMui(props.label)}
    />
  );
};
const defaultInnerHTML = { __html: '&#8203;' };
