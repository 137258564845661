import { CustomerOverride } from './overrideCustomerEditFormatted.utils';

type Field<T extends Record<string, unknown>> = {
  key: keyof T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format?: (value: string) => any;
};

export const getOverridesFromUrlParams = (): Partial<CustomerOverride> => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const values: Partial<CustomerOverride> = {};

  const fields: Field<typeof values>[] = [
    { key: 'birthCity' },
    { key: 'birthDate' },
    { key: 'email' },
    { key: 'firstName' },
    { key: 'lastName' },
    { key: 'civility' },
    {
      format: (downPayment) => {
        const parsedDownPayment = Number(downPayment);
        if (Number.isNaN(parsedDownPayment)) {
          return undefined;
        }

        return parsedDownPayment;
      },
      key: 'downPayment',
    },
    {
      format: (address) => {
        try {
          return JSON.parse(address);
        } catch {
          return {};
        }
      },
      key: 'address',
    },
  ];

  if (params.budgetMax) {
    const parsedBudgetMax = Number(params.budgetMax);
    if (!Number.isNaN(parsedBudgetMax)) {
      values.search = { budgetMax: parsedBudgetMax };
    }
  }

  fields.forEach(({ key, format }) => {
    if (!params[key]) {
      return;
    }

    values[key] = format ? format(params[key]) : params[key];
  });

  return values;
};
