import * as yup from 'yup';
import {
  addressSchema,
  financialModelSchema,
  propertyKindSchema,
  propertyLangSchema,
  strictlyPositiveNumberSchema,
} from 'features/realEstates/validators/realEstate.validator';
import { RealEstateType } from 'types/realEstate.enum';

export interface AddPropertyFormValidated {
  address: {
    street: string;
  };
  financialModel: {
    annualCharges?: number | null;
    annualRentIncome?: number | null;
    netSeller: number | null;
    renovationAmount: number | null;
  };
  lang: string;
  roomNumber?: number;
  surface?: number;
  type?: RealEstateType | null;
}

export const addPropertyForm = yup.object().shape({
  address: addressSchema,
  financialModel: yup
    .object()
    .when('type', (type: string) =>
      type !== RealEstateType.Building
        ? financialModelSchema
        : yup.object().nullable(true)
    ),
  lang: propertyLangSchema,
  roomNumber: yup
    .number()
    .when('type', (type: string) =>
      type !== RealEstateType.Building
        ? strictlyPositiveNumberSchema
        : yup.number().nullable(true)
    ),
  surface: yup
    .number()
    .when('type', (type: string) =>
      type !== RealEstateType.Building
        ? strictlyPositiveNumberSchema
        : yup.number().nullable(true)
    ),
  type: propertyKindSchema,
});
