import React from 'react';

import { Theme, useMediaQuery } from '@mui/material';
import { useRedirect, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { Tabs } from 'components/tabs/Tabs';
import HouseOutlined from '@mui/icons-material/HouseOutlined';
import StarBorderOutlined from '@mui/icons-material/StarBorderOutlined';
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined';
import { getShortOrRegularTranslateKey } from 'utils/strings/getShortOrRegularTranslateKey';
import { TransactionIcon } from 'components/headerTabs/HeaderTabs.styles';
import { truncate } from 'utils/strings/truncate';

import { URI } from 'constants/uri';

import { PropertyHeaderTabsProps } from './PropertyHeaderTabs.types';

export const PropertyHeaderTabs: React.FC<PropertyHeaderTabsProps> = ({
  propertyId,
  bookmarkCount,
  positioningCount,
}) => {
  const translate = useTranslate();
  const { pathname } = useLocation();
  const redirect = useRedirect();
  const editRealEstateUrl = `${URI.realEstates}/${propertyId}`;
  const realEstatePositioninsgUrl = `${editRealEstateUrl}/positionings`;
  const realEstateBookmarksUrl = `${editRealEstateUrl}/bookmarks`;
  const realEstateTransactionsUrl = `${editRealEstateUrl}/transactions`;
  const realEstateDiffsUrl = `${editRealEstateUrl}/diff`;

  const isSmall = useMediaQuery(
    (t: Theme) => `(max-width:${t.breakpoints.values.sm}px)`
  );

  const onChange = (_event: React.SyntheticEvent, path: string) => {
    redirect(path);
  };

  const tabs = [
    {
      icon: <HouseOutlined fontSize="small" />,
      label: translate(
        getShortOrRegularTranslateKey(`realEstate.edit.tabs.edition`, isSmall)
      ),
      value: editRealEstateUrl,
    },
    {
      icon: <StarBorderOutlined fontSize="small" />,
      label: translate(
        getShortOrRegularTranslateKey(
          `shared.headerTabs.positionings`,
          isSmall
        ),
        { count: positioningCount }
      ),
      value: realEstatePositioninsgUrl,
    },
    {
      icon: <FavoriteBorderOutlined fontSize="small" />,
      label: translate(
        getShortOrRegularTranslateKey(`shared.headerTabs.bookmarks`, isSmall),
        { count: bookmarkCount }
      ),
      value: realEstateBookmarksUrl,
    },
    {
      icon: <TransactionIcon />,
      iconPosition: 'start',
      label: isSmall
        ? truncate(translate('shared.headerTabs.transactions'), 8, '')
        : translate('shared.headerTabs.transactions'),
      value: realEstateTransactionsUrl,
    },
    {
      icon: <TransactionIcon />,
      iconPosition: 'start',
      label: isSmall
        ? truncate(translate('shared.headerTabs.diff'), 8, '')
        : translate('shared.headerTabs.diff'),
      value: realEstateDiffsUrl,
    },
  ];

  return (
    <Tabs
      tabs={tabs}
      value={pathname}
      onTabChange={onChange}
      containerStyles={{ marginBottom: '16px', marginTop: '24px' }}
    />
  );
};
