import { Typography } from '@mui/material';

import { Container } from './SectionContainer.styles';
import { SectionContainerProps } from './SectionContainer.types';

export const SectionContainer: React.FC<SectionContainerProps> = ({
  name,
  title,
  children,
  gap,
}) => {
  return (
    <Container className={name} $gap={gap}>
      {!!title && <Typography variant="body1">{title}</Typography>}
      {children}
    </Container>
  );
};
