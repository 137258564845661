import { ReactNode } from 'react';

import LinkIcon from '@mui/icons-material/Link';
import LaunchIcon from '@mui/icons-material/Launch';
import { Chip, Grid, IconButton, styled } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { ListContext } from 'react-admin';
import Highlighter from 'react-highlight-words';
import { TFunction } from 'react-i18next';
import StarOutlineOutlined from '@mui/icons-material/StarOutlineOutlined';
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined';

import { STATUS_COLORS } from 'constants/real-estates';

export { renderCellActionsCGP } from './actionCGPColumnRenderCell';

const HighlightedTagContainer = styled('span')`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HighlightedTag = styled('strong')`
  background-color: ${({ theme }) => theme.palette.grey['300']};
`;

export const renderCellStatus = (
  translate: TFunction
): ((p: GridRenderCellParams) => React.ReactElement) => {
  const CellStatus = (p: GridRenderCellParams): React.ReactElement => {
    if (!p.value) {
      return <></>;
    }

    const value = p.value.toLowerCase();

    const label = translate(`realEstate.status.${value}`);
    return <Chip label={label} color={STATUS_COLORS[value]} />;
  };

  CellStatus.displayName = 'CellStatus';

  return CellStatus;
};

export const renderCellExternalLinksUrl = (
  notify: (message: string) => void
): ((p: GridRenderCellParams) => React.ReactElement) => {
  const CellLinkUrl = ({ id }: GridRenderCellParams): React.ReactElement => {
    const publicUrl = `${process.env.REACT_APP_MASTEOS_PUBLIC_URL}/property/${id}`;
    const linkUrl = process.env.REACT_APP_MASTEOS_LINKS_URL
      ? `${process.env.REACT_APP_MASTEOS_LINKS_URL}/${id}`
      : publicUrl;

    const handleCopyLink = () => {
      navigator.clipboard.writeText(linkUrl);
      notify('shared.linkCopied');
    };

    const handleOpenLink = () => {
      window.open(publicUrl, '_blank')?.focus();
    };

    return (
      <>
        <IconButton
          aria-label="link"
          color="inherit"
          onClick={handleCopyLink}
          size="small"
        >
          <LinkIcon />
        </IconButton>
        <IconButton
          aria-label="link"
          color="inherit"
          onClick={handleOpenLink}
          size="small"
        >
          <LaunchIcon />
        </IconButton>
      </>
    );
  };

  CellLinkUrl.displayName = 'CellCellLinkUrl';

  return CellLinkUrl;
};

export const renderCellHighlighted = (): ((
  p: GridRenderCellParams
) => React.ReactElement) => {
  const Highlight = ({ children }: { children: ReactNode }) => (
    <HighlightedTag>{children}</HighlightedTag>
  );

  const CellHighlighted = (p: GridRenderCellParams) => {
    if (!p.formattedValue) {
      return <></>;
    }

    return (
      <HighlightedTagContainer>
        <ListContext.Consumer>
          {({ filterValues }) => (
            <Highlighter
              autoEscape
              highlightTag={Highlight}
              searchWords={[filterValues?.search]}
              textToHighlight={p.formattedValue}
            />
          )}
        </ListContext.Consumer>
      </HighlightedTagContainer>
    );
  };

  CellHighlighted.displayName = 'CellHighlighted';

  return CellHighlighted;
};

export const renderCellPositioningCount = (): ((
  p: GridRenderCellParams
) => React.ReactElement) => {
  const CellPositioningCount = (
    p: GridRenderCellParams
  ): React.ReactElement => {
    const { value } = p;

    return (
      <Grid container direction="row" alignItems="center">
        <Grid item>{value}</Grid>
        <Grid item paddingTop={0.5} paddingLeft={0.1}>
          <StarOutlineOutlined sx={{ fontSize: 18 }} />
        </Grid>
      </Grid>
    );
  };

  CellPositioningCount.displayName = 'CellPositioningCount';

  return CellPositioningCount;
};

export const renderCellBookmarkCount = (): ((
  p: GridRenderCellParams
) => React.ReactElement) => {
  const CellBookmarkCount = (p: GridRenderCellParams): React.ReactElement => {
    const { value } = p;

    return (
      <Grid container direction="row" alignItems="center">
        <Grid item>{value}</Grid>
        <Grid item paddingTop={0.5} paddingLeft={0.1}>
          <FavoriteBorderOutlined sx={{ fontSize: 16 }} />
        </Grid>
      </Grid>
    );
  };

  CellBookmarkCount.displayName = 'CellBookmarkCount';

  return CellBookmarkCount;
};

export const renderIsBookedCell = (): ((
  p: GridRenderCellParams
) => React.ReactElement) => {
  const CellIsBooked = (p: GridRenderCellParams): React.ReactElement => {
    const { value } = p;

    return (
      <Chip
        label={value ? 'Oui' : 'Non'}
        color={value ? 'warning' : 'info'}
        variant="outlined"
      />
    );
  };

  CellIsBooked.displayName = 'CellIsBooked';

  return CellIsBooked;
};
