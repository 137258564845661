import { styled } from '@mui/material';

export const Content = styled('div')`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0;
  width: 100%;
`;

export const Section = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
