import React from 'react';

import { Label, Wrapper } from './withLabel.styles';
import { WithLabelProps } from './withLabel.types';

export const withLabel = <P,>(
  WrappedComponent: React.FC<P>
): React.FC<P & WithLabelProps> => {
  const WithLabel: React.FC<P & WithLabelProps> = ({ label, ...other }) => {
    return (
      <Wrapper>
        <Label variant="body1">{label}</Label>
        <WrappedComponent emptyText="-" {...(other as P)} />
      </Wrapper>
    );
  };
  WithLabel.displayName = 'WithLabel';
  return WithLabel;
};
