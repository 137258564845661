import { useCallback, useMemo } from 'react';

import { useTranslate, useRecordContext, useDataProvider } from 'react-admin';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { AccordionForm } from 'ui/molecules/accordion-form/AccordionForm';
import { CustomerDocumentType } from 'types/customer.types';
import { DataTable } from 'components/data-table/DataTable';
import { getColumns } from 'utils/columns/getColumns.utils';
import { CellType } from 'types/mui.types';
import { DocumentLink } from 'ui/molecules/document-link/DocumentLink';
import { HeliosDataProvider } from 'providers/admin/dataProvider';
import { ExternalCustomerDetail } from 'types/external-customer.types';

const DEFAULT_COLUMN_WIDTH = 200;
const NAME_COLUMN_WIDTH = 300;

export const DocumentList: React.FC<{ defaultExpanded: boolean }> = ({
  defaultExpanded,
}) => {
  const { documents } = useRecordContext<ExternalCustomerDetail>();
  const translate = useTranslate();

  const dataProvider = useDataProvider<HeliosDataProvider>();

  const openMandatePdf = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      const mandateId = documents?.mandate?.id;

      event.preventDefault();
      if (!mandateId) {
        return Promise.reject();
      }
      return dataProvider.viewDocument(mandateId);
    },
    [dataProvider, documents]
  );

  const columnsList = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'customer.documentsSection.columns.name',
        renderCell: (params: GridRenderCellParams) => (
          <DocumentLink gridParams={params} onClick={openMandatePdf} />
        ),

        sortable: false,
        width: NAME_COLUMN_WIDTH,
      },
      {
        field: 'category',
        headerName: 'customer.documentsSection.columns.category',
        sortable: false,
        width: DEFAULT_COLUMN_WIDTH,
      },
      {
        cellType: CellType.DATE_TIME,
        field: 'createdAt',
        headerName: 'customer.documentsSection.columns.createdAt',
        sortable: false,
        width: DEFAULT_COLUMN_WIDTH,
      },
      {
        cellType: CellType.DATE_TIME,
        field: 'expiresAt',
        headerName: 'customer.documentsSection.columns.expiresAt',
        sortable: false,
        width: DEFAULT_COLUMN_WIDTH,
      },
      {
        cellType: CellType.DATE_TIME,
        field: 'signedAt',
        headerName: 'customer.documentsSection.columns.signedAt',
        sortable: false,
        width: DEFAULT_COLUMN_WIDTH,
      },
    ],
    [openMandatePdf]
  );

  const columns = useMemo(
    () => getColumns(columnsList, translate),
    [translate, columnsList]
  );

  const rows = [
    ...(documents?.financingCertificate
      ? [
          {
            category: translate(
              'customer.documentsSection.documentCategory.financingLetter'
            ),
            createdAt: documents.financingCertificate?.createdAt,
            id: 1,
            name: documents.financingCertificate?.S3ID,
            type: CustomerDocumentType.FinancingCertificate,
          },
        ]
      : []),
    ...(documents?.mandate
      ? [
          {
            category: translate(
              'customer.documentsSection.documentCategory.mandate'
            ),
            createdAt: documents.mandate?.createdAt,
            expiresAt: documents.mandate?.expiresAt,
            id: 2,
            name: documents.mandate?.id,
            signedAt: documents.mandate?.signedAt,
            type: CustomerDocumentType.Mandate,
          },
        ]
      : []),
  ];

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={`🗄️ ${translate('customer.documentsSection.title')}`}
    >
      <div style={{ height: 300, width: '100%' }}>
        <DataTable columns={columns} rows={rows} disableToolBar />
      </div>
    </AccordionForm>
  );
};
