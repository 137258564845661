import { Translate, useStoreResult } from 'react-admin';
import { CellType, GridColDef } from 'types/mui.types';
import {
  Positioning,
  PositioningCancelModal,
  PositioningStatus,
} from 'types/positioning.types';
import {
  GridColumns,
  GridRenderCellParams,
  GridValidRowModel,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { getColumns } from 'utils/columns/getColumns.utils';
import { Theme } from '@mui/material';
import {
  renderCellLinkCustomer,
  renderCellLinkRealEstate,
} from 'utils/columns/renderCells.utils';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import addDays from 'date-fns/addDays';
import { DAYS_BEFORE_AUTO_CANCEL } from 'features/positioning/constants';

import { renderMissingDocuments } from './missingDocumentsRenderer';
import { renderAction } from './actionRenderer';
import { renderElapsedTime } from './elapsedTimeRenderer';
import { getFullNameFormatter } from './fullNameFormatter';
import { formatOrigin } from './originFormatter';
import { cancellationReasonsRenderer } from './cancellationReasonsRenderer';
import { renderStatusColumns } from './renderStatusColumns.utils';

export type PositioningColumns = {
  columns: GridColumns<GridValidRowModel>;
};

const getAdvisorCancellationReasonsColumn = (
  translate: Translate,
  showCancelModal: useStoreResult<PositioningCancelModal>[1]
) => ({
  cellType: CellType.TAG_LIST,
  field: 'advisorCancellationReasons',
  headerName: 'positioning.cancel.advisorReasonsPlaceholder',
  renderCell: (params: GridRenderCellParams<unknown, Positioning, unknown>) =>
    cancellationReasonsRenderer(params, translate, showCancelModal),
  width: 200,
});

const getCustomerCancellationReasonsColumn = (
  translate: Translate,
  showCancelModal: useStoreResult<PositioningCancelModal>[1]
) => ({
  cellType: CellType.TAG_LIST,
  field: 'customerCancellationReasons',
  headerName: 'positioning.cancel.customerReasonsPlaceholder',
  renderCell: (params: GridRenderCellParams<unknown, Positioning, unknown>) =>
    cancellationReasonsRenderer(params, translate, showCancelModal),
  width: 200,
});

const actionColumn = {
  field: 'actions',
  headerName: 'shared.actionsLabel',
  renderCell: renderAction,
  sortable: false,
  width: 100,
};

const positionColumn = {
  field: 'position',
  headerName: 'positioning.positionLabel',
  width: 100,
};

const customerColumn = {
  field: 'customer.lastName',
  headerName: 'positioning.customerLabel',
  renderCell: ({
    row: { customerId },
    value,
  }: GridRenderCellParams<unknown, Positioning>) =>
    renderCellLinkCustomer(customerId, value as string),
  valueGetter: getFullNameFormatter('customer'),
  width: 200,
};

const getStatusColumn = (translate: Translate, theme: Theme) => ({
  field: 'status',
  headerName: 'positioning.statusLabel',
  renderCell: (params: GridRenderCellParams<PositioningStatus>) =>
    renderStatusColumns(params, translate, theme),
  width: 220,
});

const addressColumn = {
  field: 'realEstate.address.street',
  headerName: 'shared.address.street',
  valueGetter: ({ row }: GridValueGetterParams) =>
    row.realEstate?.address?.street,
  width: 200,
};

const addressLinkColumn: GridColDef<Positioning> = {
  field: 'realEstate.address.street',
  headerName: 'shared.address.street',
  renderCell: ({ row }) =>
    renderCellLinkRealEstate(
      row.realEstateId,
      row.realEstate?.address.street ?? ''
    ),
  sortable: false,
  width: 200,
};

const cityColumn = {
  field: 'realEstate.address.city',
  headerName: 'shared.address.city',
  valueGetter: ({ row }: GridValueGetterParams) =>
    row.realEstate?.address?.city,
  width: 130,
};

const hunterColumn = {
  field: 'hunter.lastName',
  headerName: 'positioning.hunterLabel',
  valueGetter: getFullNameFormatter('hunter'),
  width: 200,
};

const statusChangedAtColumn = {
  cellType: CellType.DATE_TIME,
  field: 'statusChangedAt',
  headerName: 'positioning.updatedAtLabel',
  width: 130,
};

const elapsedTimeColumn = {
  field: 'updatedAt',
  headerName: 'positioning.dateForLabel',
  renderCell: renderElapsedTime,
  width: 130,
};

const advisorColumn = {
  field: 'ownedBy.lastName',
  headerName: 'positioning.advisorLabel',
  valueGetter: getFullNameFormatter('ownedBy'),
  width: 200,
};

const getOriginColumn = (translate: Translate) => ({
  field: 'origin',
  headerName: 'positioning.originLabel',
  valueFormatter: (params: GridValueFormatterParams) =>
    formatOrigin(params, translate),
  width: 200,
});

const getCancellationOriginColumn = (translate: Translate) => ({
  field: 'cancellationOrigin',
  headerName: 'positioning.cancellationOriginLabel',
  valueFormatter: (params: GridValueFormatterParams) =>
    formatOrigin(params, translate),
  width: 200,
});

const getMissingDocColumn = (translate: Translate) => ({
  field: 'missingDocuments',
  headerName: 'positioning.missingDocumentsLabel',
  renderCell: (params: GridRenderCellParams<PositioningStatus>) =>
    renderMissingDocuments(params, translate),
  sortable: false,
  width: 200,
});

const getRemainingTimeColumn = {
  field: 'createdAt',
  headerName: 'positioning.remainingTimeLabel',
  valueGetter: ({ row }: GridValueGetterParams<Date, Positioning>) => {
    if (row.status !== PositioningStatus.Recommendation) {
      return '';
    }
    return formatDistanceToNow(
      addDays(new Date(row.createdAt), DAYS_BEFORE_AUTO_CANCEL)
    );
  },
  width: 140,
};

export const getPositioningColumns = (
  translate: Translate,
  theme: Theme
): GridColDef<Positioning>[] => {
  const columns: GridColDef<Positioning>[] = [
    positionColumn,
    customerColumn,
    getStatusColumn(translate, theme),
    addressColumn,
    cityColumn,
    elapsedTimeColumn,
    hunterColumn,
    advisorColumn,
    statusChangedAtColumn,
    getOriginColumn(translate),
  ];

  return getColumns(columns, translate);
};

export const getRealEstatePositioningColumns = (
  translate: Translate,
  theme: Theme,
  showCancelModal: useStoreResult<PositioningCancelModal>[1]
): GridColDef<Positioning>[] => {
  const columns: GridColDef<Positioning>[] = [
    actionColumn,
    positionColumn,
    customerColumn,
    getStatusColumn(translate, theme),
    statusChangedAtColumn,
    advisorColumn,
    getMissingDocColumn(translate),
    getOriginColumn(translate),
    getCancellationOriginColumn(translate),
    getCustomerCancellationReasonsColumn(translate, showCancelModal),
    getAdvisorCancellationReasonsColumn(translate, showCancelModal),
    getRemainingTimeColumn,
  ];

  return getColumns(columns, translate);
};

export const getCustomerPositioningColumns = (
  translate: Translate,
  theme: Theme,
  showCancelModal: useStoreResult<PositioningCancelModal>[1]
): GridColDef<Positioning>[] => {
  const columns: GridColDef<Positioning>[] = [
    actionColumn,
    positionColumn,
    getStatusColumn(translate, theme),
    addressLinkColumn,
    statusChangedAtColumn,
    advisorColumn,
    getMissingDocColumn(translate),
    getOriginColumn(translate),
    getCancellationOriginColumn(translate),
    getCustomerCancellationReasonsColumn(translate, showCancelModal),
    getAdvisorCancellationReasonsColumn(translate, showCancelModal),
    getRemainingTimeColumn,
  ];

  return getColumns(columns, translate);
};
