import React, { useMemo } from 'react';

import { DateInput } from 'components/custom-input/date-input/DateInput';
import {
  CommonInputProps,
  TextInput,
  SelectInput,
  useTranslate,
  useRecordContext,
} from 'react-admin';
import { FormDivider } from 'ui/atoms/form-divider/FormDivider';
import { AccordionForm } from 'ui/molecules/accordion-form/AccordionForm';
import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import { GooglePlacesAutocomplete } from 'components/google-places-autocomplete/GooglePlacesAutocomplete';
import { CustomerEditFormatted } from 'types/customer.types';
import { useCustomerPermissions } from 'features/customers/hooks/useCustomerPermissions';
import { Tooltip } from 'ui/molecules/tooltip/Tooltip';
import Info from '@mui/icons-material/Info';
import { UserAutoComplete } from 'components/user-autocomplete/UserAutoComplete';
import { PhoneInput } from 'ui/atoms/phone-input/phone-input';
import { getCivilityChoiceList } from 'utils/form/form.utils';

import { ProfileIconButton } from './ProfileForm.styles';

export const ProfileForm: React.FC<{ defaultExpanded: boolean }> = ({
  defaultExpanded,
}) => {
  const customer = useRecordContext<CustomerEditFormatted>();
  const translate = useTranslate();
  const {
    checkCanEditCustomer,
    checkCanSeeContactDetails,
    canEditCustomerBroughtBy,
  } = useCustomerPermissions();
  const canEditCustomer = checkCanEditCustomer(customer);

  const commonProps: Partial<CommonInputProps> = {
    margin: 'none',
    variant: 'outlined',
  };

  const civilityChoices = useMemo(() => getCivilityChoiceList(), []);

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={`👤 ${translate('customer.form.profile')}`}
    >
      <Fieldset>
        <SelectInput
          source="civility"
          choices={civilityChoices}
          label="customer.civilityLabel"
          disabled={!canEditCustomer}
          {...commonProps}
        />
        <TextInput
          source="firstName"
          label="user.input.firstname"
          disabled={!canEditCustomer}
          {...commonProps}
        />
        <TextInput
          source="lastName"
          label="user.input.lastname"
          disabled={!canEditCustomer}
          {...commonProps}
        />
      </Fieldset>
      <Fieldset>
        <DateInput
          source="birthDate"
          {...commonProps}
          disabled={!canEditCustomer}
          label={translate('customer.birthDate')}
        />
        <TextInput
          source="birthCity"
          label="customer.birthCity"
          disabled={!canEditCustomer}
          {...commonProps}
        />
      </Fieldset>
      {!!checkCanSeeContactDetails(customer) && (
        <Fieldset>
          <TextInput
            source="email"
            label="user.input.email"
            disabled={true}
            {...commonProps}
          />
          <PhoneInput
            source="phoneNumber"
            label="shared.phoneNumber"
            disabled={!canEditCustomer}
            {...commonProps}
          />
        </Fieldset>
      )}
      <GooglePlacesAutocomplete
        {...commonProps}
        source="address"
        defaultValue={customer?.address?.street}
        disabled={!canEditCustomer}
      />
      <FormDivider />
      <Fieldset>
        <TextInput
          source="id"
          label="customer.idClient"
          disabled={true}
          {...commonProps}
        />
        <TextInput
          disabled={true}
          source="utm.medium"
          label="customer.utmMedium"
          {...commonProps}
        />
        <TextInput
          disabled={true}
          source="utm.term"
          label="customer.utmTerm"
          {...commonProps}
        />
        <TextInput
          disabled={true}
          source="utm.source"
          label="customer.utmSource"
          {...commonProps}
        />
        <TextInput
          disabled={true}
          source="utm.campaign"
          label="customer.utmCampaign"
          {...commonProps}
        />
        <TextInput
          disabled={true}
          source="utm.content"
          label="customer.utmContent"
          {...commonProps}
        />
      </Fieldset>
      <Fieldset>
        <UserAutoComplete
          label="customer.broughtBy"
          source="broughtBy"
          disabled={true}
        />
        {!canEditCustomerBroughtBy && (
          <Tooltip title={translate('customer.broughtByInfo')}>
            <ProfileIconButton>
              <Info fontSize="medium" />
            </ProfileIconButton>
          </Tooltip>
        )}
      </Fieldset>
    </AccordionForm>
  );
};
