import { HeliosDataProvider } from 'providers/admin/dataProvider';
import {
  HttpError,
  useDataProvider,
  useEditContext,
  useNotify,
} from 'react-admin';
import { useMutation } from 'react-query';
import { ActionMutationReturn } from 'types/common.types';

export type DownloadPropertyPdfMutationReturn = ActionMutationReturn<
  void | { data: void },
  unknown,
  string | undefined,
  unknown
>;

export const useDownloadPropertyPdfAction =
  (): DownloadPropertyPdfMutationReturn => {
    const { record } = useEditContext();
    const dataProvider = useDataProvider<HeliosDataProvider>();
    const notify = useNotify();

    const mutation = useMutation(
      ['downloadPropertyPdf', record?.id, dataProvider],
      (id?: string) => {
        return dataProvider
          ?.downloadPropertyPdf(record?.id || id)
          .then(() => {
            notify('shared.successes.pdfDownloaded', {
              type: 'success',
            });
          })
          .catch((err: HttpError) => {
            const message =
              err.status === 403
                ? `realEstate.action.${err.message}`
                : 'ra.notification.http_error';

            notify(message, {
              type: 'error',
            });
          });
      }
    );
    return { ...mutation, isAllowed: true };
  };
