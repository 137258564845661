/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';

import { SelectInput, useInput, useTranslate } from 'react-admin';
import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import { PriceInput } from 'ui/atoms/price-input/PriceInput';
import { PercentInput } from 'ui/atoms/percent-input/PercentInput';
import { AccordionForm } from 'ui/molecules/accordion-form/AccordionForm';
import { Typography } from '@mui/material';
import { CheckboxInput } from 'components/checkbox-input/CheckboxInput';
import { useRealEstateDetailPermissions } from 'features/realEstates/details/edit/hooks/useRealEstateDetailPermissions';
import { InputValue } from 'features/realEstates/utils/inputValue.type';
import { formatCurrency } from 'utils/i18n/i18n.utils';
import { Disclaimer } from 'ui/molecules/disclaimer/Disclaimer';
import { DisclaimerContainer } from 'ui/molecules/disclaimer/Disclaimer.styles';

import { APPRECIATION_ZONE_DATA } from 'constants/real-estates';

import { RealEstateEditAccordeonFormProps } from '../EditFormContent.types';
import { sourceToLabelMap } from '../EditFormContent.utils';
import { computeFurnitureBudget } from './computeFurnitureBudget';

export const FinancialModelForm: React.FC<RealEstateEditAccordeonFormProps> = ({
  defaultExpanded,
  canSeeAnnualHomeOwnerInsurance,
  disabled = false,
}) => {
  const translate = useTranslate();
  const {
    field: { value: shortTermRentalCheckboxValue },
  }: InputValue<boolean> = useInput({ source: 'shortTermRentalCheckbox' });
  const {
    field: { value: hasFurnitureBudget },
  }: InputValue<boolean> = useInput({ source: 'hasFurnitureBudget' });
  const {
    field: { value: roomNumber },
  }: InputValue<number> = useInput<number>({ source: 'roomNumber' });
  const {
    field: { value: countryCode },
  }: InputValue<string> = useInput({ source: 'address.countryCode' });
  const [packAmount, setPackAmount] = useState<number>();
  const furnitureAmountHelper =
    countryCode === 'FR'
      ? translate(`realEstate.helperFurnituresAmount`, {
          packAmount: formatCurrency({
            locale: 'FR',
            value: packAmount || 0,
          }),
          roomNumber,
        })
      : '';
  const enableFurnitureAmountFeature = false;

  useEffect(() => {
    if (roomNumber) {
      setPackAmount(computeFurnitureBudget(roomNumber));
    }
  }, [roomNumber, setPackAmount]);

  const { canDoPublish, canUpdateAnnualRentIncome } =
    useRealEstateDetailPermissions();

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate('realEstate.form.financialModel')}
    >
      <Fieldset title={translate('realEstate.fieldset.acquisition')}>
        <PriceInput
          disabled={disabled}
          source="financialModel.netSeller"
          label={sourceToLabelMap.get('financialModel.netSeller')}
          margin="none"
          variant="outlined"
        />
        <PriceInput
          disabled={disabled}
          source="financialModel.agencySellerCommission"
          label={sourceToLabelMap.get('financialModel.agencySellerCommission')}
          margin="none"
          variant="outlined"
        />
        <PriceInput
          disabled={disabled}
          source="financialModel.renovationAmount"
          label={sourceToLabelMap.get('financialModel.renovationAmount')}
          margin="none"
          variant="outlined"
        />
        {!enableFurnitureAmountFeature && (
          <PriceInput
            disabled={disabled}
            source="financialModel.furnituresAmount"
            label={sourceToLabelMap.get('financialModel.furnituresAmount')}
            margin="none"
            variant="outlined"
            helperText={furnitureAmountHelper}
          />
        )}
      </Fieldset>

      {!!enableFurnitureAmountFeature && (
        <Fieldset title={translate('realEstate.fieldset.furnishings')}>
          <CheckboxInput
            label={sourceToLabelMap.get(
              'financialModel.necessaryFurnitureCheckbox'
            )}
            source="hasFurnitureBudget"
          />
          <PriceInput
            disabled
            source="financialModel.furnituresAmount"
            label={sourceToLabelMap.get('financialModel.furnitureBudgetInput')}
            margin="none"
            variant="outlined"
          />
          {!!hasFurnitureBudget && (
            <DisclaimerContainer>
              <Disclaimer
                title="realEstate.renovationDetails.warningTitle"
                content="realEstate.renovationDetails.warningContent"
                type="info"
              />
            </DisclaimerContainer>
          )}
        </Fieldset>
      )}

      <Fieldset title={translate('realEstate.fieldset.revenues')}>
        <PriceInput
          disabled={disabled}
          source="financialModel.annualRentIncome"
          label={sourceToLabelMap.get('financialModel.annualRentIncome')}
          margin="none"
          variant="outlined"
        />
        {canUpdateAnnualRentIncome ? (
          <>
            <CheckboxInput
              disabled={!canDoPublish || disabled}
              source="isAnnualRentIncomeValidated"
              label={sourceToLabelMap.get('isAnnualRentIncomeValidated')}
              {...(!canDoPublish ? { checked: true } : {})}
            />
            <br />
          </>
        ) : null}

        <PriceInput
          disabled={disabled}
          source="financialModel.annualCharges"
          label={sourceToLabelMap.get('financialModel.annualCharges')}
          margin="none"
          variant="outlined"
        />
        <PercentInput
          disabled={disabled}
          source="financialModel.evaluationRate"
          label={sourceToLabelMap.get('financialModel.evaluationRate')}
          margin="none"
          variant="outlined"
        />
        <SelectInput
          disabled={disabled}
          source="appreciationZone"
          label={sourceToLabelMap.get('appreciationZone')}
          choices={APPRECIATION_ZONE_DATA}
          margin="none"
          variant="outlined"
        />
      </Fieldset>

      <Fieldset
        title={translate('realEstate.fieldset.shortTermRentalRevenues')}
      >
        <DisclaimerContainer>
          <CheckboxInput
            disabled={disabled}
            source="shortTermRentalCheckbox"
            label={translate('realEstate.shortTermRental.checkbox')}
          />

          {!!shortTermRentalCheckboxValue && (
            <Disclaimer
              title="realEstate.shortTermRental.warningTitle"
              content="realEstate.shortTermRental.warningContent"
              type="warning"
            />
          )}
        </DisclaimerContainer>

        {!!shortTermRentalCheckboxValue && (
          <>
            <PriceInput
              disabled={disabled}
              source="financialModel.shortTermNightlyRent"
              label={sourceToLabelMap.get(
                'financialModel.shortTermNightlyRent'
              )}
              margin="none"
              variant="outlined"
              min={0}
            />
            <PercentInput
              disabled={disabled}
              source="financialModel.shortTermOccupancyRate"
              label={sourceToLabelMap.get(
                'financialModel.shortTermOccupancyRate'
              )}
              margin="none"
              variant="outlined"
              min={0}
              max={100}
              helperText={translate(
                'realEstate.shortTermRental.occupancyRateHint'
              )}
            />
          </>
        )}
      </Fieldset>

      <Fieldset title={translate('realEstate.fieldset.expenses')}>
        <PriceInput
          disabled={disabled}
          source="financialModel.annualCurrentExpenses"
          label={sourceToLabelMap.get('financialModel.annualCurrentExpenses')}
          margin="none"
          variant="outlined"
        />
        <PriceInput
          disabled={disabled}
          source="financialModel.annualPropertyTax"
          label={sourceToLabelMap.get('financialModel.annualPropertyTax')}
          margin="none"
          variant="outlined"
        />
        {canSeeAnnualHomeOwnerInsurance ? (
          <PriceInput
            disabled={disabled}
            source="financialModel.annualHomeOwnerInsurance"
            label={sourceToLabelMap.get(
              'financialModel.annualHomeOwnerInsurance'
            )}
            margin="none"
            variant="outlined"
          />
        ) : (
          <React.Fragment />
        )}
      </Fieldset>

      <Fieldset title={translate('realEstate.fieldset.financialPerformance')}>
        <PriceInput
          source="financialModel.projectAmount"
          label={sourceToLabelMap.get('financialModel.projectAmount')}
          margin="none"
          variant="outlined"
          disabled
        />
        <PriceInput
          source="financialModel.annualTotalIncome"
          label={sourceToLabelMap.get('financialModel.annualTotalIncome')}
          margin="none"
          variant="outlined"
          disabled
        />
        <PriceInput
          source="financialModel.annualTotalExpenses"
          label={sourceToLabelMap.get('financialModel.annualTotalExpenses')}
          margin="none"
          variant="outlined"
          disabled
        />
        <PriceInput
          source="financialModel.annualLoanPayments"
          label={sourceToLabelMap.get('financialModel.annualLoanPayments')}
          margin="none"
          variant="outlined"
          disabled
        />
        <PriceInput
          source="financialModel.annualTotalEarnings"
          label={sourceToLabelMap.get('financialModel.annualTotalEarnings')}
          margin="none"
          variant="outlined"
          disabled
        />
        <PercentInput
          source="financialModel.grossReturn"
          label={sourceToLabelMap.get('financialModel.grossReturn')}
          margin="none"
          variant="outlined"
          disabled
        />
        <PercentInput
          source="financialModel.netReturn"
          label={sourceToLabelMap.get('financialModel.netReturn')}
          margin="none"
          variant="outlined"
          disabled
        />
        <Typography variant="caption" className="fieldset-helper-text" p={1}>
          {translate('realEstate.fieldset.financialPerformance_helperText')}
        </Typography>
      </Fieldset>
    </AccordionForm>
  );
};
