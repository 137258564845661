import { AuthProvider } from 'react-admin';
import { checkAuth } from 'features/auth/utils/checkAuth';
import { checkError } from 'features/auth/utils/checkError';
import { getIdentity } from 'features/auth/utils/getIdentity';
import { getPermissions } from 'features/auth/utils/getPermissions';
import { login } from 'features/auth/utils/login';
import { logout } from 'features/auth/utils/logout';

export const authProvider: AuthProvider = {
  checkAuth,
  checkError,
  getIdentity,
  getPermissions,
  login,
  logout,
};
