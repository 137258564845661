import { Customer, CustomerEditFormatted } from 'types/customer.types';
import { formatCustomerAddress } from 'utils/form/form.utils';

export const transformForDisplay = ({
  address,
  advisor,
  documents,
  financingCertificateStatus,
  ownedBy,
  ...record
}: Customer): CustomerEditFormatted => {
  return {
    ...record,
    address: {
      ...address,
      street: formatCustomerAddress(address),
    },
    documents,
    'financingCertificate.date': documents?.financingCertificate?.createdAt,
    'financingCertificate.status': financingCertificateStatus,
    ownedBy: advisor?.id ?? ownedBy,
  };
};
