import { useTranslate } from 'react-admin';
import { AccordionForm } from 'ui/molecules/accordion-form/AccordionForm';
import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import { TextArea } from 'ui/atoms/textarea/TextArea';

export const InternalNoteForm: React.FC<{ defaultExpanded: boolean }> = ({
  defaultExpanded,
}) => {
  const translate = useTranslate();

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={`📝 ${translate('shared.internalNote')}`}
    >
      <Fieldset>
        <TextArea
          fullWidth
          label="shared.internalNote"
          source="internalNote"
          minRows={3}
        />
      </Fieldset>
    </AccordionForm>
  );
};
