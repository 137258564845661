import React from 'react';

import { IconButton } from '@mui/material';
import { Tooltip } from 'ui/molecules/tooltip/Tooltip';
import { useTranslate } from 'react-admin';
import { Close } from '@mui/icons-material';

export type SecondActionButtonProps = {
  isCancelDisabled: boolean;
  hasPermissionToUpdate: boolean;
  handleOnCancel: () => void;
};

export const SecondActionButton: React.FC<SecondActionButtonProps> = ({
  isCancelDisabled,
  hasPermissionToUpdate,
  handleOnCancel,
}) => {
  const translate = useTranslate();

  if (isCancelDisabled || !hasPermissionToUpdate) {
    return (
      <IconButton
        className="actionButtonGroupDisabledButton"
        data-testid="cancel-button-disabled"
      >
        <Tooltip title={translate('positioning.actions.info.cancelDisabled')}>
          <Close color="disabled" />
        </Tooltip>
      </IconButton>
    );
  }

  return (
    <IconButton onClick={handleOnCancel} data-testid="cancel-button">
      <Close color="error" />
    </IconButton>
  );
};
