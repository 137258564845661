import { RoleName } from './role.types';

// /!\ Keep sync with PermissionName Enum in masteos backend (file: core-v2/entities/role/permission.ts)
export enum PermissionName {
  // Customers ---------------------------------------------
  // 👁️ Reads
  ListCustomer = 'LIST_CUSTOMER',
  ViewCustomer = 'VIEW_CUSTOMER',
  // 📝 Writes
  EditCustomer = 'EDIT_CUSTOMER',
  CreateCustomer = 'CREATE_CUSTOMER',
  // ⚙️ Actions
  InviteCustomer = 'INVITE_CUSTOMER',
  UpdateCustomerOwnedBy = 'UPDATE_CUSTOMER_OWNED_BY',
  UpdateCustomerAssignAsAdvisor = 'UPDATE_CUSTOMER_ASSIGN_AS_ADVISOR',
  UpdateCustomerBroughtBy = 'EDIT_CUSTOMER_BROUGHT_BY',

  // RealEstates -------------------------------------------
  // 👁️ Reads
  ListRealEstate = 'LIST_REAL_ESTATE',
  ListRealEstateCGP = 'LIST_REAL_ESTATE_CGP',
  ViewRealEstateDetails = 'VIEW_REAL_ESTATE_DETAILS',
  ViewRealEstateActivities = 'VIEW_REAL_ESTATE_ACTIVITIES',
  ViewRealEstatePositionings = 'VIEW_REAL_ESTATE_POSITIONINGS',
  ViewRealEstateAllStatus = 'VIEW_REAL_ESTATE_ALL_STATUS',
  // 📝 Writes
  CreateRealEstate = 'CREATE_REAL_ESTATE',
  UpdateRealEstate = 'UPDATE_REAL_ESTATE',
  // ⚙️ Actions
  CreateRealEstatePositionings = 'CREATE_REAL_ESTATE_POSITIONINGS',
  UpdateRealEstateRenovationQuote = 'UPDATE_REAL_ESTATE_RENOVATION_QUOTE',
  UpdateRealEstateByPassMasteosChecks = 'UPDATE_REAL_ESTATE_BY_PASS_MASTEOS_CHECKS',
  UpdateRealEstatePublicationDetails = 'UPDATE_REAL_ESTATE_PUBLICATION_DETAILS',
  UpdateRealEstatePositionings = 'UPDATE_REAL_ESTATE_POSITIONINGS',
  UpdateRealEstateArchive = 'UPDATE_REAL_ESTATE_ARCHIVE',
  UpdateRealEstatePublishAppartment = 'UPDATE_REAL_ESTATE_PUBLISH_APPARTMENT',
  UpdateRealEstatePublishBuilding = 'UPDATE_REAL_ESTATE_PUBLISH_BUILDING',
  UpdateRealEstatePublishHouse = 'UPDATE_REAL_ESTATE_PUBLISH_HOUSE',
  UpdateRealEstateRequestReview = 'UPDATE_REAL_ESTATE_REQUEST_REVIEW',
  UpdateRealEstateRequestPublishArchived = 'UPDATE_REAL_ESTATE_REQUEST_PUBLISH_ARCHIVED',
  UpdateRealEstateRequestPublishDraft = 'UPDATE_REAL_ESTATE_REQUEST_PUBLISH_DRAFT',
  UpdateRealEstateAnnualRentIncome = 'UPDATE_REAL_ESTATE_ANNUAL_RENT_INCOME',
  UpdateRealEstateSendToReview = 'UPDATE_REAL_ESTATE_SEND_TO_REVIEW',
  UpdateRealEstateTransferOwnership = 'UPDATE_REAL_ESTATE_TRANSFER_OWNERSHIP',
  CreateCgpPublicLink = 'CREATE_CGP_PUBLIC_LINK',
  BookRealEstateCgp = 'BOOK_REAL_ESTATE_CGP',
  UnbookRealEstateCgp = 'UNBOOK_REAL_ESTATE_CGP',
  CancelBookingRealEstateCgp = 'CANCEL_BOOKING_REAL_ESTATE_CGP',

  // Projects / Transactions --------------------------------
  // 👁️ Reads
  ViewProject = 'VIEW_PROJECT',
  ListProject = 'LIST_PROJECT',
  ListProjectCGP = 'LIST_PROJECT_CGP',
  // 📝 Writes
  UpdateProject = 'UPDATE_PROJECT',
  // Actions
  UpdateProjectTransactionSteps = 'UPDATE_PROJECT_TRANSACTION_STEPS',
  UpdateProjectInvoiceMethod = 'UPDATE_PROJECT_INVOICE_METHOD',
  UpdateProjectTransferOwnership = 'UPDATE_PROJECT_TRANSFER_OWNERSHIP',

  // Users --------------------------------------------------
  // 👁️ Reads
  ListUser = 'LIST_USER',
  ViewUser = 'VIEW_USER',
  ListRoles = 'LIST_ROLES',

  // 📝 Writes
  UpdateUser = 'UPDATE_USER',
  CreateUser = 'CREATE_USER',
  DeleteUser = 'DELETE_USER',

  // ⚙️ Actions
  CreateUserExternal = 'CREATE_USER_EXTERNAL',
  UpdateUserExternal = 'UPDATE_USER_EXTERNAL',
  ArchiveUserExternal = 'ARCHIVE_USER_EXTERNAL',
  CreateUserHunter = 'CREATE_USER_HUNTER',
  UpdateUserHunter = 'UPDATE_USER_HUNTER',
  CreateUserAnimator = 'CREATE_USER_ANIMATOR',

  // Organizations ------------------------------------------
  // 👁️ Reads
  ListOrganization = 'LIST_ORGANIZATION',
  // 📝 Writes
  CreateOrganization = 'CREATE_ORGANIZATION',
  UpdateOrganization = 'UPDATE_ORGANIZATION',

  // Documents ----------------------------------------------
  // ⚙️ Actions
  DownloadDocument = 'DOWNLOAD_DOCUMENT',
  CreateSearchMandate = 'CREATE_SEARCH_MANDATE',
  CreateOffer = 'CREATE_OFFER',
  UploadCounterSignedOffer = 'UPLOAD_COUNTER_SIGNED_OFFER',

  // Realizations ------------------------------------------
  // 👁️ Reads
  ListRealization = 'LIST_REALIZATION',
  ViewRealization = 'VIEW_REALIZATION',
  // 📝 Writes
  CreateRealization = 'CREATE_REALIZATION',
  UpdateRealization = 'UPDATE_REALIZATION',

  // External customers ------------------------------------
  // 👁️ Reads
  ListExternalCustomer = 'LIST_EXTERNAL_CUSTOMER',
  ViewExternalCustomer = 'VIEW_EXTERNAL_CUSTOMER',
  // 📝 Writes
  CreateExternalCustomer = 'CREATE_EXTERNAL_CUSTOMER',
  UpdateExternalCustomer = 'UPDATE_EXTERNAL_CUSTOMER',
}

export enum Scope {
  Own = 'OWN',
  All = 'ALL',
  Organization = 'ORGANIZATION',
  None = 'NONE',
}

export type Permission = {
  name: PermissionName;
  scope: Scope;
};

export type PermissionRow = {
  id: string;
} & Partial<Record<RoleName, Scope>>;
