import React from 'react';

import {
  Edit,
  EditContext,
  ShowContext,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { Form } from 'components/form/Form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MobileFooter } from 'components/mobile-footer/MobileFooter';
import { TransferTransactionModal } from 'components/action-modal/TransferTransaction/TransferTransactionModal';
import { ShowContainer } from 'components/detail/show-container/ShowContainer';

import { Content } from './components/content/Content';
import { TransactionsHeader } from './components/transactions-header/TransactionsHeader';
import { CancelModal } from './components/cancel-modal/CancelModal';
import { ActionButton } from './components/action-button/ActionButton';
import { UpdateTodoModal } from './components/update-todo-modal/UpdateTodoModal';
import { projectForm } from '../validators/transaction.validator';

export const TransactionsShow: React.FC = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onError = (err: unknown) => {
    notify((err as Error).message, { type: 'error' });
  };
  const onSuccess = () => {
    notify(translate('project.form.success'), { type: 'success' });
    refresh();
  };

  return (
    <ShowContainer
      component="div"
      emptyWhileLoading
      data-testid="transaction-show-container"
    >
      <ShowContext.Consumer>
        {({ record }) => (
          <Edit
            component="div"
            resource="projects"
            id={record.projectId}
            mutationMode="pessimistic"
            mutationOptions={{
              onError,
              onSuccess,
            }}
          >
            <EditContext.Consumer>
              {({ record: projectRecord, save }) => (
                <Form
                  shouldUnregister
                  resolver={yupResolver(projectForm)}
                  record={projectRecord}
                  onSubmit={save as SubmitHandler<FieldValues> | undefined}
                >
                  <TransactionsHeader actionButtons={<ActionButton />} />
                  <Content />
                  <MobileFooter>
                    <ActionButton />
                  </MobileFooter>
                </Form>
              )}
            </EditContext.Consumer>
          </Edit>
        )}
      </ShowContext.Consumer>

      <CancelModal />
      <TransferTransactionModal />
      <UpdateTodoModal />
    </ShowContainer>
  );
};
