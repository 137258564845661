import {
  CustomerSearch,
  CustomerSearchRoomCountFilter,
} from 'types/customer.types';
import { ChoiceProps } from 'types/form.type';
import { RealEstateType } from 'types/realEstate.enum';
import { formatChoiceList } from 'utils/form/form.utils';

export const getRegionsChoiceList = (
  regionsList: CustomerSearch['regions']
): ChoiceProps =>
  Object.values(regionsList).map((region) => ({
    id: region,
    name: region,
  }));

export const getPropertyKingChoiceList = (): ChoiceProps =>
  formatChoiceList(RealEstateType, 'realEstate.type');

export const getPropertyRoomCountChoiceList = (): ChoiceProps =>
  formatChoiceList(CustomerSearchRoomCountFilter, 'realEstate.roomCount');
