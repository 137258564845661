const IMG_EXT = ['.png', '.gif', '.jpeg', '.jpg', '.heic'];

export const RESIZE_PARAMS = {
  COMPRESSION: 100,
  FORMAT: 'JPEG',
  MAX_SIZE: 1600,
};

export const ERROR_TYPE_INVALID = 'file-invalid-type';
export const ERROR_TOO_MANY_FILES = 'too-many-files';

export const getImgExt = (): string[] => {
  const upp = IMG_EXT.map((ext) => ext.toUpperCase());
  return [...IMG_EXT, ...upp];
};
