import { CellType, GridColDef } from 'types/mui.types';
import { useTheme } from '@mui/material';
import { formatOrganizationTerritoriesChip } from 'utils/columns/valueFormatters.utils';

export const useGetOrganizationsColumns = (): GridColDef[] => {
  const theme = useTheme();

  return [
    {
      field: 'name',
      headerName: 'organization.nameLabel',
      width: 500,
    },
    {
      field: 'siret',
      headerName: 'organization.siretLabel',
      width: 200,
    },
    {
      field: 'id',
      headerName: 'organization.idLabel',
      width: 350,
    },
    {
      cellType: CellType.TAG_LIST_COL,
      field: 'territories',
      headerName: 'organization.territoriesLabel',
      sortable: false,
      valueFormatter: formatOrganizationTerritoriesChip(theme),
      width: 1050,
    },
  ] as GridColDef[];
};
