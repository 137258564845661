import React from 'react';

import { Button, Chip, Divider, Theme, Typography } from '@mui/material';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import {
  DateField,
  TextField,
  useDataProvider,
  useStore,
  useTranslate,
} from 'react-admin';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TransactionModalKey } from 'types/transaction.types';
import { formatTodosStepId } from 'features/transactions/list/utils/formatTransactionList.util';
import {
  ICON_MAPPING,
  DOCUMENTS_STATES,
} from 'features/transactions/show/constants';
import { HeliosDataProvider } from 'providers/admin/dataProvider';
import { PermissionName } from 'types/permission.types';
import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';

import {
  Container,
  Content,
  ContentBlock,
  ContentDate,
  DownloadLink,
  Header,
  LeftDivider,
  Preview,
  TitleText,
  TitleWithIcon,
} from './DocumentsSection.styles';
import { DocumentsSectionProps } from './DocumentsSection.types';

export const DocumentsSection: React.FC<DocumentsSectionProps> = ({
  date,
  documentId,
  isLast,
  realizedOnDate,
  state,
  todoId,
}) => {
  const { hasPermission } = useHeliosPermissions();
  const [, showModal] = useStore(TransactionModalKey.UPDATE, [
    false,
    'default',
  ]);
  const isSmall = useMediaQuery(
    (theme: Theme) => `(max-width:${theme.breakpoints.values.sm}px)`
  );
  const translate = useTranslate();
  const dataProvider = useDataProvider<HeliosDataProvider>();

  const type = formatTodosStepId(todoId);
  const Icon = ICON_MAPPING[type];
  const canUpdateProjectTransactionSteps = hasPermission(
    PermissionName.UpdateProjectTransactionSteps
  );

  const handleShowModal = () => {
    showModal([true, todoId]);
  };

  const downloadDocumentFile = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (!documentId) {
      return;
    }
    // @todo: add notifications ?
    dataProvider.downloadDocument(documentId);
  };

  return (
    <Container>
      <Header>
        <TitleWithIcon>
          {Icon}
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {translate(`transaction.show.documents.${type}.title`)}
          </Typography>
        </TitleWithIcon>
        {!isSmall && !!canUpdateProjectTransactionSteps && (
          <Button
            data-testid="document-section-btn"
            variant="outlined"
            size="medium"
            onClick={handleShowModal}
            disabled={state === null}
          >
            {translate(`transaction.show.documents.${type}.buttonLabel`)}
          </Button>
        )}
      </Header>
      <Content>
        <ContentBlock>
          <LeftDivider />
          <ContentBlock className="column">
            <TitleText>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {translate('transaction.show.documents.state')}
              </Typography>
            </TitleText>
            <div>
              {state ? (
                <Chip
                  data-testid="documents-section-chip"
                  {...DOCUMENTS_STATES[state]}
                  label={translate(DOCUMENTS_STATES[state].label)}
                />
              ) : (
                '-'
              )}
            </div>
          </ContentBlock>
        </ContentBlock>
        <ContentBlock>
          <LeftDivider />
          <ContentDate data-testid="documents-section-date">
            <TitleText>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {translate(`transaction.show.documents.${type}.subtitle`)}
              </Typography>
              <DateField source="date" emptyText="-" record={{ date }} />
            </TitleText>
          </ContentDate>
        </ContentBlock>
        {realizedOnDate ? (
          <ContentBlock>
            <LeftDivider />
            <ContentDate data-testid="documents-section-realized-date">
              <TitleText>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {translate(`transaction.show.documents.realizedOn.subtitle`)}
                </Typography>
                <DateField
                  source="realizedOnDate"
                  emptyText="-"
                  record={{ realizedOnDate }}
                />
              </TitleText>
            </ContentDate>
          </ContentBlock>
        ) : null}
        {documentId ? (
          <Preview data-testid="documents-section-preview" $isSmall={isSmall}>
            <TitleText className="right" $isSmall={isSmall}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {translate(`transaction.show.documents.consult`)}
              </Typography>
              <TitleText>
                <DownloadLink
                  data-testid="documents-section-preview-link"
                  href="#"
                  variant="body2"
                  onClick={downloadDocumentFile}
                >
                  <InsertDriveFileOutlinedIcon />
                  <TextField
                    source="file"
                    record={{
                      file: translate('transaction.show.documents.download'),
                    }}
                  />
                </DownloadLink>
              </TitleText>
            </TitleText>
          </Preview>
        ) : null}
      </Content>
      {!!isSmall && !!canUpdateProjectTransactionSteps && (
        <Button
          data-testid="document-section-btn-small"
          variant="outlined"
          size="medium"
          onClick={handleShowModal}
        >
          {translate(`transaction.show.documents.${type}.buttonLabel`)}
        </Button>
      )}
      {!isLast && <Divider flexItem />}
    </Container>
  );
};
