import {
  Box,
  Card as CardUi,
  CircularProgress,
  styled,
  Typography,
} from '@mui/material';
import CardActions from '@mui/material/CardActions';
import { useTranslate } from 'react-admin';
import { Button } from 'ui/atoms/button/Button';
import { Image as ImageUi, ImageType } from 'ui/atoms/image/Image';
import { UploadState } from 'types/common.types';

const Card = styled(CardUi)`
  position: relative;
  height: 186px;
  display: flex;
  flex-direction: column;
`;

const Loader = styled(CircularProgress)`
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Image = styled(ImageUi)`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px
    ${({ theme }) => theme.shape.borderRadius}px 0 0;
  height: 140px;
`;

const Action = styled(CardActions)`
  height: 46px;
  border-top: 1px solid ${({ theme }) => theme.palette.grey[200]};
`;

const FooterContent = styled(Box)`
  display: flex;
  gap: 5px;
`;

export type ImageCardType = ImageType & {
  onDeleteClick?: (fileIdentifier: string) => void;
  id: string;
  icon?: React.ReactNode;
  title?: string;
  loadingState?: UploadState;
};

export const ImageCard: React.FC<ImageCardType> = ({
  src,
  id,
  loadingState = UploadState.SUCCESS,
  onDeleteClick,
  icon,
  title,
  ...rest
}) => {
  const translate = useTranslate();

  const onDelete = () => {
    id && onDeleteClick && onDeleteClick(id);
  };

  return (
    <Card {...rest}>
      {[UploadState.PENDING, UploadState.IDLE].includes(loadingState) ? (
        <Loader />
      ) : (
        <>
          <Image src={src} />
          <Action>
            <>
              {!!icon && !!title && (
                <FooterContent>
                  {!!icon && icon}
                  {!!title && (
                    <Typography variant="subtitle1">{title}</Typography>
                  )}
                </FooterContent>
              )}
              {!!onDeleteClick && (
                <Button onClick={onDelete} data-testid="deleteButton">
                  {translate('shared.delete')}
                </Button>
              )}
            </>
          </Action>
        </>
      )}
    </Card>
  );
};
