import React, { useCallback, useState } from 'react';

import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { Divider } from '@mui/material';
import { MobileFooter } from 'components/mobile-footer/MobileFooter';
import { Button } from 'ui/atoms/button/Button';
import { HeaderListProps } from 'ui/molecules/header-list/HeaderList';
import { useListContext, useTranslate } from 'react-admin';
import { Tabs, TabItem } from 'components/tabs/Tabs';

import {
  CloseFilter,
  Container,
  Filters,
  Header,
  HeaderContainer,
  Main,
  SearchBarWrapper,
} from './FilteredListLayout.styles';

type FilterListLayoutType = {
  main: React.ReactElement;
  filters?: React.ReactElement;
  searchBar?: React.ReactElement;
  tabs?: TabItem[];
  onTabChange?: (_event: React.SyntheticEvent, resource: string) => void;
} & Omit<HeaderListProps, 'onFilterClick'>;

export const FilteredListLayout: React.FC<FilterListLayoutType> = ({
  main,
  filters,
  tabs,
  onTabChange,
  onAddClick,
  title,
  addTitle,
  searchBar,
}) => {
  const [openFilter, setOpenFilter] = useState<boolean | null>(null);
  const translate = useTranslate();

  const { resource } = useListContext();

  const toggleOpenFilter = useCallback(() => {
    setOpenFilter((prevState) => !prevState);
  }, []);

  return (
    <>
      <Container>
        <HeaderContainer className="header">
          <Header
            title={title}
            addTitle={addTitle}
            onAddClick={onAddClick}
            onFilterClick={toggleOpenFilter}
          />
          <Divider />
        </HeaderContainer>

        {!!filters && (
          <Filters $openfilter={openFilter}>
            <CloseFilter
              onClick={toggleOpenFilter}
              startIcon={<CloseIcon />}
              data-testid="closeButton"
            >
              {translate('ra.action.close')}
            </CloseFilter>
            {filters}
          </Filters>
        )}
        <Main $hasSearchBar={!!searchBar} $hasTabs={!!tabs}>
          <>
            {!!searchBar && <SearchBarWrapper>{searchBar}</SearchBarWrapper>}
            {!!tabs && (
              <Tabs tabs={tabs} value={resource} onTabChange={onTabChange} />
            )}
            {main}
          </>
        </Main>
      </Container>
      <MobileFooter>
        <Button
          className="addButton"
          data-testid="add-button"
          variant="contained"
          onClick={onAddClick}
          startIcon={<AddIcon />}
        >
          {addTitle}
        </Button>
      </MobileFooter>
    </>
  );
};
