import { CheckboxProps } from 'ui/molecules/checkbox/Checkbox';
import { Translate } from 'react-admin';
import { RealEstateType, RentalSituations } from 'types/realEstate.enum';

import { RENTING_KINDS, STATUSES } from 'constants/real-estates';

export const getTypesCheckboxes = (
  translate: Translate
): Omit<CheckboxProps, 'onChange' | 'checked'>[] => [
  {
    label: translate('realEstate.type.appartment'),
    value: RealEstateType.Apartment,
  },
  {
    label: translate('realEstate.type.house'),
    value: RealEstateType.House,
  },
  {
    label: translate('realEstate.type.building'),
    value: RealEstateType.Building,
  },
];

export const getRentingTypesCheckboxes = (
  translate: Translate
): Omit<CheckboxProps, 'onChange' | 'checked'>[] => [
  {
    label: translate('realEstate.location.type.simple'),
    value: RENTING_KINDS.SIMPLE,
  },
  {
    label: translate('realEstate.location.type.colocation'),
    value: RENTING_KINDS.COLOCATION,
  },
];

export const getStatusCheckboxes = (
  isAdvisor: boolean,
  translate: Translate
): Omit<CheckboxProps, 'onChange' | 'checked'>[] => [
  ...(!isAdvisor
    ? [
        {
          label: translate('realEstate.status.draft'),
          value: STATUSES.DRAFT,
        },
        {
          label: translate('realEstate.status.in_review'),
          value: STATUSES.IN_REVIEW,
        },
      ]
    : []),
  {
    label: translate('realEstate.status.available'),
    value: STATUSES.AVAILABLE,
  },
  {
    label: translate('realEstate.status.transaction'),
    value: STATUSES.TRANSACTION,
  },
  {
    label: translate('realEstate.status.sold'),
    value: STATUSES.SOLD,
  },
  {
    label: translate('realEstate.status.archived'),
    value: STATUSES.ARCHIVED,
  },
];

export const getFurnishedCheckboxes = (
  translate: Translate
): Omit<CheckboxProps, 'onChange' | 'checked'>[] => [
  {
    label: translate('realEstate.isFurnished.furnished'),
    value: 'Furnished',
  },
  {
    label: translate('realEstate.isFurnished.notFurnished'),
    value: 'Unfurnished',
  },
];

export const getShortTermRentalCheckboxes = (
  translate: Translate
): Omit<CheckboxProps, 'onChange' | 'checked'>[] => [
  { label: translate('realEstate.rental.shortTerm'), value: 'shortTerm' },
  { label: translate('realEstate.rental.classic'), value: 'classic' },
];

export const getRentalSituationCheckboxes = (
  translate: Translate
): Omit<CheckboxProps, 'onChange' | 'checked'>[] => [
  {
    label: translate('realEstate.rentalSituation.unknown'),
    value: RentalSituations.Unknown,
  },
  {
    label: translate('realEstate.rentalSituation.empty'),
    value: RentalSituations.Empty,
  },
  {
    label: translate('realEstate.rentalSituation.partlyRented'),
    value: RentalSituations.PartlyRented,
  },
  {
    label: translate('realEstate.rentalSituation.fullyRented'),
    value: RentalSituations.FullyRented,
  },
];
