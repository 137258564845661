import { RoleName } from 'types/role.types';

export const ROLES = [
  { label: 'dev', value: RoleName.Dev },
  { label: 'admin', value: RoleName.Admin },
  { label: 'advisor', value: RoleName.Advisor },
  {
    label: 'independentAssetManagementConsultant',
    value: RoleName.IndependentAssetManagementConsultant,
  },
  {
    label: 'assetManagementConsultant',
    value: RoleName.AssetManagementConsultant,
  },
  { label: 'hunter', value: RoleName.Hunter },
  { label: 'hunterManager', value: RoleName.HunterManager },
  { label: 'user', value: RoleName.User },
  { label: 'rentalBuildingManager', value: RoleName.RentalBuildingManager },
  { label: 'advisorManager', value: RoleName.AdvisorManager },
  { label: 'animator', value: RoleName.Animator },
  { label: 'mandator', value: RoleName.Mandator },
];
