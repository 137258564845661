import { BaseSyntheticEvent, useCallback } from 'react';

import { useListContext } from 'react-admin';
import { FiltersActions } from 'ui/organisms/filters/FiltersActions';
import { useForm, FormProvider, Resolver } from 'react-hook-form';

import { Container, FiltersContainer } from './Filters.styles';

const filterFormId = 'filterForm';

export type FiltersType = {
  defaultValues: Record<string, unknown>;
  children: React.ReactNode;
  resolver?: Resolver;
};

export const Filters: React.FC<FiltersType> = ({
  defaultValues,
  children,
  resolver,
}) => {
  const { setFilters, filterValues } = useListContext();

  const form = useForm<Record<string, unknown>>({
    defaultValues: filterValues,
    mode: 'onChange',
    resolver,
  });

  const handleResetFilters = useCallback(() => {
    setFilters({ ...defaultValues, search: filterValues.search }, []);
    form.reset(defaultValues, { keepDefaultValues: false, keepValues: false });
  }, [defaultValues, filterValues.search, form, setFilters]);

  const onSubmit = useCallback(
    (value: Record<string, unknown>) => {
      setFilters({ search: filterValues.search, ...value }, []);
    },
    [filterValues.search, setFilters]
  );

  // Prevent "Promise-returning function provided to attribute where a void return was expected." sonarCloud error
  const handleSubmit = useCallback(
    (event: BaseSyntheticEvent) => {
      form
        .handleSubmit(onSubmit)(event)
        .then(() =>
          form.reset({ ...form.getValues(), search: filterValues.search })
        )
        .finally(() => null);
    },
    [form, filterValues.search, onSubmit]
  );

  return (
    <Container data-testid="filters-form-container">
      <FormProvider {...form}>
        <FiltersContainer>
          <form id={filterFormId} onSubmit={handleSubmit}>
            {children}
          </form>
        </FiltersContainer>
        <FiltersActions
          applyButtonProps={{
            form: filterFormId,
            type: 'submit',
          }}
          resetFilters={handleResetFilters}
          isDirty={form.formState.isDirty}
        />
      </FormProvider>
    </Container>
  );
};
