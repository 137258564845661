import React, { useCallback, useEffect, useState } from 'react';

import {
  ArrayInput,
  SelectInput,
  SimpleFormIterator as SimpleFormIteratorRA,
  useInput,
  useTranslate,
} from 'react-admin';
import { Button, styled } from '@mui/material';
import { SynthesisItem } from 'types/realEstate.types';
import { SynthesisTheme } from 'types/realEstate.enum';
import { TextArea } from 'ui/atoms/textarea/TextArea';
import { TFunction } from 'react-i18next';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import {
  SYNTHESIS_MIN_LENGTH,
  getDefaultValues,
} from 'features/realEstates/details/edit/RealEstateEdit.utils';
// eslint-disable-next-line max-len
import { sourceToLabelMap } from 'features/realEstates/details/edit/components/edit-form/edit-form-content/EditFormContent.utils';

type ChoiceType = {
  disabled: boolean;
  id: string;
  name: string;
};

const SimpleFormIterator = styled(SimpleFormIteratorRA)`
  & .RaSimpleFormIterator-buttons > .RaSimpleFormIterator-add {
    width: 100%;
  }

  & .RaSimpleFormIterator-line {
    border-bottom: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    & .RaSimpleFormIterator-inline {
      display: grid;
      grid-template-columns: 0.5fr 1fr;

      & .MuiFormControl-root {
        grid-column: auto;
      }
    }
  }
`;

const getThemeChoices = (translate: TFunction): ChoiceType[] => {
  return Object.entries(SynthesisTheme)
    .map(([_, value]) => ({
      disabled: false,
      id: value,
      name: translate(`realEstate.synthesis.theme.${value.toLowerCase()}`),
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const SynthesisInput: React.FC<{ disabled?: boolean }> = ({
  disabled = false,
}) => {
  const translate = useTranslate();

  const [choices, setChoices] = useState(getThemeChoices(translate));

  const { field } = useInput({
    defaultValue: getDefaultValues(),
    source: 'synthesis',
  });

  const handleSetChoices = useCallback(() => {
    setChoices((currentChoices) =>
      currentChoices.map((choice: ChoiceType) => ({
        ...choice,
        disabled: field.value?.some(
          (val: SynthesisItem) => val.theme === choice.id
        ),
      }))
    );
  }, [field.value]);

  useEffect(() => {
    handleSetChoices();
  }, [handleSetChoices]);

  return (
    <ArrayInput
      source="synthesis"
      label={false}
      className="fullColumns"
      disabled={disabled}
    >
      <SimpleFormIterator
        inline
        fullWidth
        addButton={
          <Button
            sx={{ padding: '10px', width: '100%' }}
            startIcon={<ControlPointIcon />}
            variant="outlined"
            fullWidth
          >
            {translate('realEstate.synthesis.addThemeLabel')}
          </Button>
        }
        disableRemove={field.value.length <= SYNTHESIS_MIN_LENGTH}
      >
        <SelectInput
          label={sourceToLabelMap.get('synthesis.theme')}
          source="theme"
          variant="outlined"
          onBlur={handleSetChoices}
          choices={choices}
          defaultValue={null}
          parse={(value) => (value === '' ? null : value)}
        />
        <TextArea
          source="description"
          label={sourceToLabelMap.get('synthesis.description')}
          minRows={2}
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
