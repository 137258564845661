import { Translate } from 'react-admin';
import { BackendSupportedLanguage } from 'types/backend-supported-language.types';

interface formatNumberParams {
  locale: string;
  value: number;
}

export const formatCurrency = ({ locale, value }: formatNumberParams): string =>
  new Intl.NumberFormat(locale, {
    currency: 'EUR',
    maximumFractionDigits: 0,
    style: 'currency',
  }).format(value);

export const formatPercent = ({ locale, value }: formatNumberParams): string =>
  new Intl.NumberFormat(locale, {
    maximumFractionDigits: 2,
    style: 'percent',
  }).format(value / 100);

export const dateOptions: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  month: 'numeric',
  year: '2-digit',
};

interface formatDateParams {
  locale: string;
  date: string | Date;
  options?: Intl.DateTimeFormatOptions;
}

export const formatDate = ({
  locale,
  date,
  options = {},
}: formatDateParams): string => {
  return new Date(date).toLocaleString(locale, { ...dateOptions, ...options });
};

type getFormattedHeaderDateParams = {
  createdAt: string | Date;
  updatedAt?: string | Date;
  locale: string;
  translate: Translate;
};

export const getHeaderTimeStampString = ({
  createdAt,
  updatedAt,
  locale,
  translate,
}: getFormattedHeaderDateParams): string => {
  const createdAtString = formatDate({
    date: createdAt,
    locale,
    options: { hour: undefined, minute: undefined },
  });
  const updatedAtString = updatedAt
    ? formatDate({ date: updatedAt, locale })
    : null;
  return `${translate('shared.createdOn', {
    date: createdAtString,
    interpolation: { escapeValue: false },
  })} ${
    updatedAtString
      ? ` - ${translate('shared.editedOn', {
          date: updatedAtString,
          interpolation: { escapeValue: false },
        })}`
      : ''
  }`;
};

export const getBackendSupportedLanguageFromLocale = (
  locale: string
): BackendSupportedLanguage => {
  if (locale.startsWith('fr')) {
    return 'FR';
  }

  return 'EN';
};
