import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  width: 100%;
  height: calc(100% - 37px); // minus close button height
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 8px;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    height: 100%;
  }
`;

export const FiltersContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  overflow: auto;
  margin: 0;
  padding: 0;
`;
