import { SynthesisTheme } from 'types/realEstate.enum';

export const SYNTHESIS_MIN_LENGTH = 4;

export const getDefaultValues: () => {
  synthesis: {
    description: string;
    theme: SynthesisTheme | null;
  }[];
} = () => ({
  synthesis: Array.from({ length: SYNTHESIS_MIN_LENGTH }, () => ({
    description: '',
    theme: null,
  })),
});
