import { styled } from '@mui/material';

export const DPE_RANGE_INPUT_MARK_SIZE = 24;

export const DpeRangeInputMarkContainer = styled('span')`
  align-items: center;
  border-radius: ${DPE_RANGE_INPUT_MARK_SIZE / 2}px;
  color: white;
  display: inline-flex;
  line-height: ${DPE_RANGE_INPUT_MARK_SIZE}px;
  font-size: 14px;
  height: ${DPE_RANGE_INPUT_MARK_SIZE}px;
  justify-content: center;
  width: ${DPE_RANGE_INPUT_MARK_SIZE}px;
`;
