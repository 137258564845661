import {
  firstNameSchema,
  lastNameSchema,
} from 'features/customers/validators/customer.validator';
import { addressSchema } from 'features/realEstates/validators/realEstate.validator';
import * as yup from 'yup';

const budgetMaxSchema = yup
  .number()
  .min(1, 'customer.inputErrors.budgetMaxMin')
  .nullable()
  .required('customer.inputErrors.budgetMaxRequired');

const birthDateErrorMessage = 'shared.inputErrors.birthDateRequired';

export const generateExternalCustomerSearchMandateSchema = yup
  .object()
  .shape({
    address: addressSchema,
    birthCity: yup
      .string()
      .nullable()
      .required('shared.inputErrors.birthCityRequired'),
    birthDate: yup
      .date()
      .typeError(birthDateErrorMessage)
      .required(birthDateErrorMessage),
    downPayment: yup
      .number()
      .min(0)
      .typeError('shared.inputErrors.numberTypeError')
      .nullable()
      .required('customer.inputErrors.downPaymentRequired'),
    firstName: firstNameSchema,
    lastName: lastNameSchema,
    search: yup
      .object()
      .shape({
        budgetMax: budgetMaxSchema,
      })
      .required(),
  })
  .required();
