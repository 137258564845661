import { styled } from '@mui/material';
import { DataGrid as MUIDataGrid } from '@mui/x-data-grid';

export const DataGrid = styled(MUIDataGrid)`
  background-color: white;

  && {
    .MuiDataGrid-columnHeaderTitle:first-letter,
    .MuiDataGrid-columnsPanelRow .MuiFormControlLabel-label:first-letter {
      text-transform: uppercase;
    }

    .MuiDataGrid-columnSeparator,
    .MuiDataGrid-columnSeparator--sideRight {
      display: none !important;
    }

    .MuiDataGrid-columnHeaderTitle {
      font-weight: bold !important;
    }

    .MuiDataGrid-row {
      cursor: pointer;
    }
  }
`;
