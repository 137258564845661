import {
  RealizationFieldValidationError,
  RealizationValidationException,
} from 'types/realization.types';
import { useFormContext } from 'react-hook-form';

export const getErrorTranslationKey = (
  error: RealizationFieldValidationError
): string => {
  const { path } = error;

  return `realization.action.publishValidation.fields.${path}`;
};

export const handleUpdateStatusActionErrors = (
  {
    exception,
  }: {
    exception: RealizationValidationException;
  },
  formContext?: ReturnType<typeof useFormContext>
): void => {
  const handleFieldError = (
    error: RealizationFieldValidationError,
    index: number
  ) => {
    const key = getErrorTranslationKey(error);
    const path = error.path.replaceAll('.props', '');

    formContext?.setValue(path, formContext?.getValues(path), {
      shouldTouch: true,
    });

    formContext?.setError(path, { message: key }, { shouldFocus: index === 0 });
  };

  if (
    exception?.context?.cause?.inner &&
    Array.isArray(exception?.context?.cause?.inner)
  ) {
    exception?.context?.cause?.inner.forEach(handleFieldError);
  }
};
