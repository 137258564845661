import { frFR, enUS, GridLocaleText } from '@mui/x-data-grid';
import { useLocaleState } from 'react-admin';
import { Localization } from '@mui/x-data-grid/utils/getGridLocalization.d';

type languagesType = {
  [n: string]: Localization;
};

export const DATAGRID_LANGUAGE: languagesType = {
  'en-US': enUS,
  'fr-FR': frFR,
};

export const useDataGridLanguage: () => Partial<GridLocaleText> = () => {
  const [locale] = useLocaleState();

  return DATAGRID_LANGUAGE[locale]
    ? DATAGRID_LANGUAGE[locale].components.MuiDataGrid.defaultProps.localeText
    : DATAGRID_LANGUAGE['en-US'].components.MuiDataGrid.defaultProps.localeText;
};
