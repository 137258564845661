import {
  HttpError,
  useDataProvider,
  useEditContext,
  useNotify,
  useRefresh,
} from 'react-admin';
import { useMutation } from 'react-query';
import { HeliosDataProvider } from 'providers/admin/dataProvider';
import { ArchiveBody } from 'types/customMethods.types';
import { ActionMutationReturn } from 'types/common.types';

import { useRealEstateDetailPermissions } from './useRealEstateDetailPermissions';

export type ArchiveMutationReturn = ActionMutationReturn<
  void,
  unknown,
  ArchiveBody,
  unknown
>;

export const useArchiveAction = (): ArchiveMutationReturn => {
  const { record } = useEditContext();
  const refresh = useRefresh();
  const dataProvider = useDataProvider<HeliosDataProvider>();
  const notify = useNotify();

  const { canArchive } = useRealEstateDetailPermissions();

  const mutation = useMutation(
    ['archive', record?.id, dataProvider],
    (variables: ArchiveBody) => {
      return dataProvider
        ?.archive(record?.id, variables)
        .then((response: unknown) => {
          if (response) {
            refresh();
            notify('realEstate.action.archive.success', {
              type: 'success',
            });
          }
        })
        .catch((err: HttpError) => {
          const message =
            err.status === 403
              ? `realEstate.action.${err.message}`
              : 'ra.notification.http_error';

          notify(message, {
            type: 'error',
          });
        });
    }
  );
  return { ...mutation, isAllowed: canArchive };
};
