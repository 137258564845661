import { useMemo } from 'react';

import {
  Admin as RAdmin,
  Authenticated,
  CustomRoutes,
  Resource,
} from 'react-admin';
import { AppLayout } from 'ui/layouts/app-layout/AppLayout';
import { auth } from 'features/auth/auth.provider';
import { HelpCenter } from 'features/helpCenter/HelpCenter';
import { Notifier } from 'components/notifier/Notifier';
import { Route } from 'react-router-dom';
import { ResetPassword } from 'features/resetPassword/ResetPassword';
import { StepEmail } from 'features/resetPassword/stepEmail/StepEmail';
import { StepCode } from 'features/resetPassword/stepCode/StepCode';
import { StepRenewPassword } from 'features/resetPassword/stepRenewPassword/StepRenewPassword';
import { RealEstateEdit } from 'features/realEstates/details/edit/RealEstateEdit';
import { RealEstatesList } from 'features/realEstates/list/RealEstatesList';
import { UsersList } from 'features/users/list/UsersList';
import { PositioningsList } from 'features/positioning/list/PositioningsList';
import { RealEstatePositioning } from 'features/realEstates/details/positionings/RealEstatePositioning';
import { CustomersList } from 'features/customers/list/CustomersList';
import { TransactionsList } from 'features/transactions/list/TransactionsList';
import { TransactionsShow } from 'features/transactions/show/TransactionsShow';
import { PermissionName } from 'types/permission.types';
import { RolesListWithPermission } from 'features/roles/list/RolesList';
import { OrganizationsList } from 'features/organizations/list/OrganizationsList';
import { OrganizationEdit } from 'features/organizations/edit/OrganizationEdit';
import { OrganizationsCreate } from 'features/organizations/create/OrganizationsCreate';
import { RealEstateBookmarks } from 'features/realEstates/details/bookmarks/RealEstateBookmarks';
import { Activities } from 'features/activities/Activities';
import { CustomerBookmarks } from 'features/customers/details/bookmarks/CustomerBookmarks';
import { CustomerEdit } from 'features/customers/details/edit/CustomerEdit';
import { CustomerPositionings } from 'features/customers/details/postionings/CustomerPositionings';
import { CustomerTransactions } from 'features/customers/details/transactions/CustomerTransactions';
import { RealEstateTransactions } from 'features/realEstates/details/transactions/RealEstateTransactions';
import { RealizationsList } from 'features/realizations/list/RealizationsList';
import { RealizationEdit } from 'features/realizations/details/edit/RealizationEdit';
import { RealEstatesListCGP } from 'features/realEstates/list/RealEstatesListCGP';
import { ExternalCustomersList } from 'features/external-customers/list/ExternalCustomersList';
import { ExternalCustomerEdit } from 'features/external-customers/details/edit/ExternalCustomerEdit';
import { CGPTransactionsList } from 'features/cgp-transactions/list/CGPTransactionsList';
import { CGPTransactionsShow } from 'features/cgp-transactions/show/CGPTransactionsShow';

import { URI } from 'constants/uri';

import { dataProvider } from './dataProvider';
import { authProvider } from './authProvider';
import { getI18nProvider } from './i18nProvider';
import { themeProvider } from './themeProvider';
import { RealEstateHistory } from 'features/realEstates/details/history/RealEstateHistory';

export const Admin: React.FC = () => {
  const i18nProvider = useMemo(() => getI18nProvider(), []);

  return (
    <RAdmin
      dashboard={Activities}
      notification={Notifier}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      theme={themeProvider}
      layout={AppLayout}
      loginPage={auth.login}
      disableTelemetry
    >
      <CustomRoutes>
        <Route path="/activities" element={<Activities />} />
      </CustomRoutes>
      <Resource
        name="real-estates"
        list={RealEstatesList}
        edit={RealEstateEdit}
      >
        <Route path=":id/positionings" element={<RealEstatePositioning />} />
        <Route path=":id/bookmarks" element={<RealEstateBookmarks />} />
        <Route path=":id/transactions" element={<RealEstateTransactions />} />
        <Route path=":id/diff" element={<RealEstateHistory />} />
      </Resource>
      <Resource name="real-estates-cgp" list={RealEstatesListCGP} />
      <Resource
        name="transactions"
        list={TransactionsList}
        show={TransactionsShow}
      />
      <Resource
        name="transactions-cgp"
        list={CGPTransactionsList}
        show={CGPTransactionsShow}
      />
      <Resource name="customers" list={CustomersList} edit={CustomerEdit}>
        <Route path=":id/bookmarks" element={<CustomerBookmarks />} />
        <Route path=":id/positionings" element={<CustomerPositionings />} />
        <Route path=":id/transactions" element={<CustomerTransactions />} />
      </Resource>
      <Resource
        name="roles"
        list={
          <RolesListWithPermission
            permission={PermissionName.ListRoles}
            redirectTo={URI.home}
          />
        }
      />
      <Resource name="users" list={UsersList} />
      <Resource name="positionings" list={PositioningsList} />
      <Resource
        name="organizations"
        list={OrganizationsList}
        create={OrganizationsCreate}
        edit={OrganizationEdit}
      />
      <Resource
        name="realizations"
        list={RealizationsList}
        edit={RealizationEdit}
      />
      <Resource
        name="external-customers"
        list={ExternalCustomersList}
        edit={ExternalCustomerEdit}
      />
      <CustomRoutes>
        <Route
          path="/help-center"
          element={
            <Authenticated requireAuth>
              <HelpCenter />
            </Authenticated>
          }
        />
      </CustomRoutes>
      <CustomRoutes>
        <Route path="reset-password" element={<ResetPassword />}>
          <Route path="email" element={<StepEmail />} />
          <Route path="code" element={<StepCode />} />
          <Route path="new-password" element={<StepRenewPassword />} />
        </Route>
      </CustomRoutes>
    </RAdmin>
  );
};
