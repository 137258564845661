import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';
import { HeliosDataProvider } from 'providers/admin/dataProvider';
import {
  HttpError,
  useDataProvider,
  useEditContext,
  useNotify,
  useRefresh,
} from 'react-admin';
import { ActionMutationReturn } from 'types/common.types';
import { UpdateRealizationStatusBody } from 'types/customMethods.types';
import { useMutation } from 'react-query';
import { PermissionName } from 'types/permission.types';
import { useFormContext } from 'react-hook-form';

import { handleUpdateStatusActionErrors } from '../utils/updateStatusAction.utils';

export type UpdateStatusMutationReturn = ActionMutationReturn<
  void,
  unknown,
  UpdateRealizationStatusBody,
  unknown
>;

export const useUpdateStatusAction = (
  formContext?: ReturnType<typeof useFormContext>
): UpdateStatusMutationReturn => {
  const { record } = useEditContext();
  const refresh = useRefresh();
  const dataProvider = useDataProvider<HeliosDataProvider>();
  const notify = useNotify();

  const { hasPermission } = useHeliosPermissions();

  const mutation = useMutation(
    ['change-status', record?.id, dataProvider],
    (variables: UpdateRealizationStatusBody) => {
      return dataProvider
        ?.updateRealizationStatus(record?.id, variables)
        .then((response: unknown) => {
          if (response) {
            refresh();
            notify('realization.action.success', {
              type: 'success',
            });
          }
        })
        .catch((err: HttpError) => {
          switch (err.status) {
            case 430:
              handleUpdateStatusActionErrors(err.body, formContext);
              break;
            case 403:
              notify(`realization.action.${err.message}`, {
                type: 'error',
              });
              break;
            default:
              notify('ra.notification.http_error', {
                type: 'error',
              });
          }
          throw err;
        });
    }
  );
  return {
    ...mutation,
    isAllowed: hasPermission(PermissionName.UpdateRealization),
  };
};
