import { useCallback, useEffect, useMemo, useState } from 'react';

import { isArray, uniqueId } from 'lodash';
import { FileDropZone } from 'ui/molecules/file-drop-zone/FileDropZone';
import { Box, styled } from '@mui/material';
import { useInput, useTranslate, InputHelperText } from 'react-admin';
import { FileRejection } from 'react-dropzone';
import { useNotification } from 'hooks/use-notification/useNotification';
import FormHelperText from '@mui/material/FormHelperText';
import { useFormContext } from 'react-hook-form';

import { getImgExt } from 'constants/images';

import {
  addFiles,
  deleteFile,
  formatErrorMessage,
  DropFile,
  urlToSrc,
} from './utils/imageInput.formater.utils';
import { ImageInputPreview } from '../image-input-preview/ImageInputPreview';

const Container = styled('div')``;

const FileContainer = styled(Box)`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export type ImageFieldType = {
  maxFiles?: number;
  source: string;
  disabled?: boolean;
  isForRealization?: boolean;
};

export const ImageInput: React.FC<ImageFieldType> = ({
  maxFiles,
  source,
  disabled,
  isForRealization = false,
}) => {
  const [files, setFiles] = useState<DropFile[]>([]);

  const input = useInput({ source });
  const translate = useTranslate();
  const { openError } = useNotification();

  const { clearErrors } = useFormContext();

  const {
    field: { onChange, value, ref },
    fieldState,
  } = input;

  const { error } = fieldState;

  useEffect(() => {
    if (!value) {
      return;
    }

    const currentFiles = isArray(value) ? value : [value];
    setFiles(
      currentFiles.map((file) => ({
        id: uniqueId('image'),
        preview: urlToSrc(file) || '',
        url: file,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setValue = useCallback(
    (values: DropFile[]) => {
      const dataValues = values.map((file: DropFile) => file.url);
      setFiles(values);
      onChange(maxFiles === 1 ? dataValues[0] ?? null : dataValues);
      clearErrors(source);
    },
    [clearErrors, maxFiles, onChange, source]
  );

  const HandleOnUpload = useCallback(
    (id: string, url: string) => {
      setFiles((prevFiles) => {
        const updatedFiles = prevFiles.map((file) => {
          if (file.id === id) {
            return {
              ...file,
              preview: urlToSrc(url) || '',
              url,
            } as DropFile;
          }
          return file;
        });

        setValue(updatedFiles);
        return updatedFiles;
      });
    },
    [setValue]
  );

  const onAddFiles = useCallback(
    async (acceptedFiles: File[]) => {
      const acceptedFilesList = await Promise.all(
        acceptedFiles.map(async (newFile): Promise<DropFile> => {
          const newFileId = uniqueId('image');

          return {
            file: newFile,
            id: newFileId,
            preview: '',
            url: '',
          };
        })
      );
      const newFileList = addFiles(files, acceptedFilesList, maxFiles);
      setValue(newFileList);
    },
    [files, maxFiles, setValue]
  );

  const onRejectedFiles = useCallback(
    (rejectedFile: FileRejection[]) => {
      const message = formatErrorMessage(rejectedFile, translate, maxFiles);
      message && openError({ message });
    },
    [translate, maxFiles, openError]
  );

  const onDeleteFile = useCallback(
    (fileIdentifier: string) => {
      const filteredFiles = deleteFile(files, fileIdentifier);
      setValue(filteredFiles);
    },
    [files, setValue]
  );

  const filesCards = useMemo(() => {
    return files.map((file: DropFile) => {
      return (
        <ImageInputPreview
          key={file.id}
          id={file.id}
          file={file.file}
          preview={file.preview}
          onUpload={HandleOnUpload}
          onDeleteClick={onDeleteFile}
          isForRealization={isForRealization}
          data-testid="imageInputPreview"
        />
      );
    });
  }, [HandleOnUpload, files, onDeleteFile, isForRealization]);

  const showDropZone = useMemo(
    () => (maxFiles ? files.length < maxFiles : true),
    [files, maxFiles]
  );

  const accept = {
    'image/*': getImgExt(),
  };

  return (
    <Container>
      <FileContainer>
        {filesCards}
        {!!showDropZone && !disabled && (
          <FileDropZone
            onAddFiles={onAddFiles}
            onRejectedFiles={onRejectedFiles}
            maxFiles={maxFiles}
            source={source}
            accept={accept}
          />
        )}
      </FileContainer>
      <input style={{ opacity: 0 }} type="text" ref={ref} />
      <FormHelperText error={!!error}>
        <InputHelperText touched={!!error} error={error?.message} />
      </FormHelperText>
    </Container>
  );
};
