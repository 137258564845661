import { CheckboxProps } from 'ui/molecules/checkbox/Checkbox';
import { Translate } from 'react-admin';
import { TransactionStatus } from 'types/transaction.types';

export const getStatusCheckboxes = (
  translate: Translate
): Omit<CheckboxProps, 'onChange' | 'checked'>[] => [
  {
    label: translate('transaction.status.ongoing'),
    value: TransactionStatus.ONGOING,
  },
  {
    label: translate('transaction.status.finished'),
    value: TransactionStatus.FINISHED,
  },
  {
    label: translate('transaction.status.canceled'),
    value: TransactionStatus.CANCELED,
  },
];
