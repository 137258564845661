import React from 'react';

import { Box, Button } from '@mui/material';
import { useTransactionPermissions } from 'features/transactions/show/hooks/useTransactionsPermissions';
import { useStore, useTranslate } from 'react-admin';
import { TransactionModalKey } from 'types/transaction.types';

export const ActionButton: React.FC = () => {
  const [, showCancelModal] = useStore(TransactionModalKey.CANCEL, false);
  const translate = useTranslate();

  const { canUpdateProject: canCancelOrUpdate } = useTransactionPermissions();

  console.log({ canCancelOrUpdate });

  const displayCancelModal = () => showCancelModal(true);

  if (!canCancelOrUpdate) {
    return <></>;
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={1}
      data-testid="transaction-action-button-desktop"
    >
      <Button variant="outlined" color="error" onClick={displayCancelModal}>
        {translate('transaction.show.action.cancel')}
      </Button>
    </Box>
  );
};
