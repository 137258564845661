import React from 'react';

import { styled } from '@mui/material/styles';
import {
  useTranslate,
  Create as RACreate,
  useCreateController,
  useNotify,
  useGetList,
} from 'react-admin';
import { Modal, ModalFooterButtonsContainer } from 'ui/organisms/modal/Modal';
import { Button } from 'ui/atoms/button/Button';
import { LoadingButton } from 'ui/molecules/loading-button/LoadingButton';

import { UserForm } from './UserForm';

const Create = styled(RACreate)`
  & .MuiPaper-root {
    box-shadow: none;
    border: none;
  }
`;
interface UserModalFormProps {
  open: boolean;
  onClose: () => void;
  onSuccess?: (id: string) => void;
}

export const AddUserModalForm: React.FC<UserModalFormProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const translate = useTranslate();
  const notify = useNotify();
  const { save, isLoading, saving } = useCreateController({
    resource: 'users',
    transform: (userView) => {
      return { ...userView, organization: userView.organization.id };
    },
  });

  const { data: dataCitiesManager } = useGetList('users/citiesmanagers');

  const computedCitiesManager = dataCitiesManager?.map((cityManager) => ({
    label: cityManager.name,
    value: cityManager.id,
  }));

  const handleSubmit = async (data: Record<string, unknown>) => {
    if (save) {
      save(data, {
        onError: (err) => {
          notify(err?.body?.message || 'user.create.error', {
            type: 'error',
          });
        },
        onSuccess: (response) => {
          onSuccess && onSuccess(response.id);
          notify('user.form.successToaster', { type: 'success' });
        },
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={translate('users.add')}
      footer={
        <ModalFooterButtonsContainer>
          <Button variant="text" onClick={onClose}>
            {translate('ra.action.cancel')}
          </Button>
          <LoadingButton
            loading={isLoading || saving}
            variant="text"
            type="submit"
            form="user-form"
            data-testid="user-form-submit-button"
          >
            {translate('ra.action.add')}
          </LoadingButton>
        </ModalFooterButtonsContainer>
      }
    >
      <Create>
        <UserForm
          handleSubmit={handleSubmit}
          cityManagers={computedCitiesManager}
        />
      </Create>
    </Modal>
  );
};
