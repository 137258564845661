import type {
  GridColDef as MUIGridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from '@mui/x-data-grid';

export enum CellType {
  DATE = 'date',
  DATE_TIME = 'date_time',
  PRICE = 'price',
  PERCENTAGE = 'percentage',
  TAG_LIST = 'tagList',
  TAG_LIST_COL = 'tagList_column',
}

export type GridColDefRequiredFields = 'headerName' | 'field';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GridColDef<T extends GridValidRowModel = any> = RequiredPart<
  MUIGridColDef<T>,
  GridColDefRequiredFields
> & {
  cellType?: CellType;
};

export type GridCellRenderer = (
  params: GridRenderCellParams
) => React.ReactNode;

export type TYPE_CELL_TYPE = {
  [n in CellType]: GridCellRenderer;
};
