import React from 'react';

import { EditContainer } from 'components/detail/edit-container/EditContainer';

import { EditForm } from './components/edit-form/EditForm';
import { ActionContextProvider } from './context/ActionContextProvider';

export const RealizationEdit: React.FC = () => {
  return (
    <EditContainer component="div" mutationMode="pessimistic">
      <ActionContextProvider>
        <EditForm />
      </ActionContextProvider>
    </EditContainer>
  );
};
