import { styled } from '@mui/material';

export const Container = styled('div')`
  position: sticky;
  bottom: 68px;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  display: grid;
  z-index: ${({ theme }) => theme.zIndex.appBar};
  height: 45px;
  border: 1px solid #0000001f;
  border-radius: 4px;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    bottom: 16px;
  }
`;

export const TextContainer = styled('div')`
  padding: 0 12px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #ffffff;
`;
