import { EditContext, EditControllerResult, useNotify } from 'react-admin';
import { ErrorResponse } from 'types/common.types';
import { ExternalCustomerDetail } from 'types/external-customer.types';
import { EditContainer } from 'components/detail/edit-container/EditContainer';

import { EditForm } from './component/edit-form/EditForm';

export const ExternalCustomerEdit: React.FC = () => {
  const notify = useNotify();

  const onSuccess = () => {
    notify('customer.edit.success', { type: 'success' });
  };

  const onError = (err: unknown) => {
    notify((err as ErrorResponse)?.body?.message || 'customer.edit.error', {
      type: 'error',
    });
  };

  return (
    <EditContainer
      component="div"
      mutationMode="pessimistic"
      mutationOptions={{ onError, onSuccess }}
    >
      <EditContext.Consumer>
        {({ record }: EditControllerResult<ExternalCustomerDetail>) => {
          return <EditForm record={record} />;
        }}
      </EditContext.Consumer>
    </EditContainer>
  );
};
