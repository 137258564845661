export enum ArchivingReasons {
  AlreadySold = 'alreadySold',
  Others = 'others',
  SellerRetracts = 'sellerRetracts',
  Auto = 'auto',
}

export enum Dpe {
  NoDiag = 'NoDiag',
  APlus = 'A+',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}

export enum Ges {
  NoDiag = 'NoDiag',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}

export enum Asbestos {
  NoDiag = 'NoDiag',
  One = '1',
  Two = '2',
  Three = '3',
  No = 'No',
}

export enum Termites {
  NoDiag = 'NoDiag',
  Yes = 'Yes',
  No = 'No',
}

export enum DryRot {
  NoDiag = 'NoDiag',
  Yes = 'Yes',
  No = 'No',
  Other = 'Other',
}

export enum LeadDiagnostic {
  NoDiag = 'NoDiag',
  One = '1',
  Two = '2',
  Three = '3',
  No = 'No',
}

export enum Electricity {
  NoDiag = 'NoDiag',
  No = 'No',
  WithoutB7 = 'WithoutB7',
  WithB7 = 'WithB7',
}

export enum Gas {
  NoDiag = 'NoDiag',
  No = 'No',
  A1 = 'A1',
  A2 = 'A2',
  Dgi = 'Dgi',
}

export enum EstimatedWorks {
  HeavyWork = 'HEAVY_WORK',
  None = 'NONE',
  Refresh = 'REFRESH',
  Renovate = 'RENOVATE',
  ThermalEnergyWork = 'THERMAL_ENERGY_WORK',
  Unknown = 'UNKNOWN',
}

export enum LocationType {
  Colocation = 'COLOCATION',
  Simple = 'SIMPLE',
}

export enum RealEstateType {
  Apartment = 'APPARTMENT', // Typo error is present in database, don't change it
  Building = 'BUILDING',
  House = 'HOUSE',
}

export enum RentalSituations {
  Empty = 'EMPTY',
  FullyRented = 'FULLY_RENTED',
  PartlyRented = 'PARTLY_RENTED',
  Unknown = 'UNKNOWN',
}

export enum SupportedLanguage {
  Fr = 'FR',
  En = 'EN-US',
}

export enum MasteosSupportedLanguage {
  Fr = 'FR',
  En = 'EN',
}

export enum AppreciationZone {
  NoData = 'NO_DATA',
  City = 'CITY',
  Neighborhood = 'NEIGHBORHOOD',
}

export enum Strategy {
  Patrimonial = 'PATRIMONIAL',
  Yield = 'YIELD',
}

export type PostalCode = number | string;

export enum SynthesisTheme {
  EnergyPerformance = 'ENERGY_PERFORMANCE',
  Student = 'STUDENT',
  Employment = 'EMPLOYMENT',
  Transport = 'TRANSPORT',
  DiscountedFromZone = 'DISCOUNTED_FROM_ZONE',
  LandValuation = 'LAND_VALUATION',
  HighProfitability = 'HIGH_PROFITABILITY',
  RentalTension = 'RENTAL_TENSION',
  BrightApartment = 'BRIGHT_APARTMENT',
  AdditionalLots = 'ADDITIONAL_LOTS',
  CoOwnershipService = 'CO_OWNERSHIP_SERVICE',
  CoOwnershipManagement = 'CO_OWNERSHIP_MANAGEMENT',
  CoOwnershipWork = 'CO_OWNERSHIP_WORK',
  PublicInvestmentProject = 'PUBLIC_INVESTMENT_PROJECT',
  ExceptionalProperty = 'EXCEPTIONAL_PROPERTY',
  SellerType = 'SELLER_TYPE',
  PropertySituation = 'PROPERTY_SITUATION',
  AlreadyRentedProperty = 'ALREADY_RENTED_PROPERTY',
  PropertyPrice = 'PROPERTY_PRICE',
  Location = 'LOCATION',
}

export interface ServerFieldValidationError {
  path: string;
  message: string;
  type: string;
}

/** Apartment_Sharing, UNKNOWN, CommercialBuilding and Office are deprecated */
export enum PropertyKind {
  Apartment = 'Apartment',
  Building = 'Building',
  House = 'House',
}

export enum RealEstateStatus {
  ARCHIVED = 'ARCHIVED',
  AVAILABLE = 'AVAILABLE',
  DRAFT = 'DRAFT',
  IN_REVIEW = 'IN_REVIEW',
  SOLD = 'SOLD',
  TRANSACTION = 'TRANSACTION',
}

export enum Country {
  FRANCE = 'France',
  SPAIN = 'Spain',
}
