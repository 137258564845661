import React, { useEffect } from 'react';

import { useTranslate, useInput } from 'react-admin';
import usePlacesAutocomplete from 'use-places-autocomplete';
import {
  AutocompleteInput,
  AutocompleteValue,
  AutocompleteInputProps,
} from 'ui/molecules/autocomplete-input/AutocompleteInput';
import { useGooglePlacesScript } from 'hooks/use-google-places-script/useGooglePlacesScript';

import {
  formatPlacesOptions,
  hasError,
} from './GooglePlacesAutocomplete.utils';
import type { RawGooglePlaceSuggestion } from './GooglePlacesAutocomplete.types';

interface GooglePlacesAutocompleteProps {
  label?: React.ReactNode;
  source: string;
  defaultValue?: string;
  onChange?: (
    place: string,
    rawData: Nullable<RawGooglePlaceSuggestion>
  ) => void;
  size?: AutocompleteInputProps<string>['size'];
  fullWidth?: boolean;
  disabled?: boolean;
  transform?: (param: string) => string;
  countryRestrictions?: boolean;
}

export const GooglePlacesAutocomplete: React.FC<
  GooglePlacesAutocompleteProps
> = ({
  label = 'shared.location.address',
  source,
  onChange,
  fullWidth = false,
  size,
  defaultValue,
  disabled,
  transform,
  countryRestrictions,
}) => {
  const { fieldState, field } = useInput({ source });
  const translate = useTranslate();
  const {
    init,
    value,
    setValue,
    suggestions: { data: places },
  } = usePlacesAutocomplete({
    initOnMount: false,
    requestOptions: {
      componentRestrictions: {
        country: countryRestrictions ? ['fr', 'es', 'be'] : null,
      },
    },
  });

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    } else {
      setValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useGooglePlacesScript({
    onLoad: init,
  });

  const selectPlace = (
    newValue: Nullable<AutocompleteValue<RawGooglePlaceSuggestion>>
  ) => {
    if (!newValue) {
      return;
    }

    field.onChange(
      transform ? transform(newValue.value) : { street: newValue.value }
    );
    onChange && onChange(newValue.value, newValue.rawData);
  };

  const handleOnBlur = async (
    newValue: Nullable<AutocompleteValue<RawGooglePlaceSuggestion>>
  ) => {
    if (!newValue) {
      return;
    }

    field.onChange(
      transform ? transform(newValue.value) : { street: newValue.value }
    );
    onChange && onChange(newValue.value, places[0]);
  };

  const onInputChange = (newValue: string) => {
    setValue(newValue);
  };

  const filterOptions = (
    options: AutocompleteValue<RawGooglePlaceSuggestion>[]
  ) => {
    return options;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error = fieldState?.error as Record<string, any>;

  return (
    <AutocompleteInput
      disabled={disabled}
      size={size}
      value={{ label: value, value: value }}
      label={translate(label as string)}
      options={formatPlacesOptions(places)}
      onSelect={selectPlace}
      onBlur={handleOnBlur}
      filterOptions={filterOptions}
      onInputChange={onInputChange}
      error={
        hasError(error)
          ? translate(
              error?.street?.message ||
                error?.message ||
                'shared.location.invalid_address'
            )
          : undefined
      }
      fullWidth={fullWidth}
    />
  );
};
