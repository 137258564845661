import { Positioning } from 'types/positioning.types';
import { Bookmark } from 'types/bookmark.types';
import { formatFullName } from 'utils/strings/strings.utils';

type formattedRowData = {
  id: string;
  statusChangedAt?: Date;
  createdAt?: Date;
  'customer.fullName': string;
  'realEstate.address.city'?: string;
  'realEstate.address.street'?: string;
};

function isPositioningType(data: Positioning | Bookmark): data is Positioning {
  return (data as Positioning).statusChangedAt !== undefined;
}

export const formatRowData = (
  data?: Positioning[] | Bookmark[]
): formattedRowData[] => {
  if (!data || data.length === 0) {
    return [];
  }

  return data.map((d) => ({
    'customer.fullName': formatFullName(
      d.customer.firstName,
      d.customer.lastName
    ),
    id: d.id,
    'realEstate.address.city': d.realEstate?.address.city,
    'realEstate.address.street': d.realEstate?.address.street,
    ...(isPositioningType(d)
      ? { statusChangedAt: d.statusChangedAt }
      : { createdAt: d.createdAt }),
  }));
};
