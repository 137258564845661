import { GridValueGetterParams } from '@mui/x-data-grid';
import { Positioning } from 'types/positioning.types';
import { formatFullName } from 'utils/strings/strings.utils';
import { get } from 'lodash';

export const getFullNameFormatter = (path: string) => {
  return ({ row }: GridValueGetterParams<Positioning, Positioning>): string => {
    const baseUser = get(row, path);

    if (!baseUser || !baseUser.lastName || !baseUser.firstName) {
      return '-';
    }

    const { firstName, lastName } = baseUser;
    return formatFullName(firstName, lastName);
  };
};
