import React from 'react';

import {
  ArrayInput,
  AutocompleteInput,
  Button,
  DateInput,
  ReferenceInput,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import { Territory } from 'types/organizations.types';
import { formatTerritoriesOptionText } from 'features/organizations/utils/formatTerritoriesOptionText';

import {
  SimpleFormIterator,
  TerritoriesContainer,
} from './TerritoriesForm.styles';

export const TerritoriesForm: React.FC = () => {
  const translate = useTranslate();

  const record = useRecordContext();

  const getDisabledChoice = (choice: Territory) =>
    !!choice.organization && choice.organization !== record?.id;

  return (
    <TerritoriesContainer>
      <Fieldset title={translate('createOrganization.fieldset.territories')}>
        <ArrayInput source="territories" label={false} className="fullColumns">
          <SimpleFormIterator
            inline
            fullWidth
            addButton={
              <Button
                sx={{ padding: '10px', width: '100%' }}
                startIcon={<ControlPointIcon />}
                variant="outlined"
                fullWidth
                label={translate('createOrganization.territories.addTerritory')}
              />
            }
            disableReordering
            disableClear
          >
            <ReferenceInput
              reference="organizations/territories"
              source="territoryId"
            >
              <AutocompleteInput
                label="createOrganization.territories.territoryLabel"
                variant="outlined"
                optionText={formatTerritoriesOptionText}
                getOptionDisabled={getDisabledChoice}
              />
            </ReferenceInput>
            <DateInput
              source="effectiveDate"
              variant="outlined"
              label="createOrganization.territories.effectiveDateLabel"
              defaultValue={null}
              isRequired
            />
            <DateInput
              source="endDate"
              variant="outlined"
              label="createOrganization.territories.endDateLabel"
              defaultValue={null}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Fieldset>
    </TerritoriesContainer>
  );
};
