import { HeliosDataProvider } from 'providers/admin/dataProvider';
import {
  HttpError,
  useDataProvider,
  useNotify,
  useRefresh,
  useShowContext,
} from 'react-admin';
import { useMutation } from 'react-query';
import { TransactionCancelBody } from 'types/transactionCustomMethods.types';
import { ActionMutationReturn } from 'types/common.types';

export type TransactionCancelMutationReturn = ActionMutationReturn<
  void,
  unknown,
  TransactionCancelBody,
  unknown
>;

export const useCancelAction = (): TransactionCancelMutationReturn => {
  const { record } = useShowContext();
  const refresh = useRefresh();
  const dataProvider = useDataProvider<HeliosDataProvider>();
  const notify = useNotify();

  const mutation = useMutation(
    ['cancelTransaction', record?.id, dataProvider],
    (data: TransactionCancelBody): Promise<void> => {
      return dataProvider
        ?.cancelTransaction(record?.id, data)
        .then((response: unknown) => {
          if (response) {
            refresh();
            notify('transaction.action.cancel.success', {
              type: 'success',
            });
          }
        })
        .catch((err: HttpError) => {
          const message =
            err.status === 403
              ? `transaction.action.${err.message}`
              : 'ra.notification.http_error';

          notify(message, {
            type: 'error',
          });
        });
    }
  );
  return { ...mutation, isAllowed: true };
};
