import { styled } from '@mui/material/styles';
import { Box, Paper } from '@mui/material';
import { HeaderList } from 'ui/molecules/header-list/HeaderList';
import { Button } from 'ui/atoms/button/Button';

export const SearchBarWrapper = styled('div')`
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin-top: 17px;
  }
`;

export const Container = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'header'
    'main';

  max-height: calc(100vh - 110px);
  height: calc(100vh - 110px);

  .addButton {
    display: none;
  }

  .filterButton {
    display: flex;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    grid-template-columns: 1fr;
    position: relative;
    overflow: hidden;
  }

  // Tablette
  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    .addButton {
      display: flex;
    }
  }

  // PC
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    max-height: calc(100vh - 80px);
    height: calc(100vh - 80px);

    gap: 24px;
    grid-template-areas:
      'header header'
      'filters main';

    .addButton {
      display: flex;
    }

    .filterButton {
      display: none;
    }
  }
`;

export const HeaderContainer = styled(Box)`
  grid-area: header;
  margin-top: 24px;
`;

export const Header = styled(HeaderList)`
  margin-bottom: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin-bottom: 15px;
  }
`;

export const CloseFilter = styled(Button)`
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    display: none;
  }
`;

export const Filters = styled(Paper, {
  shouldForwardProp: (prop) => prop !== '$openfilter',
})`
  grid-area: filters;
  background-color: white;
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.drawer};
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  max-width: 500px;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 0;

  > div {
    width: 100%;
  }

  transform: translateX(+100%);

  animation: ${({ $openfilter }: { $openfilter: Nullable<boolean> }) =>
      $openfilter === null
        ? ''
        : $openfilter
        ? 'drawerSlideRightIn'
        : 'drawerSlideRightOut'}
    300ms ease-in forwards;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    right: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    display: flex;
    position: relative;
    height: 100%;
    max-height: 100%;
    max-width: 250px;
    right: auto;
    z-index: 0;
    transform: none !important;
    border-radius: 4px;
  }
`;

export const Main = styled(Box, {
  shouldForwardProp: (prop) =>
    !['$hasSearchBar', '$hasTabs'].includes(prop as string),
})`
  grid-area: main;
  display: grid;
  row-gap: 24px;
  grid-template-rows: ${({
    $hasSearchBar,
    $hasTabs,
  }: {
    $hasSearchBar: Nullable<boolean>;
    $hasTabs: Nullable<boolean>;
  }) =>
    $hasSearchBar && $hasTabs
      ? 'auto auto 1fr'
      : $hasSearchBar || $hasTabs
      ? 'auto 1fr'
      : '1fr'};
  margin-top: ${({ $hasSearchBar }: { $hasSearchBar: Nullable<boolean> }) =>
    $hasSearchBar ? '0' : '17px'};

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin: 0;
  }
`;
