import jwtDecode from 'jwt-decode';

import { ACCESS_TOKEN_STORAGE_KEY, getTokenByKey } from './tokenStorage';

export interface MasteosUserIdentity {
  email: string;
  firstName: string;
  isMandatary: boolean;
  lastName: string;
  sub: string;
  roles: string[];
  organization: string;
}

export interface MasteosUserIdentityResult {
  email: string;
  organization: string;
  fullName: string;
  id: string;
  isMandatary: boolean;
  roles: string[];
}

export const getIdentity = (): Promise<MasteosUserIdentityResult> => {
  try {
    const identity = jwtDecode<MasteosUserIdentity>(
      getTokenByKey(ACCESS_TOKEN_STORAGE_KEY)
    );
    const {
      email,
      firstName,
      isMandatary,
      lastName,
      sub: id,
      roles,
      organization,
    } = identity;
    return Promise.resolve({
      email,
      fullName: `${firstName} ${lastName}`,
      id,
      isMandatary,
      organization,
      roles,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
