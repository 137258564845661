import React, { useCallback } from 'react';

import { Modal } from 'ui/organisms/modal/Modal';
import { UrlField, useEditContext, useStore, useTranslate } from 'react-admin';
import { Button } from '@mui/material';
import { RealEstateModalKey } from 'features/realEstates/constants';
import { useFormContext } from 'react-hook-form';
import { RealEstate } from 'types/realEstate.types';
import { useRealEstateActions } from 'features/realEstates/details/edit/hooks/useRealEstateActions';

import { RealEstateEditAction } from './constants';

export interface PublishConfirmationModalProps {
  isOpen: boolean;
  action?: RealEstateEditAction;
}

export const PublishConfirmationModal: React.FC = () => {
  const translate = useTranslate();
  const [{ isOpen, action }, setShowPublishConfirmationModal] =
    useStore<PublishConfirmationModalProps>(
      RealEstateModalKey.PUBLISH_CONFIRMATION,
      { isOpen: false }
    );

  const formContext = useFormContext();
  const editContext = useEditContext();
  const formValues = formContext?.getValues() as RealEstate;
  const { doPublishCallback, askPublishCallback } = useRealEstateActions(
    formContext,
    editContext
  );

  const onClose = useCallback(() => {
    setShowPublishConfirmationModal({ action: undefined, isOpen: false });
  }, [setShowPublishConfirmationModal]);

  const handleOnContinue = useCallback(() => {
    onClose();
    switch (action) {
      case RealEstateEditAction.AskPublish:
        askPublishCallback?.();
        break;
      case RealEstateEditAction.Publish:
        doPublishCallback?.();
        break;
    }
  }, [action, askPublishCallback, doPublishCallback, onClose]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={translate('publish.confirmation.title')}
      footer={
        <>
          <Button variant="text" onClick={onClose}>
            {translate('publish.confirmation.cancel')}
          </Button>
          <Button variant="text" onClick={handleOnContinue}>
            {translate('publish.confirmation.confirm')}
          </Button>
        </>
      }
    >
      <p>{translate('publish.confirmation.text')}</p>
      <UrlField
        target="_blank"
        source="url3D"
        record={{ url3D: formValues?.url3D }}
      />
    </Modal>
  );
};
