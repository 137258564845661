import { styled, Typography } from '@mui/material';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 4px;
`;

export const Label = styled(Typography)`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.common.black};
`;
