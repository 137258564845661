import { Button as MuiButton, ButtonProps, styled } from '@mui/material';

const Container = styled(MuiButton)`
  display: flex;
  align-items: center;

  span {
    height: 100%;
  }
`;

export const Button: React.FC<ButtonProps> = ({
  onClick,
  children,
  ...rest
}) => (
  <Container onClick={onClick} {...rest}>
    <span>{children}</span>
  </Container>
);
