import { isObject } from 'lodash';
import { RaRecord } from 'react-admin';
import { CustomerAddress, CustomerCivility } from 'types/customer.types';
import { ChoiceProps } from 'types/form.type';
import { PhoneNumberUtil } from 'google-libphonenumber';

export const sanitizeData = <T extends RaRecord>(data: T): T => {
  const sanitizedData = {} as RaRecord;
  for (const key in data) {
    let value: unknown = data[key];
    if (typeof data[key] === 'string' && data[key].trim().length === 0) {
      value = null;
    }
    sanitizedData[key] = value;
  }

  return sanitizedData as T;
};

type SelectIem = {
  id: string;
  name: string;
};

/**
 * Order select by alphabetic order and keep 'OTHER' as last option
 */
export const orderSelect = (itemA: SelectIem, itemB: SelectIem): number => {
  if (itemA.id === 'OTHER') {
    return 1;
  }
  if (itemB.id === 'OTHER') {
    return -1;
  }
  return itemA.name > itemB.name ? 1 : -1;
};

export const sanitizeRecord = <T extends RaRecord>(data: T): T => {
  const sanitizedRecord = {} as RaRecord;
  for (const key in data) {
    sanitizedRecord[key] = data[key] === null ? '' : data[key];
  }

  return sanitizedRecord as T;
};

interface Address {
  street: string;
  city: string;
  country: string;
}

// Format coreV1
export const formatFullStreet = (address: Address): string => {
  return `${address.street}, ${address.city}, ${address.country}`;
};

// Format coreV2
export const formatCustomerAddress = (address?: CustomerAddress): string => {
  if (!address || (isObject(address) && !Object.keys(address).length)) {
    return '';
  }

  return `${address.street || ''}, ${address.postalCode || ''} ${
    address.city || ''
  }, ${address.countryName || address.countryCode || ''}`;
};

export const formatChoiceList = (
  list: { [n: string]: string },
  tradKey: string
): ChoiceProps =>
  Object.values(list).map((key) => ({
    id: key,
    name: `${tradKey}.${key.toLowerCase()}`,
  }));

// Validate Siret number
export const validateRegistrationNumber = (
  registrationNumber?: string | null
): boolean => {
  if (!registrationNumber) {
    return true;
  }

  if (registrationNumber.length !== 14) {
    return false;
  }
  let sum = 0;
  let digit: number;
  for (let i = 0; i < registrationNumber.length; i++) {
    if (i % 2 === 0) {
      digit = parseInt(registrationNumber.charAt(i), 10) * 2;
      digit = digit > 9 ? digit - 9 : digit;
    } else {
      digit = parseInt(registrationNumber.charAt(i), 10);
    }
    sum += digit;
  }
  return sum % 10 === 0;
};

export const validatePhoneNumber = (phone?: string): boolean => {
  return validatePhoneNumberWithOptions(phone, false);
};

export const validatePhoneNumberWithOptions = (
  phone?: string,
  notRequired?: boolean
): boolean => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  if (!phone && notRequired) {
    return true;
  }
  if (!phone) {
    return false;
  }
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export const getCivilityChoiceList = (): ChoiceProps =>
  formatChoiceList(CustomerCivility, 'customer.civility');
