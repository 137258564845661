import { JWTDecoded } from 'types/jwt.types';

import { decodeToken } from './decodeToken';
import { identityApi } from './identityApi';
import { persistTokens } from './tokenStorage';

export const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<JWTDecoded> =>
  identityApi(`/authenticate`, {
    app: 'helios',
    email,
    password,
  })
    .then(persistTokens)
    .then(decodeToken);
