import React, { useState } from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  styled,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useStore, useTranslate } from 'react-admin';
import { CustomerDrawerKey, MandateLocal } from 'types/customer.types';

// eslint-disable-next-line no-restricted-imports
import { useGenerateExternalCustomerSearchMandateAction } from '../../generate-external-customer-search-mandate-drawer/useGenerateExternalCustomerSearchMandateAction.hook';

const Activator = styled('div')`
  min-width: fit-content;
  width: fit-content;
  cursor: pointer;
  padding: 0;
  width: 100%;
`;

export const ActionButton: React.FC = () => {
  const translate = useTranslate();
  const [, showSearchMandateDrawer] = useStore(
    CustomerDrawerKey.GENERATE_SEARCH_MANDATE,
    [false, 'FR_fr'] as [boolean, string]
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = (): void => {
    setAnchorEl(null);
  };
  const handleClick = () => {
    showSearchMandateDrawer([true, MandateLocal.FR_fr]);
    closeMenu();
  };
  const { isLoading } = useGenerateExternalCustomerSearchMandateAction();

  return (
    <Box display="flex" flexDirection="row" gap={1}>
      <Activator
        onClick={openMenu}
        color="inherit"
        aria-controls="language-menu"
        aria-label=""
        aria-haspopup="true"
      >
        <ButtonGroup variant="outlined" disabled={isLoading}>
          <Button variant="outlined">
            {translate('customer.actions.titleButton')}
          </Button>
          <Button size="small">
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      </Activator>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem onClick={handleClick}>
          {translate('customer.actions.generateSearchMandate.label_FR_fr')}
        </MenuItem>
      </Menu>
    </Box>
  );
};
