import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import { TextArea } from 'ui/atoms/textarea/TextArea';

import { Container } from './InternalNoteSection.styles';

export const InternalNoteSection: React.FC = () => {
  return (
    <Container>
      <Fieldset>
        <TextArea
          fullWidth
          label="shared.internalNote"
          source="internalNote"
          minRows={3}
        />
      </Fieldset>
    </Container>
  );
};
