import { ButtonProps } from '@mui/material';
import { useSaveContext } from 'react-admin';
import { useFormState } from 'react-hook-form';
import { LoadingButton } from 'ui/molecules/loading-button/LoadingButton';

interface SaveButtonProps extends ButtonProps {
  label: string;
  name?: string;
  isFetching?: boolean;
  alwaysEnabled?: boolean;
  disabledIfNotValid?: boolean;
  enableIfNotDirty?: boolean;
}

export const SaveButton: React.FC<SaveButtonProps> = ({
  alwaysEnabled,
  label,
  isFetching,
  name,
  variant = 'contained',
  disabledIfNotValid,
  enableIfNotDirty,
  ...rest
}) => {
  const { saving } = useSaveContext();
  const formState = useFormState({ name });
  const { isDirty, isValidating, isSubmitting, isValid } = formState;

  const disabled =
    !alwaysEnabled &&
    ((!isDirty && !enableIfNotDirty) ||
      isValidating ||
      saving ||
      isSubmitting ||
      isFetching ||
      (!isValid && disabledIfNotValid));

  return (
    <LoadingButton
      variant={variant}
      disabled={disabled}
      loading={saving || isFetching}
      type="submit"
      {...rest}
    >
      {label}
    </LoadingButton>
  );
};
