import { validatePhoneNumber } from 'utils/form/form.utils';
import * as yup from 'yup';

export const firstNameSchema = yup
  .string()
  .nullable()
  .required('shared.inputErrors.firstNameRequired');
export const lastNameSchema = yup
  .string()
  .nullable()
  .required('shared.inputErrors.lastNameRequired');
export const phoneNumberSchema = yup
  .string()
  .required('shared.inputErrors.phoneNumberRequired')
  .test(
    'is-international-phone-number',
    'shared.inputErrors.invalidPhoneNumber',
    validatePhoneNumber
  );
