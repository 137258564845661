import React from 'react';

import { FieldValues } from 'react-hook-form';
import { Button } from '@mui/material';
import { Modal, ModalFooterButtonsContainer } from 'ui/organisms/modal/Modal';
import { LoadingButton } from 'ui/molecules/loading-button/LoadingButton';
import {
  Create,
  useCreateController,
  useNotify,
  useRefresh,
  useStore,
  useTranslate,
} from 'react-admin';
import { RealizationModalKey } from 'types/realization.types';

import { AddRealizationForm } from './AddRealizationForm';

export type AddRealizationModalStore = {
  isOpen: boolean;
};

type RealizationCreate = {
  realEstateId: string;
};

export const AddRealizationModal: React.FC = () => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();

  const { save, isLoading, saving } = useCreateController<
    RealizationCreate,
    Error
  >({
    resource: 'realizations',
  });

  const [{ isOpen }, setAddRealizationModalStore] =
    useStore<AddRealizationModalStore>(RealizationModalKey.CREATE_REALIZATION, {
      isOpen: false,
    });

  const onClose = () => {
    setAddRealizationModalStore({ isOpen: false });
  };

  const handleSubmit = (data: FieldValues) => {
    if (save) {
      save(data, {
        onError: (err) => {
          notify(err.body.message, {
            type: 'error',
          });
        },
        onSuccess: () => {
          refresh();
          notify('realization.create.success', {
            type: 'success',
          });
          onClose();
        },
      });
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={translate('realization.add')}
      footer={
        <ModalFooterButtonsContainer>
          <Button variant="text" onClick={onClose}>
            {translate('ra.action.cancel')}
          </Button>
          <LoadingButton
            loading={isLoading || saving}
            variant="text"
            type="submit"
            form="realization-create-form"
            data-testid="realization-create-submit-button"
          >
            {translate('ra.action.add')}
          </LoadingButton>
        </ModalFooterButtonsContainer>
      }
    >
      <Create>
        <AddRealizationForm onSubmit={handleSubmit} />
      </Create>
    </Modal>
  );
};
