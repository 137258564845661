import { styled } from '@mui/material';
import { getPositionementStatusColor } from 'features/positioning/positionings.utils';
import { PositioningStatus } from 'types/positioning.types';
import { ReplayCircleFilled } from '@mui/icons-material';

export const Container = styled('div')`
  display: flex;
  width: 100%;
`;

export const ContainerButton = styled('div')`
  flex: 0 0 50%;
  display: flex;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    .actionButtonGroupDisabledButton {
      display: none;
    }
  }
`;

export const ReplayCircleFilledStyled = styled(ReplayCircleFilled)`
  color: ${(props) =>
    props.color ||
    getPositionementStatusColor(PositioningStatus.Recommendation)};
`;
