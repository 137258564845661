import React from 'react';

import { FormContainer } from 'features/organizations/styles/organizations.styles';
import { TextInput, useRecordContext } from 'react-admin';
import { SectionContainer } from 'ui/molecules/section-container/SectionContainer';
import { OrganizationUser } from 'types/organizations.types';
import { FormDivider } from 'ui/atoms/form-divider/FormDivider';

import { OrganizationUsersTable } from '../organization-users-table/OrganizationUsersTable';
import { OrganizationTerritoriesTable } from '../organization-territories-table/OrganizationTerritoriesTable';
import { TerritoriesForm } from '../territories-form/TerritoriesForm';

export const OrganizationForm: React.FC<{ mode?: 'edit' | 'create' }> = ({
  mode = 'create',
}) => {
  const record = useRecordContext();
  const formattedUsers =
    record?.users.map((user: OrganizationUser) => user.email) ?? [];

  return (
    <FormContainer>
      <SectionContainer>
        <TextInput
          isRequired
          fullWidth
          margin="none"
          variant="outlined"
          label="organization.input.name"
          source="name"
          data-testid="organization-input-name"
        />
        <TextInput
          fullWidth
          margin="none"
          variant="outlined"
          label="organization.input.siret"
          source="siret"
          data-testid="organization-input-siret"
        />
        {mode === 'edit' ? (
          <OrganizationTerritoriesTable />
        ) : (
          <TerritoriesForm />
        )}

        {formattedUsers.length ? (
          <>
            <FormDivider flexItem />
            <OrganizationUsersTable users={formattedUsers} />
          </>
        ) : (
          <></>
        )}
      </SectionContainer>
    </FormContainer>
  );
};
