import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'components/form/Form';
import { TextInput } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { Box, styled } from '@mui/material';
import { Select } from 'ui/molecules/select/Select';
import { MasteosSupportedLanguage } from 'types/realEstate.enum';

import { addRealizationFormValidator } from './AddRealizationForm.validator';

type AddRealizationFormProps = {
  onSubmit: (data: FieldValues) => void;
};

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const AddRealizationForm: React.FC<AddRealizationFormProps> = ({
  onSubmit,
}) => {
  return (
    <Form
      onSubmit={onSubmit}
      id="realization-create-form"
      resolver={yupResolver(addRealizationFormValidator)}
    >
      <Container>
        <TextInput
          margin="none"
          variant="outlined"
          label="realization.inputs.realEstateId"
          source="realEstateId"
        />
        <Select
          label="realization.inputs.titleLang"
          choices={Object.values(MasteosSupportedLanguage).map((language) => ({
            id: language,
            name: language,
          }))}
          source="titleLang"
          variant="outlined"
          margin="none"
          inputProps={{ 'data-testid': 'lang-select' }}
        />
        <TextInput
          margin="none"
          variant="outlined"
          label="realization.title"
          source="title"
        />
      </Container>
    </Form>
  );
};
