import { ServerFieldValidationError } from 'types/realEstate.enum';
import { PublishValidationException } from 'types/realEstate.types';
import { useFormContext } from 'react-hook-form';

export const publishErrorIgnoredFields: string[] = [
  'financialModel.projectAmount',
  'financialModel.annualTotalIncome',
  'financialModel.annualTotalExpenses',
  'financialModel.annualLoanPayments',
  'financialModel.annualTotalEarnings',
  'financialModel.netReturn',
  'financialModel.grossReturn',
];
export const handlePublishActionErrors = (
  {
    exception,
    message,
  }: {
    exception: PublishValidationException;
    message: string;
  },
  formContext?: ReturnType<typeof useFormContext>
): void => {
  const handleFieldError = (
    error: ServerFieldValidationError,
    index: number
  ) => {
    if (!publishErrorIgnoredFields.includes(error.path)) {
      const key = getErrorTranslationKey(error);
      formContext?.setValue(error.path, formContext?.getValues(error.path), {
        shouldTouch: true,
      });
      formContext?.setError(
        error.path,
        { message: key },
        { shouldFocus: index === 0 }
      );
    }
  };

  if (exception?.cause?.inner && Array.isArray(exception?.cause?.inner)) {
    exception?.cause?.inner.forEach(handleFieldError);
  }
  if (message.startsWith('URL is not')) {
    formContext?.setError('url3D', { message }, { shouldFocus: true });
  }
};

export function getErrorTranslationKey(
  error: ServerFieldValidationError
): string {
  const { type, path } = error;

  if (path.includes('synthesis')) {
    if (path.includes('theme')) {
      return 'realEstate.synthesis.error.themeRequired';
    }
    if (path.includes('description')) {
      return `realEstate.synthesis.error.description${
        type === 'max' ? 'MaxLength' : 'Required'
      }`;
    }
  }

  if (path === 'type') {
    return `realEstate.form.propertyKind.notInList`;
  }

  if (['max', 'min'].includes(type)) {
    const typeFormatted = type.charAt(0).toUpperCase() + type.slice(1);
    return `realEstate.publishValidation.fields.${error.path}${typeFormatted}`;
  }

  return `realEstate.publishValidation.fields.${path}`;
}
