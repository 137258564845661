import Resizer from 'react-image-file-resizer';
import { get } from 'lodash';
import { FileRejection } from 'react-dropzone';
import { Translate } from 'react-admin';
import heic2any from 'heic2any';

import {
  ERROR_TOO_MANY_FILES,
  ERROR_TYPE_INVALID,
  getImgExt,
  RESIZE_PARAMS,
} from 'constants/images';

export const IMAGEKIT_ENDPOINT = 'https://ik.imagekit.io/masteos';

export const resizeFile = (file: File | Blob): Promise<string> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      RESIZE_PARAMS.MAX_SIZE,
      RESIZE_PARAMS.MAX_SIZE,
      RESIZE_PARAMS.FORMAT,
      RESIZE_PARAMS.COMPRESSION,
      0,
      (uri: unknown) => {
        typeof uri === 'string' && resolve(uri);
      },
      'base64'
    );
  });

export const convertHeicToJpeg = async (file: File): Promise<Blob | Blob[]> => {
  return heic2any({
    blob: file,
    toType: 'image/jpg',
  }).catch(() => {
    throw new Error(`Failed to convert ${file.name}`);
  });
};

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || '');
    reader.onerror = (error) => {
      reject(new Error(`Failed to load ${error?.target?.result}`));
    };
  });
};

/** @TODO See with the backend so that it sends us the complete url */
export const fromS3ID = (s3id: string, tr = 'w-50'): string | undefined =>
  s3id ? `${IMAGEKIT_ENDPOINT}/tr:ar-3-2,${tr}/${s3id}` : undefined;

export interface DropFile {
  id: string;
  preview: string;
  file?: File;
  url: string;
}

export const addFiles = (
  currentFiles: DropFile[],
  newFiles: DropFile[],
  maxFiles?: number
): DropFile[] => {
  let newFileList = [...currentFiles, ...newFiles];

  if (maxFiles && newFileList.length > maxFiles) {
    newFileList = newFileList.slice(0, maxFiles);
  }

  return newFileList;
};

export const deleteFile = (
  files: DropFile[],
  fileIdentifier: string
): DropFile[] => {
  return files.filter((file) => file.id !== fileIdentifier);
};

export const urlToSrc = (
  url: string | undefined,
  { width }: { width: number } = { width: 500 }
): string | undefined => {
  if (!url) {
    return undefined;
  }

  return !url.startsWith('data') ? fromS3ID(url, `w-${width}`) : url;
};

export const hasErrorCode = (
  file: FileRejection,
  codeError: string
): boolean => {
  const invalidTypeFind = file.errors.find((error) => error.code === codeError);
  return !!invalidTypeFind;
};

export const formatMessageInvalidType = (
  filename: string[],
  translate: Translate
): string => {
  return translate('shared.images.error.invalidType', {
    count: filename.length,
    ext: getImgExt(),
    filename: filename.join(', '),
  });
};

export const formatErrorMessage = (
  fileError: FileRejection[],
  translate: Translate,
  maxFiles?: number
): string => {
  const errors: { [n: string]: string[] } = {
    [ERROR_TOO_MANY_FILES]: [],
    [ERROR_TYPE_INVALID]: [],
  };

  fileError.forEach((file) => {
    if (hasErrorCode(file, ERROR_TOO_MANY_FILES)) {
      errors[ERROR_TOO_MANY_FILES].push(get(file, 'file.name'));
    }
    if (hasErrorCode(file, ERROR_TYPE_INVALID)) {
      errors[ERROR_TYPE_INVALID].push(get(file, 'file.name'));
    }
  });

  if (errors[ERROR_TOO_MANY_FILES].length) {
    return translate('shared.images.error.tooManyFiles', {
      files_count: errors[ERROR_TOO_MANY_FILES].length,
      max_files: maxFiles,
    });
  }

  return formatMessageInvalidType(errors[ERROR_TYPE_INVALID], translate);
};
