import { ProfileForm } from './profile-form/ProfileForm';
import { Container } from './EditFormContent.styles';
import { DocumentList } from './document-list/DocumentList';
import { InternalNoteForm } from './internal-note/InternalNoteForm';

export const EditFormContent: React.FC = () => {
  return (
    <Container>
      <ProfileForm defaultExpanded={true} />
      <InternalNoteForm defaultExpanded={true} />
      <DocumentList defaultExpanded={true} />
    </Container>
  );
};
