import { PaperProps as MUIPaperProps, styled } from '@mui/material';

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 24px;
  width: 100%;
  word-wrap: break-word;
  height: 100%;
`;

export const defaultPaperProps: MUIPaperProps = {
  style: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    maxWidth: 622,
    overflowY: 'auto',
    paddingBottom: 40,
    paddingLeft: 40,
    paddingRight: 40,
    top: 0,
    width: '100vw',
  },
};
