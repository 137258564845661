import {
  DateField as RaField,
  DateFieldProps,
  useLocaleState,
} from 'react-admin';
import { withLabel } from 'ui/hoc/with-label/withLabel';

export const DateField = ({
  date,
  options,
  ...rest
}: {
  date?: Date | string;
  options?: Intl.DateTimeFormatOptions;
} & DateFieldProps): React.ReactElement => {
  const [locale] = useLocaleState();
  return (
    <RaField
      options={options}
      locales={locale}
      source="date"
      showTime
      {...(date && { record: { date } })}
      {...rest}
    />
  );
};

export const DateFieldWithLabel = withLabel(DateField);
