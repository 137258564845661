import { ChangeEvent, useMemo } from 'react';

import {
  AutocompleteInput,
  RaRecord,
  SelectInput,
  TextInput,
  useInput,
  useTranslate,
} from 'react-admin';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useFormContext } from 'react-hook-form';
import {
  EstimatedRenovation,
  InvoiceMethod,
  ReasonNoWork,
} from 'types/project.types';
import {
  AutocompleteRenderGroupParams,
  Box,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { useTransactionPermissions } from 'features/transactions/show/hooks/useTransactionsPermissions';
import { FormDivider } from 'ui/atoms/form-divider/FormDivider';
import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import { Tooltip } from 'ui/molecules/tooltip/Tooltip';

import {
  ESTIMATED_RENOVATION_CHOICES,
  INVOICE_METHOD_CHOICES,
  INVOICE_RECIPIENT_CHOICES,
  REASON_NO_WORK_CHOICES,
} from 'constants/project';

import { Container } from './WorkSection.styles';
import { InvoiceDataForm } from '../invoice-data-form/InvoiceDataForm';

export const WorkSection: React.FC = () => {
  const { canUpdateInvoiceMethod, canUpdateProject: canCancelOrUpdate } =
    useTransactionPermissions();
  const translate = useTranslate();
  const { setValue } = useFormContext();
  const { field: estimatedRenovationField } = useInput({
    source: 'renovation.estimatedRenovation',
  });
  const { field: reasonNoWorkField } = useInput({
    source: 'renovation.reasonNoWork',
  });
  const { field: invoiceMethodField } = useInput({
    source: 'renovation.invoiceMethod',
  });
  const { field: invoiceRecipientField } = useInput({
    source: 'renovation.invoiceData.invoiceRecipient',
  });

  const onChangeEstimatedRenovation = ({
    target,
  }: ChangeEvent<HTMLInputElement> | RaRecord) => {
    if (
      ![EstimatedRenovation.No, EstimatedRenovation.Furnitures].includes(
        target.value
      )
    ) {
      setValue('renovation.reasonNoWork', null);
      setValue('renovation.comments', null);
      if (
        !invoiceMethodField.value ||
        invoiceMethodField.value === InvoiceMethod.None
      ) {
        setValue('renovation.invoiceMethod', InvoiceMethod.Choice1);
      }
    } else {
      setValue('renovation.invoiceMethod', InvoiceMethod.None);
    }
  };

  const onChangeReason = (value: string) => {
    if (value !== ReasonNoWork.Other) {
      setValue('renovation.comments', null);
    }
  };

  const noWorkPlanned = useMemo(
    () =>
      [EstimatedRenovation.No, EstimatedRenovation.Furnitures].includes(
        estimatedRenovationField.value
      ),
    [estimatedRenovationField.value]
  );

  return (
    <Container>
      <Fieldset>
        <SelectInput
          isRequired
          label="project.form.estimatedRenovationLabel"
          onChange={onChangeEstimatedRenovation}
          source="renovation.estimatedRenovation"
          choices={ESTIMATED_RENOVATION_CHOICES}
          variant="outlined"
          data-testid="estimatedRenovation-input"
          disabled={!canCancelOrUpdate}
        />
        <AutocompleteInput
          label="project.form.reasonNoWorkLabel"
          groupBy={(option: RaRecord) => option.category}
          renderGroup={(params: AutocompleteRenderGroupParams) => (
            <li key={params.key}>
              <Box marginLeft={1}>
                <Typography color="GrayText">
                  {translate(`project.form.reasonNoWorkCat.${params.group}`)}
                </Typography>
              </Box>

              <Box>{params.children}</Box>
            </li>
          )}
          optionText={(param: RaRecord) =>
            translate(`project.form.reasonNoWork.${param.id}`)
          }
          isRequired
          onChange={onChangeReason}
          disabled={!noWorkPlanned}
          source="renovation.reasonNoWork"
          choices={REASON_NO_WORK_CHOICES}
          variant="outlined"
          sx={{ gridColumn: 'auto / span 2' }}
          data-testid="reasonNoWork-input"
        />
        <TextInput
          isRequired
          label="project.form.commentsLabel"
          placeholder={translate('project.form.commentsPlaceholder')}
          disabled={reasonNoWorkField.value !== ReasonNoWork.Other}
          multiline
          rows={5}
          source="renovation.comments"
          variant="outlined"
          fullWidth
          data-testid="comments-input"
        />
      </Fieldset>
      {!noWorkPlanned && (
        <>
          <FormDivider />
          <Fieldset>
            <SelectInput
              isRequired
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip
                      title={translate('project.form.invoiceMethodTooltip')}
                    >
                      <IconButton size="small" component="span">
                        <ErrorOutlineIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              label={translate('project.form.invoiceMethodLabel')}
              disabled={
                !estimatedRenovationField.value || !canUpdateInvoiceMethod
              }
              optionText={(param: RaRecord) =>
                translate(`project.form.invoiceMethod.${param.id}`)
              }
              source="renovation.invoiceMethod"
              choices={INVOICE_METHOD_CHOICES}
              variant="outlined"
              data-testid="invoiceMethod-input"
            />
          </Fieldset>
          <FormDivider />
          <Fieldset>
            <SelectInput
              isRequired
              fullWidth
              label="project.form.invoiceData.invoiceRecipientLabel"
              optionText={(param: RaRecord) =>
                translate(`project.form.invoiceRecipient.${param.id}`)
              }
              source="renovation.invoiceData.invoiceRecipient"
              choices={INVOICE_RECIPIENT_CHOICES}
              variant="outlined"
              data-testid="invoiceRecipient-input"
            />
          </Fieldset>
          {!!invoiceRecipientField.value && (
            <InvoiceDataForm recipient={invoiceRecipientField.value} />
          )}
        </>
      )}
    </Container>
  );
};
