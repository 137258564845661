import React from 'react';

import { useEditContext, useSaveContext, useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { LoadingButton } from 'ui/molecules/loading-button/LoadingButton';
import { useRealEstateActions } from 'features/realEstates/details/edit/hooks/useRealEstateActions';

export const PublishButton: React.FC = () => {
  const translate = useTranslate();
  const formContext = useFormContext();
  const editContext = useEditContext();
  const saveContext = useSaveContext();

  const { actionList, askPublish, doPublish } = useRealEstateActions(
    formContext,
    editContext,
    saveContext
  );

  const loadingItem = actionList.find((item) => item.loading);

  const handleAskPublish = () => {
    const action = actionList.find((a) => a.slug === 'askPublish');
    action?.callback?.();
  };

  const handleDoPublish = () => {
    const action = actionList.find((a) => a.slug === 'doPublish');
    action?.callback?.();
  };

  const showLoader =
    loadingItem?.loading ||
    formContext.formState.isSubmitting ||
    editContext.saving;

  const renderButton = () => {
    if (doPublish.isAllowed) {
      return (
        <LoadingButton
          loading={showLoader}
          variant="outlined"
          onClick={handleDoPublish}
          data-testid="publish-btn"
        >
          {translate('realEstate.action.doPublish.label')}
        </LoadingButton>
      );
    }

    if (askPublish.isAllowed) {
      return (
        <LoadingButton
          variant="outlined"
          onClick={handleAskPublish}
          loading={showLoader}
        >
          {translate('realEstate.action.askPublish.label')}
        </LoadingButton>
      );
    }

    return <></>;
  };

  return renderButton();
};
