import { get } from 'lodash';

export const REPOSITIONING_ERROR_TRAD = {
  'positioning.error.limitReached':
    'positioning.userlist.error.hasMaxPositioning',
} as {
  [key: string]: string;
};

export const extractError = (error: string): string => {
  return get(REPOSITIONING_ERROR_TRAD, error, error);
};
