import * as React from 'react';
import { useMemo } from 'react';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { BreadCrumbsProps } from './BreadCrumbs.types';

export const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ steps }) => {
  const breadCrumbSteps = useMemo(
    () =>
      steps.map(({ label, path }) =>
        path ? (
          <Typography variant="body2" key={label}>
            <Link underline="hover" color="inherit" href={path}>
              {label}
            </Link>
          </Typography>
        ) : (
          <Typography variant="body2" key={label}>
            {label}
          </Typography>
        )
      ),
    [steps]
  );

  return (
    <Breadcrumbs aria-label="breadcrumb" separator=">">
      {breadCrumbSteps}
    </Breadcrumbs>
  );
};
