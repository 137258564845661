import React, { useState } from 'react';

import {
  EmailField,
  TextField,
  useDataProvider,
  useNotify,
  useTranslate,
} from 'react-admin';
import { withLabel } from 'ui/hoc/with-label/withLabel';
import { UrlFieldWithLabel } from 'ui/atoms/url-field/UrlField';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { CircularProgress } from '@mui/material';
import { HeliosDataProvider } from 'providers/admin/dataProvider';

import { Container } from './InformationsSection.styles';
import { InformationsSectionProps } from './InformationsSection.types';

const TextFieldWithLabel = withLabel(TextField);
const EmailFieldWithLabel = withLabel(EmailField);

export const InformationsSection: React.FC<InformationsSectionProps> = ({
  adresse,
  propertyId,
  advisorEmail,
  advisorFullName,
  hunterEmail,
  hunterFullName,
  investorUrl,
  userFullName,
  searchMandate,
  realEstateTitle,
  secondInvestorFullName,
  observations,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();

  const dataProvider = useDataProvider<HeliosDataProvider>();

  const downloadDocumentFile = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    setIsLoading(true);

    if (!searchMandate?.id) {
      setIsLoading(false);
      return;
    }

    dataProvider
      .downloadDocument(searchMandate?.id)
      .catch((e: Error) =>
        notify(e.message, {
          type: 'error',
        })
      )
      .finally(() => setIsLoading(false));
  };

  return (
    <Container>
      <div className="address">
        <TextFieldWithLabel
          label={translate('transaction.show.section.label.projectAddress')}
          variant="body2"
          component="span"
          source="property.address"
          record={{ 'property.address': adresse }}
        />
      </div>

      <div className="investor">
        <UrlFieldWithLabel
          label={translate('transaction.show.section.label.investor')}
          source="user.fullName"
          record={{
            'user.fullName': `${userFullName}${
              secondInvestorFullName ? ` / ${secondInvestorFullName}` : ''
            }`,
          }}
          href={investorUrl}
        />
        <EmailFieldWithLabel
          label={translate('transaction.show.section.label.email')}
          source="user.email"
        />
        {!!searchMandate && (
          <UrlFieldWithLabel
            disabled={isLoading}
            data-testid="searchMandate-download-link"
            label={translate('transaction.show.section.label.download_mandate')}
            source="searchMandate"
            record={{
              searchMandate: translate('transaction.show.documents.download'),
            }}
            icon={
              isLoading ? (
                <CircularProgress size={25} />
              ) : (
                <InsertDriveFileOutlinedIcon />
              )
            }
            onClick={downloadDocumentFile}
            href="#"
          />
        )}
      </div>

      <div className="property">
        <UrlFieldWithLabel
          label={translate('transaction.show.section.label.projectLink')}
          source="property.title"
          record={{ 'property.title': realEstateTitle }}
          href={`/real-estates/${propertyId}`}
        />
        <UrlFieldWithLabel
          label={translate('transaction.show.section.label.hunter')}
          source="property.hunter.fullName"
          record={{ 'property.hunter.fullName': hunterFullName }}
          href={`/users?${encodeURI(`filter={"search":"${hunterEmail}"}`)}`}
        />
        <UrlFieldWithLabel
          label={translate('transaction.show.section.label.advisor')}
          source="advisor.fullName"
          record={{ 'advisor.fullName': advisorFullName }}
          href={`/users?${encodeURI(`filter={"search":"${advisorEmail}"}`)}`}
        />
      </div>

      {observations ? (
        <div className="observations">
          <TextFieldWithLabel
            label="Observations"
            source="observations"
            record={{ observations: observations }}
          />
        </div>
      ) : null}
    </Container>
  );
};
