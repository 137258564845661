import { FieldValues } from 'react-hook-form';
import { Form, useLogin, useNotify, useTranslate } from 'react-admin';
import { useSearchParams } from 'react-router-dom';
import { AuthCard } from 'ui/organisms/auth-card/AuthCard';
import { LoginCard } from 'ui/organisms/login-card/LoginCard';
import { AppLayout } from 'ui/layouts/app-layout/AppLayout';
import { segmentIdentify } from 'libs/segment/segment';

export const LoginPage: React.FC = () => {
  const login = useLogin();
  const notify = useNotify();
  const [searchParams] = useSearchParams({});
  const email = searchParams.get('email');

  const translate = useTranslate();

  const handleSubmit = async (data: FieldValues) => {
    await login({
      email: data.email,
      password: data.password,
    })
      .then(segmentIdentify)
      .catch(() => notify('auth.login.access_denied', { type: 'error' }));
  };

  return (
    <AppLayout>
      <AuthCard
        title={translate('auth.login.title')}
        subtitle={translate('auth.login.caption')}
      >
        <Form
          record={{ email: email, password: '' }}
          resource="login"
          onSubmit={handleSubmit}
        >
          <LoginCard />
        </Form>
      </AuthCard>
    </AppLayout>
  );
};
