import React, { ReactElement } from 'react';

import { Paper, Tab, Tabs as MuiTabs } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export type TabItem = {
  icon: ReactElement;
  label: string;
  value: string;
};

export const Tabs: React.FC<{
  tabs: TabItem[];
  value: string;
  onTabChange?: (_event: React.SyntheticEvent, resource: string) => void;
  containerStyles?: SxProps<Theme>;
}> = ({ tabs, value, onTabChange, containerStyles }) => {
  return (
    <Paper sx={containerStyles}>
      <MuiTabs
        sx={{ minHeight: '40px' }}
        value={value}
        onChange={onTabChange}
        textColor="secondary"
        indicatorColor="secondary"
      >
        {tabs.map((tab) => (
          <Tab
            sx={{ minHeight: '40px' }}
            label={tab.label}
            key={tab.label}
            value={tab.value}
            icon={tab.icon}
            iconPosition="start"
          />
        ))}
      </MuiTabs>
    </Paper>
  );
};
