export interface InvoiceData {
  invoiceRecipient: InvoiceRecipient;
  address: string;
}
export interface PrivateIndividual extends InvoiceData {
  firstName: string;
  lastName: string;
}
export interface LegalEntityFrance extends InvoiceData {
  company: string;
  siret: string;
  tvaIntra?: string;
}
export interface LegalEntityEurope extends InvoiceData {
  company: string;
  tvaIntra: string;
}
export interface LegalEntityOthers extends InvoiceData {
  company: string;
}
export type ProjectInvoiceData =
  | PrivateIndividual
  | LegalEntityFrance
  | LegalEntityEurope
  | LegalEntityOthers;

export type ProjectRenovation = {
  estimatedRenovation: EstimatedRenovation | null;
  reasonNoWork: ReasonNoWork | null;
  comments: string | null;
  invoiceMethod: InvoiceMethod;
  invoiceData: ProjectInvoiceData;
};

export type Project = {
  id: string;
  renovation: ProjectRenovation;
};

export enum EstimatedRenovation {
  No = 'NO',
  Furnitures = 'FURNITURES',
  Renovation = 'RENOVATION',
  RenovationFurnitures = 'RENOVATION_FURNITURES',
}

export enum NoWorkReasonChoicesCategory {
  Finance = 'FINANCE',
  WorkFlexibility = 'WORK_FLEXIBILITY',
  Investor = 'INVESTOR',
  Masteos = 'MASTEOS',
  Other = 'OTHER',
}

export enum ReasonNoWork {
  AmountOfWork = 'AMOUNT_OF_WORK',
  OverallBudget = 'OVERALL_BUDGET',
  BillingMethod = 'BILLING_METHOD',
  TooLongDelayBeforeStarting = 'TOO_LONG_DELAY_BEFORE_STARTING',
  WorkTimeTooLong = 'WORK_TIME_TOO_LONG',
  PreferToGoExternal = 'PREFER_TO_GO_EXTERNAL',
  NotRequireWork = 'NOT_REQUIRE_WORK',
  NeedMoreChoice = 'NEED_MORE_CHOICE',
  WorkTooAmbitious = 'WORK_TOO_AMBITIOUS',
  ExternalInfluence = 'EXTERNAL_INFLUENCE',
  UncertaintyOnFinalEcd = 'UNCERTAINTY_ON_FINAL_ECD',
  HunterDirectsToExternalWork = 'HUNTER_DIRECTS_TO_EXTERNAL_WORK',
  Other = 'OTHER',
}

export enum InvoiceMethod {
  /**
   * None
   */
  None = 'NONE',
  /**
   * 95% / 4% / 1% (default)
   */
  Choice1 = 'CHOICE_1',
  /**
   * 60% / 35% / 5%
   */
  Choice2 = 'CHOICE_2',
}

export enum InvoiceRecipient {
  PrivateIndividual = 'PRIVATE_INDIVIDUAL',
  LegalEntityFrance = 'LEGAL_ENTITY_FRANCE',
  LegalEntityEurope = 'LEGAL_ENTITY_EUROPE',
  LegalEntityOthers = 'LEGAL_ENTITY_OTHERS',
}
