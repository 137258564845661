import { CellType, GridColDef } from 'types/mui.types';
import { renderCellHighlighted } from 'features/realEstates/list/utils/columnsRenderCell';
import { GridValueFormatterParams } from '@mui/x-data-grid';

export const useUsersColumns = (): GridColDef[] => {
  return [
    {
      field: 'firstName',
      headerName: 'user.input.firstname',
      renderCell: renderCellHighlighted(),
      width: 150,
    },
    {
      field: 'lastName',
      headerName: 'user.input.lastname',
      renderCell: renderCellHighlighted(),
      width: 150,
    },
    {
      field: 'roles',
      headerName: 'user.input.role',
      valueFormatter: (params) => params.value.join(', '),
      width: 250,
    },
    {
      field: 'email',
      headerName: 'user.input.email',
      renderCell: renderCellHighlighted(),
      width: 350,
    },
    {
      field: 'organization',
      headerName: 'user.input.organization',
      valueFormatter: (param: GridValueFormatterParams<{ name: string }>) => {
        return param.value.name;
      },
      width: 150,
    },
    {
      cellType: CellType.DATE_TIME,
      field: 'createdAt',
      headerName: 'user.header.createdAt',
      width: 150,
    },
  ] as GridColDef[];
};
