import React, { useMemo } from 'react';

import { styled, css, Theme } from '@mui/material/styles';
import { Box, Drawer, DrawerProps, IconButton, Toolbar } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';

const StyledDrawer = styled(Drawer)`
  flex-shrink: 0;
  width: 50px;
  z-index: ${({ theme }) => theme.zIndex.appBar - 1};
`;

const contentItemsCss = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Content = styled(Box)`
  ${contentItemsCss}
`;

const Footer = styled(Box)`
  ${contentItemsCss}
  border-top: 1px solid ${({ theme }) => theme.palette.grey[300]};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Item = styled(IconButton)`
  border-radius: 0;
  background-color: ${({
    selected,
    theme,
  }: {
    selected?: boolean;
    theme?: Theme;
  }) => (selected && theme ? theme.palette.background.selected : 'inherit')};
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;

export type MenuItemProps = {
  Icon: SvgIconComponent;
  onClick: () => void;
  key?: string;
  selected?: boolean;
  title: string;
};

export type MenuUiProps = Omit<DrawerProps, 'children'> & {
  open: boolean;
  items: MenuItemProps[];
  footerItem: MenuItemProps;
};

export const Menu: React.FC<MenuUiProps> = ({
  open,
  items,
  footerItem: { Icon: IconFooter, onClick: onClickFooter, title: footerTitle },
  ...rest
}) => {
  const menuItems = useMemo(
    () =>
      items.map(({ Icon, onClick, key, selected = false, title }) => (
        <Item
          key={key}
          onClick={onClick}
          selected={selected}
          data-testid="menuItem"
          title={title}
        >
          <Icon />
        </Item>
      )),
    [items]
  );

  return (
    <StyledDrawer anchor="left" open={open} {...rest}>
      <Toolbar />
      <Content>{menuItems}</Content>
      <Footer>
        <Item
          onClick={onClickFooter}
          data-testid="footerItem"
          title={footerTitle}
        >
          <IconFooter />
        </Item>
      </Footer>
    </StyledDrawer>
  );
};
