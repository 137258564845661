export const menuPropsStyle = {
  height: '300px',
  left: '-34px',
  top: '10px',
  width: '360px',
};

export const phoneCountrySelectSx = {
  '&.Mui-focused:has(div[aria-expanded="false"])': {
    fieldset: {
      display: 'block',
    },
  },

  // Update default spacing
  '.MuiSelect-select': {
    padding: '8px',
    paddingRight: '24px !important',
  },

  // Remove default outline (display only on focus)
  fieldset: {
    display: 'none',
  },

  svg: {
    right: 0,
  },
  width: 'max-content',
};

export const flagImageSelectStyle = { display: 'flex' };

export const flagImageOptionStyle = {};
