import { useStore, useTranslate } from 'react-admin';
import { CustomerModalKey } from 'types/customer.types';
import { Modal, ModalFooterButtonsContainer } from 'ui/organisms/modal/Modal';
import { Button } from 'ui/atoms/button/Button';

import { ContactInformationModalProps } from './ContactInformationModal.types';
import { Row, Text } from './ContactInformationModal.styles';
import { CopyToClipboardButton } from './copy-to-clipboard-button/CopyToClipboardButton';

export const ContactInformationModal: React.FC<
  ContactInformationModalProps
> = ({ phoneNumber, email }) => {
  const translate = useTranslate();
  const [open, setModalStore] = useStore(
    CustomerModalKey.CONTACT_INFORMATION,
    false
  );

  const onClose = () => {
    setModalStore(false);
  };

  return (
    <Modal
      open={open}
      title={translate('customer.contactInformation.title')}
      onClose={onClose}
      footer={
        <ModalFooterButtonsContainer>
          <Button
            variant="text"
            onClick={onClose}
            data-testid="contact-information-modal-close-button"
          >
            {translate('ra.action.close')}
          </Button>
        </ModalFooterButtonsContainer>
      }
    >
      {!!phoneNumber && (
        <Row>
          <Text>
            {translate('shared.phoneNumber')} : {phoneNumber}
          </Text>
          <CopyToClipboardButton toCopy={phoneNumber} />
        </Row>
      )}
      {!!email && (
        <Row>
          <Text>
            {translate('shared.email')} : {email}
          </Text>
          <CopyToClipboardButton toCopy={email} />
        </Row>
      )}
    </Modal>
  );
};
