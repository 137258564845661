import { Theme } from '@mui/material';
import { GridValueFormatterParams } from '@mui/x-data-grid';
import { Translate } from 'react-admin';
import { CustomerSearchRoomCountFilter } from 'types/customer.types';
import { ChipListProps } from 'ui/molecules/chips-list/ChipList';
import { formatChipListValue } from 'utils/columns/valueFormatters.utils';

const REAL_ESTATE_ROOM_COUNT_TO_CHIP_LIST_PROPS = {
  [CustomerSearchRoomCountFilter.One]: {
    label: 'realEstate.roomCount.one',
  },
  [CustomerSearchRoomCountFilter.Two]: {
    label: 'realEstate.roomCount.two',
  },
  [CustomerSearchRoomCountFilter.Three]: {
    label: 'realEstate.roomCount.three',
  },
  [CustomerSearchRoomCountFilter.Four]: {
    label: 'realEstate.roomCount.four',
  },
  [CustomerSearchRoomCountFilter.Five]: {
    label: 'realEstate.roomCount.five',
  },
  [CustomerSearchRoomCountFilter.SixOrMore]: {
    label: 'realEstate.roomCount.sixOrMore',
  },
};

export const formatPropertyRoomCountColumnValue =
  (translate: Translate, theme: Theme) =>
  (param: GridValueFormatterParams): ChipListProps['list'] => {
    return formatChipListValue(
      translate,
      theme,
      REAL_ESTATE_ROOM_COUNT_TO_CHIP_LIST_PROPS
    )(param);
  };
