import { getRealEstatesDefaultFilters } from 'features/realEstates/list/components/filters/RealEstatesFilters.utils';
import { DataProvider } from 'react-admin';
import { Dpe } from 'types/realEstate.enum';

export const getGetListMethod =
  (dataProvider: DataProvider): DataProvider['getList'] =>
  (resource, params) => {
    if (['real-estates', 'real-estates-cgp'].includes(resource)) {
      if (params.filter.dpeGrades) {
        const dpeRange: [number, number] =
          params.filter.dpeGrades ?? getRealEstatesDefaultFilters().dpeGrades;
        params.filter.dpeGrades = transformDpeFilterValue(dpeRange);
      }
    }

    return dataProvider.getList(resource, params);
  };

export function transformDpeFilterValue(dpeRange: [number, number]): Dpe[] {
  const result: Dpe[] = [];
  const dpeValues = Object.values(Dpe).splice(2);
  const startRange = dpeRange[0] ?? getRealEstatesDefaultFilters().dpeGrades[0];
  const endRange = dpeRange[1] ?? getRealEstatesDefaultFilters().dpeGrades[1];

  for (let i = startRange; i <= endRange; i++) {
    result.push(dpeValues[i]);
  }

  return result;
}
