import { PropsWithChildren } from 'react';

import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import i18next from 'i18next';

import { languages } from 'constants/languages';

export const i18nDateNfsMapper = {
  [languages.enUS]: en,
  [languages.esES]: es,
  [languages.frFR]: fr,
  [languages.frBE]: fr,
};

export const DateLocalizationProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={i18nDateNfsMapper[i18next.language] || en}
    >
      {children}
    </LocalizationProvider>
  );
};
