import * as yup from 'yup';
import { validateRegistrationNumber } from 'utils/form/form.utils';

export const organizationTerritorySchema = yup.object().shape({
  effectiveDate: yup
    .date()
    .typeError('organization.input.invalidDate')
    .required(),
  endDate: yup.date().typeError('organization.input.invalidDate').nullable(),
  territoryId: yup
    .number()
    .typeError('organization.input.invalidId')
    .required(),
});

export const organizationTerritoriesSchema = yup
  .array(organizationTerritorySchema)
  .notRequired();

export const organizationAddForm = yup.object().shape({
  name: yup.string().nullable().required('organization.input.nameRequired'),
  siret: yup
    .string()
    .nullable()
    .test(
      'siret',
      'organization.input.siretInvalid',
      validateRegistrationNumber
    ),
  territories: organizationTerritoriesSchema,
});
