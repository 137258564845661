import { useRecordContext } from 'react-admin';
import { CircularProgress } from '@mui/material';
import { Header } from 'ui/organisms/header/Header';
import { formatFullName } from 'utils/strings/strings.utils';
import { Customer } from 'types/customer.types';

import { CustomerHeaderDetails } from './details/CustomerHeaderDetails';
import { CustomerHeaderProps } from './CustomerHeader.types';
import { CustomerHeaderTabs } from './tabs/CustomerHeaderTabs';

export const CustomerHeader: React.FC<CustomerHeaderProps> = ({
  actionButtons,
}) => {
  const record = useRecordContext<Customer>();

  if (!record) {
    return <CircularProgress />;
  }

  const title = formatFullName(record.firstName, record.lastName);

  const bookmarkCount = record.bookmarkCount ?? 0;
  const positioningCount = record.positioningCount ?? 0;

  return (
    <>
      <Header
        testId="customer-header"
        title={title}
        details={<CustomerHeaderDetails registrationDate={record.createdAt} />}
        actionButtons={actionButtons}
      />
      <CustomerHeaderTabs
        customerId={record.id}
        positioningCount={positioningCount}
        bookmarkCount={bookmarkCount}
      />
    </>
  );
};
