import { Theme } from '@mui/material';
import { GridValueFormatterParams } from '@mui/x-data-grid';
import { Translate } from 'react-admin';
import { CustomerInvestBudget } from 'types/customer.types';
import { ChipListProps } from 'ui/molecules/chips-list/ChipList';
import { formatChipListValue } from 'utils/columns/valueFormatters.utils';

const CUSTOMER_INVEST_BUDGET = {
  [CustomerInvestBudget.Below_300K]: {
    color: 'cyan',
    label: `customer.investBudget.${CustomerInvestBudget.Below_300K.toLowerCase()}`,
  },
  [CustomerInvestBudget.Between_300K_700K]: {
    color: 'deepPurple',
    label: `customer.investBudget.${CustomerInvestBudget.Between_300K_700K.toLowerCase()}`,
  },
  [CustomerInvestBudget.Above_700K]: {
    color: 'red',
    label: `customer.investBudget.${CustomerInvestBudget.Above_700K.toLowerCase()}`,
  },
  [CustomerInvestBudget.Unknown]: {
    label: `customer.investBudget.${CustomerInvestBudget.Unknown.toLowerCase()}`,
  },
};

export const formatInvestBudgetColumnValue =
  (translate: Translate, theme: Theme) =>
  (param: GridValueFormatterParams): ChipListProps['list'] =>
    formatChipListValue(
      translate,
      theme,
      CUSTOMER_INVEST_BUDGET
    )({ ...param, value: [param.value] });
