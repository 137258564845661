/* eslint-disable max-lines */
import { FC, useMemo } from 'react';

import {
  CheckboxGroupInput,
  ReferenceArrayInput,
  useListContext,
  useTranslate,
} from 'react-admin';
import { CollapsibleSection } from 'ui/molecules/collapsible-section/CollapsibleSection';
import FormGroup from '@mui/material/FormGroup';
import { GooglePlacesAutocomplete } from 'components/google-places-autocomplete/GooglePlacesAutocomplete';
import { capitalizeFirstLetter } from 'utils/strings/strings.utils';
import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';
import { useFormContext } from 'react-hook-form';
import { RawGooglePlaceSuggestion } from 'components/google-places-autocomplete/GooglePlacesAutocomplete.types';
import { DpeRangeInput } from 'components/custom-range-inputs/dpe-range-input/DpeRangeInput';
import { CheckboxInput } from 'components/checkbox-input/CheckboxInput';
import { DateLocalizationProvider } from 'components/form/DateLocalizationProvider';
import { DateRangeInput } from 'components/date-range-input/DateRangeInput';
import { SellTarget } from 'types/realEstate.types';

import {
  getFurnishedCheckboxes,
  getRentalSituationCheckboxes,
  getRentingTypesCheckboxes,
  getShortTermRentalCheckboxes,
  getStatusCheckboxes,
  getTypesCheckboxes,
} from './FiltersBody.utils';
import { RegionsAutocomplete } from '../filters-autocomplete/RegionsAutocomplete';
import { HuntersAutocomplete } from '../filters-autocomplete/HuntersAutocomplete';
import { CitiesAutocomplete } from '../filters-autocomplete/CitiesAutocomplete';
import { PostalCodesAutocomplete } from '../filters-autocomplete/PostalCodesAutocomplete';
import { FiltersRangeGroup } from '../filters-range-group/FiltersRangeGroup';

export const FilterBody: FC<{
  mode?: SellTarget;
}> = ({ mode = SellTarget.Masteos }) => {
  const translate = useTranslate();
  const { filterValues } = useListContext();
  const { setValue } = useFormContext();

  const { isAdvisor, isHunter, isHunterManager } = useHeliosPermissions();

  const statusesCheckboxes = useMemo(
    () =>
      getStatusCheckboxes(
        isAdvisor && !isHunter && !isHunterManager,
        translate
      ),
    [isAdvisor, isHunter, isHunterManager, translate]
  );

  const typesCheckboxes = useMemo(
    () => getTypesCheckboxes(translate),
    [translate]
  );

  const rentingTypesCheckboxes = useMemo(
    () => getRentingTypesCheckboxes(translate),
    [translate]
  );

  const furnishedCheckboxes = useMemo(
    () => getFurnishedCheckboxes(translate),
    [translate]
  );

  const rentalSituationCheckboxes = useMemo(
    () => getRentalSituationCheckboxes(translate),
    [translate]
  );

  const shortTermRentalCheckboxes = useMemo(
    () => getShortTermRentalCheckboxes(translate),
    [translate]
  );

  const onAddressChange = (
    _place: string,
    rawData: Nullable<RawGooglePlaceSuggestion>
  ) => {
    setValue('address', rawData?.description ?? '');
    setValue('placeId', rawData?.place_id ?? '');
  };

  return (
    <DateLocalizationProvider>
      {mode === SellTarget.Masteos ? (
        <CollapsibleSection
          label={translate('realEstate.statusLabel')}
          isDefaultExpanded={!!filterValues.status?.length}
        >
          <CollapsibleSection.GroupSpacer>
            <CheckboxGroupInput
              row={false}
              source="status"
              optionValue="value"
              optionText="label"
              choices={statusesCheckboxes}
              label={false}
            />
          </CollapsibleSection.GroupSpacer>
        </CollapsibleSection>
      ) : null}

      <CollapsibleSection
        label={translate('shared.hunter')}
        isDefaultExpanded={!!filterValues.hunters?.length}
      >
        <CollapsibleSection.GroupSpacer>
          <HuntersAutocomplete />
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>

      <FiltersRangeGroup
        label={translate('financial.projectAmount')}
        source="projectCost"
      />

      <FiltersRangeGroup
        label={translate('financial.grossReturn')}
        source="grossReturn"
      />

      <CollapsibleSection
        label={translate('shared.location.address')}
        isDefaultExpanded={!!filterValues.address}
      >
        <CollapsibleSection.GroupSpacer>
          <FormGroup>
            <GooglePlacesAutocomplete
              defaultValue={filterValues.address}
              source="address"
              size="small"
              onChange={onAddressChange}
            />
          </FormGroup>
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>

      <CollapsibleSection
        label={capitalizeFirstLetter(translate('shared.address.postalCode'))}
        isDefaultExpanded={!!filterValues.postalCodes?.length}
      >
        <CollapsibleSection.GroupSpacer>
          <PostalCodesAutocomplete />
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>

      <CollapsibleSection
        label={capitalizeFirstLetter(translate('shared.address.city'))}
        isDefaultExpanded={!!filterValues.cities?.length}
      >
        <CollapsibleSection.GroupSpacer>
          <CitiesAutocomplete />
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>

      <CollapsibleSection
        label={translate('shared.address.region')}
        isDefaultExpanded={!!filterValues.regions?.length}
      >
        <CollapsibleSection.GroupSpacer>
          <RegionsAutocomplete />
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>

      <CollapsibleSection
        label={translate('realEstate.input.country')}
        isDefaultExpanded={!!filterValues.countries?.length}
      >
        <CollapsibleSection.GroupSpacer>
          <ReferenceArrayInput
            source="countries"
            reference="real-estates/countries"
          >
            <CheckboxGroupInput
              row={false}
              label={false}
              optionText={(key) => translate(`shared.country.${key.id}`)}
              optionValue="id"
            />
          </ReferenceArrayInput>
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>

      <CollapsibleSection
        label={translate('realEstate.propertyType')}
        isDefaultExpanded={!!filterValues.types?.length}
      >
        <CollapsibleSection.GroupSpacer>
          <CheckboxGroupInput
            row={false}
            source="types"
            optionValue="value"
            optionText="label"
            choices={typesCheckboxes}
            label={false}
          />
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>

      <CollapsibleSection
        label={translate('realEstate.locationType')}
        isDefaultExpanded={!!filterValues.rentingTypes?.length}
      >
        <CollapsibleSection.GroupSpacer>
          <CheckboxGroupInput
            row={false}
            source="rentingTypes"
            optionValue="value"
            optionText="label"
            choices={rentingTypesCheckboxes}
            label={false}
          />
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>

      <CollapsibleSection
        label={translate('realEstate.isFurnished.furnished')}
        isDefaultExpanded={!!filterValues.furnishedValues?.length}
      >
        <CollapsibleSection.GroupSpacer>
          <CheckboxGroupInput
            row={false}
            source="furnishedValues"
            optionValue="value"
            optionText="label"
            choices={furnishedCheckboxes}
            label={false}
          />
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>

      <CollapsibleSection
        label={translate('realEstate.shortTermRental.shortTitle')}
        isDefaultExpanded={!!filterValues.withShortTermRental?.length}
      >
        <CollapsibleSection.GroupSpacer>
          <CheckboxGroupInput
            row={false}
            source="withShortTermRental"
            optionValue="value"
            optionText="label"
            choices={shortTermRentalCheckboxes}
            label={false}
          />
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>

      <CollapsibleSection
        label={translate('realEstate.input.rentalSituation')}
        isDefaultExpanded={!!filterValues.rentalSituations?.length}
      >
        <CollapsibleSection.GroupSpacer>
          <CheckboxGroupInput
            row={false}
            source="rentalSituations"
            optionValue="value"
            optionText="label"
            choices={rentalSituationCheckboxes}
            label={false}
          />
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>

      <CollapsibleSection
        label={translate('realEstate.dpe')}
        isDefaultExpanded={true}
      >
        <CollapsibleSection.GroupSpacer>
          <DpeRangeInput source="dpeGrades" />
          <CheckboxInput
            label="realEstate.unknownDpe"
            source="includeMissingDpe"
          />
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>

      <FiltersRangeGroup
        label={translate('financial.workBudget')}
        source="workBudget"
      />

      <CollapsibleSection
        label={translate('shared.publicationDate')}
        isDefaultExpanded={
          !!(
            filterValues.publicationDate?.from ||
            filterValues.publicationDate?.to
          )
        }
      >
        <CollapsibleSection.GroupSpacer>
          <DateRangeInput
            fromLabel={translate('shared.fromDate')}
            toLabel={translate('shared.toDate')}
            fromSource="publicationDate.from"
            toSource="publicationDate.to"
          />
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>
    </DateLocalizationProvider>
  );
};
