import { useContext, useMemo } from 'react';

import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';
import { PermissionName } from 'types/permission.types';
import { Box, styled } from '@mui/material';
import { ErrorStepBottomBar } from 'components/form/error-step-bottom-bar/ErrorStepBottomBar';
import { useFormContext } from 'react-hook-form';

import { ProjectForm } from './project-form/ProjectForm';
import { LocalizationForm } from './localization-form/LocalizationForm';
import { FinancialModelForm } from './financial-model-form/FinancialModelForm';
import { OverviewForm } from './overview-form/OverviewForm';
import { BeforeRenovationForm } from './before-renovation-form/BeforeRenovationForm';
import { AfterRenovationForm } from './after-renovation-form/AfterRenovationForm';
import { sourceToLabelMap } from './EditFormContent.utils';
// eslint-disable-next-line no-restricted-imports
import {
  ActionContext,
  ActionContextProps,
} from '../../../context/ActionContextProvider';

const FormContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 24px;
  row-gap: 16px;

  & .Mui-disabled {
    cursor: not-allowed;
  }
`;

export const EditFormContent: React.FC = () => {
  const { hasPermission } = useHeliosPermissions();
  const formContext = useFormContext();

  const { doDisplayError, setFormContext } =
    useContext<ActionContextProps>(ActionContext);

  setFormContext(formContext);

  const formComponents = useMemo(() => {
    if (hasPermission(PermissionName.UpdateRealization) === false) {
      return [];
    }
    return [
      {
        Component: ProjectForm,
        defaultExpanded: true,
        disabled: !hasPermission(PermissionName.UpdateRealization),
      },
      {
        Component: LocalizationForm,
        defaultExpanded: true,
      },
      {
        Component: FinancialModelForm,
        defaultExpanded: true,
        disabled: !hasPermission(PermissionName.UpdateRealization),
      },
      {
        Component: OverviewForm,
        defaultExpanded: true,
        disabled: !hasPermission(PermissionName.UpdateRealization),
      },
      {
        Component: BeforeRenovationForm,
        defaultExpanded: true,
        disabled: !hasPermission(PermissionName.UpdateRealization),
      },
      {
        Component: AfterRenovationForm,
        defaultExpanded: true,
        disabled: !hasPermission(PermissionName.UpdateRealization),
      },
    ];
  }, [hasPermission]);

  return (
    <FormContainer>
      {formComponents.map(({ Component, ...props }) => (
        <Component key={Component.name} {...props} />
      ))}
      <ErrorStepBottomBar
        visible={doDisplayError}
        sourceToLabelMap={sourceToLabelMap}
      />
    </FormContainer>
  );
};
