import { getValidToken } from 'features/auth/utils/refreshToken';
import { fetchUtils } from 'react-admin';
import { fetchAndDownloadFile, fetchFile } from 'utils/fetch/fetchFile';

interface Options extends RequestInit {
  user?: {
    authenticated?: boolean;
    token?: string;
  };
  responseType?: 'json' | 'file';
  shouldDownload?: boolean;
}

export interface HttpClientResponse {
  status: number;
  headers: Headers;
  body: string;
  json: unknown;
}

export const httpClientAuth = async (
  url: string,
  options: Options = {}
): Promise<HttpClientResponse> => {
  let jwtToken = '';
  try {
    jwtToken = await getValidToken();
  } catch (e) {
    // noop
  }

  if (jwtToken) {
    options.user = {
      authenticated: true,
      token: `bearer ${jwtToken}`,
    };
  }

  if (options.responseType === 'file') {
    return options.shouldDownload
      ? fetchAndDownloadFile(url, options)
      : fetchFile(url, options);
  }
  return fetchUtils.fetchJson(url, options);
};

export const post = <T>(
  url: string,
  options: Options = {}
): Promise<{ data: T }> =>
  httpClientAuth(url, { ...options, method: 'POST' }).then(({ json }) => ({
    data: json as T,
  }));

export const put = <T>(
  url: string,
  options: Options = {}
): Promise<{ data: T }> =>
  httpClientAuth(url, { ...options, method: 'PUT' }).then(({ json }) => ({
    data: json as T,
  }));

export const get = <T>(
  url: string,
  options: Options = {}
): Promise<{ data: T }> =>
  httpClientAuth(url, { ...options, method: 'GET' }).then(({ json }) => ({
    data: json as T,
  }));
