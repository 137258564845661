import { Translate } from 'react-admin';
import {
  PositioningCancellationReason,
  PositioningStatus,
} from 'types/positioning.types';
import { orderSelect } from 'utils/form/form.utils';

export const getCancelledStatus = (
  currentStatus?: PositioningStatus
): PositioningStatus => {
  if (
    currentStatus &&
    [
      PositioningStatus.Recommendation,
      PositioningStatus.PrePositioningCancelled,
    ].includes(currentStatus)
  ) {
    return PositioningStatus.PrePositioningCancelled;
  }

  return PositioningStatus.Cancelled;
};

export const getAdvisorCancellationReasonChoices = (
  translate: Translate
): { id: PositioningCancellationReason; name: string }[] => {
  return Object.values(PositioningCancellationReason)
    .map((reason) => ({
      id: reason,
      name: translate(`positioning.cancellationReason.${reason}`),
    }))
    .sort(orderSelect);
};
