import { PropsWithChildren } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';

interface AccordionFormProps {
  title: string;
  defaultExpanded?: boolean;
}

export const AccordionForm: React.FC<PropsWithChildren<AccordionFormProps>> = ({
  title,
  children,
  defaultExpanded,
}) => (
  <Accordion defaultExpanded={defaultExpanded}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h6">{title}</Typography>
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
);
