import React from 'react';

import { useInput } from 'react-admin';

import { SliderStyled } from './RangeInput.styles';
import { RangeInputProps } from './RangeInput.types';

export const RangeInput: React.FC<RangeInputProps> = ({
  source,
  label,
  onBlur,
  onChange,
  ...sliderProps
}) => {
  const { id, field, fieldState } = useInput({
    onBlur,
    onChange,
    source,
  });

  return (
    <label htmlFor={id}>
      {label}
      <SliderStyled {...field} {...sliderProps} />
      {!!fieldState.error && <span>{fieldState.error.message}</span>}
    </label>
  );
};
