import React from 'react';

import { ActionModal } from 'components/action-modal/ActionModal';
import { useNotify, useStore, useTranslate, useUpdate } from 'react-admin';
import {
  Positioning,
  PositioningConfirmModal,
  PositioningStatus,
} from 'types/positioning.types';

type ConfirmPositioningModalProps = {
  onSuccess?: () => void;
};

export const ConfirmPositioningModal: React.FC<
  ConfirmPositioningModalProps
> = ({ onSuccess }) => {
  const notify = useNotify();
  const translate = useTranslate();
  const [{ isOpen: isModalOpen, positioningId, customerFullName }, showModal] =
    useStore<PositioningConfirmModal>('positioning.confirm_modal', {
      customerFullName: undefined,
      isOpen: false,
      positioningId: undefined,
    });

  const [update] = useUpdate<Positioning>(
    'positionings',
    { data: { status: PositioningStatus.Confirmed } },
    {
      onError: (error) => {
        notify(error as string, { type: 'error' });
      },
      onSuccess: () => {
        notify(translate('positioning.confirm.success', { customerFullName }), {
          type: 'success',
        });
        showModal({ isOpen: false });
      },
    }
  );

  const handleCloseModal = () => {
    showModal({ isOpen: false });
  };

  const handleOnConfirm = () => {
    update(
      'positionings',
      {
        data: { status: PositioningStatus.Confirmed },
        id: positioningId,
      },
      {
        onSuccess: () => onSuccess?.(),
      }
    );
  };

  return (
    <ActionModal
      title={translate('positioning.confirm.modalTitle', { customerFullName })}
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      callback={handleOnConfirm}
      saveLabel={translate('ra.action.confirm')}
      slug={`positioning-confirm-${positioningId}`}
    >
      <div />
    </ActionModal>
  );
};
