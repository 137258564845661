import {
  useTranslate,
  AutocompleteArrayInput,
  useListContext,
} from 'react-admin';
import { CollapsibleSection } from 'ui/molecules/collapsible-section/CollapsibleSection';

import { getRoleChoices } from './FiltersBody.utils';

export const FilterBody: React.FC = () => {
  const translate = useTranslate();
  const { filterValues } = useListContext();

  const choices = getRoleChoices(translate);

  return (
    <CollapsibleSection
      label={translate('shared.roles')}
      isDefaultExpanded={!!filterValues.roles?.length}
    >
      <CollapsibleSection.GroupSpacer>
        <AutocompleteArrayInput
          source="roles"
          variant="outlined"
          choices={choices}
          label={translate('shared.roles')}
        />
      </CollapsibleSection.GroupSpacer>
    </CollapsibleSection>
  );
};
