import React from 'react';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';

export const ICON_MAPPING = {
  email_envoye: <MailOutlineIcon />,
  upload_compromis_signe: <HandshakeOutlinedIcon />,
  upload_encaissement: <EuroOutlinedIcon />,
  upload_offre_achat: <HistoryEduIcon />,
  upload_offre_pret: <SellOutlinedIcon />,
  upload_raa: <KeyOutlinedIcon />,
  validate_sending_notary: <TextSnippetOutlinedIcon />,
} as Record<string, JSX.Element>;

export const DOCUMENTS_STATES: {
  [state: string]: {
    color: 'primary' | 'success' | 'error';
    label: string;
  };
} = {
  done: {
    color: 'success',
    label: 'document.state.done',
  },
  partial: {
    color: 'error',
    label: 'document.state.partial',
  },
  todo: {
    color: 'primary',
    label: 'document.state.todo',
  },
};
