import {
  HttpError,
  useDataProvider,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { ActionMutationReturn } from 'types/common.types';
import { useMutation } from 'react-query';
import { UploadCounterSignedOfferBody } from 'types/customMethods.types';
import { HeliosDataProvider } from 'providers/admin/dataProvider';
import { useRealEstateDetailPermissions } from 'features/realEstates/details/edit/hooks/useRealEstateDetailPermissions';

export type UploadCounterSignedOfferMutationReturn = ActionMutationReturn<
  void,
  unknown,
  UploadCounterSignedOfferBody,
  unknown
>;

export const useUploadCounterSignedOfferAction = (
  id?: string
): UploadCounterSignedOfferMutationReturn => {
  const record = useRecordContext(id ? { record: { id } } : undefined);
  const dataProvider = useDataProvider<HeliosDataProvider>();
  const notify = useNotify();

  const { canUploadCounterSignedOffer } = useRealEstateDetailPermissions();

  const mutation = useMutation(
    ['uploadCounterSignedOffer', record?.id, dataProvider],
    (data: UploadCounterSignedOfferBody) => {
      return dataProvider
        ?.uploadCounterSignedOffer(record?.id, data)
        .then(() => {
          notify('transaction.action.upload.success', { type: 'success' });
        })
        .catch((err: HttpError) => {
          notify(err.body?.message ?? '', { type: 'error' });
          throw err;
        });
    }
  );

  return { ...mutation, isAllowed: canUploadCounterSignedOffer };
};
