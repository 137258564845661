import { IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Translate, useStore } from 'react-admin';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Dropdown } from 'ui/molecules/dropdown/Dropdown';
// eslint-disable-next-line max-len
import { UploadCounterSignedOfferModalStore } from 'features/realEstates/details/edit/components/action-modal/UploadCounterSignedOfferModal';
import { RealEstateModalKey } from 'features/realEstates/constants';
// eslint-disable-next-line max-len
import { GeneratePurchaseOfferModalStore } from 'features/realEstates/details/edit/components/action-modal/GeneratePurchaseOfferModal';
import { useRealEstatePublicLink } from 'hooks/use-public-link/usePublicLink';
import { useDownloadPropertyPdfAction } from 'features/realEstates/details/edit/hooks/useDownloadPropertyPdfAction';

import { PreBookingStore } from '../components/modals/CGPPreBookingModal';

export const renderCellActionsCGP = (
  t: Translate
): ((p: GridRenderCellParams) => React.ReactElement) => {
  const CellActions = ({
    id,
    row,
  }: GridRenderCellParams): React.ReactElement => {
    const [, showPurchaseOfferModal] =
      useStore<GeneratePurchaseOfferModalStore>(
        RealEstateModalKey.GENERATE_PURCHASE_OFFER,
        { isOpen: false }
      );
    const [, showPrebookingModal] = useStore<PreBookingStore>(
      RealEstateModalKey.PRE_BOOKING,
      { isOpen: false }
    );
    const [, showCounterSignedOfferModal] =
      useStore<UploadCounterSignedOfferModalStore>(
        RealEstateModalKey.UPLOAD_COUNTER_SIGNED_OFFER,
        { isOpen: false }
      );
    const openPurchaseOfferModal = () =>
      showPurchaseOfferModal({
        isOpen: true,
        realEstate: {
          financialModel: {
            agencySellerCommission:
              row['financialModel.agencySellerCommission'],
            netSeller: row['financialModel.netSeller'],
          },
          id: id as string,
        },
      });

    const openPrebookingModal = () =>
      showPrebookingModal({
        isOpen: true,
        realEstate: {
          address: row['address.street'],
          id: id as string,
          isBooked: row['isBooked'],
        },
      });
    const openCounterSignedOfferModal = () =>
      showCounterSignedOfferModal({
        isOpen: true,
        realEstate: {
          id: id as string,
          status: row['status'],
        },
      });

    const { generateAndCopyPublicLink, generateAndOpenPublicLink } =
      useRealEstatePublicLink();

    const downloadPropertyPdfAction = useDownloadPropertyPdfAction();

    const items = [
      {
        callback: openPurchaseOfferModal,
        label: 'realEstate.action.generatePurchaseOffer.label',
        name: 'purchaseOfferModal',
      },
      {
        callback: openCounterSignedOfferModal,
        label: 'realEstate.action.uploadCounterSignedOffer.label',
        name: 'counterSignedOfferModal',
      },
      {
        callback: () => downloadPropertyPdfAction.mutate(id as string),
        label: 'realEstate.action.downloadPropertyPdf.label',
        name: 'downloadPropertyPdf',
      },
      {
        callback: () => generateAndCopyPublicLink(id as string),
        label: 'realEstate.action.copyPublicLink.label',
        name: 'copyPublicLink',
      },
      {
        callback: () => generateAndOpenPublicLink(id as string),
        label: 'realEstate.action.openPublicLink.label',
        name: 'openPublicLink',
      },
      {
        callback: openPrebookingModal,
        label: 'realEstate.action.book.label',
        name: 'preBooking',
      },
    ];

    return (
      <Dropdown
        items={items}
        renderItem={(menuItem) => <>{t(menuItem.label)}</>}
        keyExtractor={(menuItem) => menuItem.name}
        onSelect={(menuItem) => menuItem.callback()}
      >
        <IconButton aria-label="link" color="inherit" size="small">
          <MoreVertOutlinedIcon />
        </IconButton>
      </Dropdown>
    );
  };

  CellActions.displayName = 'CellCellLinkUrl';

  return CellActions;
};
