import { Permission } from './permission.types';

export enum RoleName {
  User = 'user',

  // Helios roles
  Dev = 'dev',
  Animator = 'animator',
  Admin = 'admin',
  Advisor = 'advisor',
  Hunter = 'hunter',
  HunterManager = 'hunterManager',
  AdvisorManager = 'advisorManager',
  AssetManagementConsultant = 'assetManagementConsultant',
  RentalBuildingManager = 'rentalBuildingManager',
  Mandator = 'mandator',
  IndependentAssetManagementConsultant = 'independentAssetManagementConsultant',

  // Legacy roles
  DefaultAdvisor = 'defaultAdvisor',
  Backoffice = 'backoffice',
  Marketing = 'marketing',
  Gestion = 'gestion',
  Financial = 'financial',
  AdminReno = 'adminReno',
  SiteResponsibleReno = 'siteResponsibleReno',
  SiteDriverReno = 'siteDriverReno',
  SupplierReno = 'supplierReno',
  BuyerReno = 'buyerReno',
  DesignerManagerReno = 'designerManagerReno',
  DecorationManagerReno = 'decorationManagerReno',
  FurnitureSellerReno = 'furnitureSellerReno',
  BetaTesterReno = 'betaTesterReno',
}

export type Role = {
  id: string;
  name: RoleName;
  permissions: Permission[];
};
