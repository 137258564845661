import { useMemo } from 'react';

import {
  ListBase,
  ListContext,
  ShowBase,
  useStore,
  useTranslate,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { DataTable } from 'components/data-table/DataTable';
import { CustomerHeader } from 'features/customers/details/components/header/CustomerHeader';
import {
  ContentContainer,
  ContentTableContainer,
} from 'components/headerTabs/HeaderTabs.styles';
import { getCustomerPositioningColumns } from 'features/positioning/list/utils/getPositioningColumns';
import { useTheme } from '@mui/material';
import { PositioningCancelModal } from 'types/positioning.types';
// eslint-disable-next-line max-len
import { CancelPositioningModal } from 'features/positioning/list/components/modals/cancel-positionning/CancelPositioningModal';
// eslint-disable-next-line max-len
import { ConfirmPositioningModal } from 'features/positioning/list/components/modals/confirm-positionning/ConfirmPositioningModal';
import { RePositioningModal } from 'features/positioning/list/components/modals/re-positioning/RePositioningModal';

export const CustomerPositionings: React.FC = () => {
  const { id } = useParams();
  const translate = useTranslate();
  const theme = useTheme();

  const [, showCancelModal] = useStore<PositioningCancelModal>(
    'positioning.cancel_modal'
  );

  const columns = useMemo(
    () => getCustomerPositioningColumns(translate, theme, showCancelModal),
    [translate, theme, showCancelModal]
  );

  return (
    <ContentContainer>
      <ShowBase>
        <CustomerHeader />
      </ShowBase>

      <ListBase
        resource="positionings"
        disableSyncWithLocation
        key="customer-positionings"
        filter={{ customerIds: [id] }}
        filterDefaultValues={{ customerIds: [id] }}
        sort={{ field: 'createdAt', order: 'DESC' }}
      >
        <ListContext.Consumer>
          {({ data, isLoading, isFetching, refetch }) => (
            <>
              <ContentTableContainer>
                <DataTable
                  loading={isLoading || isFetching || !columns}
                  columns={columns}
                  rows={data || []}
                  autoHeight
                  density="standard"
                  noRowsMessage={translate(
                    'customer.positioningList.noResults'
                  )}
                />
              </ContentTableContainer>
              <CancelPositioningModal onSuccess={refetch} />
              <ConfirmPositioningModal onSuccess={refetch} />
              <RePositioningModal onSuccess={refetch} />
            </>
          )}
        </ListContext.Consumer>
      </ListBase>
    </ContentContainer>
  );
};
