import { FC } from 'react';

import { Filters } from 'components/filters/Filters';
import { useGetIdentity } from 'react-admin';
import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';
import { MasteosUserIdentityResult } from 'features/auth/utils/getIdentity';
import { RoleName } from 'types/role.types';
import { SellTarget } from 'types/realEstate.types';

import { FilterBody } from './filters-body/FiltersBody';
import { getRealEstatesDefaultFilters } from './RealEstatesFilters.utils';

export const RealEstatesFilters: FC<{
  mode?: SellTarget;
}> = ({ mode = SellTarget.Masteos }) => {
  const { data } = useGetIdentity();
  const id = (data as unknown as MasteosUserIdentityResult)?.id;

  const { hasOnlyRole } = useHeliosPermissions();
  const setCurrentUserAsHunterFilter =
    hasOnlyRole(RoleName.Hunter) && hasOnlyRole(RoleName.Mandator);

  return (
    <Filters
      defaultValues={getRealEstatesDefaultFilters(
        setCurrentUserAsHunterFilter ? [id] : undefined
      )}
    >
      <FilterBody mode={mode} />
    </Filters>
  );
};
