import {
  useGetManyReference,
  useLocaleState,
  useShowContext,
  useTranslate,
} from 'react-admin';
import { Header } from 'ui/organisms/header/Header';
import { Link, Typography } from '@mui/material';
import { formatFullName } from 'utils/strings/strings.utils';
import { getHeaderTimeStampString } from 'utils/i18n/i18n.utils';
import { MetricBlock } from 'ui/molecules/metric-block/MetricBlock';
import EuroIcon from '@mui/icons-material/Euro';
import { PriceField } from 'ui/atoms/price-field/PriceField';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { PercentField } from 'ui/atoms/percent-field/PercentField';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import { useRealEstateTitle } from 'hooks/use-realEstate-title/useRealEstateTitle';
import { BreadCrumbs } from 'ui/molecules/breadcrumbs/BreadCrumbs';

import { URI } from 'constants/uri';

import { TransactionsHeaderProps } from './TransactionsHeader.types';

export const TransactionsHeader: React.FC<TransactionsHeaderProps> = ({
  actionButtons,
}) => {
  const { record } = useShowContext();
  const translate = useTranslate();
  const [locale] = useLocaleState();

  const {
    createdAt,
    updatedAt,
    financialModel,
    hunter,
    id,
    status,
    address,
    locationType,
    surface,
    totalLots,
    type,
  } = record.property;

  const { total } = useGetManyReference('positionings', {
    id: id.toString(),
    target: 'realEstateId',
  });

  const title = useRealEstateTitle({
    city: address.city,
    locationType,
    surface,
    totalLots,
    type,
  });

  const timeStampString = getHeaderTimeStampString({
    createdAt,
    locale,
    translate,
    updatedAt,
  });

  const publicUrl = `${process.env.REACT_APP_MASTEOS_PUBLIC_URL}/property/${id}`;
  const positioningUrl = `${URI.realEstates}/${id}/positionings`;

  const propertyDetails = (
    <>
      <Typography variant="caption">
        {translate('shared.addedBy', {
          fullName: formatFullName(hunter.firstName, hunter.lastName),
        })}
      </Typography>
      <Typography variant="caption">{timeStampString}</Typography>
    </>
  );

  const propertyAddress = (
    <Typography variant="caption">
      {`${address.street}, ${address.postalCode}`}
    </Typography>
  );

  const propertyMetrics = financialModel && (
    <>
      <MetricBlock
        icon={<EuroIcon />}
        text={translate('financial.totalBudget')}
        value={<PriceField price={financialModel.projectAmount} />}
      />
      <MetricBlock
        icon={<AccountBalanceWalletIcon />}
        text={translate('financial.grossReturn')}
        value={<PercentField percent={financialModel.grossReturn || 0} />}
      />

      <Link
        underline="hover"
        color="inherit"
        href={positioningUrl}
        data-testid="transaction-header-positioning-link"
      >
        <MetricBlock
          icon={<PersonPinIcon />}
          text={translate('positioning_header.title')}
          value={translate('positioning_header.resultAll', {
            count: total || 0,
          })}
        />
      </Link>
    </>
  );

  const breadCrumbSteps = [
    {
      label: translate('transaction.breadcrumb.transactionList'),
      path: URI.transactions,
    },
    { label: translate('transaction.breadcrumb.transactionShow') },
  ];

  return (
    <Header
      testId="transaction-header"
      address={propertyAddress}
      title={title}
      status={translate(`realEstate.status.${status.toLowerCase()}`)}
      linkTo={publicUrl}
      actionButtons={actionButtons}
      details={propertyDetails}
      metrics={propertyMetrics}
      breadcrumbs={<BreadCrumbs steps={breadCrumbSteps} />}
    />
  );
};
