import React, { FocusEvent } from 'react';

import {
  TextField as MUITextField,
  TextFieldProps,
  Autocomplete,
  AutocompleteRenderInputParams,
  inputBaseClasses,
  AutocompleteRenderGroupParams,
  FilterOptionsState,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

const getTextFieldPadding = (props: TextFieldProps & { theme: Theme }) =>
  props?.size === 'small' ? '8.5px 14px' : `${props.theme.spacing(1.5)}`;

const TextField = styled(MUITextField)`
  & .${inputBaseClasses.root} {
    padding: ${getTextFieldPadding} !important;
  }

  & .MuiFormLabel-root {
    top: ${(props) => (props?.size === 'small' ? 0 : 6)}px;
  }
`;

export interface AutocompleteValue<T> {
  label: string;
  value: string;
  rawData?: Nullable<T>;
}

export interface AutocompleteInputProps<T> {
  label?: React.ReactNode;
  onSelect: (value: Nullable<AutocompleteValue<T>>) => void;
  onBlur?: (value: Nullable<AutocompleteValue<T>>) => void;
  value: AutocompleteValue<T>;
  options: AutocompleteValue<T>[];
  groupBy?: (option: AutocompleteValue<T>) => string;
  renderGroup?: (params: AutocompleteRenderGroupParams) => React.ReactNode;
  onInputChange: (value: string) => void;
  filterOptions?: (
    options: AutocompleteValue<T>[],
    value: FilterOptionsState<AutocompleteValue<T>>
  ) => AutocompleteValue<T>[];
  renderOption?: (
    props: object,
    option: AutocompleteValue<T>,
    state: object
  ) => React.ReactElement;
  error?: string | React.ReactElement;
  size?: TextFieldProps['size'];
  fullWidth?: boolean;
  disabled?: boolean;
}

export const AutocompleteInput = <T,>({
  error,
  fullWidth = false,
  label,
  groupBy,
  onInputChange,
  onSelect,
  onBlur,
  options,
  filterOptions,
  renderOption,
  renderGroup,
  size,
  value,
  disabled,
}: AutocompleteInputProps<T>): JSX.Element => {
  const onChange = (
    _event: React.SyntheticEvent,
    nextValue: string | Nullable<AutocompleteValue<T>>,
    reason: string
  ) => {
    const val = reason === 'clear' ? '' : nextValue;
    onSelect(typeof val === 'string' ? { label: val, value: val } : val);
  };

  const changeInputValue = (
    _event: React.SyntheticEvent,
    newInputValue: string,
    reason: string
  ) => {
    if (reason === 'clear') {
      onInputChange('');
    } else {
      onInputChange(newInputValue);
    }
  };

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    const nextValue = event.target.value;
    onBlur && onBlur({ label: nextValue, value: nextValue });
  };

  // onChange: when select from suggestion
  // onInputChange: when user type in
  return (
    <Autocomplete
      disabled={disabled}
      freeSolo
      clearOnBlur
      onBlur={handleOnBlur}
      value={value}
      groupBy={groupBy}
      renderGroup={renderGroup}
      onChange={onChange}
      onInputChange={changeInputValue}
      options={options}
      fullWidth={fullWidth}
      disablePortal
      filterOptions={filterOptions}
      renderOption={renderOption}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          error={Boolean(error)}
          size={size}
          helperText={
            error ? (
              error
            ) : (
              // Taken from react admin
              // MUI's HelperText cannot reserve space unless we pass a single
              // space as child, which isn't possible when the child is a component.
              // Therefore, we must reserve the space ourselves by passing the same
              // markup as MUI.
              // @see
              // eslint-disable-next-line
              // https://github.com/mui-org/material-ui/blob/62e439b7022d519ab638d65201e204b59b77f8da/packages/material-ui/src/FormHelperText/FormHelperText.js#L85-L90
              // eslint-disable-next-line react/no-danger
              <span dangerouslySetInnerHTML={defaultInnerHTML} />
            )
          }
        />
      )}
    />
  );
};

const defaultInnerHTML = { __html: '&#8203;' };
