import {
  useTranslate,
  AutocompleteArrayInput,
  ReferenceArrayInput,
} from 'react-admin';
import { filterSearchToQuery } from 'utils/filters/filters';

export const CitiesAutocomplete: React.FC = () => {
  const translate = useTranslate();

  return (
    <ReferenceArrayInput
      label="Cities"
      source="cities"
      reference="real-estates/cities"
      perPage={100}
    >
      <AutocompleteArrayInput
        variant="outlined"
        debounce={500}
        label={translate('shared.address.city')}
        noOptionsText={translate('shared.filters.noOptions')}
        filterToQuery={filterSearchToQuery}
        optionText={(param) => param.id}
      />
    </ReferenceArrayInput>
  );
};
