import { ReactNode } from 'react';

import { GridRenderCellParams } from '@mui/x-data-grid';
import { Positioning, PositioningStatus } from 'types/positioning.types';

import { ActionButtonGroup } from '../components/ActionButtonGroup';

export const renderAction = ({
  row,
}: GridRenderCellParams<
  PositioningStatus,
  Positioning,
  unknown
>): ReactNode => <ActionButtonGroup positioning={row} />;
