import { CommonInputProps, TextInput, useEditContext } from 'react-admin';
import { GooglePlacesAutocomplete } from 'components/google-places-autocomplete/GooglePlacesAutocomplete';
import { InvoiceRecipient } from 'types/project.types';
import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import { PercentInput } from 'ui/atoms/percent-input/PercentInput';

const commonProps: Partial<CommonInputProps> = {
  margin: 'none',
  variant: 'outlined',
};

interface InvoiceDataFormProps {
  recipient?: InvoiceRecipient;
}

export const InvoiceDataForm: React.FC<InvoiceDataFormProps> = ({
  recipient,
}) => {
  const { record } = useEditContext();
  if (!recipient) {
    return null;
  }

  return (
    <>
      <Fieldset
        nbColumn={recipient === InvoiceRecipient.LegalEntityFrance ? 3 : 2}
      >
        {recipient === InvoiceRecipient.PrivateIndividual ? (
          <>
            <TextInput
              {...commonProps}
              label="project.form.invoiceData.firstNameLabel"
              source="renovation.invoiceData.firstName"
            />
            <TextInput
              {...commonProps}
              label="project.form.invoiceData.lastNameLabel"
              source="renovation.invoiceData.lastName"
            />
          </>
        ) : (
          <>
            <TextInput
              {...commonProps}
              label="project.form.invoiceData.companyLabel"
              source="renovation.invoiceData.company"
            />
            {recipient === InvoiceRecipient.LegalEntityFrance && (
              <TextInput
                {...commonProps}
                label="project.form.invoiceData.siretLabel"
                source="renovation.invoiceData.siret"
              />
            )}
            {recipient !== InvoiceRecipient.LegalEntityOthers && (
              <PercentInput
                {...commonProps}
                label="project.form.invoiceData.tvaIntraLabel"
                source="renovation.invoiceData.tvaIntra"
              />
            )}
          </>
        )}
      </Fieldset>
      <GooglePlacesAutocomplete
        {...commonProps}
        fullWidth
        size="small"
        label="project.form.invoiceData.addressLabel"
        transform={(value) => value}
        defaultValue={record.renovation.invoiceData?.address}
        source="renovation.invoiceData.address"
      />
    </>
  );
};
