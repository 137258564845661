import { AccordionForm } from 'ui/molecules/accordion-form/AccordionForm';
import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import { TextInput, useTranslate } from 'react-admin';
import { ImageInput } from 'components/image-input/ImageInput';
import { TextArea } from 'ui/atoms/textarea/TextArea';

import { EditFormContentProps } from '../editFormContent.types';
import { sourceToLabelMap } from '../EditFormContent.utils';

export const OverviewForm: React.FC<EditFormContentProps> = ({
  defaultExpanded,
  disabled = false,
}) => {
  const translate = useTranslate();

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate('realization.form.overview')}
    >
      <Fieldset title="image" />
      <ImageInput
        disabled={disabled}
        source="coverImage"
        maxFiles={1}
        isForRealization={true}
      />
      <TextArea
        margin="none"
        minRows={1}
        maxRows={10}
        label={sourceToLabelMap.get('pointsOfInterest.businesses')}
        fullWidth
        source="pointsOfInterest.businesses"
        variant="outlined"
        disabled={disabled}
      />
      <TextArea
        margin="none"
        minRows={1}
        maxRows={10}
        label={sourceToLabelMap.get('pointsOfInterest.education')}
        fullWidth
        source="pointsOfInterest.education"
        variant="outlined"
        disabled={disabled}
      />
      <TextArea
        margin="none"
        minRows={1}
        maxRows={10}
        label={sourceToLabelMap.get('pointsOfInterest.transport')}
        fullWidth
        source="pointsOfInterest.transport"
        variant="outlined"
        disabled={disabled}
      />
      <TextArea
        margin="none"
        minRows={1}
        maxRows={10}
        label={sourceToLabelMap.get('pointsOfInterest.places')}
        fullWidth
        source="pointsOfInterest.places"
        variant="outlined"
        disabled={disabled}
      />
      <TextInput
        disabled={disabled}
        margin="none"
        variant="outlined"
        label={sourceToLabelMap.get('url3D')}
        type="url"
        source="url3D"
      />
    </AccordionForm>
  );
};
