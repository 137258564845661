import { GridRenderCellParams } from '@mui/x-data-grid';
import { Translate, useStoreResult } from 'react-admin';
import {
  Positioning,
  PositioningCancelModal,
  PositioningCancellationReason,
} from 'types/positioning.types';
import { Tooltip } from 'ui/molecules/tooltip/Tooltip';
import { Box, styled } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { ActionChip } from 'ui/molecules/action-chip/ActionChip';
import { Chip } from 'ui/molecules/chip/Chip';
import { formatFullName } from 'utils/strings/strings.utils';

import {
  getSortedReasons,
  getTranslatedReasons,
  isCancelledOrPrePositioningCancelled,
} from './cancellationReasonsRenderer.utils';

const CANCElLATION_REASONS_POSITIONING_COLORS = {
  advisorCancellationReasons: {
    bgColor: '#F3E5F5',
    iconColor: '#BA68C8',
  },
  customerCancellationReasons: {
    bgColor: '#E3F2FD',
    iconColor: '#1976D2',
  },
};

const StyledChipListContainer = styled('div')`
  max-width: 100%;
`;

export const cancellationReasonsRenderer = (
  params: GridRenderCellParams<unknown, Positioning, unknown>,
  translate: Translate,
  showCancelModal: useStoreResult<PositioningCancelModal>[1]
): React.ReactNode => {
  let reasonsFocused: PositioningCancellationReason[] | undefined;
  let reasonOtherFocused: string | undefined;
  let field:
    | 'advisorCancellationReasons'
    | 'customerCancellationReasons'
    | undefined;

  const {
    advisorCancellationReasons,
    advisorCancellationReasonOther,
    customerCancellationReasons,
    customerCancellationReasonOther,
    id,
    customer,
    status,
    metadata,
  } = params.row;

  if (params.field === 'advisorCancellationReasons') {
    reasonOtherFocused = advisorCancellationReasonOther;
    reasonsFocused = advisorCancellationReasons;
    field = 'advisorCancellationReasons';
  } else if (params.field === 'customerCancellationReasons') {
    reasonOtherFocused = customerCancellationReasonOther;
    reasonsFocused = customerCancellationReasons;
    field = 'customerCancellationReasons';
  } else {
    return '-';
  }

  const handleActionClick = () => {
    showCancelModal({
      advisorCancellationReasonOther,
      advisorCancellationReasons,
      currentStatus: status,
      customerFullName: formatFullName(customer.firstName, customer.lastName),
      isOpen: true,
      positioningId: id,
    });
  };

  const canUpdate = !!metadata.permissions.canUpdate;

  if (!reasonsFocused || reasonsFocused.length === 0) {
    if (
      field === 'advisorCancellationReasons' &&
      isCancelledOrPrePositioningCancelled(status) &&
      canUpdate
    ) {
      return (
        <ActionChip
          color="primary"
          actionIcon={<AddIcon sx={{ color: 'white' }} />}
          onActionClick={handleActionClick}
          label={translate('shared.toBeSpecified')}
        />
      );
    }
    return '-';
  }

  const translatedReasons = getTranslatedReasons(
    getSortedReasons(reasonsFocused),
    reasonOtherFocused,
    translate
  );

  const isMultiReasons = translatedReasons.length > 1;
  const tooltipTitle = translatedReasons.map((reason) => {
    return <Box key={reason}>{reason}</Box>;
  });

  const isAdvisorCancellationReasons = field === 'advisorCancellationReasons';

  const chipProps = {
    bgColor: CANCElLATION_REASONS_POSITIONING_COLORS[field].bgColor,
    icon: (
      <InfoOutlinedIcon
        fontSize="small"
        className="infoIcon"
        style={{
          color: CANCElLATION_REASONS_POSITIONING_COLORS[field].iconColor,
        }}
      />
    ),
    label: isMultiReasons
      ? `${translatedReasons[0]}, ...`
      : translatedReasons[0],
  };

  return (
    <Tooltip title={tooltipTitle || ''}>
      <StyledChipListContainer>
        {isAdvisorCancellationReasons && canUpdate ? (
          <ActionChip
            {...chipProps}
            actionIcon={<EditIcon />}
            onActionClick={handleActionClick}
          />
        ) : (
          <Chip {...chipProps} />
        )}
      </StyledChipListContainer>
    </Tooltip>
  );
};
