import {
  ListBase,
  ListContext,
  ShowBase,
  ShowContext,
  useTranslate,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { DataTable } from 'components/data-table/DataTable';
import {
  ContentContainer,
  ContentTableContainer,
} from 'components/headerTabs/HeaderTabs.styles';
import { formatTransactionList } from 'features/transactions/list/utils/formatTransactionList.util';
import { getColumns } from 'utils/columns/getColumns.utils';

import { getRealEstateTransactionColumns } from './getRealEstateTransactionColumns';
import { PropertyHeader } from '../components/property-header/PropertyHeader';
import { PropertyBreadCrumbs } from '../components/breadcrumbs/PropertyBreadCrumbs';

export const RealEstateTransactions: React.FC = () => {
  const { id } = useParams();
  const translate = useTranslate();

  const columns = getColumns(
    getRealEstateTransactionColumns(translate),
    translate
  );

  return (
    <ContentContainer>
      <ShowBase>
        <ShowContext.Consumer>
          {({ record }) => {
            return (
              <PropertyHeader
                breadcrumbs={
                  <PropertyBreadCrumbs propertyId={record?.id.toString()} />
                }
              />
            );
          }}
        </ShowContext.Consumer>
      </ShowBase>

      <ListBase
        resource="transactions"
        disableSyncWithLocation
        key="real-estate-transactions"
        filter={{ realEstate: [id] }}
        filterDefaultValues={{ realEstate: [id] }}
        sort={{ field: 'createdAt', order: 'DESC' }}
      >
        <ListContext.Consumer>
          {({ data, isLoading, isFetching }) => (
            <ContentTableContainer>
              <DataTable
                loading={isLoading || isFetching || !columns}
                columns={columns}
                rows={formatTransactionList(data) || []}
                autoHeight
                density="standard"
                noRowsMessage={translate('property.transactionList.noResults')}
              />
            </ContentTableContainer>
          )}
        </ListContext.Consumer>
      </ListBase>
    </ContentContainer>
  );
};
