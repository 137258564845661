import jwtDecode from 'jwt-decode';
import { UserIdentity } from 'react-admin';

import { ACCESS_TOKEN_STORAGE_KEY } from './tokenStorage';

export const checkError = ({ status }: { status: number }): Promise<void> => {
  if (status === 401) {
    const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);

    if (!token) {
      return Promise.reject();
    }

    const user = jwtDecode<UserIdentity>(token);
    if (user.roles?.length === 1 && user.roles[0] === 'user') {
      localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
      return Promise.reject({ message: 'ra.notification.not_authorized' });
    }
    return Promise.reject({
      logoutUser: false,
      message: 'ra.notification.not_authorized',
    });
  }
  // other error code (404, 500, etc.): no need to log out
  return Promise.resolve();
};
