import { useMemo } from 'react';

import {
  useTranslate,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  CheckboxGroupInput,
  useListContext,
} from 'react-admin';
import { CollapsibleSection } from 'ui/molecules/collapsible-section/CollapsibleSection';
import { formatFullName } from 'utils/strings/strings.utils';
import { UserWithAdvisor } from 'types/user.types';
import { RoleName } from 'types/role.types';

import { getStatusCheckboxes } from './FiltersBody.utils';

// @todo: move to utils
const renderOptionUser = ({ firstName, lastName }: UserWithAdvisor) =>
  formatFullName(firstName, lastName);

// @todo: move to utils
const filterSearchToQuery = (search: string) => ({ search });

export const FilterBody: React.FC = () => {
  const translate = useTranslate();

  const { filterValues } = useListContext();

  const statusesCheckboxes = useMemo(
    () => getStatusCheckboxes(translate),
    [translate]
  );

  return (
    <>
      <CollapsibleSection
        label={translate('transaction.statusLabel')}
        isDefaultExpanded={!!filterValues.status?.length}
      >
        <CollapsibleSection.GroupSpacer>
          <CheckboxGroupInput
            source="status"
            optionValue="value"
            optionText="label"
            choices={statusesCheckboxes}
            label={false}
          />
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>

      <CollapsibleSection
        label={translate('positioning.filter.advisors')}
        isDefaultExpanded={!!filterValues.advisor?.length}
      >
        <CollapsibleSection.GroupSpacer>
          <ReferenceArrayInput
            label="Advisors"
            source="advisor"
            reference="users/filter"
            filter={{ roles: [RoleName.Advisor, RoleName.Mandator] }}
            perPage={100}
          >
            <AutocompleteArrayInput
              variant="outlined"
              debounce={500}
              label={translate('positioning.filter.advisors')}
              filterToQuery={filterSearchToQuery}
              optionText={renderOptionUser}
            />
          </ReferenceArrayInput>
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>

      <CollapsibleSection
        label={translate('positioning.filter.customers')}
        isDefaultExpanded={!!filterValues.customer?.length}
      >
        <CollapsibleSection.GroupSpacer>
          <ReferenceArrayInput
            label="customers"
            source="customer"
            reference="users/filter"
            filter={{ roles: [RoleName.User] }}
            perPage={100}
          >
            <AutocompleteArrayInput
              variant="outlined"
              debounce={500}
              label={translate('positioning.filter.customers')}
              filterToQuery={filterSearchToQuery}
              optionText={renderOptionUser}
            />
          </ReferenceArrayInput>
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>
    </>
  );
};
