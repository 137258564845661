import { styled } from '@mui/material';

export const AppCss = styled('div')`
  body {
    background-color: #fafafa;
  }

  @keyframes drawerSlideRightIn {
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes drawerSlideRightOut {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(+100%);
    }
  }
`;
