import isArray from 'lodash/isArray';
import { formatFullName } from 'utils/strings/strings.utils';
import {
  Todos,
  TodosStep,
  TransactionListItem,
  TransactionListItemFormatted,
} from 'types/transaction.types';
import { RealEstateFinancialModelInterface } from 'types/realEstate.types';
import { BaseUser } from 'types/user.types';

export const formatTransactionList = (
  transactionList: TransactionListItem[]
): TransactionListItemFormatted[] => {
  return isArray(transactionList)
    ? transactionList.map(
        ({ user, advisor, todos, property, organization, ...rest }) => ({
          ...rest,
          'advisor.fullName': formatFullName(
            advisor.firstName,
            advisor.lastName
          ),
          currentStep: getStepFromTodos(todos),
          furnituresAndWork: getFurnitureAndWork(property.financialModel),
          organizationName: organization?.name ?? '',
          property,
          title: `${property.address.city} - ${
            property.address.street
          } - ${formatFullName(user.firstName, user.lastName)}`,
          'user.email': user.email,
          'user.fullName': formatFullName(user.firstName, user.lastName),
          // FIXME: need to change the backend to use id instead of _id
          'user.id': (user as BaseUser & { _id: string })._id,
        })
      )
    : [];
};

const getFurnitureAndWork = (
  financialModel: RealEstateFinancialModelInterface
): number => {
  return (
    (financialModel.furnituresAmount ?? 0) +
    (financialModel.renovationAmount ?? 0)
  );
};

export const formatTodosStepId = (id: string): string => {
  return id
    .toLowerCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '');
};

export const getStepFromTodos = (
  todos: { state: string; id: string }[]
): string => {
  const notDone = todos.filter((todo) => todo.state === 'todo');
  if (notDone.length === 0) {
    return 'finished';
  }
  return formatTodosStepId(notDone[0].id);
};

const isDone = (todo: Todos) => {
  if (todo.state === 'skip') {
    return true;
  }
  if (todo.state === 'done' && (!!todo.updateDoc || !!todo.estimatedDate)) {
    if (
      [
        TodosStep.EMAIL_ENVOYE,
        TodosStep.SALE_AGREEMENT_ESTIMATION,
        TodosStep.AUTHENTICATED_DEED_RECEPTION_ESTIMATION,
        TodosStep.VALIDATE_SENDING_NOTARY,
        TodosStep.UPLOAD_ENCAISSEMENT,
        TodosStep.UPLOAD_OFFRE_ACHAT,
      ].includes(todo.id)
    ) {
      return true;
    }
    return !!todo.documents?.[0]?.id;
  }
  return false;
};

export const getDocumentState = (
  todos: Todos[],
  index: number
): 'done' | 'partial' | 'todo' | null => {
  // First step
  if (index === 0 && !todos.some(isDone)) {
    return 'todo';
  }
  const currentTodo = todos[index];

  // Current step done
  if (isDone(currentTodo)) {
    return 'done';
  }

  // Only one of two operations are done
  if (!!currentTodo.updateDoc !== !!currentTodo.documents?.[0]?.id) {
    return 'partial';
  }

  // If the step before is done
  if (isDone(todos[index - 1])) {
    return 'todo';
  }
  // If further steps are reached
  if (todos.slice(index).find(isDone)) {
    return 'todo';
  }

  return null;
};

export const getRealizedOnDate = ({
  id,
  updateDoc,
  updatedAt,
}: Omit<Todos, 'state' | 'updatedAt' | 'documents'> & { updatedAt?: string }):
  | string
  | undefined => {
  if (
    updateDoc &&
    ![TodosStep.EMAIL_ENVOYE, TodosStep.VALIDATE_SENDING_NOTARY].includes(id)
  ) {
    return updatedAt;
  }
  return undefined;
};
