export enum TerritoryStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  ACTIVE_SOON = 'ACTIVE_SOON',
  ACTIVE_WITH_END_DATE = 'ACTIVE_WITH_END_DATE',
}

export type Territory = {
  id: string;
  territoryId: number;
  name: string;
  organization: string | null;
  effectiveDate: Date;
  endDate?: Date;
  status: TerritoryStatus;
};

export type OrganizationUser = {
  firstName: string;
  lastName: string;
  email: string;
};

interface IOrganization {
  id: string;
  isMasteosCore: boolean;
  name: string;
  siret: string;
  users: OrganizationUser[];
}

export type Organization = IOrganization & {
  territories: Territory[];
};

export type OrganizationFormatted = IOrganization & {
  territories: { name: string; status: TerritoryStatus }[];
};
