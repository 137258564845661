import { PropertyKind } from './realEstate.enum';
import { RealEstate, RealEstateAddress } from './realEstate.types';
import { User, UserWithAdvisor } from './user.types';
import { DocumentCategory } from './document.enum';

export interface Positioning {
  id: string;
  realEstateId: string;
  realEstate?: {
    address: RealEstateAddress;
    surface?: RealEstate['surface'];
    rentalType?: RealEstate['locationType'];
    type: PropertyKind;
    totalLots?: RealEstate['totalLots'];
  };
  createdAt: Date;
  updatedAt: Date;
  statusChangedAt: Date;
  status: PositioningStatus;
  previousStatus?: PositioningStatus;
  advisorCancellationReasons?: PositioningCancellationReason[];
  advisorCancellationReasonOther?: string;
  customerCancellationReasons?: PositioningCancellationReason[];
  customerCancellationReasonOther?: string;
  customer: UserWithAdvisor;
  customerId: string;
  ownedBy: User;
  position?: number;
  missingDocuments: string[];
  origin: PositioningOrigin;
  metadata: {
    permissions: {
      canUpdate: boolean;
    };
  };
}

export enum PositioningStatus {
  Pending = 'PENDING',
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Transaction = 'TRANSACTION',
  Archived = 'ARCHIVED',
  Recommendation = 'RECOMMENDATION',
  PrePositioningCancelled = 'PRE_POSITIONING_CANCELLED',
}

export type PositioningFilter = {
  ownedBy?: string[];
  hunter?: string[];
  customerIds?: string[];
  fromDate?: Date | null;
  toDate?: Date | null;
};

export enum PositioningOrigin {
  BackOffice = 'BACK_OFFICE',
  Apollon = 'APOLLON',
}

export interface HeliosCustomerOption {
  fullName?: string;
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  meta?: {
    missingDocuments: DocumentCategory[];
    isPositioned: boolean;
    hasMaxPositioning: boolean;
    positioningCount: number;
  };
}

export enum PositioningCancellationReason {
  BadConfiguration = 'BAD_CONFIGURATION',
  CondominiumCommonParts = 'CONDOMINIUM_COMMON_PARTS',
  CondominiumFacade = 'CONDOMINIUM_FACADE',
  LocationTooFarFromResidence = 'LOCATION_TOO_FAR_FROM_RESIDENCE',
  LocationDontLikeCity = 'LOCATION_DONT_LIKE_CITY',
  LocationDontLikeNeighborhood = 'LOCATION_DONT_LIKE_NEIGHBORHOOD',
  LowYield = 'LOW_YIELD',
  Other = 'OTHER',
  PricePerSquareMeterTooHigh = 'PRICE_PER_SQUARE_METER_TOO_HIGH',
  PoorDpeRating = 'POOR_DPE_RATING',
  TooMuchSavingsEffort = 'TOO_MUCH_SAVINGS_EFFORT',
  SeasonalRentalImpossible = 'SEASONAL_RENTAL_IMPOSSIBLE',
  WorkBudgetTooHigh = 'WORK_BUDGET_TOO_HIGH',
}
export interface PositioningConfirmModal {
  positioningId?: string;
  customerFullName?: string;
  isOpen: boolean;
  currentStatus?: PositioningStatus;
}

export interface PositioningCancelModal {
  positioningId?: string;
  customerFullName?: string;
  isOpen: boolean;
  currentStatus?: PositioningStatus;
  advisorCancellationReasons?: PositioningCancellationReason[];
  advisorCancellationReasonOther?: string;
}
