import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import { TextInput, useEditContext, useInput, useTranslate } from 'react-admin';
import { AccordionForm } from 'ui/molecules/accordion-form/AccordionForm';
import { GooglePlacesAutocomplete } from 'components/google-places-autocomplete/GooglePlacesAutocomplete';
import { getGeocode } from 'use-places-autocomplete';
import { getAddressComponents } from 'utils/google-maps/googleMaps.utils';
import { formatFullStreet } from 'utils/form/form.utils';
import { useFormContext } from 'react-hook-form';

import { RealEstateEditAccordeonFormProps } from '../EditFormContent.types';
import { sourceToLabelMap } from '../EditFormContent.utils';

export const LocalizationForm: React.FC<RealEstateEditAccordeonFormProps> = ({
  defaultExpanded,
  disabled = false,
}) => {
  const translate = useTranslate();
  const { record } = useEditContext();

  const { setError, clearErrors } = useFormContext();

  const fieldStreet = useInput({ source: 'address.street' });
  const fieldCity = useInput({ source: 'address.city' });
  const fieldRegionName = useInput({ source: 'address.regionName' });
  const fieldCountry = useInput({ source: 'address.country' });
  const fieldCountryCode = useInput({ source: 'address.countryCode' });
  const fieldPostalCode = useInput({ source: 'address.postalCode' });

  const handleOnSelect = async (street: string) => {
    if (!street) {
      return;
    }

    let result;

    try {
      [result] = await getGeocode({ address: street });
    } catch (e) {
      fieldStreet.field.onChange(null);
      fieldCity.field.onChange(null);
      fieldRegionName.field.onChange(null);
      fieldCountry.field.onChange(null);
      fieldCountryCode.field.onChange(null);
      fieldPostalCode.field.onChange(null);

      setError('geocode', { message: 'shared.location.invalid_address' });

      return;
    }

    if (result) {
      const formattedAddressComponents = getAddressComponents(result);

      fieldStreet.field.onChange(formattedAddressComponents.street);
      fieldCity.field.onChange(formattedAddressComponents.city);
      fieldRegionName.field.onChange(formattedAddressComponents.regionName);
      fieldCountry.field.onChange(formattedAddressComponents.country);
      fieldCountryCode.field.onChange(formattedAddressComponents.countryCode);
      fieldPostalCode.field.onChange(formattedAddressComponents.postalCode);

      clearErrors('geocode');
    }
  };

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate('realEstate.form.localization')}
    >
      <Fieldset>
        <GooglePlacesAutocomplete
          disabled={disabled}
          fullWidth
          onChange={handleOnSelect}
          source="geocode"
          defaultValue={formatFullStreet(record.address)}
        />
        <TextInput
          margin="none"
          variant="outlined"
          disabled
          label={sourceToLabelMap.get('address.street')}
          source="address.street"
        />
        <TextInput
          disabled={disabled}
          margin="none"
          variant="outlined"
          label={sourceToLabelMap.get('address.complement')}
          source="address.complement"
        />
        <TextInput
          disabled={disabled}
          margin="none"
          variant="outlined"
          label={sourceToLabelMap.get('address.buildingCode')}
          source="address.buildingCode"
        />
        <TextInput
          margin="none"
          variant="outlined"
          disabled
          label={sourceToLabelMap.get('address.postalCode')}
          source="address.postalCode"
        />
        <TextInput
          margin="none"
          variant="outlined"
          disabled
          label={sourceToLabelMap.get('address.city')}
          source="address.city"
        />
        <TextInput
          margin="none"
          variant="outlined"
          disabled
          label={sourceToLabelMap.get('address.regionName')}
          source="address.regionName"
        />
        <TextInput
          margin="none"
          variant="outlined"
          disabled
          label={sourceToLabelMap.get('address.country')}
          source="address.country"
        />
        <TextInput
          margin="none"
          variant="outlined"
          disabled
          label={sourceToLabelMap.get('address.countryCode')}
          source="address.countryCode"
        />
      </Fieldset>
    </AccordionForm>
  );
};
