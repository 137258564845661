import { useCallback, useState } from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Box, css, styled, Theme } from '@mui/material';
import { useTranslate } from 'react-admin';

const getDragStyle = (theme: Theme) => css`
  border-style: dashed;
  background-color: ${theme.palette.background.selected};
`;

export const DropZone = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$ondrag',
})<{ $ondrag: number }>`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  color: ${({ theme }) => theme.palette.primary.main};
  height: 186px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  &:hover {
    ${({ theme }) => getDragStyle(theme)}
  }

  ${({ $ondrag, theme }) => ($ondrag ? getDragStyle(theme) : null)};
`;

const PlaceHolder = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  pointer-events: none;
`;

type FileDropZoneType = {
  onAddFiles: (acceptedFiles: File[]) => void;
  onRejectedFiles: (rejectedFile: FileRejection[]) => void;
  maxFiles?: number;
  source: string;
  accept?: { [n: string]: string[] };
};

export const FileDropZone: React.FC<FileDropZoneType> = ({
  onAddFiles,
  maxFiles,
  onRejectedFiles,
  accept,
}) => {
  const [onDrag, setOnDrag] = useState(false);
  const translate = useTranslate();

  const onDragEnter = useCallback(() => {
    !onDrag && setOnDrag(true);
  }, [onDrag]);

  const onDragLeave = useCallback(() => {
    onDrag && setOnDrag(false);
  }, [onDrag]);

  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      onDragLeave();
      onAddFiles && onAddFiles(acceptedFiles);
    },
    [onAddFiles, onDragLeave]
  );

  const onDropRejected = useCallback(
    (rejectedFiles: FileRejection[]) => {
      onDragLeave();
      onRejectedFiles && onRejectedFiles(rejectedFiles);
    },
    [onRejectedFiles, onDragLeave]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxFiles,
    onDropAccepted,
    onDropRejected,
    useFsAccessApi: false,
  });

  return (
    <DropZone
      {...getRootProps()}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      $ondrag={onDrag ? 1 : 0}
      data-testid="dropZone"
    >
      <input {...getInputProps()} data-testid="dropzone-input" />
      <PlaceHolder>
        <AddCircleOutlineIcon /> {translate('shared.images.add')}
      </PlaceHolder>
    </DropZone>
  );
};
