import {
  AutocompleteArrayInput,
  AutocompleteArrayInputProps,
  RaRecord,
  useInput,
  useTranslate,
} from 'react-admin';

import {
  getBasicAutocompleteInputTextFunction,
  getBasicAutocompleteMatchSuggestionFunction,
} from '../custom-autocomplete.utils';
import { CheckboxOption } from '../custom-autocomplete-options/checkbox-option/CheckboxOption';
import { AutocompleteArrayInputWithCheckboxesProps } from './AutocompleteArrayInputWithCheckboxes.types';

export const AutocompleteArrayInputWithCheckboxes = <
  OptionType extends RaRecord,
  DisableClearable extends boolean | undefined = boolean,
  SupportCreate extends boolean | undefined = boolean
>({
  source,
  checkboxOptionProps = {},
  ...props
}: AutocompleteArrayInputWithCheckboxesProps<
  OptionType,
  DisableClearable,
  SupportCreate
>): JSX.Element => {
  const translate = useTranslate();
  const {
    field: { value },
  } = useInput({
    source,
  });

  return (
    <AutocompleteArrayInput
      blurOnSelect={false}
      disableCloseOnSelect
      filterSelectedOptions={false}
      inputText={getBasicAutocompleteInputTextFunction(translate)}
      matchSuggestion={getBasicAutocompleteMatchSuggestionFunction(translate)}
      optionText={
        <CheckboxOption
          selectedOptions={value}
          preventDefault
          {...checkboxOptionProps}
        />
      }
      source={source}
      {...(props as AutocompleteArrayInputProps<OptionType>)}
    />
  );
};
