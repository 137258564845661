import { AutocompleteRenderGroupParams, Box, Typography } from '@mui/material';
import {
  useTranslate,
  AutocompleteArrayInput,
  ReferenceArrayInput,
} from 'react-admin';
import { filterSearchToQuery } from 'utils/filters/filters';

export const PostalCodesAutocomplete: React.FC = () => {
  const translate = useTranslate();

  return (
    <ReferenceArrayInput
      label="PostalCodes"
      source="postalCodes"
      reference="real-estates/postal-codes"
      perPage={100}
    >
      <AutocompleteArrayInput
        variant="outlined"
        noOptionsText={translate('shared.filters.noOptions')}
        debounce={500}
        groupBy={(option: { id: string; regionCode: string }) =>
          option.regionCode
        }
        renderGroup={(params: AutocompleteRenderGroupParams) => {
          const [country] = params.group.split('-');
          const countryLabel = translate(`shared.country.${country}`);
          const regionLabel = translate(`shared.region.${params.group}`);
          return (
            <li key={params.key}>
              <Box marginLeft={1}>
                <Typography color="GrayText">
                  {countryLabel} : {regionLabel}
                </Typography>
              </Box>

              <Box>{params.children}</Box>
            </li>
          );
        }}
        label={translate('shared.address.postalCode')}
        filterToQuery={filterSearchToQuery}
        optionText={(param) => param.id}
      />
    </ReferenceArrayInput>
  );
};
