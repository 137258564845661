import React from 'react';

import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
  useStore,
  useTranslate,
} from 'react-admin';
import { ActionModal } from 'components/action-modal/ActionModal';
import { Organization, Territory } from 'types/organizations.types';
import { styled } from '@mui/material/styles';
import { HeliosDataProvider } from 'providers/admin/dataProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import { organizationTerritorySchema } from 'features/organizations/validators/organization.validator';
import { formatTerritoriesOptionText } from 'features/organizations/utils/formatTerritoriesOptionText';

export const FormContainer = styled('div')`
  display: grid;
  gap: 24px;
  margin-top: 32px;
  margin-bottom: 24px;
`;

export interface TerritoryEditModalStore {
  territory?: Territory;
  isOpen: boolean;
}

export const TerritoryEditModal: React.FC = () => {
  const notify = useNotify();
  const translate = useTranslate();
  const record = useRecordContext<Organization>();

  const refresh = useRefresh();

  const dataProvider = useDataProvider<HeliosDataProvider>();

  const [{ isOpen, territory }, showModal] = useStore<TerritoryEditModalStore>(
    'territory.show_edit_modal',
    {
      isOpen: false,
    }
  );
  const handleCloseModal = () => {
    showModal({ isOpen: false });
  };

  const handleOnSave = async ({ endDate }: { endDate: Date }) => {
    if (!territory) {
      return;
    }

    try {
      await dataProvider.putTerritory(record.id as string, territory.id, {
        effectiveDate: territory.effectiveDate,
        endDate,
        territoryId: territory.territoryId,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      notify(e.message, {
        type: 'error',
      });
      throw new Error(e.message);
    }

    notify(translate('territory.edit.success'), {
      type: 'success',
    });

    refresh();
    showModal({ isOpen: false });
  };

  return (
    <ActionModal
      title={translate('territory.edit.modalTitle')}
      isOpen={isOpen}
      onClose={handleCloseModal}
      callback={handleOnSave}
      saveLabel={translate('ra.action.confirm')}
      slug="organization-territory-edit"
      resolver={yupResolver(organizationTerritorySchema)}
      defaultValues={{
        effectiveDate: territory?.effectiveDate,
        endDate: territory?.endDate,
        territoryId: territory?.territoryId,
      }}
    >
      <FormContainer>
        <ReferenceInput
          reference="organizations/territories"
          source="territoryId"
        >
          <AutocompleteInput
            label="createOrganization.territories.territoryLabel"
            variant="outlined"
            optionText={formatTerritoriesOptionText}
            disabled
          />
        </ReferenceInput>
        <DateInput
          source="effectiveDate"
          variant="outlined"
          label="createOrganization.territories.effectiveDateLabel"
          defaultValue={null}
          disabled
        />
        <DateInput
          source="endDate"
          variant="outlined"
          label="createOrganization.territories.endDateLabel"
        />
      </FormContainer>
    </ActionModal>
  );
};
