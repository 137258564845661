import React from 'react';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { NumberInput, useTranslate } from 'react-admin';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export interface RangeGroupProps {
  source: string;
  testId?: string;
}

export const RangeGroup: React.FC<RangeGroupProps> = ({
  source,
  testId = '',
}) => {
  const translate = useTranslate();

  return (
    <Container>
      <NumberInput
        source={`${source}.min`}
        label={translate('shared.input.min')}
        variant="outlined"
        helperText={false}
        inputProps={{ 'data-testid': `min-input${testId}` }}
      />
      <NumberInput
        source={`${source}.max`}
        label={translate('shared.input.max')}
        variant="outlined"
        helperText={false}
        inputProps={{ 'data-testid': `max-input${testId}` }}
      />
    </Container>
  );
};
