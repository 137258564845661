import { useCallback, useMemo } from 'react';

import { useGetIdentity, useStore } from 'react-admin';
import {
  Positioning,
  PositioningCancelModal,
  PositioningConfirmModal,
} from 'types/positioning.types';
import { formatFullName } from 'utils/strings/strings.utils';
import { RealEstateModalKey } from 'features/realEstates/constants';
// eslint-disable-next-line max-len
import { GeneratePurchaseOfferModalStore } from 'features/realEstates/details/edit/components/action-modal/GeneratePurchaseOfferModal';

import { getPositioningState } from './actionButtonGroup.utils';
import { Container, ContainerButton } from './ActionButtonGroup.styles';
import { FirstActionButton } from './FirstActionButton';
import { SecondActionButton } from './SecondActionButton';

interface ActionButtonGroupProps {
  positioning: Positioning;
}

export const ActionButtonGroup: React.FC<ActionButtonGroupProps> = ({
  positioning,
}) => {
  const { identity } = useGetIdentity();
  const { status, id, customer } = positioning;

  const [, showConfirmModal] = useStore<PositioningConfirmModal>(
    'positioning.confirm_modal'
  );
  const [, showCancelModal] = useStore<PositioningCancelModal>(
    'positioning.cancel_modal'
  );
  const [, showRepositioningModal] = useStore<PositioningConfirmModal>(
    'positioning.repositioning_modal'
  );

  const [, showPurchaseOfferModal] = useStore<GeneratePurchaseOfferModalStore>(
    RealEstateModalKey.GENERATE_PURCHASE_OFFER,
    {
      isOpen: false,
    }
  );

  const {
    isConfirmDisabled,
    isCancelDisabled,
    canBeRepositioned,
    canCreateOffer,
  } = useMemo(
    () =>
      getPositioningState({
        loggedInUserId: identity?.id,
        positioning,
      }),
    [positioning, identity]
  );

  const customerFullName = useMemo(
    () => formatFullName(customer.firstName, customer.lastName),
    [customer]
  );

  const handleOnCreateOffer = () => {
    if (!canCreateOffer) {
      return;
    }

    showPurchaseOfferModal({
      isOpen: true,
      user: customer,
    });
  };

  const handleOnConfirm = useCallback(() => {
    if (isConfirmDisabled) {
      return;
    }

    showConfirmModal({
      currentStatus: status,
      customerFullName,
      isOpen: true,
      positioningId: id,
    });
  }, [customerFullName, id, isConfirmDisabled, showConfirmModal, status]);

  const handleOnCancel = useCallback(() => {
    if (isCancelDisabled) {
      return;
    }
    showCancelModal({
      advisorCancellationReasons: [],
      currentStatus: status,
      customerFullName,
      isOpen: true,
      positioningId: id,
    });
  }, [customerFullName, id, isCancelDisabled, showCancelModal, status]);

  const handleRepositioning = useCallback(() => {
    if (!canBeRepositioned) {
      return;
    }

    showRepositioningModal({
      currentStatus: status,
      customerFullName,
      isOpen: true,
      positioningId: id,
    });
  }, [canBeRepositioned, customerFullName, id, showRepositioningModal, status]);

  const hasPermissionToUpdate = !!positioning?.metadata.permissions.canUpdate;

  return (
    <Container>
      <ContainerButton>
        <FirstActionButton
          hasPermissionToUpdate={hasPermissionToUpdate}
          canBeRepositioned={canBeRepositioned}
          canCreateOffer={canCreateOffer}
          isConfirmDisabled={isConfirmDisabled}
          handleOnConfirm={handleOnConfirm}
          handleOnCreateOffer={handleOnCreateOffer}
          handleRepositioning={handleRepositioning}
        />
      </ContainerButton>
      <ContainerButton>
        <SecondActionButton
          hasPermissionToUpdate={hasPermissionToUpdate}
          isCancelDisabled={isCancelDisabled}
          handleOnCancel={handleOnCancel}
        />
      </ContainerButton>
    </Container>
  );
};
