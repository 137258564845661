import jwtDecode, { JwtPayload } from 'jwt-decode';

import {
  AuthTokens,
  getTokensFromStorage,
  persistTokens,
} from './tokenStorage';
import { identityApi } from './identityApi';

export async function getValidToken(): Promise<string> {
  const { access_token, refresh_token } = await getTokensFromStorage();
  if (shouldRefreshToken({ access_token, refresh_token })) {
    await refreshTokens({ access_token, refresh_token });
    const { access_token: newAccessToken } = await getTokensFromStorage();
    return newAccessToken;
  }
  return access_token;
}

let isRefreshing: Promise<AuthTokens> | null = null;
export const refreshTokens = ({
  refresh_token,
}: AuthTokens): Promise<AuthTokens> => {
  if (!isRefreshing) {
    isRefreshing = identityApi(`/refresh`, {
      refresh_token,
    })
      .then(persistTokens)
      .finally(() => {
        isRefreshing = null;
      });
  }
  return isRefreshing;
};

const REFRESH_BEFORE_SECONDS = 30;
/**
 * Checks if we can/should refresh the tokens
 * throws error if refresh token is expired
 * @param authTokens : { access_token, refresh_token }
 * @returns shouldRefresh : boolean
 * @throws 'refresh token expired'
 */
function shouldRefreshToken({ access_token, refresh_token }: AuthTokens) {
  const accessTokenTTL = getTokenRemainingSeconds(access_token);
  if (accessTokenTTL < REFRESH_BEFORE_SECONDS) {
    return true;
  }
  const refreshTokenTTL = getTokenRemainingSeconds(refresh_token);
  if (refreshTokenTTL <= 0) {
    return true;
  }
  return false;
}

/**
 * Gets the remaining time left before token expires (in seconds)
 * @param jwtToken : string
 * @returns tokenTTL : number of seconds (can be negative)
 */
export function getTokenRemainingSeconds(jwtToken: string): number {
  const { exp: tokenExpireSeconds } = jwtDecode<JwtPayload>(jwtToken);
  if (!tokenExpireSeconds) {
    return 0;
  }
  const nowSeconds = new Date().getTime() / 1000;
  return tokenExpireSeconds - nowSeconds;
}
