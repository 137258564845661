import { useMemo } from 'react';

import {
  CommonInputProps,
  NumberInput,
  SelectInput,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { CustomerEditFormatted } from 'types/customer.types';
import { FormDivider } from 'ui/atoms/form-divider/FormDivider';
import { AccordionForm } from 'ui/molecules/accordion-form/AccordionForm';
import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import { SelectArrayInput } from 'ui/organisms/select-array-input/SelectArrayInput';
import { getYesNoOrAnyChoices } from 'utils/form/form.commonChoices';
import { useCustomerPermissions } from 'features/customers/hooks/useCustomerPermissions';

import {
  getPropertyKingChoiceList,
  getPropertyRoomCountChoiceList,
  getRegionsChoiceList,
} from './searchSettingsForm.utils';

export const SearchSettingsForm: React.FC<{ defaultExpanded: boolean }> = ({
  defaultExpanded,
}) => {
  const translate = useTranslate();
  const customer = useRecordContext<CustomerEditFormatted>();
  const { checkCanEditCustomer } = useCustomerPermissions();
  const canEditCustomer = checkCanEditCustomer(customer);

  const commonProps: Partial<CommonInputProps> = {
    margin: 'none',
    variant: 'outlined',
  };

  const regionsChoiceList = useMemo(
    () => getRegionsChoiceList(customer?.search?.regions),
    [customer?.search?.regions]
  );

  const propertyKingChoiceList = useMemo(() => getPropertyKingChoiceList(), []);

  const propertyRoomCountChoiceList = useMemo(
    () => getPropertyRoomCountChoiceList(),
    []
  );

  const yesNoOrAnyChoiceList = getYesNoOrAnyChoices(translate);

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={`🔍 ${translate('customer.form.searchCriteria')}`}
    >
      <Fieldset>
        <NumberInput
          source="downPayment"
          label="customer.downPayment"
          disabled={!canEditCustomer}
          {...commonProps}
        />
        <NumberInput
          source="search.budgetMin"
          label="customer.searchSettings.budgetMin"
          disabled={!canEditCustomer}
          {...commonProps}
        />
        <NumberInput
          source="search.budgetMax"
          label="customer.searchSettings.budgetMax"
          disabled={!canEditCustomer}
          {...commonProps}
        />
      </Fieldset>
      <FormDivider />
      <SelectArrayInput
        source="search.regions"
        label="customer.searchSettings.regions"
        choices={regionsChoiceList}
        fullWidth
        disabled={true}
        {...commonProps}
      />
      <Fieldset>
        <SelectArrayInput
          source="search.propertyKind"
          label="customers.searchSettings.propertyKind"
          choices={propertyKingChoiceList}
          disabled={true}
          {...commonProps}
        />
        <SelectArrayInput
          source="search.propertyRoomCount"
          label="customer.searchSettings.propertyRoomCount"
          choices={propertyRoomCountChoiceList}
          disabled={true}
          {...commonProps}
        />
        <SelectInput
          source="search.propertyOnTheGroundFloor"
          label="customer.searchSettings.groundFloor"
          choices={yesNoOrAnyChoiceList}
          disabled={true}
          {...commonProps}
        />
      </Fieldset>
      <Fieldset>
        <SelectInput
          source="search.sharedProperty"
          label="customer.searchSettings.flatSharing"
          choices={yesNoOrAnyChoiceList}
          disabled={true}
          {...commonProps}
        />
        <SelectInput
          source="search.hasNeedRenovation"
          label="customer.searchSettings.renovationNeeded"
          choices={yesNoOrAnyChoiceList}
          disabled={true}
          {...commonProps}
        />
        <SelectInput
          source="search.propertyRented"
          label="customer.searchSettings.propertyRented"
          choices={yesNoOrAnyChoiceList}
          disabled={true}
          {...commonProps}
        />
      </Fieldset>
    </AccordionForm>
  );
};
