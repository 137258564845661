import { ReactNode } from 'react';

import { GridRenderCellParams } from '@mui/x-data-grid';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { Positioning, PositioningStatus } from 'types/positioning.types';

export const renderElapsedTime = ({
  row,
}: GridRenderCellParams<PositioningStatus, Positioning>): ReactNode => {
  return (
    <div key={row.id}>
      {formatDistanceToNow(new Date(row.updatedAt), {
        includeSeconds: true,
      })}
    </div>
  );
};
