import { useMemo } from 'react';

import {
  ListBase,
  SortPayload,
  useListController,
  useTranslate,
} from 'react-admin';
import { FilteredListLayout } from 'ui/layouts/filtered-list-layout/FilteredListLayout';
import { DataTable } from 'components/data-table/DataTable';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { PositioningFilter } from 'types/positioning.types';

import { PositioningsFilters } from './components/filters/PositioningsFilters';
import { getPositioningColumns } from './utils/getPositioningColumns';

const PER_PAGE = 20;
const DEFAULT_SORT: SortPayload = { field: 'statusChangedAt', order: 'DESC' };

const PAST_MONTH = new Date(new Date().setMonth(new Date().getMonth() - 1));

const DEFAULT_FILTERS: PositioningFilter = {
  fromDate: PAST_MONTH,
};

export const PositioningsList: React.FC = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const theme = useTheme();

  const columns = getPositioningColumns(translate, theme);

  const { data, isLoading, total } = useListController({
    filterDefaultValues: DEFAULT_FILTERS,
    perPage: PER_PAGE,
    sort: DEFAULT_SORT,
  });

  const table = useMemo(
    () => (
      <DataTable
        onRowClick={({ row }) =>
          navigate(`/real-estates/${row.realEstateId}/positionings`)
        }
        loading={isLoading}
        columns={columns}
        rows={data || []}
        initialState={{
          sorting: {
            sortModel: [{ field: 'statusChangedAt', sort: 'desc' }],
          },
        }}
      />
    ),
    [isLoading, data, navigate, columns]
  );

  return (
    <ListBase
      perPage={PER_PAGE}
      filterDefaultValues={DEFAULT_FILTERS}
      sort={DEFAULT_SORT}
    >
      <FilteredListLayout
        main={table}
        filters={<PositioningsFilters />}
        title={translate('positioning.list', { count: total ?? 0 })}
      />
    </ListBase>
  );
};
