import * as yup from 'yup';
import {
  addressSchema,
  archivingReasonSchema,
  propertyKindSchema,
  propertyLangSchema,
  synthesisSchema,
  roomNumberSchema,
  floorSchema,
  DPESchema,
  GESSchema,
  loanDownPaymentSchema,
  loanInterestRateSchema,
  loanDurationSchema,
  exclusiveEndDateSchema,
  agencyCommissionSchema,
  customerWithMandateSchema,
  netSellerSchema,
  contactSchema,
  shortTermRentalSchema,
  renovationDetailsSchema,
} from 'features/realEstates/validators/realEstate.validator';

// @todo: get schema from helios backend
export const propertyEditFormValidator = yup.object().shape({
  address: addressSchema,
  contact: contactSchema,
  exclusiveEndDate: exclusiveEndDateSchema,
  'financialModel.shortTermNightlyRent': shortTermRentalSchema(
    'shortTermNightlyRent'
  ),
  'financialModel.shortTermOccupancyRate': shortTermRentalSchema(
    'shortTermOccupancyRate'
  ),
  floor: floorSchema,
  lang: propertyLangSchema,
  'realEstate.dpe': DPESchema,
  'realEstate.ges': GESSchema,
  renovationDetails: renovationDetailsSchema,
  roomNumber: roomNumberSchema,
  synthesis: synthesisSchema,
  type: propertyKindSchema,
  url3D: yup.string().url().nullable(),
});

export const propertyArchiveForm = yup.object().shape({
  archivingReason: archivingReasonSchema,
});

export const propertyDownloadBankPdfForm = yup.object().shape({
  loanDownPayment: loanDownPaymentSchema,
  loanDuration: loanDurationSchema,
  loanInterestRate: loanInterestRateSchema,
});

export const propertyPurchaseOfferForm = yup.object().shape({
  agencyCommission: agencyCommissionSchema,
  customer: customerWithMandateSchema,
  netSeller: netSellerSchema,
  secondInvestor: yup.object().when('second_investor_toggle', {
    is: true,
    otherwise: yup.object().notRequired(),
    then: yup
      .object()
      .shape({
        address: yup.string().required('shared.location.addressRequired'),
        birthCity: yup
          .string()
          .required('shared.inputErrors.birthCityRequired'),
        birthDate: yup.date().required('shared.inputErrors.birthDateRequired'),
        firstName: yup
          .string()
          .required('shared.inputErrors.firstNameRequired'),
        lastName: yup.string().required('shared.inputErrors.lastNameRequired'),
      })
      .required(),
  }),
});
