import { FunctionComponent, useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Form,
  required,
  SaveButton,
  TextInput,
  useDataProvider,
  useTranslate,
} from 'react-admin';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { FieldValues } from 'react-hook-form';
import { AuthCard } from 'ui/organisms/auth-card/AuthCard';
import { Box } from '@mui/material';
import { HeliosDataProvider } from 'providers/admin/dataProvider';
import { ResetPasswordBody } from 'types/customMethods.types';

import { codeSchema } from '../resetPasswordValidation';

export const StepCode: FunctionComponent = () => {
  const [searchParams] = useSearchParams({});
  const email = searchParams.get('email');
  const translate = useTranslate();
  const dataProvider = useDataProvider<HeliosDataProvider>();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (data: FieldValues) => {
      try {
        const result = await dataProvider.resetPassword(
          data as ResetPasswordBody
        );
        return navigate({
          pathname: '/reset-password/new-password',
          search: createSearchParams({
            email: (data as ResetPasswordBody).email,
            hash: result.data.hash,
          }).toString(),
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        return { code: e.message };
      }
    },
    [dataProvider, navigate]
  );

  return (
    <AuthCard
      title={translate('forgotPassword.stepCode.title')}
      subtitle={translate('forgotPassword.stepCode.subtitle', { email })}
    >
      <Form
        mode="onChange"
        reValidateMode="onChange"
        onSubmit={handleSubmit}
        defaultValues={{ code: '', email: email }}
        resolver={yupResolver(codeSchema)}
      >
        <Box mb={2}>
          <TextInput
            label="forgotPassword.stepCode.codeLabel"
            variant="outlined"
            type="text"
            source="code"
            validate={[required()]}
            size="medium"
            margin="none"
            fullWidth
            inputProps={{
              role: 'input',
            }}
          />
        </Box>
        <SaveButton
          disabled={false}
          variant="contained"
          type="submit"
          size="large"
          fullWidth
          label="submit"
          icon={<></>}
          data-testid="forgot-password-code-submit"
        >
          {translate('forgotPassword.stepCode.sendCode')}
        </SaveButton>
      </Form>
    </AuthCard>
  );
};
