import { useDataProvider, useTranslate } from 'react-admin';
import { HeliosDataProvider } from 'providers/admin/dataProvider';
import { useNotification } from 'hooks/use-notification/useNotification';

export const useRealEstatePublicLink = (): {
  generateAndCopyPublicLink: (
    id: string,
    callback?: (isSuccess: boolean, link?: string) => void
  ) => Promise<void>;
  generateAndOpenPublicLink: (id: string) => Promise<void>;
} => {
  const dataProvider = useDataProvider<HeliosDataProvider>();
  const { openSuccess, openError, openWarning } = useNotification();
  const translate = useTranslate();

  const generatePublicLink = async (id: string) => {
    return dataProvider.generatePublicLink(id);
  };

  const generateAndOpenPublicLink = async (id: string) => {
    try {
      const {
        data: { url },
      } = await generatePublicLink(id);
      window.open(url, '_blank')?.focus();
    } catch (error) {
      openError({
        message: translate('realEstate.action.openPublicLink.error'),
      });
    }
  };

  const generateAndCopyPublicLink = async (
    id: string,
    callback?: (isSuccess: boolean, link?: string) => void
  ) => {
    try {
      const {
        data: { url },
      } = await generatePublicLink(id);
      navigator.clipboard
        .writeText(url)
        .then(() => {
          openSuccess({
            message: translate('realEstate.action.copyPublicLink.success'),
          });
        })
        .catch(() => {
          openWarning({
            message: translate(
              'realEstate.action.copyPublicLink.errorWithLink',
              {
                link: url,
                interpolation: { escapeValue: false },
              }
            ),
          });
        });
      if (callback) {
        callback(true, url);
      }
    } catch (error) {
      openError({
        message: translate('realEstate.action.copyPublicLink.error'),
      });
      if (callback) {
        callback(false);
      }
    }
  };

  return {
    generateAndCopyPublicLink,
    generateAndOpenPublicLink,
  };
};
