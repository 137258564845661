import { useRefresh, useStore, useTranslate } from 'react-admin';
import { CustomerDrawerKey, MandateLocal } from 'types/customer.types';
import { GenerateSearchMandateBody } from 'types/customMethods.types';
import { Drawer } from 'ui/organisms/drawer/Drawer';

import {
  GenerateSearchMandateDrawerForm,
  GenerateSearchMandateDrawerFormRecord,
} from './GenerateSearchMandateDrawerForm';
import { useGenerateSearchMandateAction } from './useGenerateSearchMandateAction.hook';

export interface GenerateSearchMandateDrawerProps {
  record: GenerateSearchMandateDrawerFormRecord | undefined;
}

export const GenerateSearchMandateDrawer: React.FC<
  GenerateSearchMandateDrawerProps
> = ({ record }) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const [drawerStore, showDrawer] = useStore(
    CustomerDrawerKey.GENERATE_SEARCH_MANDATE,
    [false, MandateLocal.FR_fr] as [boolean, string]
  );

  const [isDrawerOpen, local] = drawerStore;

  const handleCloseDrawer = () => {
    refresh();
    showDrawer([false, MandateLocal.FR_fr]);
  };

  const { mutate, isLoading } = useGenerateSearchMandateAction();

  const handleSubmit = async (data: GenerateSearchMandateBody) => {
    await mutate(data, {
      onSuccess: handleCloseDrawer,
    });
  };

  return (
    <Drawer
      open={isDrawerOpen}
      title={translate('customer.generateSearchMandate.title')}
      anchor="right"
      onClose={handleCloseDrawer}
      data-testid="generate-search-mandate-drawer"
    >
      <GenerateSearchMandateDrawerForm
        record={record}
        local={local}
        isFetching={isLoading}
        handleCancel={handleCloseDrawer}
        handleSubmit={handleSubmit}
      />
    </Drawer>
  );
};
