import { FinancingCertificateStatus } from './financing-certificate.types';
import { ManagementMandateStatus } from './management-mandate.types';

export type ExternalCustomerListItem = {
  id: string;
  phoneNumber: string;
  email: string;
  maxBudget: number;
  downPayment: number;
  fullName: string;
  financingCertificateStatus: FinancingCertificateStatus;
  managementMandateStatus: ManagementMandateStatus;
};

export type ExternalCustomerBase = {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  email: string;
};

export type ExternalCustomerDetail = {
  id: string;
  documents?: ExternalCustomerDetailDocuments;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  maxBudget: number;
  downPayment: number;
  address?: {
    city: string;
    country: string;
    countryCode: string;
    lat: number;
    lng: number;
    placeId: string;
    postalCode: string;
    street: string;
  };
  birthCity: string;
  birthDate: string;
  internalNote: string;
  owner: {
    id: string;
    firstName: string;
    lastName: string;
  };
};

export type ExternalCustomerDetailDocuments = {
  financingCertificate?: {
    S3ID: string;
    createdAt: Date;
    id: string;
    toVerify: boolean;
    updatedAt: Date;
  };
  mandate?: {
    createdAt: Date;
    id: string;
    toVerify: boolean;
    updatedAt: Date;
    pandadocID: string;
    expiresAt: Date;
    signedAt?: Date;
  };
};

export enum ExternalCustomerModalKey {
  CREATE_EXTERNAL_CUSTOMER = 'external_customer.create.create_external_customer_modal',
}
