import React, { MouseEvent } from 'react';

import { useRecordContext, useTranslate } from 'react-admin';
import { Checkbox } from 'ui/molecules/checkbox/Checkbox';

import { CheckboxOptionProps } from './CheckboxOption.types';

export const CheckboxOption: React.FC<CheckboxOptionProps> = ({
  preventDefault = false,
  recordField = 'name',
  selectedOptions,
  ...props
}) => {
  const record = useRecordContext();
  const translate = useTranslate();
  const label = translate(record[recordField]);
  const eventPreventDefault = (event: MouseEvent<HTMLDivElement>) =>
    preventDefault && event.preventDefault();

  return !record[recordField] ? null : (
    <div onClick={eventPreventDefault}>
      <Checkbox
        checked={selectedOptions?.includes(record.id) ?? false}
        key={label}
        label={label}
        onChange={() => null}
        value={label}
        {...props}
      />
    </div>
  );
};
