import { PermissionName } from 'types/permission.types';
import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';

export type RealEstatePermissionsType = {
  canAddOrDuplicate: boolean;
  setCurrentUserAsHunterFilter: boolean;
};

export const useRealEstatePermissions = (): RealEstatePermissionsType => {
  const { hasPermission, isHunter, isMandator } = useHeliosPermissions();

  const canCreateRealEstate = hasPermission(PermissionName.CreateRealEstate);

  const setCurrentUserAsHunterFilter =
    hasPermission(PermissionName.CreateRealEstate) && (isHunter || isMandator);

  return {
    canAddOrDuplicate: canCreateRealEstate,
    setCurrentUserAsHunterFilter,
  };
};
