import React from 'react';

import { FieldValues } from 'react-hook-form';
import { Button } from '@mui/material';
import { Modal, ModalFooterButtonsContainer } from 'ui/organisms/modal/Modal';
import { LoadingButton } from 'ui/molecules/loading-button/LoadingButton';
import {
  Create,
  useCreateController,
  useNotify,
  useRefresh,
  useStore,
  useTranslate,
} from 'react-admin';
import {
  ExternalCustomerBase,
  ExternalCustomerModalKey,
} from 'types/external-customer.types';
import { formatAddCustomerError } from 'features/customers/list/utils/formatAddCustomerError.utils';

import { AddExternalCustomerModalStore } from './AddExternalCustomerModal.types';
import { AddExternalCustomerForm } from './AddExternalCustomerForm';

export const AddExternalCustomerModal: React.FC = () => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();

  const { save, isLoading, saving } = useCreateController<
    ExternalCustomerBase,
    Error
  >({
    resource: 'external-customers',
  });

  const [{ isOpen, title }, setAddCustomerModalStore] =
    useStore<AddExternalCustomerModalStore>(
      ExternalCustomerModalKey.CREATE_EXTERNAL_CUSTOMER,
      {
        isOpen: false,
      }
    );

  const onClose = () => {
    setAddCustomerModalStore({ isOpen: false, title: '' });
  };

  const handleSubmit = (data: FieldValues) => {
    if (save) {
      save(data, {
        onError: (err) => {
          notify(formatAddCustomerError(err), {
            messageArgs: {
              advisor: err.body.exception?.context?.advisorName,
            },
            type: 'error',
          });
        },
        onSuccess: () => {
          refresh();
          onClose();
          notify('customer.create.success', {
            type: 'success',
          });
        },
      });
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={title ?? ''}
      footer={
        <ModalFooterButtonsContainer>
          <Button variant="text" onClick={onClose}>
            {translate('ra.action.cancel')}
          </Button>
          <LoadingButton
            loading={isLoading || saving}
            variant="text"
            type="submit"
            form="customer-create-form"
            data-testid="customer-create-submit-button"
          >
            {translate('ra.action.add')}
          </LoadingButton>
        </ModalFooterButtonsContainer>
      }
    >
      <Create>
        <AddExternalCustomerForm onSubmit={handleSubmit} />
      </Create>
    </Modal>
  );
};
