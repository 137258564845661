import React, { useCallback, useState } from 'react';

import { Box, Button } from '@mui/material';
import { useTranslate, useEditContext, NumberInput } from 'react-admin';
import { styled } from '@mui/material/styles';
import { ModalFooterButtonsContainer, Modal } from 'ui/organisms/modal/Modal';
import { GooglePlacesAutocomplete } from 'components/google-places-autocomplete/GooglePlacesAutocomplete';
import { Select } from 'ui/molecules/select/Select';
import { formatFullStreet } from 'utils/form/form.utils';
import { PriceInput } from 'ui/atoms/price-input/PriceInput';
import { LoadingButton } from 'ui/molecules/loading-button/LoadingButton';
import { RealEstateType } from 'types/realEstate.enum';

import { LANGS_DATA, PROPERTY_KIND_DATA } from 'constants/real-estates';

import { SelectOnChangeEvent } from './types';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
`;

interface AddRealEstateModalFormProps {
  open: boolean;
  onClose: () => void;
  loading: boolean;
}

export const AddRealEstateModalForm: React.FC<AddRealEstateModalFormProps> = ({
  open,
  onClose,
  loading,
}) => {
  const { record } = useEditContext();
  const translate = useTranslate();

  const isDuplicating = !!record;
  const [shouldDisplayQualityFields, setShouldDisplayQualityFields] =
    useState<boolean>(isDuplicating && record.type !== RealEstateType.Building);

  const [shouldDisplayInputs, setShouldDisplayInputs] = useState<boolean>(
    !!record?.type
  );

  const onChangePropertyKind = useCallback((e: unknown) => {
    const { target } = e as SelectOnChangeEvent;

    setShouldDisplayInputs(!!target?.value);
    setShouldDisplayQualityFields(target.value !== RealEstateType.Building);
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={translate(
        isDuplicating
          ? 'realEstate.action.duplicate.form_title'
          : 'realEstate.add'
      )}
      footer={
        <ModalFooterButtonsContainer>
          <Button variant="text" onClick={onClose}>
            {translate('ra.action.cancel')}
          </Button>
          <LoadingButton
            variant="text"
            type="submit"
            form="create-property-form"
            loading={loading}
            data-testid="form-add-btn"
          >
            {!loading &&
              translate(
                isDuplicating
                  ? 'realEstate.action.duplicate.submit'
                  : 'ra.action.add'
              )}
          </LoadingButton>
        </ModalFooterButtonsContainer>
      }
    >
      <Container data-testid="create-real-estate-form-container">
        <Select
          label="realEstate.input.propertyKind"
          choices={PROPERTY_KIND_DATA}
          source="type"
          variant="outlined"
          margin="none"
          onChange={onChangePropertyKind}
          inputProps={{ 'data-testid': 'propertyKind-select' }}
        />

        {!!shouldDisplayInputs && (
          <>
            <GooglePlacesAutocomplete
              source="address"
              defaultValue={record ? formatFullStreet(record.address) : ''}
              countryRestrictions
            />
            {!!shouldDisplayQualityFields && (
              <>
                <PriceInput
                  source="financialModel.netSeller"
                  label="create.financial.netSeller"
                  margin="none"
                  variant="outlined"
                  data-testid="netSeller"
                />
                <PriceInput
                  source="financialModel.renovationAmount"
                  label="create.financialModel.renovationAmount"
                  margin="none"
                  variant="outlined"
                />
                <NumberInput
                  source="roomNumber"
                  label="create.realEstate.input.totalLots"
                  margin="none"
                  variant="outlined"
                />
                <NumberInput
                  source="surface"
                  label="create.realEstate.input.surface"
                  margin="none"
                  variant="outlined"
                />
                <NumberInput
                  source="financialModel.annualRentIncome"
                  label="create.financialModel.annualRentIncome"
                  margin="none"
                  variant="outlined"
                />
                <NumberInput
                  source="financialModel.annualCharges"
                  label="create.financialModel.annualCharges"
                  margin="none"
                  variant="outlined"
                />
              </>
            )}

            <Select
              label="realEstate.input.lang"
              choices={LANGS_DATA}
              source="lang"
              variant="outlined"
              margin="none"
              inputProps={{ 'data-testid': 'lang-select' }}
            />
          </>
        )}
      </Container>
    </Modal>
  );
};
