export const sourceToLabelMap = new Map<string, string>([
  ['type', 'realization.input.propertyKind'],
  ['surface', 'realization.input.surface'],
  ['url3D', 'realization.input.url3D'],
  ['condominium', 'realization.input.condominium'],
  ['totalLots', 'realization.input.totalLots'],
  [
    'pointsOfInterest.businesses',
    'realization.input.pointsOfInterest.businesses',
  ],
  ['pointsOfInterest.places', 'realization.input.pointsOfInterest.places'],
  [
    'beforeRenovation.description',
    'realization.input.beforeRenovation.description',
  ],
  ['beforeRenovation.images', 'realization.input.beforeRenovation.images'],
  ['beforeRenovation.dpe', 'realization.input.beforeRenovation.dpe'],
  [
    'afterRenovation.description',
    'realization.input.afterRenovation.description',
  ],
  ['afterRenovation.images', 'realization.input.afterRenovation.images'],
  ['afterRenovation.dpe', 'realization.input.afterRenovation.dpe'],
  ['afterRenovation.layout', 'realization.input.afterRenovation.layout'],
  ['afterRenovation.esthetics', 'realization.input.afterRenovation.esthetics'],
  [
    'afterRenovation.performances',
    'realization.input.afterRenovation.performances',
  ],
  [
    'pointsOfInterest.education',
    'realization.input.pointsOfInterest.education',
  ],
  [
    'pointsOfInterest.transport',
    'realization.input.pointsOfInterest.transport',
  ],
  ['address.city', 'realization.input.city'],
  ['address.regionName', 'realization.input.regionName'],
  ['address.country', 'realization.input.country'],
  ['address.countryCode', 'realization.input.countryCode'],
  ['finances.renovationAmount', 'financial.renovationAmount'],
  ['finances.annualRent', 'financial.annualRent'],
  ['finances.type', 'realization.input.strategy'],
  ['finances.annualCharges', 'financial.annualCharges'],
  ['finances.projectAmount', 'financial.projectAmount'],
  ['finances.grossReturn', 'financial.grossReturn'],
  ['finances.netReturn', 'financial.netReturn'],
  ['finances.monthlyAmount', 'financial.monthlyAmount'],
  ['finances.monthlyCashflow', 'financial.monthlyCashflow'],
  ['finances.rentingDelay', 'realization.input.rentingDelay'],
]);
