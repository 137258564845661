import { SnackbarKey, SnackbarAction } from 'notistack';
import { CloseButton } from 'ui/atoms/close-button/CloseButton';

type CloseSnackbar = (snackbarKey?: SnackbarKey) => void;

interface CloseActionProps {
  closeSnackbar: CloseSnackbar;
}

export interface GetSnackbarActionProps {
  closeSnackbar: CloseSnackbar;
  closable?: boolean;
}

export const getSnackBarAction = ({
  closeSnackbar,
  closable,
}: GetSnackbarActionProps): { action?: SnackbarAction } => {
  if (closable) {
    return { action: closeAction({ closeSnackbar }) };
  }

  return {};
};

export const closeAction = ({
  closeSnackbar,
}: CloseActionProps): ((snackbarId: SnackbarKey) => JSX.Element) => {
  const action = (snackbarId: SnackbarKey) => (
    <CloseButton onClose={() => closeSnackbar(snackbarId)} onDark />
  );
  return action;
};
