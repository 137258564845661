const furniturePacks = {
  T1: 7_338,
  T2: 10_082,
  T3: 12_234,
  T4: 14_990,
  T5: 17_740,
  T6: 20_662,
};

export function computeFurnitureBudget(roomNumber?: number | null): number {
  switch (roomNumber) {
    case undefined:
    case null:
    case 0:
      return 0;
    case 1:
      return furniturePacks.T1;
    case 2:
      return furniturePacks.T2;
    case 3:
      return furniturePacks.T3;
    case 4:
      return furniturePacks.T4;
    case 5:
      return furniturePacks.T5;
    case 6:
      return furniturePacks.T6;
    default:
      return furniturePacks.T6;
  }
}
