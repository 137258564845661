import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'components/form/Form';
import { MobileFooter } from 'components/mobile-footer/MobileFooter';
import { ExternalCustomerDetail } from 'types/external-customer.types';
import { ExternalCustomerHeader } from 'features/external-customers/details/components/header/ExternalCustomerHeader';
import { CustomerCivility } from 'types/customer.types';
import { formatCustomerAddress } from 'utils/form/form.utils';
import { GenerateSearchMandateDrawerFormRecord } from 'features/customers/details/edit/component/generate-search-mandate-drawer/GenerateSearchMandateDrawerForm';

import { GenerateExternalCustomerSearchMandateDrawer } from '../generate-external-customer-search-mandate-drawer/GenerateExternalCustomerSearchMandateDrawer';
import { externalCustomerEditFormValidator } from './EditForm.validator';
import { EditFormContent } from './edit-form-content/EditFormContent';
import { ActionButtons } from './action-buttons/ActionButtons';

type CustomerEditFormProps = {
  record: ExternalCustomerDetail | undefined;
};

export const transformForDisplay = ({
  address,
  documents,
  maxBudget,
  ...record
}: ExternalCustomerDetail): GenerateSearchMandateDrawerFormRecord => {
  return {
    ...record,
    address: {
      ...address,
      street: formatCustomerAddress(address),
    },
    civility: CustomerCivility.UNKNOWN,
    search: {
      budgetMax: maxBudget,
    },
  };
};

export const EditForm: React.FC<CustomerEditFormProps> = ({ record }) => {
  const formattedRecord = record && transformForDisplay(record);
  return (
    <Form
      mode="onBlur"
      record={record}
      resolver={yupResolver(externalCustomerEditFormValidator)}
    >
      <ExternalCustomerHeader actionButtons={<ActionButtons />} />
      <EditFormContent />
      <GenerateExternalCustomerSearchMandateDrawer
        record={formattedRecord}
        isExternalCustomer={true}
      />
      <MobileFooter>
        <ActionButtons />
      </MobileFooter>
    </Form>
  );
};
