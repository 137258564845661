import React, { useMemo } from 'react';

import {
  ChipField,
  TextField,
  useShowContext,
  useTranslate,
} from 'react-admin';
import {
  getDocumentState,
  getRealizedOnDate,
  getStepFromTodos,
} from 'features/transactions/list/utils/formatTransactionList.util';
import {
  CancellationReasons,
  Steps,
  Todos,
  Transaction,
} from 'types/transaction.types';
import { SectionContainer } from 'ui/molecules/section-container/SectionContainer';
import { formatFullName } from 'utils/strings/strings.utils';
import { PriceFieldWithLabel } from 'ui/atoms/price-field/PriceField';
import { withLabel } from 'ui/hoc/with-label/withLabel';
import { DateFieldWithLabel } from 'ui/atoms/date-field/DateField';
import { useRealEstateTitle } from 'hooks/use-realEstate-title/useRealEstateTitle';
import { RealEstateType } from 'types/realEstate.enum';

import { CURRENT_STEP_COLORS, STATUS_COLORS } from 'constants/transactions';

import { DocumentsSection } from '../documents-section/DocumentsSection';
import { InformationsSection } from '../informations-section/InformationsSection';
import { Container } from './Content.styles';
import { WorkSection } from '../work-section/WorkSection';
import { InternalNoteSection } from '../internal-note-section/InternalNoteSection';

const TextFieldWithLabel = withLabel(TextField);
const ChipFieldWithLabel = withLabel(ChipField);

export const Content: React.FC = () => {
  const { record } = useShowContext();
  const translate = useTranslate();

  const {
    advisor,
    documents: documentsFile,
    property,
    todos,
    steps,
    user,
    secondInvestor,
    observations,
  } = record as Transaction;

  const realEstateTitle = useRealEstateTitle({
    city: property.address.city,
    locationType: property.locationType,
    surface: property.surface,
    totalLots: property.totalLots,
    type: property.type as RealEstateType,
  });

  const getTodos = (t: Todos[], s: Steps) => {
    const updatedTodo = [...t];
    updatedTodo.splice(1, 0, s.estimatedSalesAgreement);
    updatedTodo.splice(4, 0, s.estimatedAuthenticatedDeedReception);

    return updatedTodo;
  };

  const informationProps = {
    adresse: `${property.address.street}, ${property.address.city}, ${property.address.country}`,
    advisorEmail: advisor.email,
    advisorFullName: formatFullName(advisor.firstName, advisor.lastName),
    hunterEmail: property.hunter.email,
    hunterFullName: formatFullName(
      property.hunter.firstName,
      property.hunter.lastName
    ),
    investorUrl: `${process.env.REACT_APP_HUBSPOT_CONTACT_BY_EMAIL_URL}${user.email}`,
    observations,
    propertyId: property.id,
    realEstateTitle,
    searchMandate: documentsFile.find(
      (doc) => doc.category === 'SEARCH_MANDATE'
    ),
    secondInvestorFullName: secondInvestor
      ? formatFullName(secondInvestor.firstName, secondInvestor.lastName)
      : undefined,
    userFullName: formatFullName(user.firstName, user.lastName),
  };

  const todosStep = useMemo(
    () =>
      getTodos(todos, steps).map(
        (
          { documents, id, updatedAt, updateDoc, estimatedDate }: Todos,
          index: number,
          array: Todos[]
        ) => (
          <DocumentsSection
            date={updateDoc ?? estimatedDate}
            documentId={documents?.[0]?.id}
            isLast={index === array.length - 1}
            key={id}
            realizedOnDate={getRealizedOnDate({
              id,
              updateDoc,
              updatedAt,
            })}
            state={getDocumentState(array, index)}
            todoId={id}
          />
        )
      ),
    [todos, steps]
  );

  return (
    <Container>
      <SectionContainer
        name="info"
        title={translate('transaction.show.section.title.informations')}
      >
        <InformationsSection {...informationProps} />
      </SectionContainer>
      <SectionContainer
        name="finances"
        title={translate('transaction.show.section.title.finances')}
      >
        <PriceFieldWithLabel
          label={translate('transaction.show.section.label.netReturn')}
          source="realNetSeller"
          record={record}
        />
        <PriceFieldWithLabel
          label={translate('transaction.show.section.label.masteosCommission')}
          source="realMasteosCommission"
          record={record}
        />
        <PriceFieldWithLabel
          label={translate('transaction.show.section.label.agencyCommission')}
          source="realAgencySellerCommission"
          record={record}
        />
      </SectionContainer>
      <SectionContainer
        name="work"
        title={translate('transaction.show.section.title.work')}
      >
        <WorkSection />
      </SectionContainer>
      <SectionContainer
        name="internalNote"
        title={translate('shared.internalNote')}
      >
        <InternalNoteSection />
      </SectionContainer>
      <SectionContainer
        name="status"
        title={translate('transaction.show.section.title.status')}
      >
        <ChipFieldWithLabel
          label={translate('transaction.show.section.label.currentStep')}
          source="currentStep"
          record={{
            currentStep: translate(
              `transaction.currentStep.${record.currentStep}`
            ),
          }}
          color={CURRENT_STEP_COLORS[record.currentStep]}
        />
        <DateFieldWithLabel
          label={translate('transaction.show.section.label.projectStartedAt')}
          source="createdAt"
          record={record}
          showTime={false}
        />
        <DateFieldWithLabel
          label={translate('transaction.show.section.label.lastActionAt')}
          source="updatedAt"
          record={record}
          showTime={false}
        />
        <DateFieldWithLabel
          label={translate('transaction.show.section.label.transactionEndedAt')}
          source={
            getStepFromTodos(todos) === 'canceled' ? 'updatedAt' : 'noDate'
          }
          record={record}
          showTime={false}
        />
        <ChipFieldWithLabel
          label={translate('transaction.show.section.label.transactionStatus')}
          source="status"
          record={{
            status: translate(
              `transaction.status.${record.status.toLowerCase()}`
            ),
          }}
          color={STATUS_COLORS[record.status.toLowerCase()]}
        />
        <TextFieldWithLabel
          label={translate('transaction.show.section.label.cancelReason')}
          variant="body2"
          component="span"
          source="cancellationReason"
          record={{
            cancellationReason: record.cancellationReason
              ? translate(
                  `transaction.action.cancel.reasons_${record.cancellationReason}`
                )
              : undefined,
          }}
        />
        <TextFieldWithLabel
          label={translate(
            'transaction.show.section.label.additionalCancellationReasons'
          )}
          source="additionalCancellationReasons"
          record={{
            additionalCancellationReasons: record.additionalCancellationReasons
              ?.length
              ? record.additionalCancellationReasons
                  .map((additionalCancellationReason: CancellationReasons) =>
                    translate(
                      `transaction.action.cancel.reasons_${additionalCancellationReason}`
                    )
                  )
                  .join(', ')
              : undefined,
          }}
        />
        <TextFieldWithLabel
          label={translate(
            'transaction.show.section.label.cancellationCommentary'
          )}
          variant="body2"
          component="span"
          source="cancellationCommentary"
          record={{
            cancellationCommentary: record.cancellationCommentary,
          }}
        />
      </SectionContainer>
      <SectionContainer
        name="documents"
        title={translate('transaction.show.section.title.documents')}
        gap="32px"
      >
        {todosStep}
      </SectionContainer>
    </Container>
  );
};
