import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';
import { useGetIdentity } from 'react-admin';
import { Customer } from 'types/customer.types';
import { PermissionName, Scope } from 'types/permission.types';

export type CustomerPermissionsType = {
  checkCanEditCustomer: (
    customer: Pick<Customer, 'organization' | 'ownedBy'>
  ) => boolean;
  canViewCustomer: boolean;
  checkCanSeeContactDetails: (customer: Pick<Customer, 'email'>) => boolean;
  canCreateCustomer: boolean;
  canEditCustomerBroughtBy: boolean;
};

export const useCustomerPermissions = (): CustomerPermissionsType => {
  const { identity } = useGetIdentity();
  const { hasPermission, getScope } = useHeliosPermissions();

  const checkEditCustomer = (
    customer: Parameters<CustomerPermissionsType['checkCanEditCustomer']>[0]
  ) => {
    if (!hasPermission(PermissionName.EditCustomer)) {
      return false;
    }

    switch (getScope(PermissionName.EditCustomer)) {
      case Scope.Organization: {
        if (!customer?.organization || !identity?.organization) {
          return false;
        }
        return customer.organization === identity.organization;
      }
      case Scope.All:
        return true;
      case Scope.Own: {
        if (!customer?.ownedBy || !identity?.id) {
          return false;
        }
        return customer.ownedBy === identity.id;
      }
      default:
        return false;
    }
  };

  const canViewCustomer = hasPermission(PermissionName.ViewCustomer);
  const canEditCustomerBroughtBy = hasPermission(
    PermissionName.UpdateCustomerBroughtBy
  );
  const checkSeeContactDetails = (
    customer: Parameters<
      CustomerPermissionsType['checkCanSeeContactDetails']
    >[0]
  ) => !identity?.isMandatary || (identity?.isMandatary && customer?.email);
  const canCreateCustomer = hasPermission(PermissionName.CreateCustomer);

  return {
    canCreateCustomer,
    canEditCustomerBroughtBy,
    canViewCustomer,
    checkCanEditCustomer: checkEditCustomer,
    checkCanSeeContactDetails: checkSeeContactDetails,
  };
};
