import { defaultTheme } from 'react-admin';
import type {} from '@mui/x-data-grid/themeAugmentation';

declare module '@mui/material/styles' {
  interface Palette {
    colors: {
      [key: string]: {
        50: string;
        900: string;
        600?: string;
      };
    };
  }
  interface TypeBackground {
    selected: string;
  }
}

export const themeProvider = {
  ...defaultTheme,
  components: {
    MuiDataGrid: {
      // remove cell selection outline
      styleOverrides: {
        root: {
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
        },
      },
    },
  },
  palette: {
    ...defaultTheme.palette,
    background: {
      ...defaultTheme.palette?.background,
      default: '#fafafa',
      selected: '#EDF4FC',
    },
    colors: {
      cyan: {
        50: '#E0F7FA',
        900: '#006064',
      },
      deepPurple: {
        50: '#EDE7F6',
        600: '#5E35B1',
        900: '#311B92',
      },
      green: {
        50: '#E8F5E9',
        900: '#1B5E20',
      },
      lightBlue: {
        50: '#E1F5FE',
        900: '#01579B',
      },
      lightGreen: {
        50: '#F1F8E9',
        900: '#33691E',
      },
      lime: {
        50: '#F9FBE7',
        900: '#827717',
      },
      orange: {
        50: '#FFF3E0',
        900: '#E65100',
      },
      pink: {
        50: '#FCE4EC',
        900: '#880E4F',
      },
      red: {
        50: '#FEEBEE',
        900: '#B71C1C',
      },
      terminal: {
        50: '#1E1E1E',
        900: '#CCCCCC',
      },
      yellow: {
        50: '#FFFDE7',
        900: '#F57F17',
      },
    },
  },
  typography: {
    ...defaultTheme.typography,
    caption: {
      color: '#757575',
    },
  },
};
