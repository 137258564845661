import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'components/form/Form';

import { addCustomerFormValidator } from './AddCustomerForm.validator';
import { AddCustomerFormProps } from './AddCustomerForm.types';
import { AddCustomerFormFields } from './AddCustomerFormFields';

export const AddCustomerForm: React.FC<AddCustomerFormProps> = ({
  onSubmit,
}) => {
  return (
    <Form
      onSubmit={onSubmit}
      id="customer-create-form"
      resolver={yupResolver(addCustomerFormValidator)}
      defaultValues={{ firstAppointmentDate: new Date().toISOString() }}
    >
      <AddCustomerFormFields />
    </Form>
  );
};
