import React from 'react';

import { AutocompleteInput, ReferenceInput, useTranslate } from 'react-admin';
import { RoleName } from 'types/role.types';
import { filterSearchToQuery } from 'utils/filters/filters';
import { formatFullName } from 'utils/strings/strings.utils';

export type UserAutoCompleteProps = {
  label: string;
  source: string;
  roles?: RoleName[];
  disabled?: boolean;
};

export const UserAutoComplete: React.FC<UserAutoCompleteProps> = ({
  label,
  source,
  roles,
  disabled,
}) => {
  const translate = useTranslate();
  return (
    <ReferenceInput
      source={source}
      perPage={20}
      reference="users/filter"
      filter={roles ? { roles: roles } : undefined}
    >
      <AutocompleteInput
        variant="outlined"
        debounce={500}
        label={translate(label)}
        noOptionsText={translate('shared.filters.noOptions')}
        filterToQuery={filterSearchToQuery}
        optionText={(opt) => formatFullName(opt.firstName, opt.lastName)}
        disabled={disabled}
      />
    </ReferenceInput>
  );
};
