import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import { TextInput, useTranslate } from 'react-admin';
import { AccordionForm } from 'ui/molecules/accordion-form/AccordionForm';

import { sourceToLabelMap } from '../EditFormContent.utils';
import { EditFormContentProps } from '../editFormContent.types';

export const LocalizationForm: React.FC<EditFormContentProps> = ({
  defaultExpanded,
}) => {
  const translate = useTranslate();

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate('realization.form.localization')}
    >
      <Fieldset>
        <TextInput
          margin="none"
          variant="outlined"
          disabled
          label={sourceToLabelMap.get('address.city')}
          source="address.city"
        />
        <TextInput
          margin="none"
          variant="outlined"
          disabled
          label={sourceToLabelMap.get('address.regionName')}
          source="address.regionName"
        />
        <TextInput
          margin="none"
          variant="outlined"
          disabled
          label={sourceToLabelMap.get('address.country')}
          source="address.country"
        />
        <TextInput
          margin="none"
          variant="outlined"
          disabled
          label={sourceToLabelMap.get('address.countryCode')}
          source="address.countryCode"
        />
      </Fieldset>
    </AccordionForm>
  );
};
