import { Theme } from '@mui/material';
import { GridValueFormatterParams } from '@mui/x-data-grid';
import { Translate } from 'react-admin';
import { ManagementMandateStatus } from 'types/management-mandate.types';
import { ChipListProps } from 'ui/molecules/chips-list/ChipList';
import { formatChipListValue } from 'utils/columns/valueFormatters.utils';

const MANDATE_STATUS_TO_CHIP_LIST_PROPS = {
  [ManagementMandateStatus.NO_MANDATE]: {
    color: 'red',
    label: `customer.mandateStatus.${ManagementMandateStatus.NO_MANDATE}`,
  },
  [ManagementMandateStatus.SENDED_MANDATE]: {
    color: 'orange',
    label: `customer.mandateStatus.${ManagementMandateStatus.SENDED_MANDATE}`,
  },
  [ManagementMandateStatus.EXPIRED_MANDATE]: {
    label: `customer.mandateStatus.${ManagementMandateStatus.EXPIRED_MANDATE}`,
  },
  [ManagementMandateStatus.SIGNED_MANDATE]: {
    color: 'green',
    label: `customer.mandateStatus.${ManagementMandateStatus.SIGNED_MANDATE}`,
  },
  [ManagementMandateStatus.ASSOCIATED_MANDATE]: {
    color: 'lightBlue',
    label: `customer.mandateStatus.${ManagementMandateStatus.ASSOCIATED_MANDATE}`,
  },
  [ManagementMandateStatus.INTEGRITY_ERROR]: {
    color: 'terminal',
  },
};

export const formatMandateStatusColumnValue =
  (translate: Translate, theme: Theme) =>
  (param: GridValueFormatterParams): ChipListProps['list'] => {
    return formatChipListValue(
      translate,
      theme,
      MANDATE_STATUS_TO_CHIP_LIST_PROPS
    )({ ...param, value: [param.value] });
  };
