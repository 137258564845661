import { Box, styled } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 24px;
  padding: 0 5px;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    margin-top: 24px;
    margin-left: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    max-width: 70%;
  }

  & .Mui-disabled {
    cursor: not-allowed;
  }
`;
