import React, { useCallback } from 'react';

import { useListContext, useRedirect, useTranslate } from 'react-admin';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { FilteredListLayout } from 'ui/layouts/filtered-list-layout/FilteredListLayout';
import { getColumns } from 'utils/columns/getColumns.utils';
import { SearchBar } from 'ui/molecules/search-bar/SearchBar';
import { DataTable } from 'components/data-table/DataTable';
import { GridCellParams } from '@mui/x-data-grid';
import { TabItem } from 'components/tabs/Tabs';

import { getActivitiesColumns } from './utils/getActivitiesColumns';
import { Filters } from './Filters';
import { formatRowData } from './utils/formatRowData';

export const Content: React.FC<{
  onResourceChange: (_event: React.SyntheticEvent, resource: string) => void;
}> = ({ onResourceChange }) => {
  const redirect = useRedirect();
  const translate = useTranslate();

  const { data, setFilters, filterValues, resource } = useListContext();

  const columnsList = getActivitiesColumns(resource);

  const columns = data ? getColumns(columnsList, translate) : [];
  const rows = formatRowData(data);

  const searchBar = (
    <SearchBar
      title={translate('activities.search.title')}
      placeholder={translate('activities.search.placeholder')}
      setFilters={setFilters}
      filterValues={filterValues}
    />
  );

  const handleCellClick = useCallback(
    (params: GridCellParams) => {
      switch (params.field) {
        case 'customer.fullName':
          {
            const { customerId, customer } = data.find(
              (d) => d.id === params.id
            );
            redirect('show', 'customers', customerId || customer.id);
          }
          break;
        case 'realEstate.address.street':
          {
            const { realEstateId } = data.find((d) => d.id === params.id);
            redirect('edit', 'real-estates', realEstateId);
          }
          break;
      }
    },
    [data, redirect]
  );

  const table = (
    <DataTable onCellClick={handleCellClick} columns={columns} rows={rows} />
  );

  const tabs: TabItem[] = [
    {
      icon: <StarOutlineOutlinedIcon fontSize="small" />,
      label: translate('activities.tab.positioning'),
      value: 'positionings',
    },
    {
      icon: <FavoriteBorderOutlinedIcon fontSize="small" />,
      label: translate('activities.tab.bookmarks'),
      value: 'bookmarks',
    },
  ];

  return (
    <FilteredListLayout
      main={table}
      tabs={tabs}
      onTabChange={onResourceChange}
      filters={<Filters />}
      searchBar={searchBar}
      title={translate('activities.title')}
    />
  );
};
