/* eslint-disable max-lines */
import { useTheme } from '@mui/material';
import { useGetIdentity, useTranslate } from 'react-admin';
import { CellType, GridColDef } from 'types/mui.types';
import {
  formatChipListValue,
  formatYesNoOrAnyValueForChipList,
} from 'utils/columns/valueFormatters.utils';
import { formatMandateStatusColumnValue } from 'utils/columns/formatMandateStatusColumnValue.utils';
// eslint-disable-next-line max-len
import { formatFinancingCertificateStatusColumnValue } from 'utils/columns/formatFinancingCertificateStatusColumnValue.utils';

import { formatInvestBudgetColumnValue } from '../utils/formatInvestBudgetColumnValue.utils';
import { formatPropertyKindColumnValue } from '../utils/formatPropertyKindColumnValue.utils';
import { formatPropertyRoomCountColumnValue } from '../utils/formatPropertyRoomCountColumnValue.utils';
import { formatTransactionStatusColumnValue } from '../utils/formatTransactionStatusColumnValue.utils';

const DEFAULT_WIDTH = 200;

export const useGetCustomersColumns = (): GridColDef[] => {
  const translate = useTranslate();
  const theme = useTheme();
  const { identity } = useGetIdentity();
  const canSeeContactsColumns = !identity?.isMandatary;

  return [
    {
      field: 'fullName',
      headerName: 'customer.fullName',
      sortable: false,
      width: DEFAULT_WIDTH,
    },
    ...(canSeeContactsColumns
      ? [
          {
            field: 'email',
            headerName: 'user.input.email',
            width: 250,
          },
          {
            field: 'phoneNumber',
            headerName: 'shared.phoneNumber',
            sortable: false,
            width: 150,
          },
        ]
      : []),
    {
      field: 'owner.fullName',
      headerName: 'shared.advisor',
      sortable: false,
      width: DEFAULT_WIDTH,
    },
    {
      field: 'broughtBy.fullName',
      headerName: 'customer.broughtBy',
      sortable: false,
      width: DEFAULT_WIDTH,
    },
    {
      field: 'organizationName',
      headerName: 'shared.organization',
      sortable: false,
      width: DEFAULT_WIDTH,
    },
    {
      cellType: CellType.TAG_LIST,
      field: 'mandateStatus',
      headerName: 'customer.mandateStatusLabel',
      sortable: false,
      valueFormatter: formatMandateStatusColumnValue(translate, theme),
      width: 250,
    },
    {
      cellType: CellType.TAG_LIST,
      field: 'financingCertificateStatus',
      headerName: 'customer.financingCertificateStatusLabel',
      sortable: false,
      valueFormatter: formatFinancingCertificateStatusColumnValue(
        translate,
        theme
      ),
      width: 200,
    },
    {
      cellType: CellType.TAG_LIST,
      field: 'transactionStatus',
      headerName: 'customer.transactionStatusLabel',
      sortable: false,
      valueFormatter: formatTransactionStatusColumnValue(translate, theme),
      width: 230,
    },
    {
      cellType: CellType.DATE_TIME,
      field: 'createdAt',
      headerName: 'user.header.createdAt',
      width: DEFAULT_WIDTH,
    },
    {
      cellType: CellType.TAG_LIST,
      field: 'investBudget',
      headerName: 'customer.investBudgetLabel',
      valueFormatter: formatInvestBudgetColumnValue(translate, theme),
      width: 300,
    },
    {
      cellType: CellType.TAG_LIST,
      field: 'hasBorrowingCapacity',
      headerName: 'customer.capacity',
      valueFormatter: formatYesNoOrAnyValueForChipList(translate, theme),
      width: DEFAULT_WIDTH,
    },
    {
      cellType: CellType.PRICE,
      field: 'searchSettings.budgetMax',
      headerName: 'customer.searchSettings.budgetMax',
      sortable: false,
      width: DEFAULT_WIDTH,
    },
    {
      cellType: CellType.PRICE,
      field: 'searchSettings.budgetMin',
      headerName: 'customer.searchSettings.budgetMin',
      sortable: false,
      width: DEFAULT_WIDTH,
    },
    {
      cellType: CellType.TAG_LIST,
      field: 'searchSettings.flatSharing',
      headerName: 'customer.searchSettings.flatSharing',
      sortable: false,
      valueFormatter: formatYesNoOrAnyValueForChipList(translate, theme),
      width: DEFAULT_WIDTH,
    },
    {
      cellType: CellType.TAG_LIST,
      field: 'searchSettings.groundFloor',
      headerName: 'customer.searchSettings.groundFloor',
      sortable: false,
      valueFormatter: formatYesNoOrAnyValueForChipList(translate, theme),
      width: DEFAULT_WIDTH,
    },
    {
      cellType: CellType.TAG_LIST,
      field: 'searchSettings.propertyKind',
      headerName: 'customers.searchSettings.propertyKind',
      sortable: false,
      valueFormatter: formatPropertyKindColumnValue(translate, theme),
      width: DEFAULT_WIDTH,
    },
    {
      cellType: CellType.TAG_LIST,
      field: 'searchSettings.propertyRented',
      headerName: 'customer.searchSettings.propertyRented',
      sortable: false,
      valueFormatter: formatYesNoOrAnyValueForChipList(translate, theme),
      width: DEFAULT_WIDTH,
    },
    {
      cellType: CellType.TAG_LIST,
      field: 'searchSettings.propertyRoomCount',
      headerName: 'customer.searchSettings.propertyRoomCount',
      minWidth: 250,
      sortable: false,
      valueFormatter: formatPropertyRoomCountColumnValue(translate, theme),
    },
    {
      field: 'utm.campaign',
      headerName: 'customer.utmCampaign',
      width: DEFAULT_WIDTH,
    },
    {
      cellType: CellType.TAG_LIST,
      field: 'searchSettings.regions',
      headerName: 'customer.searchSettings.regions',
      sortable: false,
      valueFormatter: formatChipListValue(translate, theme),
      width: 1050,
    },
    {
      field: 'utm.content',
      headerName: 'customer.utmContent',
      width: DEFAULT_WIDTH,
    },
    {
      cellType: CellType.TAG_LIST,
      field: 'searchSettings.renovationNeeded',
      headerName: 'customer.searchSettings.renovationNeeded',
      sortable: false,
      valueFormatter: formatYesNoOrAnyValueForChipList(translate, theme),
      width: DEFAULT_WIDTH,
    },
    {
      field: 'utm.medium',
      headerName: 'customer.utmMedium',
      width: DEFAULT_WIDTH,
    },
    {
      field: 'utm.source',
      headerName: 'customer.utmSource',
      width: DEFAULT_WIDTH,
    },
    {
      field: 'utm.term',
      headerName: 'customer.utmTerm',
      width: DEFAULT_WIDTH,
    },
    {
      field: 'deletedAt',
      headerName: 'customer.deletedAt',
      sortable: false,
      width: DEFAULT_WIDTH,
    },
    {
      field: 'searchSettings.timing',
      headerName: 'customer.searchSettings.timing',
      sortable: false,
      width: DEFAULT_WIDTH,
    },
    {
      field: 'account.affiliateEmail',
      headerName: 'customer.affiliateEmail',
      width: DEFAULT_WIDTH,
    },
    {
      field: 'account.affiliateId',
      headerName: 'customer.affiliateId',
      width: DEFAULT_WIDTH,
    },
  ] as GridColDef[];
};
