interface ScriptTagOptions {
  src: string;
  async?: boolean;
  id?: string;
  onLoad?: () => void;
}

export const createScriptElement = ({
  src,
  async = false,
  id,
  onLoad,
}: ScriptTagOptions): HTMLScriptElement => {
  const script = document.createElement('script');

  if (id) {
    script.id = id;
  }

  script.src = src;
  script.async = async;

  if (id) {
    script.id = id;
  }

  if (onLoad) {
    script.onload = onLoad;
  }

  return script;
};
