import { Translate } from 'react-admin';
import {
  CustomerSource,
  CustomerTransactionStatus,
} from 'types/customer.types';
import { FinancingCertificateStatus } from 'types/financing-certificate.types';
import { ChoiceProps } from 'types/form.type';
import { ManagementMandateStatus } from 'types/management-mandate.types';
import { RoleName } from 'types/role.types';
import { formatChoiceList } from 'utils/form/form.utils';

export const getPermissionChoiceList = (): ChoiceProps =>
  Object.keys(RoleName).map((value) => ({
    id: value.toLowerCase(),
    name: value,
  }));

export const getTransactionStatusChoiceList = (
  translate: Translate
): ChoiceProps =>
  Object.values(CustomerTransactionStatus).map((value) => ({
    id: value,
    name: translate(`customer.transactionStatus.${value}`),
  }));

export const getAccountSourceChoiceList = (): ChoiceProps =>
  formatChoiceList(CustomerSource, 'customer.source');

export const getFinancingCertificateStatusChoiceList = (
  translate: Translate
): ChoiceProps =>
  Object.values(FinancingCertificateStatus).map((value) => ({
    id: value,
    name: translate(`customer.financingCertificateStatus.${value}`),
  }));

export const getMandateStatusChoiceList = (translate: Translate): ChoiceProps =>
  Object.values(ManagementMandateStatus).map((value) => ({
    id: value,
    name: translate(`customer.mandateStatus.${value}`),
  }));
