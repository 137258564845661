import React from 'react';

import { TextInput } from 'react-admin';
import { PhoneInput } from 'ui/atoms/phone-input/phone-input';

import { Container } from './AddExternalCustomerFormFields.style';

export const AddExternalCustomerFormFields: React.FC = () => {
  return (
    <Container>
      <TextInput
        margin="none"
        variant="outlined"
        label="user.input.firstname"
        source="firstName"
      />
      <TextInput
        margin="none"
        variant="outlined"
        label="user.input.lastname"
        source="lastName"
      />
      <TextInput
        margin="none"
        variant="outlined"
        label="user.input.email"
        source="email"
      />
      <PhoneInput
        margin="none"
        variant="outlined"
        label="shared.phoneNumber"
        source="phoneNumber"
      />
    </Container>
  );
};
