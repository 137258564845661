import { useCallback, useMemo } from 'react';

import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';
import { useGetIdentity, useShowContext } from 'react-admin';
import { Transaction, TransactionStatus } from 'types/transaction.types';
import { PermissionName, Scope } from 'types/permission.types';

export type TransactionPermissionsType = {
  canUpdateProject: boolean;
  canUpdateInvoiceMethod: boolean;
  canTransferTransaction?: boolean;
};

export const useTransactionPermissions = (): TransactionPermissionsType => {
  const { record } = useShowContext<Transaction>();
  const { identity } = useGetIdentity();
  const { hasPermission, getScope } = useHeliosPermissions();

  const hasPermissionOnRecord = useCallback(
    (permission: PermissionName) => {
      if (!identity) {
        return false;
      }
      if (!record) {
        return hasPermission(permission);
      }

      if (!hasPermission(permission)) {
        return false;
      }

      const scope = getScope(permission);

      switch (scope) {
        case Scope.All:
          return true;
        case Scope.Organization:
          return record.organization === identity.organization;
        case Scope.Own:
          return record.advisor.id === identity.id;
        default:
          console.error(`Could not apply scope ${scope}`);
          return false;
      }
    },
    [record, identity, hasPermission, getScope]
  );

  const canUpdateProject = useMemo(
    () =>
      record?.status === TransactionStatus.ONGOING &&
      hasPermission(PermissionName.UpdateProject),
    [record?.status, hasPermission]
  );

  const canTransferTransaction = !!hasPermissionOnRecord(
    PermissionName.UpdateProjectTransferOwnership
  );

  const canUpdateInvoiceMethod = useMemo(
    () =>
      record?.status === TransactionStatus.ONGOING &&
      hasPermission(PermissionName.UpdateProjectInvoiceMethod),
    [hasPermission, record?.status]
  );

  return {
    canTransferTransaction,
    canUpdateInvoiceMethod,
    canUpdateProject,
  };
};
