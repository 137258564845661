import { Box, Button, Modal as MUIModal, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close as CloseIcon } from '@mui/icons-material';

const ModalContentStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$autoWidth',
})<{ $autoWidth: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  background-color: white;
  box-shadow: 1px 2px 0px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 90%;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: ${({ $autoWidth }) => ($autoWidth ? 'auto' : '75%')};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: ${({ $autoWidth }) => ($autoWidth ? 'auto' : '45%')};
  }
`;

const FooterContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const ModalFooterButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacing(2)};
`;

const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const StyledBox = styled(Box)`
  overflow: auto;
`;

const Title = styled(Typography)`
  flex: 1;
`;

const CloseButton = styled(Button)`
  color: ${({ theme }) => theme.palette.grey['600']};
  min-width: unset;
  padding: 0;
  margin: 0;
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }
`;

interface ModalProps {
  open: boolean;
  onClose: () => void;
  showCloseButton?: boolean;
  title: JSX.Element | string;
  footer: JSX.Element;
  autoWidth?: boolean;
}

export const ModalContent: React.FC<
  React.PropsWithChildren<Omit<ModalProps, 'open'>>
> = ({
  onClose,
  title,
  children,
  footer,
  showCloseButton,
  autoWidth = false,
}) => (
  <ModalContentStyled data-testid="modal-content" $autoWidth={!!autoWidth}>
    <HeaderContainer>
      {typeof title === 'string' ? (
        <Title variant="h6" sx={{ fontWeight: 'bold' }}>
          {title}
        </Title>
      ) : (
        title
      )}
      {!!showCloseButton && (
        <CloseButton variant="text" onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      )}
    </HeaderContainer>

    <StyledBox>{children}</StyledBox>

    <FooterContainer>{footer}</FooterContainer>
  </ModalContentStyled>
);

export const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  open,
  onClose,
  ...rest
}) => (
  <MUIModal open={open} onClose={onClose}>
    <Box>
      <ModalContent {...rest} onClose={onClose} />
    </Box>
  </MUIModal>
);
