import { AuthTokens } from './tokenStorage';

const identityApiUrl = process.env.REACT_APP_IDENTITY_API_URL;

export function identityApi(
  path: string,
  body: Record<string, string>
): Promise<AuthTokens> {
  const request = new Request(`${identityApiUrl}${path}`, {
    body: JSON.stringify(body),
    headers: new Headers({
      'Content-Type': 'application/json',
      accept: 'application/json',
    }),
    method: 'POST',
  });
  return fetch(request).then(getJsonResponseOrError);
}

const getJsonResponseOrError = (response: Response) => {
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return response.json();
};
