import { IdName } from '../features/realEstates/validators/realEstate.validator';
import {
  AppreciationZone,
  ArchivingReasons,
  Asbestos,
  Dpe,
  DryRot,
  Electricity,
  EstimatedWorks,
  Gas,
  Ges,
  LeadDiagnostic,
  LocationType,
  RealEstateStatus,
  RealEstateType,
  RentalSituations,
  ServerFieldValidationError,
  Strategy,
  SupportedLanguage,
  SynthesisTheme,
  Termites,
} from './realEstate.enum';
import { Zone } from './zone.types';

export type PublishValidationException = {
  cause: {
    errors: string[];
    inner: ServerFieldValidationError[];
  };
};

export type PublishValidationErrorBody = {
  exception: PublishValidationException;
  message: string;
};

export type PublishValidationError = Error & {
  body: PublishValidationErrorBody;
};

export interface RealEstateAction {
  slug:
    | 'archive'
    | 'duplicate'
    | 'askPublish'
    | 'doPublish'
    | 'downloadBankPdf'
    | 'downloadPropertyPdf'
    | 'generatePurchaseOffer'
    | 'transferRealEstate'
    | 'uploadCounterSignedOffer';
  openModal?: () => void;
  name: string;
  show: boolean;
  loading: boolean;
  callback?: (data?: any) => Promise<unknown> | void; // eslint-disable-line
}

export interface SynthesisItem {
  theme: SynthesisTheme;
  description: string;
}

export type FormSpecificities = {
  annualHomeOwnerInsurance: {
    isVisible: boolean;
    isMandatory: boolean;
  };
  dpe: {
    isVisible: boolean;
    isMandatory: boolean;
    overrideValues: IdName[];
  };
  ges: {
    isVisible: boolean;
    isMandatory: boolean;
  };
};

export type RealEstateAddress = {
  buildingCode?: string;
  city: string;
  complement?: string;
  country: string;
  countryCode: string;
  lat: number;
  lng: number;
  placeId?: string;
  postalCode: string;
  regionCode?: string;
  regionName?: string;
  street: string;
};

export type RealEstateHunter = {
  _id: string;
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

export type RealEstateContact = {
  _id: string;
  id: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  avatarS3ID: string;
};

export type RealEstateSynthesis = {
  theme: SynthesisTheme;
  description: string;
};

export interface RealEstateFinancialModelInterface {
  agencySellerCommission: number | null;
  annualCharges: number | null;
  annualCurrentExpenses: number | null;
  annualHomeOwnerInsurance: number | null;
  annualLoanPayments: number; // default 0
  annualPropertyTax: number | null;
  annualRentIncome: number | null;
  annualTotalEarnings: number; // default 0
  annualTotalExpenses: number; // default 0
  annualTotalIncome: number; // default 0
  bankCommissionRate: number; // default 1
  evaluationRate: number | null;
  furnituresAmount: number | null;
  grossReturn: number | null;
  leasingCost: number | null;
  loanMonthlyAmount: number; // default 0
  masteosCommissionRate: number | null;
  netReturn: number; // default 0
  netSeller: number | null;
  notaryCommissionRate: number; // default 8
  projectAmount: number; // default 0
  renovationAmount: number | null;
  rentalManagementFees: number | null;
  shortTermNightlyRent: number | null;
  shortTermOccupancyRate: number | null;
}

export type DescriptionWithImages = {
  description: string;
  images: string[];
};

export interface RealEstate {
  _id: string;
  access: DescriptionWithImages;
  address: RealEstateAddress;
  annualRevaluationRate?: number;
  appreciationZone?: AppreciationZone | null;
  archivingReason?: ArchivingReasons | null;
  asbestos?: Asbestos | null;
  contact?: RealEstateHunter;
  common: DescriptionWithImages;
  coverImage?: string;
  createdAt: Date;
  description: string;
  diagnosticUrl?: string;
  dpe?: Dpe | null;
  dryRot?: DryRot | null;
  electricity?: Electricity | null;
  estimatedWorks?: EstimatedWorks | null;
  exclusiveEndDate: Date | null;
  financialModel: RealEstateFinancialModelInterface;
  financialModelVersion?: number;
  firstPublishedAt?: Date;
  floor?: number;
  organization: string;
  furniture: DescriptionWithImages;
  gas?: Gas | null;
  geocode?: {
    street: string;
  };
  ges?: Ges | null;
  hasCoOwnership?: boolean;
  hunter: RealEstateHunter;
  id: string;
  images: string[];
  inside: DescriptionWithImages;
  internalNote: string;
  isFurnished?: boolean;
  lang: SupportedLanguage;
  lastPublishedAt?: Date;
  leadDiagnostic?: LeadDiagnostic | null;
  locationType?: LocationType;
  neighborhood: DescriptionWithImages;
  outside: DescriptionWithImages;
  plans: DescriptionWithImages;
  ref?: string;
  renovation: DescriptionWithImages;
  renovationToolUrl?: string;
  rentalDetail?: string;
  rentalSituation?: RentalSituations | null;
  roomNumber?: number;
  sellTarget: SellTarget;
  status: RealEstateStatus;
  strategy?: Strategy;
  subtitle?: string;
  surface?: number;
  synthesis?: RealEstateSynthesis[];
  termites?: Termites | null;
  title?: string;
  totalLots?: number;
  type?: RealEstateType | null;
  updatedAt: Date;
  url3D?: string;
}

export enum SellTarget {
  Masteos = 'MASTEOS',
  CGP = 'CGP',
}

export type RealEstateOperationalMetadata = {
  positioningCount: number;
  bookmarkCount: number;
  zone?: Zone;
};

export type RealEstateItemView = {
  id: string;
  organization: {
    id: string;
    name: string;
  };
  link: string;
  status: RealEstateStatus;
  exclusiveEndDate: Date;
  financialModel: RealEstateFinancialModelInterface;
  surface: number;
  type: RealEstateType;
  totalLots: number;
  roomNumber: number;
  dpe: Dpe;
  createdAt: Date;
  lastPublishedAt: Date;
  locationType: LocationType;
  floor: number;
  address: {
    city: string;
    street: string;
  };
  bookmarkCount: number;
  positioningCount: number;
  hunter: RealEstateHunter;
  ownedBy: RealEstateHunter & { phoneNumber: string };
  isBooked: boolean;
};

export type RealEstateWithOperationalMetadata = RealEstate &
  RealEstateOperationalMetadata;
