import { DataTable } from 'components/data-table/DataTable';
import { withPermissionCheck } from 'hoc/withPermissionCheck';
import { useGetList, useTranslate } from 'react-admin';
import { PermissionName, PermissionRow, Scope } from 'types/permission.types';
import { Role } from 'types/role.types';
import { FilteredListLayout } from 'ui/layouts/filtered-list-layout/FilteredListLayout';
import { getColumns } from 'utils/columns/getColumns.utils';

import { useGetPermissionColumns } from './getPermissionColumns';

export const RolesList: React.FC = () => {
  const translate = useTranslate();
  const permissionColumns = useGetPermissionColumns();
  const columns = getColumns(permissionColumns, translate);

  const { data, isLoading, isFetching } = useGetList('roles');
  const dataAdapter = (roleList: Role[]): PermissionRow[] => {
    return Object.values(PermissionName).map(
      (permissionName: PermissionName) => {
        const item: PermissionRow = {
          id: permissionName,
        };
        for (const role of roleList) {
          item[role.name] =
            role.permissions.find(
              (permission) => permission.name === permissionName
            )?.scope || Scope.None;
        }
        return item;
      }
    );
  };

  const rows = dataAdapter(data || []);

  return (
    <FilteredListLayout
      main={
        <DataTable
          loading={isLoading || isFetching}
          columns={columns}
          rows={rows}
          autoHeight
          density="compact"
        />
      }
      title={translate('roles.list.title')}
    />
  );
};

export const RolesListWithPermission = withPermissionCheck(RolesList);
