import {
  HttpError,
  RaRecord,
  useDataProvider,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { useMutation } from 'react-query';
import { ActionMutationReturn } from 'types/common.types';
import { HeliosDataProvider } from 'providers/admin/dataProvider';
import { GeneratePurchaseOfferBody } from 'types/customMethods.types';

import { useRealEstateDetailPermissions } from './useRealEstateDetailPermissions';

export type GeneratePurchaseOfferMutationReturn = ActionMutationReturn<
  void,
  unknown,
  GeneratePurchaseOfferBody,
  unknown
>;

export const useGeneratePurchaseOfferAction = (
  record?: RaRecord
): GeneratePurchaseOfferMutationReturn => {
  const { id: realEstateId } = useRecordContext(
    record ? { record } : undefined
  );
  const dataProvider = useDataProvider<HeliosDataProvider>();
  const notify = useNotify();

  const { canGeneratePurchaseOffer } = useRealEstateDetailPermissions();

  const mutation = useMutation(
    ['generatePurchaseOffer', realEstateId, dataProvider],
    (data: GeneratePurchaseOfferBody) => {
      return dataProvider
        ?.generatePurchaseOffer(realEstateId as string, data)
        .then(() => {
          notify('realEstate.action.generatePurchaseOffer.success', {
            type: 'success',
          });
          return;
        })
        .catch((err: HttpError) => {
          notify(err.body.message, {
            type: 'error',
          });
          throw new Error();
        });
    }
  );

  return { ...mutation, isAllowed: canGeneratePurchaseOffer };
};
