import { PropsWithChildren } from 'react';

import { styled } from '@mui/material';

interface FieldsetProps {
  title?: string;
  nbColumn?: number;
}

export const shouldForwardProp = <CustomProps extends Record<string, unknown>>(
  props: Array<keyof CustomProps>,
  prop: PropertyKey
): boolean => !props.includes(prop as string);

const FieldsetContainer = styled('fieldset', {
  shouldForwardProp: (prop) =>
    shouldForwardProp(['$withTitle', '$nbColumn'], prop),
})<{ $withTitle: boolean; $nbColumn: number }>`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0;
  padding: 0;

  border: 0;
  border-top: ${({ theme, $withTitle }) =>
    $withTitle ? '1px solid ' + theme.palette.grey['400'] : 0};

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    & .fullColumns {
      grid-column: auto / span 2;
    }
    & .MuiFormControl-fullWidth {
      grid-column: auto / span 2;
    }
    & .MuiAutocomplete-fullWidth {
      grid-column: auto / span 2;
    }
    & .fieldset-helper-text {
      grid-column: auto / span 2;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    grid-template-columns: repeat(${({ $nbColumn }) => $nbColumn}, 1fr);
    gap: 10px;

    & .fullColumns {
      grid-column: auto / span 3;
    }
    & .MuiFormControl-fullWidth {
      grid-column: auto / span 3;
    }
    & .MuiAutocomplete-fullWidth {
      grid-column: auto / span 3;
    }
    & .fieldset-helper-text {
      grid-column: auto / span 3;
    }
  }
`;

const Legend = styled('legend')`
  margin-left: 20px;
  padding: 15px 10px;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  text-transform: uppercase;
`;

export const Fieldset: React.FC<PropsWithChildren<FieldsetProps>> = ({
  title,
  nbColumn = 3,
  children,
}) => {
  return (
    <FieldsetContainer
      $withTitle={!!title}
      $nbColumn={nbColumn}
      data-testid="fieldset"
    >
      {!!title && <Legend>{title}</Legend>}
      {children}
    </FieldsetContainer>
  );
};
