import { Positioning, PositioningStatus } from 'types/positioning.types';
import { Identifier } from 'react-admin';

import { GetPositioningStateReturnType } from './types';

type GetActionsParams = {
  positioning: Pick<Positioning, 'status' | 'missingDocuments' | 'position'> & {
    ownedBy: {
      id: Positioning['ownedBy']['id'];
    };
  };
  loggedInUserId?: Identifier;
};

export const getPositioningState = ({
  positioning,
  loggedInUserId: currentUserId,
}: GetActionsParams): GetPositioningStateReturnType => {
  const { status, missingDocuments, position, ownedBy } = positioning;

  return {
    canBeRepositioned: [
      PositioningStatus.Cancelled,
      PositioningStatus.PrePositioningCancelled,
    ].includes(status),
    canCreateOffer: canCreateOffer(),
    isCancelDisabled: [
      PositioningStatus.Cancelled,
      PositioningStatus.Archived,
      PositioningStatus.PrePositioningCancelled,
    ].includes(status),
    isConfirmDisabled:
      [
        PositioningStatus.Cancelled,
        PositioningStatus.Confirmed,
        PositioningStatus.Transaction,
        PositioningStatus.Archived,
        PositioningStatus.PrePositioningCancelled,
      ].includes(status) || missingDocuments.length !== 0,
  };

  function canCreateOffer() {
    const customerAdvisorId = ownedBy?.id;

    if (!customerAdvisorId) {
      return false;
    }

    return (
      [PositioningStatus.Transaction, PositioningStatus.Confirmed].includes(
        status
      ) &&
      position === 1 &&
      customerAdvisorId === currentUserId
    );
  }
};
