import React, { useCallback } from 'react';

import { Locale } from 'react-admin';
import { useLocaleState, useLocales } from 'ra-core';
import { Dropdown } from 'ui/molecules/dropdown/Dropdown';

interface LocalesButtonProps {
  children?: React.ReactNode;
}

export const LocalesButton: React.FC<LocalesButtonProps> = ({ children }) => {
  const languages: Locale[] = useLocales();
  const [locale, setLocale] = useLocaleState();

  const changeLocale = (nextLocale: Locale) => {
    setLocale(nextLocale.locale);
  };

  const renderLocale = (itemLocale: Locale) => {
    return <>{itemLocale.name}</>;
  };

  const keyExtractor = useCallback((item: Locale) => item.locale, []);

  const selectedValue = languages.find((lang) => lang.locale === locale);

  return (
    <Dropdown
      items={languages}
      renderItem={renderLocale}
      onSelect={changeLocale}
      keyExtractor={keyExtractor}
      value={selectedValue}
    >
      {children}
    </Dropdown>
  );
};
