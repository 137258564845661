import { Translate } from 'react-admin';
import { Bookmark } from 'types/bookmark.types';
import { CellType, GridColDef } from 'types/mui.types';
import { getColumns } from 'utils/columns/getColumns.utils';
import { renderCellLinkRealEstate } from 'utils/columns/renderCells.utils';

export const getCustomerBookmarkColumns = (
  translate: Translate
): GridColDef<Bookmark>[] => {
  const columns: GridColDef<Bookmark>[] = [
    {
      cellType: CellType.DATE_TIME,
      field: 'createdAt',
      headerName: 'shared.dateTime',
      width: 200,
    },
    {
      field: 'realEstate.address.street',
      headerName: 'realEstate.streetName',
      renderCell: ({ row }) =>
        renderCellLinkRealEstate(
          row.realEstateId,
          row.realEstate?.address.street ?? ''
        ),
      sortable: false,
      width: 300,
    },
    {
      field: 'realEstate.address.city',
      headerName: 'realEstate.cityName',
      renderCell: ({ row }) => {
        return row.realEstate?.address.city ?? '';
      },
      sortable: false,
      width: 300,
    },
  ];

  return getColumns(columns, translate);
};
