import { styled } from '@mui/material/styles';
import { Box as MUIBox } from '@mui/material';

const Box = styled(MUIBox)`
  margin-top: 4px;
  margin-left: 16px;
`;

export const GroupSpacer: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;

  return <Box>{children}</Box>;
};
