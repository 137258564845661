import { Slider, styled } from '@mui/material';

export const SliderStyled = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-mark': {
    opacity: 0,
  },
  '& .MuiSlider-rail': {
    opacity: 0,
  },
  '& .MuiSlider-thumb': {
    '&:before': {
      display: 'none',
    },
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    height: 14,
    width: 14,
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  color: theme.palette.text.secondary,
  height: 3,
  padding: '13px 0',
  width: `calc(100% - 24px)`,
}));
