import { Chip, IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { TFunction } from 'i18next';
import LinkIcon from '@mui/icons-material/Link';
import LaunchIcon from '@mui/icons-material/Launch';

export const STATUS_COLORS: Record<string, 'primary' | 'success' | 'default'> =
  {
    archived: 'default',
    draft: 'primary',
    published: 'success',
  };

export const renderCellStatus = (
  translate: TFunction
): ((p: GridRenderCellParams) => React.ReactElement) => {
  const CellStatus = (p: GridRenderCellParams): React.ReactElement => {
    if (!p.value) {
      return <></>;
    }

    const value = p.value.toLowerCase();

    const label = translate(`realization.status.${value}`);
    return <Chip label={label} color={STATUS_COLORS[value]} />;
  };

  CellStatus.displayName = 'CellStatus';

  return CellStatus;
};

export const renderCellExternalLinksUrl = (
  notify: (message: string) => void
): ((p: GridRenderCellParams) => React.ReactElement) => {
  const CellLinkUrl = ({ id }: GridRenderCellParams): React.ReactElement => {
    const linkUrl = `${process.env.REACT_APP_MASTEOS_PUBLIC_URL}/property/sold/${id}`;

    const handleCopyLink = () => {
      navigator.clipboard.writeText(linkUrl);
      notify('shared.linkCopied');
    };

    const handleOpenLink = () => {
      window.open(linkUrl, '_blank')?.focus();
    };

    return (
      <>
        <IconButton
          aria-label="link"
          color="inherit"
          onClick={handleCopyLink}
          size="small"
        >
          <LinkIcon />
        </IconButton>
        <IconButton
          aria-label="open-link"
          color="inherit"
          onClick={handleOpenLink}
          size="small"
        >
          <LaunchIcon />
        </IconButton>
      </>
    );
  };

  CellLinkUrl.displayName = 'CellCellLinkUrl';

  return CellLinkUrl;
};
