import { useMemo } from 'react';

import {
  ListBase,
  useListController,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { DataTable } from 'components/data-table/DataTable';
import { getColumns } from 'utils/columns/getColumns.utils';
import { FilteredListLayout } from 'ui/layouts/filtered-list-layout/FilteredListLayout';
import { GridValidRowModel } from '@mui/x-data-grid/models/gridRows';
import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';
import { PermissionName } from 'types/permission.types';

import { useGetOrganizationsColumns } from './hooks/useGetOrganizationsColumns';
import { formatOrganizationsList } from './utils/formatOrganizationsList.utils';

const PER_PAGE = 20;

export const OrganizationsList: React.FC = () => {
  const redirect = useRedirect();
  const columnsList = useGetOrganizationsColumns();
  const translate = useTranslate();

  const { hasPermission } = useHeliosPermissions();

  const { data: organizations, total } = useListController({
    perPage: PER_PAGE,
  });

  const columns = useMemo(
    () => (organizations ? getColumns(columnsList, translate) : []),
    [organizations, translate, columnsList]
  );

  const rows = useMemo(
    () => formatOrganizationsList(organizations) ?? [],
    [organizations]
  );

  const getRowHeight = ({ model }: { model: GridValidRowModel }) =>
    model.territories.length ? 'auto' : null; // return null or undefined for default row height

  const table = useMemo(
    () => (
      <DataTable
        getRowHeight={getRowHeight}
        columns={columns}
        rows={rows}
        paginationMode="client"
        rowAsLink={hasPermission(PermissionName.UpdateOrganization)}
        sortingMode="client"
      />
    ),
    [columns, hasPermission, rows]
  );

  const handleOnAddClick = () => {
    redirect('create', 'organizations');
  };

  return (
    <>
      <ListBase perPage={PER_PAGE}>
        <FilteredListLayout
          main={table}
          title={translate('organization.list', { count: total ?? 0 })}
          {...(hasPermission(PermissionName.CreateOrganization) && {
            addTitle: translate('organization.add'),
            onAddClick: handleOnAddClick,
          })}
        />
      </ListBase>
    </>
  );
};
