import { HeliosDataProvider } from 'providers/admin/dataProvider';
import {
  HttpError,
  useDataProvider,
  useEditContext,
  useNotify,
} from 'react-admin';
import { useMutation } from 'react-query';
import { ActionMutationReturn } from 'types/common.types';
import { GenerateSearchMandateBody } from 'types/customMethods.types';

export const useGenerateSearchMandateAction = (): ActionMutationReturn<
  unknown,
  unknown,
  GenerateSearchMandateBody,
  unknown
> => {
  const { record } = useEditContext();
  const dataProvider = useDataProvider<HeliosDataProvider>();
  const notify = useNotify();

  const mutation = useMutation(
    ['generateSearchMandate', record?.id, dataProvider],
    (data: GenerateSearchMandateBody) => {
      return dataProvider
        ?.generateSearchMandate(record?.id, data)
        .then(() => {
          notify('customer.generateSearchMandate.success', {
            type: 'success',
          });
        })
        .catch((err: HttpError) => {
          let errorMessage = '';
          const serverError = err.message
            ? err.message.replace(' ', '_').toLowerCase()
            : '';
          switch (serverError) {
            case 'missing_advisor':
              errorMessage = 'customer.generateSearchMandate.missing_advisor';
              break;
            default:
              errorMessage = 'customer.generateSearchMandate.error';
          }
          notify(errorMessage, {
            type: 'error',
          });
          throw err;
        });
    }
  );
  return { ...mutation };
};
