import {
  HttpError,
  useDataProvider,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { useMutation } from 'react-query';
import { HeliosDataProvider } from 'providers/admin/dataProvider';
import { ActionMutationReturn } from 'types/common.types';
import { useTransactionPermissions } from 'features/transactions/show/hooks/useTransactionsPermissions';
import { TransferTransactionPayload } from 'components/action-modal/TransferTransaction/TransferTransactionModal';

export type TransferTransactionMutationReturn = ActionMutationReturn<
  void,
  unknown,
  TransferTransactionPayload,
  unknown
>;

export const useTransferTransactionAction =
  (): TransferTransactionMutationReturn => {
    const refresh = useRefresh();
    const dataProvider = useDataProvider<HeliosDataProvider>();
    const notify = useNotify();
    const translate = useTranslate();
    const { canTransferTransaction } = useTransactionPermissions();

    const mutation = useMutation(
      ['transfer', dataProvider],
      (variables: TransferTransactionPayload) => {
        return dataProvider
          ?.transferProject(variables)
          .then((response: unknown) => {
            if (response) {
              refresh();
              notify('transaction.action.transfer.success', {
                type: 'success',
              });
            }
          })
          .catch((err: HttpError) => {
            let message = 'ra.notification.http_error';
            switch (err.status) {
              case 403:
                message = `${translate(`ra.notification.not_authorized`)} - ${
                  err.message
                }`;
                break;
              case 400:
                message = `${translate(`ra.notification.bad_item`)} - ${
                  err.message
                }`;
                break;
              case 404:
                message = translate(`ra.notification.item_doesnt_exist`);
                break;
              default:
                break;
            }

            notify(message, {
              type: 'error',
            });
          });
      }
    );
    return { ...mutation, isAllowed: canTransferTransaction };
  };
