import { useMemo } from 'react';

import { styled } from '@mui/material';

import { ChipListProps, ChipListListProps } from './ChipList.types';
import { Chip } from '../chip/Chip';

export type { ChipListProps, ChipListListProps };

const StyledContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== '$column',
})`
  display: flex;
  padding: 5px 0;
  flex-direction: ${({ $column }: { $column: Nullable<boolean> }) =>
    $column ? 'column' : 'row'};
  gap: 5px;
`;

export const ChipList: React.FC<ChipListProps> = ({ list, column = false }) => {
  const chips = useMemo(
    () =>
      list
        ? list.map(({ label, icon, bgColor, textColor }) => {
            return (
              <Chip
                data-testid="chip-list-item"
                key={label}
                label={label}
                icon={icon}
                bgColor={bgColor}
                textColor={textColor}
              />
            );
          })
        : null,
    [list]
  );

  return chips ? (
    <StyledContainer $column={column}>{chips}</StyledContainer>
  ) : (
    <></>
  );
};
