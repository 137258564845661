import React from 'react';

import { RangeInput } from 'components/range-input/RangeInput';

import { DpeRangeInputMark } from './DpeRangeInputMark';
import { getDpeGrades } from './DpeRangeInput.utils';

type DpeRangeInputProps = {
  source: string;
  label?: string;
};

export const DpeRangeInput: React.FC<DpeRangeInputProps> = ({
  source,
  label,
}) => {
  const dpeGrades = getDpeGrades();
  const dpeGradesLength = dpeGrades.length;

  return (
    <RangeInput
      source={source}
      label={label}
      min={0}
      max={dpeGradesLength - 1}
      step={1}
      marks={dpeGrades.map((dpeGrade, index) => ({
        label: <DpeRangeInputMark dpeGrade={dpeGrade} />,
        value: index,
      }))}
    />
  );
};
