import {
  DrawerProps as MUIDrawerProps,
  Drawer as MUIDrawer,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';

import { Content, defaultPaperProps } from './Drawer.styles';

export type DrawerProps = MUIDrawerProps;

export const Drawer: React.FC<DrawerProps> = (props) => {
  const { children, title, PaperProps = defaultPaperProps } = props;
  const theme = useTheme();

  return (
    <MUIDrawer {...props} PaperProps={PaperProps}>
      <Content>
        <Toolbar />
        {!!title && (
          <Typography variant="h5" color={theme.palette.colors.deepPurple[600]}>
            {title}
          </Typography>
        )}
        {children}
      </Content>
    </MUIDrawer>
  );
};
