import { styled } from '@mui/material';

export const Container = styled('div')`
  display: grid;
  gap: 24px;

  margin-top: 32px;
  margin-bottom: 24px;

  grid-template-areas:
    'info info info finances'
    'work work work status'
    'internalNote internalNote internalNote status'
    'documents documents documents status';

  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto 1fr;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    grid-template-areas:
      'info'
      'finances'
      'status'
      'work'
      'internalNote'
      'documents';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    grid-template-areas:
      'info info'
      'finances status'
      'internalNote internalNote'
      'work work'
      'documents documents';
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 1fr;
  }

  .grid-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 24px 32px;

    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 8px;
  }

  .info {
    grid-area: info;
  }
  .finances {
    grid-area: finances;
  }
  .work {
    grid-area: work;
    height: fit-content;
  }
  .internalNote {
    grid-area: internalNote;
    height: fit-content;
  }
  .documents {
    grid-area: documents;
  }
  .status {
    grid-area: status;
    height: fit-content;
  }
`;
