import { useInput, useTranslate } from 'react-admin';

import { DateInput } from '../custom-input/date-input/DateInput';

type DateRangeInputProps = {
  fromLabel?: string;
  toLabel?: string;
  fromSource: string;
  toSource: string;
};
export const DateRangeInput: React.FC<DateRangeInputProps> = ({
  fromLabel,
  toLabel,
  fromSource,
  toSource,
}) => {
  const translate = useTranslate();

  const {
    field: { value: fromDateValue },
  } = useInput({ source: fromSource });
  const {
    field: { value: toDateValue },
  } = useInput({ source: toSource });

  return (
    <>
      <DateInput
        label={fromLabel ?? translate('shared.fromDate')}
        source={fromSource}
        helperText={false}
        maxDate={toDateValue}
      />
      <DateInput
        label={toLabel ?? translate('shared.toDate')}
        source={toSource}
        helperText={false}
        minDate={fromDateValue}
      />
    </>
  );
};
