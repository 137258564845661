import { AccordionForm } from 'ui/molecules/accordion-form/AccordionForm';
import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import { TextInput, useTranslate } from 'react-admin';
import { ImageInput } from 'components/image-input/ImageInput';

import { RealEstateEditAccordeonFormProps } from '../EditFormContent.types';
import { sourceToLabelMap } from '../EditFormContent.utils';

export const OverviewForm: React.FC<RealEstateEditAccordeonFormProps> = ({
  defaultExpanded,
  disabled = false,
}) => {
  const translate = useTranslate();

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate('realEstate.form.overview')}
    >
      <Fieldset>
        <TextInput
          disabled={disabled}
          margin="none"
          variant="outlined"
          label={sourceToLabelMap.get('url3D')}
          type="url"
          source="url3D"
        />
      </Fieldset>
      <Fieldset title="image" />
      <ImageInput disabled={disabled} source="coverImage" maxFiles={1} />
    </AccordionForm>
  );
};
