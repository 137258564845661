import { HeliosDataProvider } from 'providers/admin/dataProvider';
import {
  HttpError,
  useDataProvider,
  useEditContext,
  useNotify,
} from 'react-admin';
import { useMutation } from 'react-query';
import { ActionMutationReturn } from 'types/common.types';
import { DownloadBankPdfBody } from 'types/customMethods.types';

export type DownloadBankPdfMutationReturn = ActionMutationReturn<
  void | { data: void },
  unknown,
  DownloadBankPdfBody,
  unknown
>;

export const useDownloadBankPdfAction = (): DownloadBankPdfMutationReturn => {
  const { record } = useEditContext();
  const dataProvider = useDataProvider<HeliosDataProvider>();
  const notify = useNotify();

  const mutation = useMutation(
    ['downloadBankPdf', record?.id, dataProvider],
    (variables: DownloadBankPdfBody) => {
      return dataProvider
        ?.downloadBankPdf(record?.id, variables)
        .then(() => {
          notify('shared.successes.pdfDownloaded', {
            type: 'success',
          });
        })
        .catch((err: HttpError) => {
          const message =
            err.status === 403
              ? `realEstate.action.${err.message}`
              : 'ra.notification.http_error';

          notify(message, {
            type: 'error',
          });
        });
    }
  );
  return { ...mutation, isAllowed: true };
};
