import { useCallback } from 'react';

import { DateInput } from 'components/custom-input/date-input/DateInput';
import { DateLocalizationProvider } from 'components/form/DateLocalizationProvider';
import {
  AutocompleteArrayInput,
  ReferenceArrayInput,
  useListContext,
  useTranslate,
} from 'react-admin';
import { RoleName } from 'types/role.types';
import { UserWithAdvisor } from 'types/user.types';
import { CollapsibleSection } from 'ui/molecules/collapsible-section/CollapsibleSection';
import { formatFullName } from 'utils/strings/strings.utils';

const renderOptionUser = ({ firstName, lastName }: UserWithAdvisor) =>
  formatFullName(firstName, lastName);

const filterSearchToQuery = (search: string) => ({ search });

export const FiltersBody: React.FC = () => {
  const translate = useTranslate();
  const { filterValues } = useListContext();

  const enableGetChoice = useCallback(
    ({ search }: { search: string }) => search?.length >= 3,
    []
  );

  return (
    <DateLocalizationProvider>
      <CollapsibleSection
        label={translate('positioning.filter.advisors')}
        isDefaultExpanded={!!filterValues.advisor?.length}
      >
        <CollapsibleSection.GroupSpacer>
          <ReferenceArrayInput
            source="ownedBy"
            reference="users/filter"
            filter={{ roles: [RoleName.Advisor, RoleName.Mandator] }}
          >
            <AutocompleteArrayInput
              variant="outlined"
              debounce={500}
              label={translate('positioning.filter.advisors')}
              filterToQuery={filterSearchToQuery}
              optionText={renderOptionUser}
            />
          </ReferenceArrayInput>
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>

      <CollapsibleSection
        label={translate('positioning.filter.customers')}
        isDefaultExpanded={!!filterValues.customer?.length}
      >
        <CollapsibleSection.GroupSpacer>
          <ReferenceArrayInput
            label="customers"
            source="customerIds"
            reference="customers/available"
            perPage={20}
            enableGetChoices={enableGetChoice}
          >
            <AutocompleteArrayInput
              variant="outlined"
              debounce={500}
              helperText={translate('positioningForm.searchCustomer.helper')}
              label={translate('positioning.filter.customers')}
              filterToQuery={filterSearchToQuery}
              optionText={renderOptionUser}
            />
          </ReferenceArrayInput>
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>
      <CollapsibleSection
        label={translate('positioning.filter.hunters')}
        isDefaultExpanded={!!filterValues.hunter?.length}
      >
        <CollapsibleSection.GroupSpacer>
          <ReferenceArrayInput
            label="Hunter"
            source="hunter"
            reference="users/filter"
            filter={{ roles: [RoleName.Hunter, RoleName.Mandator] }}
          >
            <AutocompleteArrayInput
              variant="outlined"
              debounce={500}
              label={translate('positioning.filter.hunters')}
              filterToQuery={filterSearchToQuery}
              optionText={renderOptionUser}
            />
          </ReferenceArrayInput>
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>

      <CollapsibleSection
        label={translate('positioning.filter.date')}
        isDefaultExpanded={!!(filterValues.fromDate || filterValues.toDate)}
      >
        <CollapsibleSection.GroupSpacer>
          <DateInput
            label={translate('shared.fromDate')}
            source="fromDate"
            helperText={false}
          />
          <DateInput
            label={translate('shared.toDate')}
            source="toDate"
            helperText={false}
          />
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>
    </DateLocalizationProvider>
  );
};
