import { useStore, useTranslate } from 'react-admin';
import { ActionModal } from 'components/action-modal/ActionModal';
import { Transaction, TransactionModalKey } from 'types/transaction.types';
import { useTransferTransactionAction } from 'features/transactions/show/hooks/useTransferTransactionAction';
import { yupResolver } from '@hookform/resolvers/yup';

import { OrganizationInput } from './form/OrganizationInput';
import { UserInput } from './form/UserInput';
import { transferValidator } from './form/transfer.validator';

export const TransferTransactionModal: React.FC = () => {
  const translate = useTranslate();
  const [isModalOpen, showModal] = useStore(
    TransactionModalKey.TRANSFER_TRANSACTION,
    false
  );

  const { mutate: transfer, isLoading } = useTransferTransactionAction();

  const handleCallback = (transaction: Transaction) => {
    const transferTransactionPayload: TransferTransactionPayload = {
      advisor: transaction.advisor.id,
      id: transaction.id,
      organization: transaction.organization,
    };

    transfer(transferTransactionPayload);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    showModal(false);
  };

  return (
    <ActionModal<Transaction>
      resolver={yupResolver(transferValidator)}
      slug="transferTransaction"
      isOpen={isModalOpen}
      title={translate('transaction.action.transfer.formTitle')}
      saveLabel={translate('transaction.action.transfer.submit')}
      onClose={handleCloseModal}
      disableSaveButton={isLoading}
      callback={handleCallback}
    >
      <OrganizationInput
        label="transaction.action.transfer.fields.organization"
        source="organization"
      />
      <UserInput
        source="advisor.id"
        label="transaction.action.transfer.fields.advisor"
        perPage={2000} // Because no pagination so far
      />
    </ActionModal>
  );
};

export interface TransferTransactionPayload {
  id: string;
  organization: string;
  advisor: string;
}
