import { styled } from '@mui/material';

export const Row = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:nth-child(n + 2) {
    margin-top: 12px;
  }
`;

export const Text = styled('div')`
  margin-right: 16px;
`;
