import React, { useCallback, useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import {
  AutocompleteInput,
  SelectArrayInput,
  TextInput,
  useGetList,
} from 'react-admin';
import { Select } from 'ui/molecules/select/Select';
import { RoleName } from 'types/role.types';
import { useUserPermissions } from 'features/users/list/hooks/useUserPermissions';
import { OrganizationOption } from 'features/users/list/components/organization/OrganizationOption';
import { organizationRenderInput } from 'features/users/list/components/organization/organizationRenderInput';

import { getUserRoleChoices, userRoleOptionText } from './UserFormFields.utils';

interface UserFormFieldsProps {
  isEdit?: boolean;
  cityManagers:
    | {
        label: string;
        value: string;
      }[]
    | undefined;
}

export const UserFormFields: React.FC<UserFormFieldsProps> = ({
  cityManagers,
  isEdit,
}) => {
  const [disableRoles, setDisableRoles] = useState(true);
  const { canOnlyCreateExternalUser } = useUserPermissions();
  useEffect(() => {
    setDisableRoles(canOnlyCreateExternalUser);
  }, [canOnlyCreateExternalUser, setDisableRoles]);

  const { data: organizationsChoices, isLoading: isLoadingOrganizations } =
    useGetList('organizations');
  const { getValues, setValue } = useFormContext();

  const [showCityManager, setShowCityManager] = useState<boolean>(() => {
    const roles = getValues('roles');
    return roles && roles.includes('hunter');
  });

  const [showCGPInfo, setShowCGPInfo] = useState<boolean>(() => {
    const roles = getValues('roles');
    return roles && roles.includes('assetManagementConsultant');
  });

  const handleOrganizationChange = useCallback(
    (value: { id: string }) => {
      const selectedOrganization = organizationsChoices?.find(
        (organization) => organization.id === value.id
      );
      if (selectedOrganization && !selectedOrganization.isMasteosCore) {
        setValue('roles', [RoleName.Mandator]);
        setDisableRoles(true);
      } else {
        value && setDisableRoles(false);
      }
    },
    [organizationsChoices, setValue]
  );

  useEffect(() => {
    if (!isLoadingOrganizations) {
      handleOrganizationChange(getValues('organization'));
    }
  }, [getValues, handleOrganizationChange, isLoadingOrganizations]);

  const handleCityManagerVisibility = useCallback(() => {
    const roles = getValues('roles');
    setShowCityManager(roles && roles.includes('hunter'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCGPInfoVisibility = useCallback(() => {
    const roles = getValues('roles');
    setShowCGPInfo(roles && roles.includes('assetManagementConsultant'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRoleChange = useCallback(() => {
    handleCityManagerVisibility();
    handleCGPInfoVisibility();
  }, [handleCityManagerVisibility, handleCGPInfoVisibility]);

  useEffect(() => {
    if (!isEdit && canOnlyCreateExternalUser) {
      setValue('roles', [RoleName.Mandator]);
      setValue('organization', '');
      setDisableRoles(true);
    }
  }, [canOnlyCreateExternalUser, isEdit, setValue]);

  return (
    <>
      <TextInput
        margin="none"
        variant="outlined"
        label="user.input.firstname"
        source="firstName"
      />
      <TextInput
        margin="none"
        variant="outlined"
        label="user.input.lastname"
        source="lastName"
      />
      <TextInput
        margin="none"
        variant="outlined"
        label="user.input.email"
        source="email"
        disabled={isEdit}
      />
      <SelectArrayInput
        label="user.input.role"
        disabled={disableRoles}
        choices={getUserRoleChoices(getValues('roles'))}
        optionText={userRoleOptionText}
        optionValue="value"
        source="roles"
        variant="outlined"
        onChange={handleRoleChange}
      />
      {!!showCityManager && (
        <Select
          label="user.input.cityManager"
          choices={cityManagers}
          optionText="label"
          optionValue="value"
          source="cityManagerId"
          variant="outlined"
        />
      )}
      {!!showCGPInfo && (
        <>
          <TextInput
            margin="none"
            variant="outlined"
            label="user.input.company"
            source="professionalExtraData.company"
          />
          <TextInput
            margin="none"
            variant="outlined"
            label="user.input.registrationAt"
            source="professionalExtraData.registrationAt"
          />
          <TextInput
            margin="none"
            variant="outlined"
            label="user.input.registrationNumber"
            source="professionalExtraData.registrationNumber"
          />
        </>
      )}
      <AutocompleteInput
        source="organization.id"
        choices={organizationsChoices}
        getOptionDisabled={
          canOnlyCreateExternalUser ? (opt) => opt.isMasteosCore : undefined
        }
        label="user.input.organization"
        isRequired
        variant="outlined"
        inputText={organizationRenderInput}
        optionText={OrganizationOption}
        onChange={handleOrganizationChange}
        isLoading={isLoadingOrganizations}
      />
    </>
  );
};
