import React from 'react';

import { Theme, useMediaQuery } from '@mui/material';
import { useRedirect, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import {
  BookmarkIcon,
  Container,
  InfoIcon,
  PositioningIcon,
  Tab,
  Tabs,
  TransactionIcon,
} from 'components/headerTabs/HeaderTabs.styles';
import { getShortOrRegularTranslateKey } from 'utils/strings/getShortOrRegularTranslateKey';
import { truncate } from 'utils/strings/truncate';

import { URI } from 'constants/uri';

import { CustomerHeaderTabsProps } from './CustomerHeaderTabs.types';

export const CustomerHeaderTabs: React.FC<CustomerHeaderTabsProps> = ({
  customerId,
  bookmarkCount,
  positioningCount,
}) => {
  const translate = useTranslate();
  const { pathname } = useLocation();
  const redirect = useRedirect();
  const isSmall = useMediaQuery(
    (t: Theme) => `(max-width:${t.breakpoints.values.sm}px)`
  );

  const editCustomerUrl = `${URI.customers}/${customerId}`;
  const customerPositioninsgUrl = `${editCustomerUrl}/positionings`;
  const customerBookmarksUrl = `${editCustomerUrl}/bookmarks`;
  const customerTransactionsUrl = `${editCustomerUrl}/transactions`;

  const onChange = (_event: React.SyntheticEvent, path: string) => {
    redirect(path);
  };

  return (
    <Container>
      <Tabs
        value={pathname}
        onChange={onChange}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab
          value={editCustomerUrl}
          label={translate(
            getShortOrRegularTranslateKey(`customer.edit.tabs.edition`, isSmall)
          )}
          icon={<InfoIcon />}
          iconPosition="start"
        />
        <Tab
          value={customerPositioninsgUrl}
          label={translate(
            getShortOrRegularTranslateKey(
              `shared.headerTabs.positionings`,
              isSmall
            ),
            { count: positioningCount }
          )}
          icon={<PositioningIcon />}
          iconPosition="start"
        />
        <Tab
          value={customerBookmarksUrl}
          label={translate(
            getShortOrRegularTranslateKey(
              `shared.headerTabs.bookmarks`,
              isSmall
            ),
            { count: bookmarkCount }
          )}
          icon={<BookmarkIcon />}
          iconPosition="start"
        />
        <Tab
          value={customerTransactionsUrl}
          label={
            isSmall
              ? truncate(translate('shared.headerTabs.transactions'), 8, '')
              : translate('shared.headerTabs.transactions')
          }
          icon={<TransactionIcon />}
          iconPosition="start"
        />
      </Tabs>
    </Container>
  );
};
