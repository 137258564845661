import React from 'react';

import { Card, styled, TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useTranslate } from 'react-admin';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[200],
    fontWeight: 'bold',
  },
}));

export const OrganizationUsersTable: React.FC<{ users: string[] }> = ({
  users,
}) => {
  const translate = useTranslate();
  return (
    <TableContainer component={Card} elevation={1}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>{translate('organization.users')}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((row: string) => (
            <TableRow key={row}>
              <StyledTableCell component="th" scope="row">
                {row}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
