import { Translate } from 'react-admin';

export const getBasicAutocompleteGroupByFunction =
  (translate: Translate) =>
  ({ category }: { id: string; category: string }): string =>
    translate(category);

export const getBasicAutocompleteInputTextFunction =
  (translate: Translate) =>
  ({ name }: { id: string; name: string }): string =>
    translate(name);

export const getBasicAutocompleteMatchSuggestionFunction =
  (translate: Translate) =>
  (filter = '', { name }: { name: string }): boolean => {
    const normalizedName = translate(name)
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\W/g, ' ');
    const normalizedFilter = filter
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\W/g, ' ');
    const regExp = new RegExp(String.raw`\b${normalizedFilter}`, 'i');
    return !!regExp.exec(normalizedName)?.length;
  };
