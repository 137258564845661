import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'components/form/Form';
import { CustomerEditFormatted } from 'types/customer.types';
import { MobileFooter } from 'components/mobile-footer/MobileFooter';
import { CustomerHeader } from 'features/customers/details/components/header/CustomerHeader';

import { customerEditFormValidator } from './EditForm.validator';
import { EditFormContent } from './edit-form-content/EditFormContent';
import { ActionButtons } from './action-buttons/ActionButtons';

type CustomerEditFormProps = {
  record: CustomerEditFormatted | undefined;
};

export const EditForm: React.FC<CustomerEditFormProps> = ({ record }) => {
  return (
    <Form
      mode="onBlur"
      record={record}
      resolver={yupResolver(customerEditFormValidator)}
    >
      <CustomerHeader actionButtons={<ActionButtons />} />
      <EditFormContent />
      <MobileFooter>
        <ActionButtons />
      </MobileFooter>
    </Form>
  );
};
