import { SelectInput as RASelectInput } from 'react-admin';
import { styled } from '@mui/material/styles';

export const Select = styled(RASelectInput)`
  & .MuiFormHelperText-root {
    margin: 0;
    padding: 0;
  }

  & .MuiInputBase-input {
    padding: ${(props) => props.theme.spacing(2)};
  }

  & .MuiInputLabel-root {
    top: 6px;
  }
`;
