import { useCallback, useMemo } from 'react';

import { useTranslate, useListController, ListBase } from 'react-admin';
import { DataTable } from 'components/data-table/DataTable';
import { getColumns } from 'utils/columns/getColumns.utils';
import { FilteredListLayout } from 'ui/layouts/filtered-list-layout/FilteredListLayout';
import { GridCellParams, MuiEvent } from '@mui/x-data-grid';
import { SearchBar } from 'ui/molecules/search-bar/SearchBar';

import { formatTransactionList } from './utils/formatTransactionList.util';
import { TransactionsFilters } from './components/filters/TransactionsFilters';
import { getTransactionsColumns } from './getTransactionColumns';

const PER_PAGE = 20;

export const TransactionsList: React.FC = () => {
  const translate = useTranslate();
  const columnsList = getTransactionsColumns(translate);

  const {
    data: transactions,
    total,
    setFilters,
    filterValues,
  } = useListController({
    perPage: PER_PAGE,
  });

  const columns = useMemo(
    () => (transactions ? getColumns(columnsList, translate) : []),
    [transactions, translate, columnsList]
  );

  const rows = useMemo(
    () =>
      transactions
        ? formatTransactionList(transactions.filter((t) => t.property))
        : [],
    [transactions]
  );

  const handleCellClick = useCallback(
    ({ field }: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
      if (field === 'link') {
        event.preventDefault();
      }
    },
    []
  );

  const searchBar = useMemo(
    () => (
      <SearchBar
        title={translate('transaction.search.title')}
        placeholder={translate('transaction.search.placeholder')}
        setFilters={setFilters}
        filterValues={filterValues}
      />
    ),
    [filterValues, setFilters, translate]
  );

  const table = useMemo(
    () => (
      <DataTable
        onCellClick={handleCellClick}
        columns={columns}
        rows={rows}
        linkTarget="show"
        rowAsLink
      />
    ),
    [columns, handleCellClick, rows]
  );

  return (
    <>
      <ListBase perPage={PER_PAGE}>
        <FilteredListLayout
          main={table}
          filters={<TransactionsFilters />}
          searchBar={searchBar}
          title={translate('transaction.list', { count: total ?? 0 })}
        />
      </ListBase>
    </>
  );
};
