import React, { useMemo } from 'react';

import { styled } from '@mui/material/styles';
import {
  Edit as RAEdit,
  useGetList,
  useNotify,
  useTranslate,
} from 'react-admin';
import { Modal, ModalFooterButtonsContainer } from 'ui/organisms/modal/Modal';
import { Button } from 'ui/atoms/button/Button';
import { DataCitiesManager } from 'types/user.types';
import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';
import { PermissionName } from 'types/permission.types';

import { UserForm } from './UserForm';
import { formatCitiesManager } from './formatCitiesManager.utils';

const Edit = styled(RAEdit)`
  & .MuiPaper-root {
    box-shadow: none;
    border: none;
  }
`;

interface UserModalFormProps {
  onClose: () => void;
  id: string;
  openModalConfirmDelete: () => void;
}

export const EditUserModalForm: React.FC<UserModalFormProps> = ({
  onClose,
  id,
  openModalConfirmDelete,
}) => {
  const { data: dataCitiesManager } = useGetList('users/citiesmanagers');

  const { hasPermission } = useHeliosPermissions();

  const canArchiveUser =
    hasPermission(PermissionName.ArchiveUserExternal) ||
    hasPermission(PermissionName.DeleteUser);

  const translate = useTranslate();
  const notify = useNotify();

  const computedCitiesManager = useMemo(
    () => formatCitiesManager(dataCitiesManager as DataCitiesManager[]),
    [dataCitiesManager]
  );

  const onSuccess = () => {
    notify('user.form.edit.successToaster', { type: 'success' });
    onClose();
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onError = (err: any) => {
    notify(err?.body?.message || 'user.edit.error', {
      type: 'error',
    });
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      title={translate('users.edit')}
      showCloseButton={true}
      footer={
        <ModalFooterButtonsContainer>
          {!!canArchiveUser && (
            <Button
              variant="text"
              onClick={() => {
                openModalConfirmDelete();
              }}
            >
              {translate('ra.action.delete')}
            </Button>
          )}

          <Button
            variant="text"
            type="submit"
            form="user-form"
            data-testid="user-form-submit-button"
          >
            {translate('ra.action.save')}
          </Button>
        </ModalFooterButtonsContainer>
      }
    >
      <Edit
        id={id}
        resource="users"
        transform={(userView) => {
          return {
            ...userView.professionalExtraData,
            ...userView,
            organization: userView.organization.id,
          };
        }}
        mutationMode="pessimistic"
        mutationOptions={{ onError, onSuccess }}
      >
        <UserForm isEdit cityManagers={computedCitiesManager} />
      </Edit>
    </Modal>
  );
};
