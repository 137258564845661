import { RealEstateType } from 'types/realEstate.enum';

export const RENTING_KINDS = {
  COLOCATION: 'COLOCATION',
  SIMPLE: 'SIMPLE',
};

export const STATUS_COLORS: {
  [n: string]: 'primary' | 'success' | 'warning';
} = {
  available: 'primary',
  sold: 'success',
  transaction: 'warning',
};

export const ARCHIVING_REASONS_DATA = [
  { id: 'alreadySold', name: 'realEstate.action.archive.alreadySold' },
  { id: 'sellerRetracts', name: 'realEstate.action.archive.sellerRetracts' },
  { id: 'others', name: 'realEstate.action.archive.others' },
];

export const PROPERTY_KIND_DATA = [
  { id: RealEstateType.Building, name: 'realEstate.propertyKind.building' },
  {
    id: RealEstateType.Apartment,
    name: 'realEstate.propertyKind.apartment',
  },
  {
    id: RealEstateType.House,
    name: 'realEstate.propertyKind.house',
  },
];

export const RENTAL_SITUATIONS_DATA = [
  { id: 'EMPTY', name: 'realEstate.rentalSituation.empty' },
  { id: 'FULLY_RENTED', name: 'realEstate.rentalSituation.fullyRented' },
  { id: 'PARTLY_RENTED', name: 'realEstate.rentalSituation.partlyRented' },
];

export const LOCATION_TYPE_DATA = [
  { id: RENTING_KINDS.COLOCATION, name: 'realEstate.location.type.colocation' },
  { id: RENTING_KINDS.SIMPLE, name: 'realEstate.location.type.simple' },
];

export const APPRECIATION_ZONE_DATA = [
  { id: 'CITY', name: 'realEstate.appreciationZone.city' },
  { id: 'NEIGHBORHOOD', name: 'realEstate.appreciationZone.neighborhood' },
  { id: 'NO_DATA', name: 'realEstate.appreciationZone.noData' },
];

export const ESTIMATED_WORKS_DATA = [
  { id: 'HEAVY_WORK', name: 'realEstate.estimatedWorks.heavyWork' },
  { id: 'NONE', name: 'realEstate.estimatedWorks.none' },
  { id: 'REFRESH', name: 'realEstate.estimatedWorks.refresh' },
  { id: 'RENOVATE', name: 'realEstate.estimatedWorks.renovate' },
  {
    id: 'THERMAL_ENERGY_WORK',
    name: 'realEstate.estimatedWorks.thermalEnergyWork',
  },
  { id: 'UNKNOWN', name: 'realEstate.estimatedWorks.unknown' },
];

export const LANGS_DATA = [
  { id: 'FR', name: 'FR' },
  { id: 'EN-US', name: 'EN-US' },
  { id: 'ES', name: 'ES' },
];

export const DPE_DATA = [
  { id: 'NoDiag', name: 'NoDiag' },
  { id: 'A+', name: 'A+' },
  { id: 'A', name: 'A' },
  { id: 'B', name: 'B' },
  { id: 'C', name: 'C' },
  { id: 'D', name: 'D' },
  { id: 'E', name: 'E' },
  { id: 'F', name: 'F' },
  { id: 'G', name: 'G' },
];

export const GES_DATA = [
  { id: 'NoDiag', name: 'NoDiag' },
  { id: 'A', name: 'A' },
  { id: 'B', name: 'B' },
  { id: 'C', name: 'C' },
  { id: 'D', name: 'D' },
  { id: 'E', name: 'E' },
  { id: 'F', name: 'F' },
  { id: 'G', name: 'G' },
];

export const STATUSES = {
  ARCHIVED: 'ARCHIVED',
  AVAILABLE: 'AVAILABLE',
  DRAFT: 'DRAFT',
  IN_REVIEW: 'IN_REVIEW',
  SOLD: 'SOLD',
  TRANSACTION: 'TRANSACTION',
};
