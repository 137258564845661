import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'components/form/Form';

import { addExternalCustomerFormValidator } from './AddExternalCustomerForm.validator';
import { AddExternalCustomerFormProps } from './AddExternalCustomerForm.types';
import { AddExternalCustomerFormFields } from './AddExternalCustomerFormFields';

export const AddExternalCustomerForm: React.FC<
  AddExternalCustomerFormProps
> = ({ onSubmit }) => {
  return (
    <Form
      onSubmit={onSubmit}
      id="customer-create-form"
      resolver={yupResolver(addExternalCustomerFormValidator)}
    >
      <AddExternalCustomerFormFields />
    </Form>
  );
};
