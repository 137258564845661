import { Theme } from '@mui/material';

export const detailContainerStyles = `
  width: 100%;
    .RaEdit-card {
    width: 100%;
  }

  @media (min-width: ${({ theme }: { theme: Theme }) =>
    theme.breakpoints.values.lg}px) {
    margin-left: 64px;
    margin-right: 64px;
  }
`;
