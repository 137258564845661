import { Fieldset } from 'ui/molecules/fieldset/Fieldset';
import { useTranslate } from 'react-admin';
import { AccordionForm } from 'ui/molecules/accordion-form/AccordionForm';
import { TextArea } from 'ui/atoms/textarea/TextArea';
import { ImageInput } from 'components/image-input/ImageInput';

import { RealEstateEditAccordeonFormProps } from '../EditFormContent.types';
import { sourceToLabelMap } from '../EditFormContent.utils';

export const MiscForm: React.FC<RealEstateEditAccordeonFormProps> = ({
  defaultExpanded,
  disabled = false,
}) => {
  const translate = useTranslate();

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate('realEstate.form.misc')}
    >
      <Fieldset title={translate('realEstate.fieldset.exterior')}>
        <TextArea
          disabled={disabled}
          fullWidth
          source="outside.description"
          label={sourceToLabelMap.get('outside.description')}
        />
      </Fieldset>
      <ImageInput disabled={disabled} source="outside.images" />
      <Fieldset title={translate('realEstate.fieldset.common')}>
        <TextArea
          fullWidth
          disabled={disabled}
          source="common.description"
          label={sourceToLabelMap.get('common.description')}
        />
      </Fieldset>
      <ImageInput disabled={disabled} source="common.images" />
      <Fieldset title={translate('realEstate.fieldset.inside')}>
        <TextArea
          fullWidth
          disabled={disabled}
          source="inside.description"
          label={sourceToLabelMap.get('inside.description')}
        />
      </Fieldset>
      <ImageInput disabled={disabled} source="inside.images" />
    </AccordionForm>
  );
};
