import {
  HttpError,
  useDataProvider,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { ActionMutationReturn } from 'types/common.types';
import { useMutation } from 'react-query';
import { HeliosDataProvider } from 'providers/admin/dataProvider';
import { BookRealEstateBody } from 'types/cgpCustomMethods.types';
import { PermissionName } from 'types/permission.types';
import { useHeliosPermissions } from 'hooks/use-helios-permissions/useHeliosPermissions';

export type BookRealEstateMutationReturn = ActionMutationReturn<
  void,
  unknown,
  BookRealEstateBody,
  unknown
>;

export const useBookRealEstateAction = (
  id?: string
): BookRealEstateMutationReturn => {
  const record = useRecordContext(id ? { record: { id } } : undefined);
  const dataProvider = useDataProvider<HeliosDataProvider>();
  const notify = useNotify();
  const { hasPermission } = useHeliosPermissions();

  const isAllowed = hasPermission(PermissionName.BookRealEstateCgp);

  const mutation = useMutation(
    ['bookRealEstate', record?.id, dataProvider],
    (data: BookRealEstateBody) => {
      return dataProvider
        ?.bookRealEstate(record?.id, data)
        .then(() => {
          notify('realEstate.action.book.success', { type: 'success' });
        })
        .catch((err: HttpError) => {
          notify(err.body?.message ?? '', { type: 'error' });
          throw err;
        });
    }
  );

  return { ...mutation, isAllowed };
};
