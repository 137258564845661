export const sourceToLabelMap = new Map<string, string>([
  ['internalNote', 'realEstate.input.internalNote'],
  ['description', 'realEstate.input.description'],
  ['type', 'realEstate.input.propertyKind'],
  ['surface', 'realEstate.input.surface'],
  ['roomNumber', 'realEstate.input.roomNumber'],
  ['floor', 'realEstate.input.floor'],
  ['locationType', 'realEstate.input.locationType'],
  ['rentalSituation', 'realEstate.input.rentalSituation'],
  ['totalLots', 'realEstate.input.totalLots'],
  ['isFurnished', 'realEstate.input.isFurnished'],
  ['hasCoOwnership', 'realEstate.input.hasCoOwnership'],
  [
    'exclusiveEndDateCheckbox',
    'realEstate.form.exclusiveDateEnd.checkboxLabel',
  ],
  ['exclusiveEndDate', 'realEstate.form.exclusiveDateEnd.label'],
  ['address.street', 'realEstate.input.street'],
  ['address.complement', 'realEstate.input.complement'],
  ['address.buildingCode', 'realEstate.input.buildingCode'],
  ['address.postalCode', 'realEstate.input.postalCode'],
  ['address.city', 'realEstate.input.city'],
  ['address.regionName', 'realEstate.input.regionName'],
  ['address.country', 'realEstate.input.country'],
  ['address.countryCode', 'realEstate.input.countryCode'],
  ['estimatedWorks', 'realEstate.input.estimatedWorks'],
  ['renovation.description', 'realEstate.input.renovationDescription'],
  ['financialModel.netSeller', 'financial.netSeller'],
  ['financialModel.agencySellerCommission', 'financial.agencySellerCommission'],
  ['financialModel.renovationAmount', 'financial.renovationAmount'],
  ['financialModel.furnituresAmount', 'financial.furnituresAmount'],
  ['financialModel.annualRentIncome', 'financial.annualRentIncome'],
  [
    'isAnnualRentIncomeValidated',
    'realEstate.input.isAnnualRentIncomeValidated',
  ],
  ['financialModel.annualCharges', 'financial.annualCharges'],
  ['financialModel.evaluationRate', 'financial.evaluationRate'],
  ['appreciationZone', 'realEstate.input.appreciationZone'],
  ['financialModel.annualCurrentExpenses', 'financial.annualCurrentExpenses'],
  ['financialModel.annualPropertyTax', 'financial.annualPropertyTax'],
  [
    'financialModel.annualHomeOwnerInsurance',
    'financial.annualHomeOwnerInsurance',
  ],
  ['financialModel.projectAmount', 'financial.projectAmount'],
  ['financialModel.annualTotalIncome', 'financial.annualTotalIncome'],
  ['financialModel.annualTotalExpenses', 'financial.annualTotalExpenses'],
  ['financialModel.annualLoanPayments', 'financial.annualLoanPayments'],
  ['financialModel.annualTotalEarnings', 'financial.annualTotalEarnings'],
  ['financialModel.grossReturn', 'financial.grossReturn'],
  [
    'financialModel.necessaryFurnitureCheckbox',
    'financial.necessaryFurnitureCheckbox',
  ],
  ['financialModel.furnitureBudgetInput', 'financial.furnitureBudgetInput'],
  ['financialModel.netReturn', 'financial.netReturn'],
  ['financialModel.shortTermNightlyRent', 'financial.shortTermNightlyRent'],
  ['financialModel.shortTermOccupancyRate', 'financial.shortTermOccupancyRate'],
  ['url3D', 'realEstate.input.url3D'],
  ['contact._id', 'shared.contact'],

  ['outside.description', 'realEstate.input.outsideDescription'],
  ['common.description', 'realEstate.input.commonDescription'],
  ['inside.description', 'realEstate.input.insideDescription'],
  ['dpe', 'realEstate.dpe'],
  ['ges', 'realEstate.ges'],
  ['renovationToolUrl', 'realEstate.input.renovationToolUrl'],
  ['estimatedWorks', 'realEstate.input.estimatedWorks'],
  ['renovation.description', 'realEstate.input.renovationDescription'],
  ['access.description', 'realEstate.input.accessDescription'],
  ['neighborhood.description', 'realEstate.input.neighborhoodDescription'],
  ['synthesis.theme', 'realEstate.input.synthesisTheme'],
  ['synthesis.description', 'realEstate.input.synthesisDescription'],
  ['sellTarget', 'realEstate.sellTargetCheckboxCGP'],
]);
