import { Translate, useNotify } from 'react-admin';
import { CellType, GridColDef } from 'types/mui.types';

import {
  renderCellExternalLinksUrl,
  renderCellStatus,
} from './columnsRenderCell';

export const getRealizationsColumns = (
  translate: Translate,
  notify: ReturnType<typeof useNotify>
): GridColDef[] => {
  return [
    {
      field: 'link',
      headerName: 'shared.link',
      renderCell: renderCellExternalLinksUrl(notify),
      sortable: false,
      width: 80,
    },
    {
      field: 'title',
      headerName: 'realization.title',
      width: 400,
    },
    {
      field: 'status',
      headerName: 'realization.realisationStatus',
      renderCell: renderCellStatus(translate),
      width: 150,
    },
    {
      field: 'realEstate',
      headerName: 'realization.realEstateId',
      width: 250,
    },
    {
      field: 'address.city',
      headerName: 'realization.city',
      width: 150,
    },
    {
      cellType: CellType.PRICE,
      field: 'finances.projectAmount',
      headerName: 'realization.projectAmount',
      width: 150,
    },
    {
      field: 'afterRenovation.dpe',
      headerName: 'realization.dpeAfterRenovation',
      width: 200,
    },
    {
      field: 'beforeRenovation.dpe',
      headerName: 'realization.dpeBeforeRenovation',
      width: 200,
    },
    {
      field: 'finances.rentingDelay',
      headerName: 'realization.rentingDelay',
      width: 200,
    },
  ];
};
