import { useCallback, useMemo } from 'react';

import {
  useTranslate,
  useStore,
  useListController,
  ListBase,
  useNotify,
  useGetIdentity,
} from 'react-admin';
import { DataTable } from 'components/data-table/DataTable';
import { getColumns } from 'utils/columns/getColumns.utils';
import { FilteredListLayout } from 'ui/layouts/filtered-list-layout/FilteredListLayout';
import { GridCellParams, MuiEvent } from '@mui/x-data-grid';
import { SearchBar } from 'ui/molecules/search-bar/SearchBar';
import { RealEstateItemView } from 'types/realEstate.types';
import { MasteosUserIdentityResult } from 'features/auth/utils/getIdentity';

import { formatRealEstateList } from './utils/formatRealEstateList.utils';
import { RealEstatesFilters } from './components/filters/RealEstatesFilters';
import { AddRealEstateModal } from './components/modals/AddRealEstateModal';
import { RealEstateModalKey } from '../constants';
import { getRealEstatesColumns } from './utils/getRealEstatesColumns';
import { getRealEstatesDefaultFilters } from './components/filters/RealEstatesFilters.utils';
import { useRealEstatePermissions } from '../hooks/useRealEstatePermissions';

const PER_PAGE = 20;

export const RealEstatesList: React.FC = () => {
  const [, showModal] = useStore(RealEstateModalKey.ADD, false);
  const translate = useTranslate();
  const notify = useNotify();

  const { data } = useGetIdentity();
  const userId = (data as unknown as MasteosUserIdentityResult)?.id;
  const { canAddOrDuplicate, setCurrentUserAsHunterFilter } =
    useRealEstatePermissions();

  const { dpeGrades, hunters } = getRealEstatesDefaultFilters(
    setCurrentUserAsHunterFilter ? [userId] : undefined
  );

  const columnsList = getRealEstatesColumns(translate, notify);

  const {
    data: realEstates,
    total,
    setFilters,
    filterValues,
  } = useListController<RealEstateItemView>({
    filterDefaultValues: { dpeGrades, hunters },
    perPage: PER_PAGE,
  });

  const columns = useMemo(
    () => (realEstates ? getColumns(columnsList, translate) : []),
    [realEstates, translate, columnsList]
  );

  const rows = useMemo(
    () => (realEstates ? formatRealEstateList(realEstates) : []),
    [realEstates]
  );

  const openModal = () => showModal(true);

  const handleCellClick = useCallback(
    ({ field }: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
      if (field === 'link') {
        event.preventDefault();
      }
    },
    []
  );

  const searchBar = useMemo(
    () => (
      <SearchBar
        title={translate('realEstate.search.title')}
        placeholder={translate('realEstate.search.placeholder')}
        setFilters={setFilters}
        filterValues={filterValues}
      />
    ),
    [filterValues, setFilters, translate]
  );

  const table = useMemo(
    () => (
      <DataTable
        onCellClick={handleCellClick}
        columns={columns}
        rows={rows}
        rowAsLink
      />
    ),
    [columns, handleCellClick, rows]
  );

  return (
    <>
      <ListBase
        perPage={PER_PAGE}
        filterDefaultValues={{
          dpeGrades,
          hunters,
        }}
      >
        <FilteredListLayout
          main={table}
          filters={<RealEstatesFilters />}
          searchBar={searchBar}
          title={translate('realEstate.list', { count: total ?? 0 })}
          {...(canAddOrDuplicate && {
            addTitle: translate('realEstate.add'),
            onAddClick: openModal,
          })}
        />
      </ListBase>
      <AddRealEstateModal />
    </>
  );
};
