import { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { DocumentDirectUploadCategory } from 'types/document.enum';

export const useUploadOptions = () => {
  const translate = useTranslate();
  const documentDirectUploadCategoryChoices = useMemo(
    () => [
      {
        id: DocumentDirectUploadCategory.FinancingCertificate,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.financingLetter'
        ),
      },
      {
        id: DocumentDirectUploadCategory.Kbis,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.kbis'
        ),
      },
      {
        id: DocumentDirectUploadCategory.IdentityCard,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.identityCard'
        ),
      },
      {
        id: DocumentDirectUploadCategory.ProofOfAddress,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.proofOfAddress'
        ),
      },
      {
        id: DocumentDirectUploadCategory.BankAccountDetails,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.bankAccountDetails'
        ),
      },
      {
        id: DocumentDirectUploadCategory.JobContract,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.jobContract'
        ),
      },
      {
        id: DocumentDirectUploadCategory.Payslip,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.payslip'
        ),
      },
      {
        id: DocumentDirectUploadCategory.TaxNotice,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.taxNotice'
        ),
      },
      {
        id: DocumentDirectUploadCategory.Other,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.other'
        ),
      },
      {
        id: DocumentDirectUploadCategory.DesignPlan,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.designPlan'
        ),
      },
      {
        id: DocumentDirectUploadCategory.WorksiteReport,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.worksiteReport'
        ),
      },
      {
        id: DocumentDirectUploadCategory.WorksiteImages,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.worksiteImages'
        ),
      },
      {
        id: DocumentDirectUploadCategory.ThermalDiagnosis,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.thermalDiagnosis'
        ),
      },
      {
        id: DocumentDirectUploadCategory.CustomerAcceptanceReport,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.customerAcceptanceReport'
        ),
      },
      {
        id: DocumentDirectUploadCategory.Doe,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.doe'
        ),
      },
      {
        id: DocumentDirectUploadCategory.RaaBis,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.raaBis'
        ),
      },
      {
        id: DocumentDirectUploadCategory.InstalledEquipmentList,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.installedEquipmentList'
        ),
      },
      {
        id: DocumentDirectUploadCategory.PropertySheet,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.propertySheet'
        ),
      },
      {
        id: DocumentDirectUploadCategory.GlobalContract,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.globalContract'
        ),
      },
      {
        id: DocumentDirectUploadCategory.PostSaleDiagnostics,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.postSaleDiagnostics'
        ),
      },
      {
        id: DocumentDirectUploadCategory.HunterDocument,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.hunterDocument'
        ),
      },
      {
        id: DocumentDirectUploadCategory.ManagementMandate,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.managementMandate'
        ),
      },
      {
        id: DocumentDirectUploadCategory.TransactionInvoice,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.transactionInvoice'
        ),
      },
      {
        id: DocumentDirectUploadCategory.CustomerInvoice,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.customerInvoice'
        ),
      },
      {
        id: DocumentDirectUploadCategory.FinancingCertificate,
        name: translate(
          'customer.actions.addDocumentModal.documentType.options.financingCertificate'
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return documentDirectUploadCategoryChoices;
};
