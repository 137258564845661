import { useEffect, useState } from 'react';

import {
  useStore,
  Create as RACreate,
  useNotify,
  useRedirect,
  useEditContext,
  useCreate,
  useDataProvider,
} from 'react-admin';
import { formatFullStreet } from 'utils/form/form.utils';
import { getInternalNoteTemplate } from 'features/realEstates/utils/getInternalNoteTemplate';
import { transformForDuplication } from 'features/realEstates/utils/payloadTransforms';
import { useNavigate } from 'react-router-dom';
import { RealEstate } from 'types/realEstate.types';
import { yupResolver } from '@hookform/resolvers/yup';
import { RealEstateModalKey } from 'features/realEstates/constants';
import { useMutation } from 'react-query';
import { DuplicatedBody } from 'types/customMethods.types';
import { RealEstateType } from 'types/realEstate.enum';
import { Form } from 'components/form/Form';
import { HeliosDataProvider } from 'providers/admin/dataProvider';
import { ErrorResponse } from 'types/common.types';
import {
  trackAddRealEstate,
  trackDuplicateRealEstate,
} from 'libs/segment/segment.trackings';
import { styled } from '@mui/material';

import { AddRealEstateModalForm } from './AddRealEstateModalForm';
import {
  AddPropertyFormValidated,
  addPropertyForm,
} from './AddRealEstate.validator';

const Create = styled(RACreate)`
  & .MuiPaper-root {
    box-shadow: none;
    border: none;
  }
` as unknown as typeof RACreate;

const StyledForm = styled(Form)`
  box-shadow: none;
` as unknown as typeof Form;

export const AddRealEstateModal: React.FC = () => {
  const [isOpen, setOpenModalCreate] = useStore(RealEstateModalKey.ADD, false);

  const [showFormModal, setShowFormModal] = useStore(
    RealEstateModalKey.ADD_FORM,
    false
  );
  const [formData, setFormData] = useState({});
  const [loadingForm, setLoadingForm] = useState(false);
  const navigate = useNavigate();
  const { record } = useEditContext();
  const redirect = useRedirect();
  const notify = useNotify();
  const [create] = useCreate();
  const dataProvider = useDataProvider<HeliosDataProvider>();
  const { mutateAsync: duplicate } = useMutation(
    ['duplicate', record?.id, dataProvider],
    (data: DuplicatedBody) => dataProvider?.duplicate(record?.id, data)
  );

  useEffect(() => {
    if (isOpen) {
      setShowFormModal(true);
      setOpenModalCreate(false);
    }
  }, [isOpen, setShowFormModal, setOpenModalCreate]);

  const isDuplicating = !!record;

  const onCloseFormModal = () => {
    setShowFormModal(false);
    setLoadingForm(false);
  };

  const onError = (err: unknown) => {
    notify((err as ErrorResponse)?.body?.message || 'realEstate.create.error', {
      type: 'error',
    });
  };

  const onSuccess = (response: RealEstate) => {
    notify(isDuplicating ? 'realEstate.duplicate.ok' : 'realEstate.create.ok', {
      type: 'success',
    });
    redirect('edit', 'real-estates', response.id);
    isDuplicating && navigate(0);
    onCloseFormModal();
  };

  const handleTransform = (
    data: Partial<RealEstate>
  ): Partial<DuplicatedBody> => {
    const formattedRecord = {
      ...data,
      address: {
        ...data.address,
        ...(data.address ? { street: formatFullStreet(data.address) } : {}),
      },
      internalNote: getInternalNoteTemplate(data.lang || 'fr'),
      type: data.type || RealEstateType.Apartment,
    };

    if (!isDuplicating) {
      return formattedRecord;
    }

    const {
      _id,
      lang,
      address,
      type,
      internalNote,
      surface,
      roomNumber,
      financialModel,
    } = formattedRecord;

    return {
      address,
      annualCharges: financialModel?.annualCharges,
      annualIncome: financialModel?.annualRentIncome,
      internalNote,
      lang,
      netSeller: financialModel?.netSeller,
      realEstateId: _id,
      renovationAmount: financialModel?.renovationAmount,
      roomCount: roomNumber,
      surface,
      type,
    };
  };

  const onSubmitForm = async (
    data: Partial<RealEstate> & AddPropertyFormValidated
  ) => {
    setLoadingForm(true);

    setFormData(data);
    try {
      await onCreate(data);
    } catch (e) {
      setLoadingForm(false);
      const message = (e as ErrorResponse)?.body?.message;
      if (message?.startsWith('Address not found in geocode provider')) {
        return { address: 'shared.location.invalid_address' };
      }
    }
    setLoadingForm(false);
  };

  const onCreate = async (data?: Partial<RealEstate>) => {
    const createData = data || formData;
    if (!isDuplicating) {
      await create(
        'real-estates',
        { data: handleTransform(createData) },
        { onError, onSuccess, returnPromise: true }
      );
      trackAddRealEstate();
    } else {
      await duplicate(handleTransform(createData) as DuplicatedBody, {
        onError,
        onSuccess: (response, _, __) =>
          onSuccess((response as { data: RealEstate }).data),
      });
      trackDuplicateRealEstate();
    }
  };

  return (
    <Create<RealEstate> record={transformForDuplication(record)}>
      <StyledForm<RealEstate>
        id="create-property-form"
        resolver={yupResolver(addPropertyForm)}
        onSubmit={onSubmitForm}
      >
        <AddRealEstateModalForm
          open={showFormModal}
          onClose={onCloseFormModal}
          loading={loadingForm}
        />
      </StyledForm>
    </Create>
  );
};
