import { Box, Typography } from '@mui/material';
import {
  AutocompleteInput,
  RaRecord,
  ReferenceInput,
  ReferenceInputProps,
  useInput,
  useRecordContext,
} from 'react-admin';

export const UserInput: React.FC<Omit<ReferenceInputProps, 'reference'>> = ({
  source,
  label,
  ...rest
}) => {
  const { field: organizationId } = useInput({ source: 'organizationId' });
  const optionText = <OptionRenderer />;
  const inputText = (option: RaRecord) =>
    `${option?.firstName} ${option?.lastName}`;

  return (
    <ReferenceInput
      source={source}
      reference="users"
      filter={{
        ...(organizationId ? { organizations: [organizationId.value] } : {}),
      }}
      sort={{ field: 'lastName', order: 'ASC' }}
      {...rest}
    >
      <AutocompleteInput
        label={label}
        variant="outlined"
        filterToQuery={(query: string) => ({ search: query })}
        source={source}
        optionText={optionText}
        inputText={inputText}
      />
    </ReferenceInput>
  );
};

const OptionRenderer = () => {
  const record = useRecordContext();
  return (
    <Box>
      {record?.firstName} {record?.lastName}
      <Typography variant="caption">{` - organization '${record.organization.name}'`}</Typography>
    </Box>
  );
};
