import React from 'react';

import {
  ArrayField,
  Button,
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  TextField,
  useRecordContext,
  useStore,
  useTranslate,
} from 'react-admin';
import { styled } from '@mui/material/styles';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import EditIcon from '@mui/icons-material/Edit';
import { Organization, Territory } from 'types/organizations.types';

import { TerritoryEditModalStore } from '../territory-modal/TerritoryEditModal';

export const Container = styled('div')`
  display: grid;
  width: 100%;
  gap: 24px;
  margin-top: 32px;
  margin-bottom: 24px;
`;
export const OrganizationTerritoriesTable: React.FC = () => {
  const translate = useTranslate();
  const record = useRecordContext<Organization>();
  const [, showAddModal] = useStore('territory.show_add_modal', false);
  const [, showEditModal] = useStore<TerritoryEditModalStore>(
    'territory.show_edit_modal',
    { isOpen: false }
  );

  if (record.isMasteosCore) {
    return null;
  }

  return (
    <Container>
      <ArrayField source="territories" sort={{ field: 'status', order: 'ASC' }}>
        <Datagrid bulkActionButtons={false}>
          <ChipField
            source="status"
            label="createOrganization.territories.statusLabel"
          />
          <TextField
            source="name"
            label="createOrganization.territories.territoryLabel"
          />
          <DateField
            source="effectiveDate"
            label="createOrganization.territories.effectiveDateLabel"
          />
          <DateField
            source="endDate"
            label="createOrganization.territories.endDateLabel"
          />
          <FunctionField
            render={(territory: Territory) => (
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                label={translate(
                  'createOrganization.territories.editButtonLabel'
                )}
                onClick={() => showEditModal({ isOpen: true, territory })}
              />
            )}
          />
        </Datagrid>
      </ArrayField>
      <Button
        sx={{ padding: '10px', width: '100%' }}
        startIcon={<ControlPointIcon />}
        variant="outlined"
        fullWidth
        onClick={() => showAddModal(true)}
        label={translate('createOrganization.territories.addTerritory')}
      />
    </Container>
  );
};
