import React from 'react';

import { Dpe } from 'types/realEstate.enum';

import { dpeColorMap } from './DpeRangeInputMark.utils';
import { DpeRangeInputMarkContainer } from './DpeRangeInputMark.styles';

type DpeRangeInputMarkProps = {
  dpeGrade: Dpe;
};

export const DpeRangeInputMark: React.FC<DpeRangeInputMarkProps> = ({
  dpeGrade,
}) => {
  return (
    <DpeRangeInputMarkContainer
      style={{
        backgroundColor: dpeColorMap.get(dpeGrade),
      }}
    >
      {dpeGrade}
    </DpeRangeInputMarkContainer>
  );
};
