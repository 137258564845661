import { useForm, useWatch } from 'react-hook-form';
import { useRealEstateTitle } from 'hooks/use-realEstate-title/useRealEstateTitle';
import { useRecordContext } from 'react-admin';

export const useGenerateTitle = (): string => {
  const record = useRecordContext();
  const { control } = useForm({
    defaultValues: {
      'address.city': record?.address.city,
      locationType: record?.locationType,
      surface: record?.surface,
      totalLots: record?.totalLots,
      type: record?.type,
    },
  });

  const [
    watchCity,
    watchLocationType,
    watchSurface,
    watchTotalLots,
    watchType,
  ] = useWatch({
    control,
    name: ['address.city', 'locationType', 'surface', 'totalLots', 'type'],
  });

  const city = watchCity || record?.address.city;
  const locationType = watchLocationType || record?.locationType;
  const surface = watchSurface || record?.surface;
  const totalLots = watchTotalLots || record?.totalLots;
  const type = watchType || record?.type;

  return useRealEstateTitle({
    city,
    locationType,
    surface,
    totalLots,
    type,
  });
};
