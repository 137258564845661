import { ErrorResponse } from 'types/common.types';

export const CUSTOMER_ALREADY_HAS_AN_ADVISOR =
  'CUSTOMER_ALREADY_HAS_AN_ADVISOR';

export const CUSTOMER_ALREADY_ASSIGNED = 'CUSTOMER_ALREADY_ASSIGNED';

export const EXECUTOR_CANNOT_BE_ASSIGNED_AS_ADVISOR =
  'EXECUTOR_CANNOT_BE_ASSIGNED_AS_ADVISOR';

export const formatAddCustomerError = (err?: ErrorResponse): string => {
  if (err?.body?.message === CUSTOMER_ALREADY_HAS_AN_ADVISOR) {
    return 'customer.create.hasAdvisorError';
  }

  if (err?.body?.message === CUSTOMER_ALREADY_ASSIGNED) {
    return 'customer.create.alreadyAssignedError';
  }

  if (err?.body?.message === EXECUTOR_CANNOT_BE_ASSIGNED_AS_ADVISOR) {
    return 'customer.create.userAlreadyExist';
  }
  return err?.body?.message || 'customer.create.error';
};
