import { Button } from '@mui/material';
import { useMemo } from 'react';
import { ShowBase, ShowContext } from 'react-admin';
import { useParams } from 'react-router-dom';

import { ContentContainer } from 'components/headerTabs/HeaderTabs.styles';
import { PropertyHeader } from '../components/property-header/PropertyHeader';
import { PropertyBreadCrumbs } from '../components/breadcrumbs/PropertyBreadCrumbs';
import { useGetRealEstateChangeEntries } from './useGetRealEstateChangeEntry';

import { formatChangeEntries, filterChangeEntries } from './history.utils';
import { RealEstateHistoryItem } from './EntryElement';

export const RealEstateHistory: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading, loadMore } = useGetRealEstateChangeEntries(id);

  const formattedData = useMemo(
    () => filterChangeEntries(formatChangeEntries(data)),
    [data]
  );

  return (
    <ContentContainer>
      <ShowBase>
        <ShowContext.Consumer>
          {({ record }) => {
            return (
              <PropertyHeader
                breadcrumbs={
                  <PropertyBreadCrumbs propertyId={record?.id.toString()} />
                }
              />
            );
          }}
        </ShowContext.Consumer>
      </ShowBase>

      <div>
        {formattedData.length < 1 && !loading && <div>No history to show</div>}

        {!loading ? (
          formattedData.map((entry, index) => (
            <RealEstateHistoryItem key={index} entry={entry} />
          ))
        ) : (
          <div>Loading ...</div>
        )}
      </div>
      {formattedData.length > 1 && !loading && (
        <div>
          <Button onClick={loadMore}>Load more</Button>
        </div>
      )}
    </ContentContainer>
  );
};
