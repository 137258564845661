import React from 'react';

import { Pagination } from '@mui/material';
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';

export interface DataGridPaginationProps {
  currentPage: number;
  onChangePage: (event: React.ChangeEvent<unknown>, nextPage: number) => void;
  totalRecords: number;
  perPage: number;
}

export const DataGridPagination: React.FC<DataGridPaginationProps> = (
  props
) => {
  const { currentPage, onChangePage, totalRecords, perPage } = props;
  return (
    <Pagination
      data-testid="data-grid-pagination"
      count={totalRecords ? Math.ceil(totalRecords / perPage) : 0}
      page={currentPage ?? 1}
      size="small"
      onChange={onChangePage}
      siblingCount={2}
    />
  );
};

export const ClientSidePagination: React.FC = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const count = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      data-testid="data-grid-pagination"
      count={count}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      size="small"
      siblingCount={2}
    />
  );
};
