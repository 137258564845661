import { useRecordContext } from 'react-admin';
import { CircularProgress } from '@mui/material';
import { Header } from 'ui/organisms/header/Header';
import { formatFullName } from 'utils/strings/strings.utils';
import { ExternalCustomerDetail } from 'types/external-customer.types';

import { ExternalCustomerHeaderProps } from './ExternalCustomerHeader.types';
import { ExternalCustomerHeaderTabs } from './tabs/ExternalCustomerHeaderTabs';

export const ExternalCustomerHeader: React.FC<ExternalCustomerHeaderProps> = ({
  actionButtons,
}) => {
  const record = useRecordContext<ExternalCustomerDetail>();

  if (!record) {
    return <CircularProgress />;
  }

  const title = formatFullName(record.firstName, record.lastName);

  return (
    <>
      <Header
        testId="external-customer-header"
        title={title}
        actionButtons={actionButtons}
      />
      <ExternalCustomerHeaderTabs externalCustomerId={record.id} />
    </>
  );
};
