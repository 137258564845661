import { PositioningStatus } from 'types/positioning.types';
import { get } from 'lodash';

import { STATUS_POSITIONING_COLORS_AND_LABELS } from './constants';

export const getPositionementStatusColor = (
  status: PositioningStatus
): string | null => {
  return get(STATUS_POSITIONING_COLORS_AND_LABELS[status], 'bgColor', null);
};
